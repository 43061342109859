import React, { useState, useEffect } from "react";
import "../../Style/EventPosting/EventPosting.css";
import FeaturesPosting from "../PostJob/FeaturesPosting";
import { eventPostings } from "../../Api/OrgApi";
import { Address } from "../../Api/OrgApi";
import { eventCategory } from "../../Api/OrgApi";
import { useNavigate } from "react-router-dom";
import FeatureModal from "./FeatureModal";
import moment from "moment";
import EventModal from "./EventModal";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from "react-bootstrap";
const data3 = {
  title: "Free or Featured Event Listing?",
  free: "Free Event Posting",
  features: "Featured Event Posting",
};
const EventsPosting = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const handleSuceesClose = () => setIsSuccess(false);
  const handleSuceesShow = () => {
    setIsSuccess(true);
  };
  const navigate = useNavigate();
  const [category, setCategory] = useState([]);
  const [address, setAddress] = useState([]);
  const [skill, setSkill] = useState([]);
  const [disable, setDisable] = useState(false);
  const [data, setData] = useState({
    name: "",
    description: "",
    start_date: "",
    fee: "",
    category: "",
    is_free: "",
    is_online: "",
    thumbnail_images: "",
    banner_image: "",
    end_date: "",
    location: address[0]?.id || "",
    is_featured_post: "",
    // email: "",
    website: "",
    // course_link: "",
  });

  const [thumbimg, setThumbImg] = useState({});
  const [thumbimg2, setThumbImg2] = useState();
  const [bannerimg, setBannerImg] = useState({});
  const [bannerimg2, setBannerImg2] = useState();
  const handleSubmit = (e) => {
    e.preventDefault();

    const date = moment(data.date).format("YYYY-MM-DD");
    const time = moment(data.time).format("hh:mm");
    const enddate = moment(data.enddate).format("YYYY-MM-DD");
    const endtime = moment(data.endtime).format("hh:mm");
    const start_date = `${date} ${data.time}`;

    const end_date = `${enddate} ${data.time}`;
    moment(data.end_date).format("YYYY-MM-DD");
    moment(data.end_time).format("hh:mm");
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append(" description", data.description);
    formData.append(" start_date", start_date);
    formData.append(" banner_image", bannerimg);
    formData.append(" thumbnail_images", thumbimg);
    formData.append(" is_free", data.is_free);
    formData.append(" is_online", data.is_online);
    formData.append(" is_featured_post", data.is_featured_post);
    formData.append(" end_date", end_date);
    formData.append(" location", data.location);
    formData.append(" category", data.category);
    formData.append(" website ", data.website);

    eventPostings(formData)
      .then((res) => {
        const response = res;

        localStorage.setItem("event_id", response?.data?.data?.id);
        if (response?.status === 200) {
          handleSuceesShow();
          toast("Event Add SuccesFull", {
            autoClose: 500,
            position: toast.POSITION.TOP_RIGHT,
          });
          setTimeout(() => {
            // window.location.href = "/orgdashboard";
          }, 1000);
        }
      })
      .catch((e) =>
        e.response.status === 400
          ? toast(" Bad Request", {
              autoClose: 500,
              position: toast.POSITION.TOP_RIGHT,
            })
          : ""
      );
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };

  const handleThumbImage = (e) => {
    const image = e.target.files[0];
    setThumbImg(image);
    setThumbImg2(URL.createObjectURL(e.target.files[0]));
  };
  const handleBannerImage = (e) => {
    const bimage = e.target.files[0];
    setBannerImg(bimage);
    setBannerImg2(URL.createObjectURL(e.target.files[0]));
  };
  useEffect(() => {
    const loadData = () => {
      Address().then((res) => {
        const response = res;
        setAddress(response.data);
      });
    };
    loadData();
  }, []);
  useEffect(() => {
    setData({ ...data, location: address[0]?.id });
  }, [address]);
  useEffect(() => {
    const loadData = () => {
      eventCategory().then((res) => {
        const response = res;

        const data1 = response;

        let temp = { ...data, category: data1[0]?.id + "" };

        setData(temp);
        setCategory(data1);
        // setData({ ...data, category: category[1]?.id });
      });
    };
    loadData();
  }, []);
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div>
          <h1 className="jobs-header">Event Posting</h1>
        </div>

        <div className="event-posting-input-main">
          <div className="event-posting-input-container">
            <div className="event-posting-input">
              <div>
                <h1 className="event-posting-input-title">Event Name/Title</h1>
              </div>
              <div className="event-just-check">
                <input
                  className="event-posting-input-box"
                  placeholder="Enter The Name and Title of event"
                  type="text"
                  height="40px"
                  name="name"
                  onChange={handleInput}
                  required
                />
              </div>
            </div>
            <div className="event-posting-input">
              <div>
                <h1 className="event-posting-input-title">Category</h1>
              </div>
              <div className="course-just-check">
                <select
                  className="event-posting-input-box"
                  name="category"
                  onChange={handleInput}
                >
                  {category.map((value) => {
                    return <option value={value?.id}>{value?.name}</option>;
                  })}
                </select>
              </div>
            </div>

            <div className="event-posting-input-start">
              <div>
                <h1 className="event-posting-input-title">Start Date & Time</h1>
              </div>
              <div className="event-just-check1">
                <input
                  className="event-posting-input-box-min"
                  placeholder="Min"
                  type="date"
                  name="date"
                  onChange={handleInput}
                  required
                />

                <input
                  className="event-posting-input-box-max"
                  placeholder="Max"
                  type="time"
                  name="time"
                  onChange={handleInput}
                  required
                />
              </div>
            </div>
            <div className="event-posting-input-start">
              <h1 className="event-posting-input-title">End Date & Time</h1>
              <div className="event-just-check1">
                <input
                  className="event-posting-input-box-min"
                  placeholder="Min"
                  type="date"
                  name="enddate"
                  onChange={handleInput}
                  required
                />

                <input
                  className="event-posting-input-box-max"
                  placeholder="Max"
                  type="time"
                  name="endtime"
                  onChange={handleInput}
                  required
                />
              </div>
            </div>
            <div className="event-posting-input">
              <h1 className="event-posting-input-title">Location</h1>

              <div className="event-just-check">
                {}
                <select
                  className="event-posting-input-box"
                  name="location"
                  onChange={handleInput}
                  readOnly={data?.is_online === "true" ? "false" : "true"}
                >
                  {address.map((value) => {
                    return (
                      <option value={value?.id}>{value?.city_name}</option>
                    );
                  })}
                </select>
                <input
                  className="container-checkbox1"
                  type="checkbox"
                  name="is_online"
                  onChange={handleInput}
                  // checked={data?.is_featured_post === "false"}
                  value={data?.is_online === "true" ? "false" : "true"}
                  checked={data?.is_online === "true" ? true : false}
                  // ref={isFree}
                />
                <span className="checkmark1"> Is Online</span>
              </div>
            </div>
            <div className="event-desp-box">
              <div className="event-posting-input-con">
                <h1 className="event-posting-input-title">Event Description</h1>
              </div>
              <div className="about-event-content-box">
                <textarea
                  className="e-about-company-desps"
                  name="description"
                  onChange={handleInput}
                  required
                />
              </div>
            </div>
            <div className="event-border"></div>
          </div>
        </div>
        <div className="eventImgContainer">
          <h1>Event Images</h1>

          <div className="courseUploadComponent">
            <div className="event-upload-main">
              <div className="event-thumbnailsContainer">
                <input
                  type="file"
                  name="thumbnail_image"
                  id="e-file-input"
                  onChange={handleThumbImage}
                  className="event-thumbnails"
                  placeholder="upload-thumbnails"
                  style={{ display: "none" }}
                />
                <div className="event-thumbnails">
                  {thumbimg2 === undefined ? (
                    ""
                  ) : (
                    <img
                      src={thumbimg2}
                      className="event-thumbnails-preview"
                      alt="thumbImage"
                    />
                  )}
                </div>

                <label for="e-file-input" className="event-post-upload">
                  <img
                    className="upload-icon"
                    src="Icons/upload.svg"
                    alt="edit"
                  />
                  <p>Upload</p>
                </label>
              </div>
              <div className="event-bannerContainer">
                <input
                  type="file"
                  name="banner_image"
                  id="eb-file-input"
                  onChange={handleBannerImage}
                  className="event-thumbnails"
                  placeholder="Upload Banner"
                  style={{ display: "none" }}
                />
                <div className="event-thumbnails">
                  {bannerimg2 === undefined ? (
                    ""
                  ) : (
                    <img
                      src={bannerimg2}
                      className="event-thumbnails-preview"
                      alt="thumbImage"
                    />
                  )}
                </div>

                <label for="eb-file-input" className="event-post-upload2">
                  <img
                    className="upload-icon"
                    src="Icons/upload.svg"
                    alt="edit"
                  />
                  <p>Upload</p>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="event-border"></div>
        <div className="Event-fee-mode" onChange={handleInput}>
          <div>
            <h1 className="Event-entry">Event Entry Type</h1>
          </div>
          <div>
            <input
              className="container-checkbox"
              type="checkbox"
              name="is_free"
              value={true}
              checked={data?.is_free === "true"}
            />
            <span className="checkmark">Free</span>
          </div>
          <div>
            <input
              className="container-checkbox"
              type="checkbox"
              name="is_free"
              value={false}
              checked={data?.is_free === "false"}
            />
            <span className="checkmark">Paid</span>
          </div>
        </div>
        <div className="link-web">
          <h2 className="Event-entry">Link to your ticketing system</h2>
          <input
            className="ticketing-system"
            type="text"
            placeholder="Enter the link to your website or external ticketing system."
            name="website"
            onChange={handleInput}
            required
          />
        </div>
        <div className="features-posting"></div>

        <button type="submit" className="publish-btn">
          Publish
        </button>
      </form>
      <Modal centered={true} show={isSuccess}>
        <FeatureModal closed={handleSuceesClose} />
      </Modal>
    </>
  );
};

export default EventsPosting;
