import React from "react";
import JobPosting from "../../Component/PostJob/JobPosting";
import { Helmet } from "react-helmet";
import OrgNav from "../../Component/Organisation/OrgNav";
const PostJob = () => {
  return (
    <div>
      <Helmet>
        <title>55notout | Post Job</title>
        <meta name="description" content="Post A New Job" />
      </Helmet>
      <OrgNav />
      <JobPosting />
    </div>
  );
};

export default PostJob;
