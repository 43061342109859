import React from "react";
import Personal from "../../Component/PersonalDetails/Personal";
import ProfesonalDetails from "../../Component/PersonalDetails/ProfesonalDetails";
import EducationaDetailsl from "../../Component/PersonalDetails/EducationaDetailsl";
const PersanolDetails = () => {
  return (
    <div>
      <Personal />
      <ProfesonalDetails />
      <EducationaDetailsl />
    </div>
  );
};

export default PersanolDetails;
