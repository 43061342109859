import React, { useState, useEffect } from "react";
import Carousel from "react-elastic-carousel";
import "../../Style/JobDetails/realatedCourse.css";
import { NavLink } from "react-router-dom";
import { ListCourse } from "../../Api/Course";
import Person from "../../Image/person.png";
const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 350, itemsToShow: 2, itemsToScroll: 2 },
  { width: 500, itemsToShow: 3 },
  { width: 1000, itemsToShow: 4 },
];
const RealatedCourse = () => {
  const myArrow = ({ onClick, isEdge }) => {
    return <button onClick={onClick} disabled={isEdge}></button>;
  };
  const [courseList, setCourseList] = useState([]);
  useEffect(() => {
    const loadData = () => {
      ListCourse().then((res) => {
        const response = res;

        setCourseList(response);
      });
    };
    loadData();
  }, []);
  return (
    <div>
      <h1 className="course-job">Related Course</h1>
      <div className="course-job-border"></div>

      <Carousel
        breakPoints={breakPoints}
        renderPagination={myArrow}
        // className="course-crousel"
        className="realted-crousel"
      >
        {/* <div className="course-listing-releted-job-container"> */}
        {courseList?.map((listValue) => {
          return (
            <div className="course-listing-job-main">
              <NavLink to={`/coursedetails/${listValue?.slug}`}>
                <img
                  className="course-listing-Job-Img"
                  src={listValue?.thumbnail_images}
                  alt="Find Jobs"
                />
                <h1 className="course-listing-Job-Title">{listValue?.name}</h1>
                <div className="realated-person">
                  <img src={Person} alt="person" />
                  <span className="course-Listing-name">
                    {listValue?.organization_details?.organization_name}
                  </span>
                </div>
                <h4 className="course-listing-rate"> {listValue?.fee}</h4>
              </NavLink>
            </div>
          );
        })}
        {/* </div> */}
      </Carousel>
    </div>
  );
};

export default RealatedCourse;
