import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import FeatureModal from "../../Component/Mentor/FeatureModal";
import { Modal } from "react-bootstrap";
const MentorQuestionaire = ({ saveData }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const handleSuceesClose = () => setIsSuccess(false);
  const handleSuceesShow = () => {
    setIsSuccess(true);
  };
  const [data, setData] = useState({});
  const handleSubmit = (e) => {
    e.preventDefault();
    saveData(data);
  };
  const handleInput = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="f-profile">
        <h1 className="Personal-detail">Apply as a Mentor</h1>
      </div>

      <div className="mentor-qulification-container">
        <label className="mentor-qulification">How did you find us?</label>
        <select
          className="ment-quli-details-box"
          name="find_us"
          onChange={handleInput}
        >
          <option value="google_search_engine">google_search_engine</option>
          <option value="google_ads">google_ads</option>
          <option value="linkedin_ads">linkedin_ads</option>
          <option value="linkedin_post">linkedin_post</option>
          <option value="facebook_ads">facebook_ads</option>
          <option value="facebook_post">facebook_post</option>
          <option value="twitter_post">twitter_post</option>
          <option value="instagram_post">instagram_post</option>
          <option value="blog_post">blog_post</option>
          <option value="email">email</option>
          <option value="work_of_mouth">work_of_mouth</option>
          <option value="news">news</option>
          <option value="other">other</option>
          <option value="" selected>
            Find Us
          </option>
        </select>
      </div>
      <div className="mentor-questionaire-container">
        <label className="mentor-qulification">
          Have you applied for or taken the role of mentor in the past?
        </label>
        <input
          className="ment-ques-details-box"
          name="area_of_expertise"
          onChange={handleInput}
          required
        />
      </div>
      <div className="mentor-questionaire-container">
        <label className="mentor-qulification">
          What is your area of expertise?
        </label>
        <input
          className="ment-ques-details-box"
          name="have_you_apply"
          onChange={handleInput}
          required
        />
      </div>
      <div className="mentor-questionaire-container">
        <label className="mentor-qulification">
          How many years of work experience do you have?
        </label>
        <input
          className="ment-ques-details-box"
          name="year_of_experince"
          onChange={handleInput}
          required
        />
      </div>
      <div className="mentor-questionaire-container">
        <label className="mentor-qulification">
          Which age group do you prefer working with?
        </label>
        <input
          className="ment-ques-details-box"
          name="prefer_age_group"
          onChange={handleInput}
          required
        />
      </div>
      <div className="mentor-questionaire-container">
        <label className="mentor-qulification">
          What are your expectations as a mentor?
        </label>
        <input
          className="ment-ques-details-box"
          name="expectations"
          onChange={handleInput}
          required
        />
      </div>
      {/* Features Posting */}
      <div className="e-features-posting">
        <div className="features-posting-container">
          <h1 className="features-posting-title">
            List yourself as Free or Featured Mentor?
          </h1>
        </div>
        <div className="features-check-box" onChange={handleInput}>
          <div className="free">
            <input
              className="f-check-box"
              type="checkbox"
              name="is_featured_post"
              value={"true"}
              checked={data?.is_featured_post === "true"}
            />
            <span className="f-checkBox">Free Mentor Listing</span>
          </div>
          <div className="feature">
            <input
              className="f-check-box"
              type="checkbox"
              name="is_featured_post"
              value={"false"}
              // checked={data?.is_featured_post === "false"}
              onClick={handleSuceesShow}
            />
            <span className="f-checkBox">Featured Mentor Listing</span>
          </div>
        </div>
      </div>
      <button type="submit" className="publish-btn" onClick={handleSuceesShow}>
        Become a Mentor
        <ToastContainer />
      </button>
    </form>
  );
};

export default MentorQuestionaire;
