import React, { useState, useEffect, useRef } from "react";
import { AuthTalentRegister } from "../../Api/api";
import { NavLink, useNavigate } from "react-router-dom";
import "../../Style/Login/Signin.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faXmark } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
// import "../../Style/Login/Signin.css";
const Signup = ({ setShowLoginModel, close, hello }) => {
  const navigate = useNavigate();
  const [error, setError] = useState({ open: false, msg: "" });
  const [captchValue, setCaptchValue] = useState(false);
  const fName = useRef();
  const lName = useRef();
  const email = useRef();
  const password = useRef();
  const cnfrmPassword = useRef();

  const submitData = (e) => {
    e.preventDefault();
    setError({ open: false, msg: "" });
    const firstName = fName.current.value;
    const LastName = lName.current.value;
    const signemail = email.current.value;
    const password1 = password.current.value;
    const password2 = cnfrmPassword.current.value;

    if (password1 !== password2) {
      setError({ open: true, msg: "password does not match" });
      return;
    } else if (password1.length < 8) {
      setError({ open: true, msg: "password must shoud be 8 digit" });
      return;
    }

    const data = {
      first_name: firstName,
      last_name: LastName,
      email: signemail,
      password: password1,
    };

    AuthTalentRegister(data)
      .then((res) => {
        const response = res;

        if (response?.status === 200) {
          // navigate(`/dashboard`);
          toast("SignUp SuccesFull", {
            autoClose: 4000,
            closeOnClick: false,
            closeButton: false,
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: false,
            className: "toaster",
          });
          // notify();

          setTimeout(() => {
            setShowLoginModel(true);
          }, 1000);
        }
        //localStorage.setItem("token", response.data.token);
      })
      .catch((e) =>
        toast(e.response.data.msg, {
          autoClose: 2000,
          closeOnClick: false,
          closeButton: false,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: false,
          className: "toaster",
        })
      );
  };
  function onChange(value) {
    if (value) {
      setCaptchValue(true);
    } else {
      setCaptchValue(false);
    }
  }

  return (
    <div className="Signin-display-page">
      <div className="Sign-Container">
        <NavLink to="/">
          <img className="Sign-page-header" src="Icons/logo.png" alt="logo" />
        </NavLink>

        <h1 className="Sign-in">Talent Sign Up</h1>
        <div id="warning" className="form"></div>

        <form onSubmit={submitData}>
          <div className="Name-box">
            <div className="Name-conatiner">
              <input
                className="first-Name"
                type="text"
                name="fName"
                placeholder="Enter First Name"
                ref={fName}
                required
              />

              <input
                className="Last-Name"
                type="text"
                name="lName"
                placeholder="Enter Last Name"
                ref={lName}
                required
              />
            </div>
            <div className="Sign-Input_box">
              <input
                className="Sign-email"
                type="email"
                placeholder="Enter your email address"
                ref={email}
                required
              />

              <input
                className="Sign-password"
                type="password"
                placeholder="Password"
                ref={password}
                required
              />

              <input
                className="Sign-cnfrm-password"
                type="password"
                placeholder="Confirm Password"
                ref={cnfrmPassword}
                required
              />
              {error.open && (
                <p className="error" style={{ color: "red" }}>
                  {error.msg}
                </p>
              )}
            </div>
          </div>
          <div className="reCaptcha">
            <ReCAPTCHA
              sitekey="6LcKk5IjAAAAAOILTl5EhutetFTwrAOxNjC0fBh-"
              onChange={onChange}
            />
          </div>
          {captchValue === true ? (
            <button type="submit" className="SignPage-btn">
              Sign Up
              <FontAwesomeIcon className="icon" icon={faAngleRight} />
              {window.innerWidth < "720" ? <ToastContainer /> : ""}
            </button>
          ) : (
            <button type="submit" className="SignPage-btn" disabled>
              Sign Up
              <FontAwesomeIcon className="icon" icon={faAngleRight} />
              {window.innerWidth < "720" ? <ToastContainer /> : ""}
            </button>
          )}
        </form>

        <div className="resgister-box">
          <h6 className="Alredy-register">Already Registered ?</h6>
          <h6 className="login-create-link" onClick={() => navigate(`/login`)}>
            Login To your Account
          </h6>
        </div>
      </div>
    </div>
  );
};

export default Signup;
