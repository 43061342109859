import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { FeaturesStep1, FeaturesStep2, FeaturesStep3 } from "../../Api/api";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { showPrice } from "../../Api/api";
import {
  offerData,
  postCreateOrder,
  createPayment,
} from "../../Api/PaymentGetway";
import { displayRazorpay } from "../../Utill/Utility";

const FeatureModal = ({ closed }) => {
  const navigate = useNavigate();
  const [offer, setOffer] = useState([]);
  const [price, setPrice] = useState([]);
  const [createOrder, setCreateOrder] = useState([]);
  const [payment, setPayment] = useState([]);
  useEffect(() => {
    const loadData = () => {
      showPrice().then((res) => {
        setPrice(res?.data);
      });
    };
    loadData();
  }, []);
  useEffect(() => {
    const loadData = () => {
      offerData().then((res) => {
        const response = res.data;
        setOffer(response);
      });
    };
    loadData();
  }, []);

  var api_1_data = {};
  var api_2_data = {};

  async function handleClick(id, ammount) {
    const data = {
      offer: id,
      ammount: ammount,
    };

    await postCreateOrder(data)
      .then((res) => {
        const response = res;

        api_1_data = response.data;
      })
      .catch((e) => console.log("e", e));

    const data1 = {
      amount: ammount,
      order_id: api_1_data.order_number,
      currency: "INR",
    };

    await createPayment(data1).then((resp) => {
      const response2 = resp.data;
      api_2_data = response2;

      displayRazorpay(api_2_data.data);
    });
  }

  return (
    <div>
      <h4 className="close" onClick={closed}>
        x
      </h4>
      <div className="my-4">
        <div className="card mb-2 mx-4">
          <div className="card-body">
            <h2 className="card-title text-center">
              Do You Want To Feature Your Posting ?
            </h2>
            <h5 className="text-center">
              <p className="featuresCost">
                Cost:{" "}
                <FontAwesomeIcon
                  className=""
                  icon={faIndianRupeeSign}
                  color="black"
                  style={{ width: "1rem", height: "1.5rem" }}
                />{" "}
              </p>
            </h5>

            <div className="feauters-condition">
              <div className="FeaturesPrice">
                <button
                  className="mentor-booking"
                  value={"false"}
                  onClick={() => navigate(`/orgdashboard`)}
                >
                  No
                </button>
              </div>
              <div className="FeaturesPrice">
                <button
                  className="mentor-booking"
                  onClick={() => handleClick(offer[0]?.id, offer[0]?.ammount)}
                >
                  Yes
                  <ToastContainer />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureModal;
