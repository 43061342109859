import React from "react";
import MentorBanner from "../../Component/MentorListing/MentorBanner";
import MentorFeatures from "../../Component/MentorListing/MentorFeatures";
import MentorListings from "../../Component/MentorListing/MentorListings";
import { Helmet } from "react-helmet";
import { Navbar } from "../../Component/Navbar";
import OrgNav from "../../Component/Organisation/OrgNav";
import AllListingBtn from "../../Component/AllListingBtn";
const MentorLists = () => {
  const data = {
    text: "View all Mentors",
    link: `/Mentors`,
  };
  return (
    <div>
      <Helmet>
        <title>55notout | Find A Mentor</title>
        <meta name="description" content="Find A Mentor" />
      </Helmet>
      {localStorage.getItem("route") === "ORG" ? <OrgNav /> : <Navbar />}

      <MentorBanner />
      <MentorFeatures />
      <MentorListings />
      <AllListingBtn data={data} />
    </div>
  );
};

export default MentorLists;
