import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { EductionPut, EductionDelete } from "../../Api/TalentsApi";
import { ToastContainer, toast } from "react-toastify";
const EditEducation = ({ data, closeModal }) => {
  const [edudata, setEduData] = useState({
    university_name: "",
    start_from: "",
    end_from: "",
    title: "",
    course: "",
    user: 0,
  });
  const updateEducationalData = (formData) => {
    EductionPut(formData).then((res) => {
      const response = res;
      if (response.status === 200) {
        // navigate(`/dashboard`);
        toast("Educational Detail Update suceessfull", {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 1000);
      }
    });
  };

  const deleteEducationalData = (formData) => {
    EductionDelete(formData)
      .then((res) => {
        const response = res;

        // navigate(`/dashboard`);
        toast("Educational Detail delete suceessfull", {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 1000);
      })
      .catch((e) =>
        toast(e.msg, {
          position: toast.POSITION.TOP_RIGHT,
        })
      );
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = { ...edudata };

    updateEducationalData(formData);
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    setEduData({ ...edudata, ...{ [name]: value } });
  };
  const deleteHandle = () => {
    const formData = edudata.id;

    deleteEducationalData(formData);
  };
  const cancel = () => {
    closeModal();
  };
  useEffect(() => {
    setEduData(data);
  }, [data]);

  return (
    <div>
      <div>
        <h6 className="cancelModel" onClick={cancel}>
          x
        </h6>
        <div className="f-profile">
          <h1 className="Personal-detail">Educational Details</h1>
          <NavLink to="/ProfesonalDetails">
            <div className="pro-details-w-arrow">
              <h4 className="Pro-details">DashBoard</h4>
              <img
                className="side-arrow"
                src="Images/SideArrow.svg"
                alt="sideArrow"
              />
            </div>
          </NavLink>
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <div className="edu-details-Box">
              <h2 className="profile-pic-details-name">Institute</h2>
              <input
                value={edudata?.university_name}
                className="educational-details-box"
                type="text"
                name="university_name"
                placeholder="Type here"
                onChange={handleInput}
                required
              />
            </div>
            <div className="edu-details-Box">
              <h2 className="profile-pic-details-name">Batch</h2>
              <input
                className="educational-details-box1"
                type="date"
                name="start_from"
                value={edudata?.start_from}
                onChange={handleInput}
                required
              />

              <h2 className="to">To</h2>
              <input
                className="educational-details-box2"
                value={edudata?.end_from}
                type="date"
                name="end_from"
                onChange={handleInput}
                required
              />
            </div>
            <div className="edu-details-Box">
              <h2 className="profile-pic-details-name">Course Type</h2>

              <select
                className="educational-details-box"
                name="course"
                onChange={handleInput}
                selected="Full_time"
                value={edudata?.course}
              >
                <option className="optionValue" value="">
                  Select Course Type
                </option>
                <option className="optionValue" value="Full_time">
                  Full Time
                </option>
                <option className="optionValue" value="part_time">
                  Part Time
                </option>
                <option className="optionValue" value="Distance">
                  Distance Learning Program
                </option>
                <option className="optionValue" value="Executive_Program">
                  Executive Program
                </option>
                <option className="optionValue" value="Certification">
                  Certification
                </option>
              </select>
            </div>
            <div className="edu-details-Box">
              <h2 className="profile-pic-details-name">Degree</h2>
              <input
                className="educational-details-box"
                type="text"
                name="title"
                value={edudata?.title}
                onChange={handleInput}
                required
              />
            </div>
            <div className="educational-btn">
              <h4 onClick={deleteHandle}>Delete</h4>
              <ToastContainer />
            </div>
            <button type="submit" className="save-persnol-details">
              Save & Continue
              <ToastContainer />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditEducation;
