import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../../Style/Events/FeaturedEvent.css";
import moment from "moment";
import ShowMoreText from "react-show-more-text";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "../../Component/FullTime-jobs/Pagination";
const FeaturesEventsProfile = ({ featured }) => {
  const navigate = useNavigate();
  const popUp = () => {
    toast("Please Login To Continue", {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const featurePopup = () => {
    toast("you are not Feature Account", {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const [showPerPage, setShowPerPage] = useState(3);
  const [paginat, setPaginat] = useState({
    start: 0,
    end: showPerPage,
  });
  const PaginationHandle = (start, end) => {
    setPaginat({ start: start, end: end });
  };
  return (
    <div>
      <div className="Featured-Job">
        <h4 className="Jobs">Featured Events</h4>
      </div>
      <div className="Apply-job-Container">
        {featured.slice(paginat?.start, paginat?.end)?.map((value) => {
          return (
            <div className="find-job-main">
              <img
                className="Find-Job-Img"
                src={value?.thumbnail_images}
                alt="Find Jobs"
              />

              <h2 className="Job-Title">{value?.name}</h2>
              <div className="Find-Job-Time">
                <div className="timer">
                  <img src="Icons/timer.jpg" alt="timer" />
                  <span className="Publish-time">
                    {moment(value?.start_date).format("ll")}
                  </span>
                </div>

                <img
                  className="Icon-time"
                  src="Icons/Motivation.svg"
                  alt="timer"
                />
                <span className="Publish-year"> {value?.category}</span>
              </div>
              <div className="loaction">
                <div className="location-icon">
                  <img src="Icons/location.png" alt="timer" />
                  <span className="Location-name">
                    {value?.location_details?.city_name}
                  </span>
                </div>
              </div>

              {localStorage.getItem("TalentToken") ? (
                <ShowMoreText
                 
                  lines={3}
                  more="Show more"
                  less="Show less"
                  className="Job-Desp"
                  anchorclassName="my-anchor-css-class"
                  onClick={() => navigate(`/eventsdetails/${value?.slug}`)}
                  expanded={false}
                  expandByClick={false}
                  width={0}
                  truncatedEndingComponent={"... "}
                >
                  {value?.description}
                </ShowMoreText>
              ) : (
                <ShowMoreText
                 
                  lines={3}
                  more="Show more"
                  less="Show less"
                  className="Job-Desp"
                  anchorclassName="my-anchor-css-class"
                  onClick={() =>
                    localStorage.getItem("TalentToken")
                      ? navigate(`/eventsdetails/${value?.slug}`)
                      : popUp()
                  }
                  // onClick={() => navigate(`/eventsdetails/${value?.slug}`)}
                  expanded={false}
                  expandByClick={false}
                  width={0}
                  truncatedEndingComponent={"... "}
                >
                  {value?.description}
                </ShowMoreText>
              )}

              {localStorage.getItem("TalentToken") ? (
                <NavLink
                  to={{
                    pathname: `/eventsdetails/${value?.slug}`,
                    state: { value },
                  }}
                >
                  <button className="Apply">Details</button>
                </NavLink>
              ) : (
                <button
                  className="Apply"
                  onClick={
                    localStorage.getItem("TalentToken") ? featurePopup : popUp
                  }
                >
                  Details
                  <ToastContainer />
                </button>
              )}
            </div>
          );
        })}
      </div>
      {featured.length >= 4 ? (
        <Pagination
          showPerPage={showPerPage}
          PaginationHandle={PaginationHandle}
          total={featured?.length}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default FeaturesEventsProfile;
