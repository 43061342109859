import React, { useState, useEffect } from "react";
import Carousel from "react-elastic-carousel";
import "../../Style/JobDetails/realatedJob.css";
import { NavLink } from "react-router-dom";
// import styled from "styled-components";
// import Listing from "../FullTime-jobs/Listing";
import Info from "../../Image/info.png";
import Location from "../../Image/location.png";
import Person from "../../Image/person.png";
import { ListEvent } from "../../Api/EventApi";
import moment from "moment";
const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 250, itemsToShow: 2, itemsToScroll: 2 },
  { width: 500, itemsToShow: 3 },
  { width: 1000, itemsToShow: 4 },
];

const RealatedEvents = () => {
  const myArrow = ({ onClick, isEdge }) => {
    return <button onClick={onClick} disabled={isEdge}></button>;
  };
  const [list, setList] = useState([]);
  useEffect(() => {
    const loadData = () => {
      ListEvent().then((res) => {
        const response = res;
        setList(response);
      });
    };
    loadData();
  }, []);
  return (
    <div>
      <h1 className="realated-job">Related Events</h1>
      <div className="realted-job-border"></div>

      <Carousel
        breakPoints={breakPoints}
        renderPagination={myArrow}
        className="realted-crousel"
      >
        {list?.map((value) => {
          return (
            <div className="realted-listing-job-main">
              <NavLink to={`/eventsdetails/${value?.slug}`}>
                <img
                  className="realted-listing-Job-Img"
                  src={value?.thumbnail_images}
                  alt="Find Jobs"
                />
                <h1 className="realted-listing-Job-Title">{value?.name}</h1>
                <div className="realated-location">
                  <img src="Icons/Motivation.svg" alt="info" />
                  <span className="Events-Listing-field">
                    {value?.category}
                  </span>
                </div>
                <div className="realated-location">
                  <img src={Location} alt="info" />
                  <span className="realted-listing-place">
                    {value?.location_details.city_name}
                  </span>
                </div>
                <div className="realated-person">
                  <img src="Icons/clander.svg" alt="info" />
                  <span className="realted-listing-year">
                    {moment(value?.start_date).format("ll")}
                  </span>
                </div>
              </NavLink>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default RealatedEvents;
