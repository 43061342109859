import React from "react";

const Listed = () => {
  return (
    <div>
      <h4 className="listsP">Listed Profiles</h4>
      <h1 className="org-ListHead">
        Sign up to check out highly curated profiles
      </h1>
    </div>
  );
};

export default Listed;
