import React from "react";

const OrgOppertunity = ({ data }) => {
  return (
    <div>
      <div className="TalentMain">
        <div className="talent">
          <h1 className="TalentHead">{data?.title}</h1>
          <p className="TalentPara">{data?.content}</p>
          <button
            className="SignUp-btn"
            onClick={() => window.open("/orgSignUp")}
          >
            {data?.button_text}
          </button>
        </div>
        <div className="banner-img">
          <img className="Main-Img" src={data?.image} alt="talent" />
        </div>
      </div>
    </div>
  );
};

export default OrgOppertunity;
