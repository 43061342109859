import React from "react";

const TrustedMentees = () => {
  return (
    <div className="trustedMentess_container">
      <div className="trustedMentessMain">
        <h1 className="trustedMentessTitle">
          Trusted by mentees all around the world
        </h1>
        <p className="trustedMentessParagrph">
          55notout attracts driven, highly ambitious mentees that are looking to
          grow in their careers, businesses and learnings.
        </p>
      </div>
      <div className="trustedMentess_Achivments">
        <div className="trustedMentessDetails">
          <h1 className="trustedMentessNumber">97%</h1>
          <h6 className="trustedMentessName">Satisfaction</h6>
        </div>
        <div className="trustedMentessDetails">
          <h1 className="trustedMentessNumber">12,000+</h1>
          <h6 className="trustedMentessName">Mentees</h6>
        </div>
        <div className="trustedMentessDetails">
          <h1 className="trustedMentessNumber">~$1M</h1>
          <h6 className="trustedMentessName">Paid to mentors</h6>
        </div>
      </div>
    </div>
  );
};

export default TrustedMentees;
