import React, { useState, useEffect } from "react";
import OpportunityBox from "../../Component/TalentFile/OpportunityBox";
import FeatureService from "../../Component/Service/FeatureService";
import SpecialService from "../../Component/Service/SpecialService";
import Pagination from "../../Component/FullTime-jobs/Pagination";
import { Navbar } from "../../Component/Navbar";
import {
  ServiceBanner,
  FeatureServiceDetails,
  SpecialServices,
} from "../../Api/ServiceApi.js";
import { Helmet } from "react-helmet";
import AllListingBtn from "../../Component/AllListingBtn";
const Service = () => {
  const [feature, setFeature] = useState([]);
  const [serviceBanner, setServiceBanner] = useState({});
  useEffect(() => {
    const loadData = () => {
      ServiceBanner().then((res) => {
        const response = res;
        setServiceBanner(response.data);
      });
    };
    loadData();
  }, {});
  useEffect(() => {
    const loadData = () => {
      FeatureServiceDetails().then((res) => {
        const response = res;

        setFeature(response);
      });
    };
    loadData();
  }, []);
  const [list, setList] = useState([]);
  useEffect(() => {
    const loadData = () => {
      SpecialServices().then((res) => {
        const response = res;

        setList(response);
      });
    };
    loadData();
  }, []);
  // const getValue = () => {

  // };

  const fService = {
    Head: "Featured Service",
    title: "Featured Service",
  };
  const services = {
    head: "All Services",
    title: "Get into the world of services",
  };
  const [showPerPage, setShowPerPage] = useState(12);
  const [paginat, setPaginat] = useState({
    start: 0,
    end: showPerPage,
  });
  const PaginationHandle = (start, end) => {
    setPaginat({ start: start, end: end });
  };
  const data = {
    text: "View all Services",
    link: `/Services`,
  };
  return (
    <div>
      <Helmet>
        <title>55notout | Service</title>
        <meta name="description" content="Our Services" />
      </Helmet>
      <Navbar />
      <OpportunityBox data={serviceBanner} dataBtn={data} />
      <FeatureService
        dataService={feature}
        fService={fService}
        navigateTo={"/servicedetails/"}
      />
      <SpecialService
        specialService={list}
        services={services}
        navigateTo={"/servicedetails/"}
        paginat={paginat}
      />
      {list.length >= 13 ? (
        <Pagination
          showPerPage={showPerPage}
          PaginationHandle={PaginationHandle}
          total={list?.length}
        />
      ) : (
        ""
      )}
      <AllListingBtn data={data} />
    </div>
  );
};

export default Service;
