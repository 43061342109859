import React, { useState } from "react";
import "../../Style/FullTimeJobs/FeaturedProfile.css";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ShowMoreText from "react-show-more-text";
import { Carousel } from "react-responsive-carousel";
import Pagination from "../../Component/FullTime-jobs/Pagination";
const FeaturedJobsProfile = ({ featured }) => {
  const navigate = useNavigate();
  const popUp = () => {
    toast("Please Login To Continue", {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const featurePopup = () => {
    toast("you are not Feature Account", {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const [showPerPage, setShowPerPage] = useState(3);
  const [paginat, setPaginat] = useState({
    start: 0,
    end: showPerPage,
  });
  const PaginationHandle = (start, end) => {
    setPaginat({ start: start, end: end });
  };

  return (
    <>
      <div>
        <div className="Featured-Job">
          <h4 className="Jobs">Featured FullTime Job</h4>
        </div>

        <div className="Apply-job-Container">
          {featured?.slice(paginat?.start, paginat?.end)?.map((value, key) => {
            return (
              <div className="find-job-main">
                <img
                  className="Find-Job-Img"
                  src={value?.image}
                  alt="Find Jobs"
                />
                <h2 className="Job-Title">{value?.job_title}</h2>
                <div className="Find-Job-Time">
                  <div className="timer">
                    <img src="Icons/timer.jpg" alt="timer" />
                    <span className="Publish-time">{value?.apply_before}</span>
                  </div>

                  <img
                    className="Icon-time"
                    src="Icons/person.png"
                    alt="timer"
                  />
                  <span className="Publish-year"> {value?.max_exp}</span>
                </div>
                <div className="loaction">
                  <div className="location-icon">
                    <img src="Icons/location.png" alt="timer" />
                    <span className="Location-name">
                      {value?.location_details?.city_name}
                    </span>
                  </div>

                  <img
                    className="Job-feild-icon"
                    src="Icons/info.png"
                    alt="timer"
                  />
                  <span className="Publish-year">
                    {value?.job_type === "full_time"
                      ? "Full Time"
                      : value?.job_type === "consulting"
                      ? "Consulting"
                      : value?.job_type === "volunteering"
                      ? "Volunteering"
                      : ""}
                  </span>
                </div>

                {localStorage.getItem("TalentToken") ? (
                  <ShowMoreText
                    lines={3}
                    more="Show more"
                    less="Show less"
                    // className="content-css"
                    className="Job-Desp"
                    anchorclassName="my-anchor-css-class"
                    onClick={
                      () =>
                        navigate(`/jobsdetails/${value?.slug}`, {
                          state: value,
                        })
                      // : popUp()
                    }
                    expanded={false}
                    width={0}
                    truncatedEndingComponent={"..."}
                  >
                    {value?.description}
                  </ShowMoreText>
                ) : (
                  <ShowMoreText
                    lines={3}
                    more="Show more"
                    less="Show less"
                    className="Job-Desp"
                    anchorclassName="my-anchor-css-class"
                    onClick={() =>
                      localStorage.getItem("TalentToken")
                        ? navigate(`/jobsdetails/${value?.slug}`)
                        : popUp()
                    }
                    // onClick={() => navigate(`/eventsdetails/${value?.slug}`)}
                    expanded={false}
                    expandByClick={false}
                    width={0}
                    truncatedEndingComponent={"... "}
                  >
                    {value?.description}
                  </ShowMoreText>
                )}

                {localStorage.getItem("TalentToken") ? (
                  <NavLink
                    to={{
                      pathname: `/jobsdetails/${value?.slug}`,
                      state: { value },
                    }}
                  >
                    <button className="Apply">Apply Now</button>
                  </NavLink>
                ) : (
                  <button
                    className="Apply"
                    onClick={
                      localStorage.getItem("TalentToken") ? featurePopup : popUp
                    }
                  >
                    Apply Now
                    <ToastContainer />
                  </button>
                )}
              </div>
            );
          })}
        </div>
        {featured.length >= 4 ? (
          <Pagination
            showPerPage={showPerPage}
            PaginationHandle={PaginationHandle}
            total={featured?.length}
          />
        ) : (
          ""
        )}

        <Carousel className="mob-response"></Carousel>
        <div className="All-listing-Job">
          <h4 className="lists">All Listing</h4>
        </div>
      </div>
    </>
  );
};

export default FeaturedJobsProfile;
