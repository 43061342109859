import React from "react";

const CallSlotModal = ({ close }) => {
  return (
    <div>
      <h1 className="close" onClick={close}>
        X
      </h1>
      <div className="Call-Modal">
        <h1> Introductory Call is a Free Slot It's Only For 15 Mins</h1>
      </div>
    </div>
  );
};

export default CallSlotModal;
