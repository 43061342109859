import axios from "axios";
const baseUrl = "https://admin.55notout.com/";
const TalentProfile = () => {
  const slug = `talents/talent_listing/`;
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const listTalentProfile = () => {
  const slug = `talents/talent_listing/?is_featured=true`;
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const getTalentPublicsDetails = ({ slug }) => {
  const urlPath = `talents/talent_profile_public/`;
  const url = `${baseUrl}${urlPath}${slug}`;

  try {
    const response = axios
      .get(url, {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
        // },
      })
      .then((response) => {
        return response.data;
      });
    return response;
  } catch (error) {
    return error;
  }
};
const skillNames = () => {
  const slug = "miscellaneous/get_all_skills_names/";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const Education = () => {
  const urlPath = "talents/get_create_education/";
  const url = `${baseUrl}${urlPath}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
    },
  };

  try {
    const response = axios.get(url, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const EductionPost = (formData) => {
  const urlPath = "talents/get_create_education/";
  const url = `${baseUrl}${urlPath}`;

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
    },
  };

  try {
    const response = axios.post(url, formData, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const EductionPut = (formData) => {
  const urlPath = "talents/get_update_delete_education/";
  const url = `${baseUrl}${urlPath}`;

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
    },
  };

  try {
    const response = axios.put(url, formData, config).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const EductionDelete = (formData) => {
  const urlPath = `talents/get_update_delete_education/`;
  const url = `${baseUrl}${urlPath}`;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
    },
    data: { id: formData },
  };

  try {
    const response = axios.delete(url, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const createJob = () => {
  const urlPath = "talents/get_create_jobs/";
  const url = `${baseUrl}${urlPath}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
    },
  };

  try {
    const response = axios.get(url, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const createJobPost = (formData) => {
  const urlPath = "talents/get_create_jobs/ ";
  const url = `${baseUrl}${urlPath}`;

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
    },
  };
  if (formData.end_from === "") {
    delete formData.end_from;
  }
  try {
    const response = axios.post(url, formData, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const ProfessionPut = (formData) => {
  const urlPath = "talents/get_update_delete_jobs/";
  const url = `${baseUrl}${urlPath}`;

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
    },
  };

  try {
    const response = axios.put(url, formData, config).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const ProfessionDelete = (formData) => {
  const urlPath = `talents/get_update_delete_jobs/`;
  const url = `${baseUrl}${urlPath}`;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
    },
    data: { id: formData },
  };

  try {
    const response = axios.delete(url, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const skills = () => {
  const urlPath = "talents/get_create_skills/";
  const url = `${baseUrl}${urlPath}`;
  try {
    const response = axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
        },
      })
      .then((response) => {
        return response.data;
      });
    return response;
  } catch (error) {
    return error;
  }
};
const skillsPost = (formData) => {
  const urlPath = "talents/get_create_skills/";
  const url = `${baseUrl}${urlPath}`;

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
    },
  };

  try {
    const response = axios.post(url, formData, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const jobHistory = () => {
  const urlPath = "talents/get_talent_job_history/";
  const url = `${baseUrl}${urlPath}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
    },
  };

  try {
    const response = axios.get(url, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const persnol = () => {
  const urlPath = "talents/talent_profile/";
  const url = `${baseUrl}${urlPath}`;

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
    },
  };

  try {
    const response = axios.get(url, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const persnolPut = (file, IsImage) => {
  const urlPath = "talents/talent_profile/";
  const url = `${baseUrl}${urlPath}`;

  const config = {
    headers: {
      "content-type": IsImage ? "multipart/form-data" : "application/json",
      Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
    },
  };

  try {
    const response = axios.put(url, file, config).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};

export {
  TalentProfile,
  listTalentProfile,
  Education,
  EductionPost,
  EductionPut,
  EductionDelete,
  createJob,
  createJobPost,
  ProfessionPut,
  ProfessionDelete,
  skills,
  skillsPost,
  jobHistory,
  persnol,
  persnolPut,
  skillNames,
  getTalentPublicsDetails,
};
