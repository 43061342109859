import React, { useState, useEffect } from "react";
import "../../Style/MentorAvailbility/MentorAvailbilities.css";
import MentorListings from "../MentorListing/MentorListings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { deleteReview } from "../../Api/Mentor";
import { ToastContainer, toast } from "react-toastify";
import { getMentorsDetails, getReview } from "../../Api/Mentor";
const carouselProp = {
  showStatus: false,
  useKeyboardArrows: true,
  infiniteLoop: true,
  autoPlay: false,
  stopOnHover: true,
  emulateTouch: true,
  transitionTime: 400,
  showArrows: true,
  renderArrowPrev: (clickHandler, hasPrev, label) => {
    return (
      <span className="arrow-left" onClick={clickHandler}>
        <span className="icon-keyboard_arrow_left">
          <FontAwesomeIcon icon={faAngleLeft} color="red" />
        </span>
      </span>
    );
  },
  renderArrowNext: (clickHandler, hasNext, label) => {
    return (
      <span className="arrow-right" onClick={clickHandler}>
        <span className="icon-keyboard_arrow_right">
          <FontAwesomeIcon icon={faAngleRight} color="red" />
        </span>
      </span>
    );
  },
};
// const MentorStudent = ({ review, data, slug }) => {
const MentorStudent = ({ data, slug }) => {
  const [show, setShow] = useState(false);
  const [isRefesh, setIsRefresh] = useState(false);
  const [review, setReview] = useState([]);

  useEffect(() => {
    const loadData = () => {
      getReview({ slug }).then((res) => {
        const response = res;

        setReview(response.data);
      });
    };
    loadData();
  }, [slug, isRefesh]);

  const deleteData = (formData) => {
    deleteReview(formData)
      .then((res) => {
        const response = res;
        toast("Review Delete Succesfull", {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsRefresh(!isRefesh);
      })
      .catch((e) =>
        toast(e?.response?.data?.msg, {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        })
      );
  };
  const deleteHandle = (value) => {
    const formData = value.id;

    deleteData(formData);
    setShow(false);
  };
  return (
    <>
      <h1 className="student-say">What Mentees say</h1>
      <Carousel {...carouselProp}>
        <div className="student-say-main-container">
          {review?.map((value) => {
            return (
              <div className="student-container">
                <div className="sudent-imageContainer">
                  <img
                    className="student-list-mentor-image"
                    src={value?.image}
                    alt="mentor-pic"
                  />
                </div>
                <div>
                  <p className="student-para">{value?.review}</p>
                  <div className="student-name-date">
                    <h2 className="student-name">
                      {value?.talent_details?.name}
                    </h2>
                    <h2 className="book-slot">
                      {moment(value?.created_at).format("DD/MM/YYYY")}
                    </h2>
                  </div>
                </div>
                <FontAwesomeIcon
                  className="cross-circle-xIcon"
                  icon={faCircleXmark}
                  color="red"
                  style={{
                    textAlign: "left",
                    float: "right",
                  }}
                  onClick={() => {
                    setShow(true);
                  }}
                />
                <Modal show={show} centered={true}>
                  <Modal.Header>
                    <FontAwesomeIcon
                      className="cross-circle-xIcon"
                      icon={faCircleXmark}
                      color="red"
                      onClick={() => {
                        setShow(false);
                      }}
                    />
                  </Modal.Header>
                  <Modal.Body>Do you want to delete</Modal.Body>
                  <Modal.Footer
                    onClick={() => {
                      deleteHandle(value);
                    }}
                  >
                    Delete
                  </Modal.Footer>
                </Modal>
              </div>
            );
          })}
        </div>
      </Carousel>

      <div className="coreSkill-container">
        <div>
          <h1 className="core-skill">Core Skills</h1>
        </div>
        <div className="skill-container">
          {data?.key_skills_names?.map((value) => {
            return <h1 className="core-skill-details">{value?.name}</h1>;
          })}
        </div>
      </div>

      <div>
        <h1 className="similar-mentors">Similar Mentors</h1>
        <MentorListings />
      </div>
    </>
  );
};

export default MentorStudent;
