import React from "react";
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
const Listing = ({ listJob }) => {
  const popUp = () => {
    toast("Please Login To Continue", {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });

    <ToastContainer />;
  };

  return (
    <div>
      <div className="ListingTotalJobs">
        {listJob?.length}
        <span
          style={{
            fontWeight: "600",
          }}
        >
          {listJob?.length === 1 ? "  Job" : "  Jobs"}
        </span>
      </div>
      <div className="filter-listing-container">
        {listJob?.map((job) => {
          return (
            <div className="filter-listing-job-main">
              {localStorage.getItem("TalentToken") ? (
                <NavLink
                  to={{
                    pathname: `/jobsdetails/${job?.slug}`,
                    state: { job },
                  }}
                >
                  <img
                    className="listing-Job-Img"
                    src={job?.image}
                    alt="Find Jobs"
                  />
                  <h1 className="listing-Job-Title">{job?.job_title}</h1>
                  <div className="info-field">
                    <img src="Icons/info.png" alt="info" />
                    <span className="Listing-field">
                      {job?.job_type === "full_time"
                        ? "Full Time"
                        : job?.job_type === "consulting"
                        ? "Consulting"
                        : job?.job_type === "volunteering"
                        ? "Volunteering"
                        : ""}
                    </span>
                  </div>
                  <div className="place-field">
                    <img src="Icons/location.png" alt="info" />
                    <span className="listing-place">
                      {job?.location_details?.city_name}
                    </span>
                  </div>
                  <div className="year-field">
                    <img src="Icons/person.png" alt="info" />
                    <span className="listing-year"> {job?.max_exp}</span>
                  </div>
                </NavLink>
              ) : (
                <NavLink to="" onClick={popUp}>
                  <img
                    className="listing-Job-Img"
                    src={job?.image}
                    alt="Find Jobs"
                  />
                  <h1 className="listing-Job-Title">{job?.job_title}</h1>
                  <div className="info-field">
                    <img src="Icons/info.png" alt="info" />
                    <span className="Listing-field">
                      {job?.job_type === "full_time"
                        ? "Full Time"
                        : job?.job_type === "consulting"
                        ? "Consulting"
                        : job?.job_type === "volunteering"
                        ? "Volunteering"
                        : ""}
                    </span>
                  </div>
                  <div className="place-field">
                    <img src="Icons/location.png" alt="info" />
                    <span className="listing-place">
                      {job?.location_details?.city_name}
                    </span>
                  </div>
                  <div className="year-field">
                    <img src="Icons/person.png" alt="info" />
                    <span className="listing-year"> {job?.max_exp}</span>
                  </div>
                </NavLink>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Listing;
