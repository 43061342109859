import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import DetailsNavbar from "./DetailsNavbar";
import JobsDetails from "./JobsDetails";
import EventsDetails from "./EventsDetails";

import CoursePageDetails from "./CoursePageDetails";
import ProductDetails from "./ProductDetails";
import ServiceDetails from "./ServiceDetails";
import {
  jobGet,
  eventGet,
  courseGet,
  productGet,
  serviceGet,
} from "../../Api/OrgApi";

import "../../Style/OrgDashboard/OrgDashboardDetils.css";
const dataPost = {
  title: "Course Posting",
  titleHead: "Course Heading/Title",
  desp: "Course Description",
  catgory: "Delivery Mode",
  price: "Course Price (INR)",
  phone: "Phone Number",
  email: "Email",
  web: "Website",
  link: "Link To Your Course",
};
const dataProduct = {
  title: "Product Posting",
  titleHead: "Product Heading/Title",
  desp: "Product Description",
  catgory: "Delivery Mode",
  price: "Product Price (INR)",
  phone: "Phone Number",
  email: "Email",
  web: "Website",
  link: "Link To Your Product",
};
const dataService = {
  title: "Service Posting",
  titleHead: "Service Heading/Title",
  desp: "Service Description",
  catgory: "Delivery Mode",
  price: "Service Price (INR)",
  phone: "Phone Number",
  email: "Email",
  web: "Website",
  link: "Link To Your Service",
};

const DashBoardDetails = () => {
  const [selected, setSelected] = useState("");
  const [jobData, setJobData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  useEffect(() => {
    const loadData = () => {
      jobGet().then((res) => {
        const response = res;

        setJobData(response.results);
        setSelected("JOB");
      });
    };
    loadData();
  }, []);
  useEffect(() => {
    const loadData = () => {
      eventGet().then((res) => {
        const response = res;

        setEventData(response.results);
        // setSelected("JOB");
      });
    };
    loadData();
  }, []);
  useEffect(() => {
    const loadData = () => {
      courseGet().then((res) => {
        const response = res;

        setCourseData(response.results);
        // setSelected("JOB");
      });
    };
    loadData();
  }, []);
  useEffect(() => {
    const loadData = () => {
      productGet().then((res) => {
        const response = res;

        setProductData(response.results);
        // setSelected("JOB");
      });
    };
    loadData();
  }, []);
  useEffect(() => {
    const loadData = () => {
      serviceGet().then((res) => {
        const response = res;

        setServiceData(response.results);
        // setSelected("JOB");
      });
    };
    loadData();
  }, []);

  // const [course, setCourse] = useState([]);
  const handleChange = (key) => {
    setSelected(key);
    switch (key) {
      case "JOB":
        setJobData(jobData);
        break;
      case "EVENT":
        setEventData(eventData);
        break;
      case "COURSE":
        setCourseData(courseData);
        break;

      case "PRODUCT":
        setProductData(productData);
        break;
      case "SERVICE":
        setServiceData(serviceData);
        break;
      default:
        break;
    }
  };

  const getComponent = () => {
    switch (selected) {
      case "JOB":
        return jobData.map((data, key) => <JobsDetails data={data} />);

      case "EVENT":
        return eventData.map((data, key) => <EventsDetails data={data} />);
      case "COURSE":
        return courseData.map((data, key) => (
          <CoursePageDetails data={data} dataPost={dataPost} />
        ));
      case "PRODUCT":
        return productData.map((data, key) => (
          <ProductDetails data={data} dataPost={dataProduct} />
        ));
      case "SERVICE":
        return serviceData.map((data, key) => (
          <ServiceDetails data={data} dataPost={dataService} />
        ));
      default:
        return <h1>No records found</h1>;
    }
  };
  // useEffect(() => {
  //   setSelected("JOB");
  //   setJobData(jobData);
  // }, []);
  return (
    <div>
      <div className="f-profile">
        <h1 className="Personal-detail">Organisation DashBoard</h1>
        <NavLink to="/orgdashboard">
          <div className="pro-details-w-arrow">
            <img className="side-arrow" src="Icons/Back.svg" alt="sideArrow" />
            <h4 className="Pro-details">Go Back</h4>
          </div>
        </NavLink>
      </div>

      <DetailsNavbar selected={selected} setSelected={handleChange} />
      <div>{getComponent()}</div>
    </div>
  );
};

export default DashBoardDetails;
