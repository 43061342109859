import React, { useState, useEffect, useRef } from "react";
import "../../Style/CoursePosting/CoursePosting.css";
// import CourseUploadImg from "./CourseUploadImg";
import FeaturesPosting from "../PostJob/FeaturesPosting";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
import FeatureModal from "./FeatureModal";
import { Modal } from "react-bootstrap";
export const CoursePost = ({
  dataPost,
  data3,

  data,
  thumbimg,
  handleInput,
  handleBannerImage,
  handleThumbImage,
  handleSubmit,
  bannerimg,
  handleSuceesClose,
  isSuccess,
}) => {
  return (
    <div>
      <div className="course-posting-input-container">
        <h1 className="jobs-header">{dataPost.title}</h1>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="course-posting-input">
          <div>
            <h1 className="course-posting-input-title">{dataPost.titleHead}</h1>
          </div>
          <div className="course-just-check">
            <input
              className="course-posting-input-box"
              placeholder="Enter the name or title of the course"
              type="text"
              name="name"
              onChange={handleInput}
              height="40px"
              required
              // ref={names}
            />
          </div>
        </div>
        <div className="course-desp-box">
          <div className="course-posting-input-con">
            <h1 className="course-posting-input-title">{dataPost.desp}</h1>
          </div>
          <div className="about-course-content-box">
            <textarea
              className="about-company-desps"
              name="description"
              onChange={handleInput}
            />
          </div>
        </div>
        <div className="course-posting-input">
          <div>
            <h1 className="course-posting-input-title">{dataPost.catgory}</h1>
          </div>
          <div className="course-just-check">
            <select
              className="course-posting-input-box2"
              name="delivery_mode"
              onChange={handleInput}
            >
              <option className="optionValue" value="online">
                Online
              </option>
              <option className="optionValue" value="offline">
                Offline
              </option>
              <option className="optionValue" value="hybrid">
                Hybrid
              </option>
            </select>
          </div>
        </div>

        <div className="course-posting-input">
          <div>
            <h1 className="course-posting-input-title">
              {dataPost.price}
              <FontAwesomeIcon
                className=""
                icon={faIndianRupeeSign}
                color="black"
                style={{ width: "1rem", height: "1.5rem" }}
              />
            </h1>
          </div>
          <div className="course-just-check">
            {data.is_free === "true" ? (
              <input
                className="course-posting-input-box2"
                placeholder="Enter amount"
                name="fee"
                type="text"
                onChange={handleInput}
                value={data.is_free === "true" ? "0" : ""}
              />
            ) : (
              <input
                className="course-posting-input-box2"
                placeholder="Enter amount"
                name="fee"
                type="text"
                onChange={handleInput}
                // value={data.is_free === "true" ? "0" : ""}
              />
            )}
            <input
              className="container-checkbox1"
              type="checkbox"
              name="is_free"
              onChange={handleInput}
              value={data?.is_free === "true" ? "false" : "true"}
              checked={data?.is_free === "true" ? true : false}
              // ref={isFree}
            />
            <span className="checkmark1"> Is Free</span>
          </div>
        </div>

        <div className="courseImgContainer">
          <div className="courseUploadComponent">
            <div className="event-upload-main">
              <h1 className="course-posting-input-title">Course Image</h1>

              <div className="event-thumbnailsContainer">
                <input
                  type="file"
                  name="thumbnail_image"
                  id="file-input"
                  onChange={handleThumbImage}
                  className="event-thumbnails"
                  placeholder="upload-thumbnails"
                  required
                  style={{ display: "none" }}
                />
                <div className="event-thumbnails">
                  {thumbimg === undefined ? (
                    ""
                  ) : (
                    <img
                      src={thumbimg}
                      className="event-thumbnails-preview"
                      alt="thumbImage"
                    />
                  )}
                </div>
                <div>
                  <label for="file-input" className="event-post-upload">
                    <img
                      className="upload-icon"
                      src="Icons/upload.svg"
                      alt="edit"
                    />
                    <p>Upload</p>
                  </label>
                </div>
              </div>
              <div className="bannerCourse-Container">
                <input
                  type="file"
                  name="banner_image"
                  id="file-input-banner"
                  onChange={handleBannerImage}
                  className="event-thumbnails"
                  placeholder="Upload Banner"
                  style={{ display: "none" }}
                />
                <div className="event-thumbnails">
                  {bannerimg === undefined ? (
                    ""
                  ) : (
                    <img
                      src={bannerimg}
                      className="event-thumbnails-preview"
                      alt="thumbImage"
                    />
                  )}
                </div>

                <label for="file-input-banner" className="event-post-upload2">
                  <img
                    className="upload-icon"
                    src="Icons/upload.svg"
                    alt="edit"
                  />
                  <p>Upload</p>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="course-posting-input">
          <div>
            <h1 className="course-posting-input-title">{dataPost.phone}</h1>
          </div>
          <div className="course-just-check">
            <input
              className="course-posting-input-box2"
              placeholder="Enter phone number"
              type="text"
              name="phone_number"
              onChange={handleInput}
              height="40px"
              required
              // ref={phone}
            />
          </div>
        </div>
        <div className="course-posting-input">
          <div>
            <h1 className="course-posting-input-title">{dataPost.email}</h1>
          </div>
          <div className="course-just-check">
            <input
              className="course-posting-input-box2"
              placeholder="Enter email address"
              type="email"
              name="email"
              onChange={handleInput}
              height="40px"
              required
              // ref={emails}
            />
          </div>
        </div>
        <div className="course-posting-input">
          <div>
            <h1 className="course-posting-input-title">{dataPost.web}</h1>
          </div>
          <div className="course-just-check">
            <input
              className="course-posting-input-box2"
              placeholder="Enter your website"
              type="text"
              name="website"
              onChange={handleInput}
              height="40px"
              required
              // ref={web}
            />
          </div>
        </div>
        <div className="course-link-web">
          <h2 className="course-entry">{dataPost.link}</h2>
          <input
            className="course-ticketing-system"
            type="text"
            name="link"
            onChange={handleInput}
            placeholder="Enter the link to your website or external course."
            // ref={courseLink}
            required
          />
        </div>
        <div className="features-posting"></div>

        <button type="submit" className="publish-btn">
          Publish
        </button>
      </form>
      <Modal centered={true} show={isSuccess}>
        <FeatureModal closed={handleSuceesClose} />
      </Modal>
    </div>
  );
};
export default CoursePost;
