import axios from "axios";
const baseUrl = "https://admin.55notout.com/";

const offerData = () => {
  const slug = "payment_module/offers_data/";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const createOrder = () => {
  const urlPath = "payment_module/get_and create_order";
  const url = `${baseUrl}${urlPath}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
    },
  };

  try {
    const response = axios.get(url, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const postCreateOrder = (data) => {
  const urlPath = "payment_module/get_and create_order/";
  const url = `${baseUrl}${urlPath}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
    },
  };

  try {
    const response = axios.post(url, data, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const createPayment = (data) => {
  const urlPath = "payment_module/create_payment/";
  const url = `${baseUrl}${urlPath}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
    },
  };

  try {
    const response = axios.post(url, data, config).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const paymentFinalStep = (data) => {
  const urlPath = "payment_module/payment_status/";
  const url = `${baseUrl}${urlPath}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
    },
  };

  try {
    const response = axios.post(url, data, config).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};

const slotBookingOrder = (data) => {
  const urlPath = "payment_module/get_and_create_slot_order/";
  const url = `${baseUrl}${urlPath}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
    },
  };

  try {
    const response = axios.post(url, data, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};

const createSlotPayment = (data) => {
  const urlPath = "payment_module/create_slot_order/";
  const url = `${baseUrl}${urlPath}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
    },
  };

  try {
    const response = axios.post(url, data, config).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};

const slotPaymentFinalStep = (data) => {
  const urlPath = "payment_module/slot_book_payment_status/";
  const url = `${baseUrl}${urlPath}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
    },
  };

  try {
    const response = axios.post(url, data, config).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const gstPayment = () => {
  const urlPath = "mentorModule/get_commission_and_gst/";
  const url = `${baseUrl}${urlPath}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
export {
  offerData,
  createOrder,
  postCreateOrder,
  createPayment,
  paymentFinalStep,
  slotBookingOrder,
  createSlotPayment,
  slotPaymentFinalStep,
  gstPayment,
};
