import React from "react";
import CnfrmPassword from "../../Component/ConfirmPasword/CnfrmPassword";
const ConfirmPassword = () => {
  return (
    <div>
      <CnfrmPassword />
    </div>
  );
};

export default ConfirmPassword;
