import React, { useState } from "react";
import "../../Style/Courses/FeatureCourses.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { NavLink, useNavigate } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../Component/FullTime-jobs/Pagination";
const FeatureCourses = ({ featured }) => {
  const navigate = useNavigate();
  const popUp = () => {
    toast("Please Login To Continue", {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const featurePopup = () => {
    toast("you are not Feature Account", {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const [showPerPage, setShowPerPage] = useState(4);
  const [paginat, setPaginat] = useState({
    start: 0,
    end: showPerPage,
  });
  const PaginationHandle = (start, end) => {
    setPaginat({ start: start, end: end });
  };

  return (
    <>
      <div>
        <h4 className="FeatureListOrag">Featured Courses</h4>
      </div>

      <div className="feature-course">
        {featured.slice(paginat?.start, paginat?.end)?.map((value) => {
          return (
            <div className="feature-course-listing-job-main">
              <div className="feature-course-image-container">
                <img
                  className="feature-course-listing-Job-Img"
                  src={value?.thumbnail_images}
                  alt="Find Jobs"
                />
                <div className="feature-course-listing-Job">
                  <img
                    className="course-logo"
                    src={value?.organization_details.image}
                    alt="logo"
                    style={{ width: "30%" }}
                  />
                </div>

                <h1 className="feature-course-listing-company">
                  {value?.organization_details?.organization_name}
                </h1>
              </div>
              <div className="feature-course-listing-Job-left">
                <h1 className="feature-course-listing-Job-Title">
                  {value?.name}
                </h1>

                {localStorage.getItem("TalentToken") ? (
                  <ShowMoreText
                    lines={3}
                    more="Show more"
                    less="Show less"
                    className="Job-Desp"
                    anchorclassName="my-anchor-css-class"
                    onClick={() => navigate(`/coursedetails/${value?.slug}`)}
                    expanded={false}
                    expandByClick={false}
                    width={0}
                    truncatedEndingComponent={"... "}
                  >
                    {value?.description}
                  </ShowMoreText>
                ) : (
                  <ShowMoreText
                    lines={3}
                    more="Show more"
                    less="Show less"
                    className="Job-Desp"
                    anchorclassName="my-anchor-css-class"
                    onClick={() =>
                      localStorage.getItem("TalentToken")
                        ? navigate(`/coursedetails/${value?.slug}`)
                        : popUp()
                    }
                    expanded={false}
                    expandByClick={false}
                    width={0}
                    truncatedEndingComponent={"... "}
                  >
                    {value?.description}
                  </ShowMoreText>
                )}

                <div className="feature-course-listing-rate-percent">
                  <h4 className="feature-course-listing-rate">
                    {" "}
                    <FontAwesomeIcon
                      className="cross-Icon"
                      icon={faIndianRupeeSign}
                      color="red"
                    />
                    {value?.fee}
                  </h4>

                  <h4 className="feature-service-listing-percent">
                    {localStorage.getItem("TalentToken") ? (
                      <NavLink
                        to={{
                          pathname: `/coursedetails/${value?.slug}`,
                          state: { value },
                        }}
                      >
                        View Listing
                      </NavLink>
                    ) : (
                      <NavLink
                        to={""}
                        onClick={
                          localStorage.getItem("TalentToken")
                            ? featurePopup
                            : popUp
                        }
                      >
                        View Listing
                        <ToastContainer />
                      </NavLink>
                    )}
                  </h4>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {featured.length >= 4 ? (
        <Pagination
          showPerPage={showPerPage}
          PaginationHandle={PaginationHandle}
          total={featured?.length}
        />
      ) : (
        ""
      )}

      {/*   Mobile-Design */}

      <Carousel className="mob-response">
        {featured.map((value) => {
          return (
            <div className="feature-course-listing-job-main">
              <div className="feature-course-image-container">
                <NavLink to={`/coursedetails/${value?.slug}`}>
                  <img
                    className="feature-course-listing-Job-Img"
                    src={value?.thumbnail_images}
                    alt="Find Jobs"
                  />
                  <div className="feature-course-listing-Job">
                    <img
                      className="course-logo"
                      src={value?.organization_details.image}
                      alt="logo"
                      style={{ width: "5rem", borderRadius: "50%" }}
                    />
                  </div>

                  <h1 className="feature-course-listing-company">
                    {value.organization_details.organization_name}
                  </h1>
                </NavLink>
              </div>
              <div className="feature-course-listing-Job-left">
                <NavLink to={`/coursedetails/${value.slug}`}>
                  <h1 className="feature-course-listing-Job-Title">
                    {value?.name}
                  </h1>

                  {
                    <ShowMoreText
                      lines={3}
                      more="Show more"
                      less="Show less"
                      className="feature-course-listing-Job-para"
                      anchorclassName="my-anchor-css-class"
                      onClick={() => navigate(`/coursedetails/${value?.slug}`)}
                      expanded={false}
                      width={0}
                      truncatedEndingComponent={"... "}
                    >
                      {value?.description}
                    </ShowMoreText>
                  }
                  <div className="feature-course-listing-rate-percent">
                    <h4 className="feature-course-listing-rate">
                      {value?.fee}
                    </h4>
                    <h4 className="feature-course-listing-percent">
                      100% Online
                    </h4>
                  </div>
                </NavLink>
              </div>
            </div>
          );
        })}
      </Carousel>
      <div>
        <h4 className="course-ListOrag">All Courses</h4>
        <h1 className="org-ListHead">
          Get enrolled in specially designed courses for you
        </h1>
      </div>
    </>
  );
};

export default FeatureCourses;
