import React from "react";
import ContactUs from "../../Component/ContactUs/ContactUs";
import { Helmet } from "react-helmet";
const Contact = () => {
  return (
    <div>
      <Helmet>
        <title>55notout | Contact Us</title>
        <meta name="description" content="Contact Us" />
      </Helmet>
      <ContactUs />
    </div>
  );
};

export default Contact;
