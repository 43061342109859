import React, { useState, useEffect } from "react";
import { CoursePost } from "../../Component/CoursePost/CoursePost";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { coursePostings } from "../../Api/OrgApi";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import OrgNav from "../../Component/Organisation/OrgNav";
const dataPost = {
  title: "Course Posting",
  titleHead: "Course Heading/Title",
  desp: "Course Description",
  catgory: "Delivery Mode",
  price: "Course Price(INR)",
  phone: "Phone Number",
  email: "Email",
  web: "Website",
  link: "Link To Your Course",
};
const data3 = {
  title: "Free or Featured Posting?",
  free: "Free Posting",
  features: "Featured Posting",
};
const CoursePostings = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const handleSuceesClose = () => setIsSuccess(false);
  const handleSuceesShow = () => {
    setIsSuccess(true);
  };
  const [thumbimg, setThumbImg] = useState({});
  const [thumbimg2, setThumbImg2] = useState();
  const [bannerimg, setBannerImg] = useState({});
  const [bannerimg2, setBannerImg2] = useState();
  const [data, setData] = useState({
    name: "",
    description: "",
    delivery_mode: "online",
    fee: "",
    is_free: "false",
    thumbnail_images: "",
    banner_image: "",
    phone_number: "",
    email: "",
    website: "",
    link: "",
    location: "1882",
    is_featured_post: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append(" delivery_mode", data.delivery_mode);
    formData.append(" banner_image", bannerimg);
    formData.append(" thumbnail_images", thumbimg);
    formData.append(" phone_number", data.phone_number);
    formData.append(" email", data.email);
    formData.append(" website ", data.website);
    formData.append("link", data.link);
    formData.append(" fee", data.fee);
    formData.append(" is_free", data.is_free);
    formData.append(" is_featured_post", data?.is_featured_post);
    formData.append(" location", "1882");
    // formData.append(" location", data?.location);
    const notify = () => {
      // Calling toast method by passing string

      toast("Submit SuccesFull", {
        autoClose: 500,
        position: toast.POSITION.TOP_CENTER,
      });
    };
    coursePostings(formData)
      .then((res) => {
        const response = res;
        localStorage.setItem("course_id", response?.data?.data?.id);
        if (response?.status === 200) {
          handleSuceesShow();
          notify();
          setTimeout(() => {
            // window.location.href = "/orgdashboard";
          }, 1000);
        }
      })
      .catch((e) =>
        e.response.status === 400
          ? toast(" Bad Request", {
              autoClose: 500,
              position: toast.POSITION.TOP_RIGHT,
            })
          : ""
      );
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };
  const handleThumbImage = (e) => {
    const image = e.target.files[0];
    setThumbImg(image);
    setThumbImg2(URL.createObjectURL(e.target.files[0]));
  };
  const handleBannerImage = (e) => {
    const bimage = e.target.files[0];
    setBannerImg(bimage);
    setBannerImg2(URL.createObjectURL(e.target.files[0]));
  };

  // useEffect(() => {
  //   courseApplication().then((res) => {
  //     const response = res;

  //   });
  // }, []);
  return (
    <div>
      <Helmet>
        <title>55notout | Post Course</title>
        <meta name="description" content="Post A New Course" />
      </Helmet>
      <OrgNav />
      <CoursePost
        data={data}
        dataPost={dataPost}
        data3={data3}
        handleInput={handleInput}
        handleBannerImage={handleBannerImage}
        handleThumbImage={handleThumbImage}
        handleSubmit={handleSubmit}
        thumbimg={thumbimg2}
        bannerimg={bannerimg2}
        handleSuceesClose={handleSuceesClose}
        isSuccess={isSuccess}
      />
    </div>
  );
};

export default CoursePostings;
