import React from "react";
import OpportunityBox from "../TalentFile/OpportunityBox";
import Listed from "../FeaturedProfile/Listed";
import FeaturedListProfile from "../FeaturedProfile/FeaturedListProfile";
const BrowserFeaturesProfiles = () => {
  const data = {
    title: "Handpicked Profiles. Personalised Experiences. ",
    content:
      "Welcome to our Hall of Skills curated by 50+ professionals! With different sets of skills and expertise, their profiles are categorised and featured for you to find the right match for your organisation. Have a look at some of these profiles by signing up with 55NotOut below!",
    url: "SIGNUP NOW",
    image: "Images/talent.png",
  };
  return (
    <div>
      <OpportunityBox data={data} />
      <Listed />
      <FeaturedListProfile />
    </div>
  );
};

export default BrowserFeaturesProfiles;
