import React from "react";
import DashBoardDetails from "../../Component/OrganizationDetails/DashBoardDetails";
import OrgRoutes from "../../Component/OrganizationDetails/OrgRoutes";
import { Helmet } from "react-helmet";
const OrgDashDetails = () => {
  return (
    <div>
      <Helmet>
        <title>55notout | Organisation DashBoard</title>
        <meta name="description" content="Organisation DashBoard" />
      </Helmet>
      <OrgRoutes />
    </div>
  );
};

export default OrgDashDetails;
