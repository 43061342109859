import React, { useState, useEffect } from "react";
import "../../Style/PaymentGetway/payment.css";
import {
  offerData,
  postCreateOrder,
  createPayment,
} from "../../Api/PaymentGetway";
import { showPrice } from "../../Api/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { Button } from "react-bootstrap";
import { displayRazorpay } from "../../Utill/Utility";
import { ToastContainer, toast } from "react-toastify";
const Offer = () => {
  const [offer, setOffer] = useState([]);
  const [price, setPrice] = useState([]);
  const [createOrder, setCreateOrder] = useState([]);
  const [payment, setPayment] = useState([]);
  useEffect(() => {
    const loadData = () => {
      showPrice().then((res) => {
        setPrice(res?.data);
      });
    };
    loadData();
  }, []);
  useEffect(() => {
    const loadData = () => {
      offerData().then((res) => {
        const response = res.data;
        setOffer(response);
      });
    };
    loadData();
  }, []);

  var api_1_data = {};
  var api_2_data = {};

  async function handleClick(id, ammount) {
    const data = {
      offer: id,
      ammount: ammount,
    };

    await postCreateOrder(data)
      .then((res) => {
        const response = res;

        api_1_data = response.data;
      })
      .catch((e) => console.log("e", e));

    const data1 = {
      amount: ammount,
      order_id: api_1_data.order_number,
      currency: "INR",
    };

    await createPayment(data1).then((resp) => {
      const response2 = resp.data;
      api_2_data = response2;

      displayRazorpay(api_2_data.data);
    });
  }

  return (
    <div className="container offerConatiner">
      <h1 className="Featured-Plan-text">Featured Plan</h1>
      <div className="row offer text-center">
        <div className="col-md-3 offerCoulmn">
          <h1 className="Featured-Plan-text-2">For Mentors</h1>
          <div className="card">
            <div className="card-header cardName-box">
              Mentor | Standard plan
            </div>
            <div className="card-body offerBody">
              <h5 className="card-title offer-amount">
                {" "}
                <FontAwesomeIcon
                  className=""
                  icon={faIndianRupeeSign}
                  color="white"
                  style={{ width: "1rem", height: "1.5rem" }}
                />{" "}
                {offer[0]?.ammount}
                <span style={{ fontSize: "10px", marginLeft: "10%" }}>
                  30 days
                </span>
              </h5>

              <p className="card-text card-description">
                Boost your exposure by featuring your posting(s) on top of all
                listings
              </p>
              <p className="card-text card-description">
                Featured Mentor ad will get an expected and estimated 77% more
                views
              </p>
              <p className="card-text card-description">
                Mentor will be featured for 30 days
              </p>
              <p className="card-text card-description">Use within 30 days</p>
              <p className="card-text card-description">
                GST will be charged extra
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 offerCoulmn">
          <h1 className="Featured-Plan-text-2">For Talents</h1>
          <div className="card">
            <div className="card-header cardName-box">
              Talent | Standard plan
            </div>
            <div className="card-body offerBody">
              <h5 className="card-title offer-amount">
                {" "}
                <FontAwesomeIcon
                  className=""
                  icon={faIndianRupeeSign}
                  color="white"
                  style={{ width: "1rem", height: "1.5rem" }}
                />{" "}
                {offer[1]?.ammount}
                <span style={{ fontSize: "10px", marginLeft: "10%" }}>
                  30 days
                </span>
              </h5>

              <p className="card-text card-description">
                Boost your exposure by featuring your posting(s) on top of all
                listings
              </p>
              <p className="card-text card-description">
                Featured Talents ad will get an expected and estimated 77% more
                views
              </p>
              <p className="card-text card-description">
                Talents will be featured for 30 days
              </p>
              <p className="card-text card-description">Use within 30 days</p>
              <p className="card-text card-description">
                GST will be charged extra
              </p>
            </div>
          </div>
        </div>
      </div>
      <h1 className="Featured-Plan-text">For Organisation</h1>
      <div className="row offer text-center">
        <div className="col-md-3 offerCoulmn">
          <h1 className="Featured-Plan-text-2">For Job</h1>
          <div className="card">
            <div className="card-header cardName-box">Feature Job</div>
            <div className="card-body offerBody">
              <h5 className="card-title offer-amount">
                {" "}
                <FontAwesomeIcon
                  className=""
                  icon={faIndianRupeeSign}
                  color="white"
                  style={{ width: "1rem", height: "1.5rem" }}
                />{" "}
                {price[0]?.price}
                <span style={{ fontSize: "10px", marginLeft: "10%" }}>
                  30 days
                </span>
              </h5>

              <p className="card-text card-description">
                Boost your exposure by featuring your posting(s) on top of all
                listings
              </p>
              <p className="card-text card-description">
                Featured Job ad will get an expected and estimated 77% more
                views
              </p>
              <p className="card-text card-description">
                Job will be featured for 30 days
              </p>
              <p className="card-text card-description">Use within 30 days</p>
              <p className="card-text card-description">
                GST will be charged extra
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 offerCoulmn">
          <h1 className="Featured-Plan-text-2">For Event</h1>
          <div className="card">
            <div className="card-header cardName-box">Feature Your Event</div>
            <div className="card-body offerBody">
              <h5 className="card-title offer-amount">
                {" "}
                <FontAwesomeIcon
                  className=""
                  icon={faIndianRupeeSign}
                  color="white"
                  style={{ width: "1rem", height: "1.5rem" }}
                />{" "}
                {price[2]?.price}
                <span style={{ fontSize: "10px", marginLeft: "10%" }}>
                  30 days
                </span>
              </h5>

              <p className="card-text card-description">
                Boost your exposure by featuring your posting(s) on top of all
                listings
              </p>
              <p className="card-text card-description">
                Featured Events ad will get an expected and estimated 77% more
                views
              </p>
              <p className="card-text card-description">
                Events will be featured for 30 days
              </p>
              <p className="card-text card-description">Use within 30 days</p>
              <p className="card-text card-description">
                GST will be charged extra
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 offerCoulmn">
          <h1 className="Featured-Plan-text-2">For Course</h1>
          <div className="card">
            <div className="card-header cardName-box">Feature Your Course</div>
            <div className="card-body offerBody">
              <h5 className="card-title offer-amount">
                {" "}
                <FontAwesomeIcon
                  className=""
                  icon={faIndianRupeeSign}
                  color="white"
                  style={{ width: "1rem", height: "1.5rem" }}
                />{" "}
                {price[3]?.price}
                <span style={{ fontSize: "10px", marginLeft: "10%" }}>
                  30 days
                </span>
              </h5>

              <p className="card-text card-description">
                Boost your exposure by featuring your posting(s) on top of all
                listings
              </p>
              <p className="card-text card-description">
                Featured Course ad will get an expected and estimated 77% more
                views
              </p>
              <p className="card-text card-description">
                Course will be featured for 30 days
              </p>
              <p className="card-text card-description">Use within 30 days</p>
              <p className="card-text card-description">
                GST will be charged extra
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 offerCoulmn">
          <h1 className="Featured-Plan-text-2">For Product/Service</h1>
          <div className="card">
            <div className="card-header cardName-box">
              Feature Your Product/Service
            </div>
            <div className="card-body offerBody">
              <h5 className="card-title offer-amount">
                {" "}
                <FontAwesomeIcon
                  className=""
                  icon={faIndianRupeeSign}
                  color="white"
                  style={{ width: "1rem", height: "1.5rem" }}
                />{" "}
                {price[1]?.price} /{price[4]?.price}
                <span style={{ fontSize: "10px", marginLeft: "10%" }}>
                  30 days
                </span>
              </h5>

              <p className="card-text card-description">
                Boost your exposure by featuring your posting(s) on top of all
                listings
              </p>
              <p className="card-text card-description">
                Featured Product/Service ad will get an expected and estimated
                77% more views
              </p>
              <p className="card-text card-description">
                Product/Service will be featured for 30 days
              </p>
              <p className="card-text card-description">Use within 30 days</p>
              <p className="card-text card-description">
                GST will be charged extra
              </p>
            </div>
          </div>
        </div>
        <p className="offer-desp">
          For bulk featured posting on 55notout write to us at
          <a href=" mailto:contact@55notout.com">
            {" "}
            contact@55notout.com
          </a> or{" "}
          <a href="#">
            {" "}
            +91-9810206966{" "}
            <FontAwesomeIcon
              className=""
              icon={faWhatsapp}
              onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send/?phone=919810206966&text=55notout- A one-stop platform to find jobs, consulting, volunteering, mentoring, events, courses, products and services for age 50+ Senior Professionals.&type=phone_number&app_absent=0"
                )
              }
              style={{ cursor: "pointer" }}
            />
          </a>
        </p>
      </div>
    </div>
  );
};

export default Offer;
