import React, { useState, useEffect } from "react";
import "../../Style/Service.css";
import { TalentService } from "../../Api/api";

const Services = () => {
  const [talentService, setTalentService] = useState([]);
  useEffect(() => {
    const loadData = () => {
      TalentService().then((res) => {
        const response = res;

        setTalentService(response.data);
      });
    };
    loadData();
  }, []);
  return (
    <div>
      <div className="SeviceProvide">
        <h4 className="service">Our Services</h4>
        <h1 className="provide">What We Provide</h1>
      </div>
      <div className="serviceMain">
        {talentService.map((value, index) => {
      

          return (
            <div className="serviceContainer">
              <div className="iconContainer">
                <img className="icon" src={value?.image} alt="icons" />
                <h1 className="num">0{index + 1}</h1>
              </div>
              <h1 className="head">{value.title}</h1>

              <p className="p">{value.content}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Services;
