import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../../Style/MentorDashBoard/MentorSkill.css";
import { mentorRegister } from "../../Api/Mentor";
import { Address } from "../../Api/OrgApi";
import { skillNames } from "../../Api/TalentsApi";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";
const MentorSkills = ({ saveData }) => {
  const [address, setAddress] = useState([]);
  const [skill, setSkill] = useState([]);
  const [data, setData] = useState({});
  const [img, setImg] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    saveData(data);
  };
  const [key_skills, setKey_skills] = useState("");

  const handleOnchange = (val) => {
    setKey_skills(val);

    setData({ ...data, key_skills });
  };
  const handleImage = (e) => {
    let image = e.target.files[0];

    const formData = new FormData();

    formData.append("image", image.name);

    setData({ ...data, formData });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  useEffect(() => {
    const loadData = () => {
      Address().then((res) => {
        const response = res;

        setAddress(response.data);
      });
    };
    loadData();
  }, []);

  useEffect(() => {
    const loadData = () => {
      skillNames().then((res) => {
        const response = res;

        setSkill(response.results);
      });
    };
    loadData();
  }, []);
  useEffect(() => {
    const loadData = () => {
      skillNames().then((res) => {
        const response = res;
        let temp = response.results;

        const newArrayOfObj = temp.map(({ name: label, id: value }) => ({
          label,
          value,
        }));

        setSkill(newArrayOfObj);
      });
    };
    loadData();
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="f-profile">
          <h1 className="Personal-detail">Apply as a Mentor</h1>

          <div className="pro-details-w-arrow">
            <h4 className="Pro-details">Education</h4>
            <img
              className="side-arrow"
              src="Images/SideArrow.svg"
              alt="sideArrow"
            />
          </div>
        </div>
        <div className="mentor-personal-profile-container">
          <div className="m-profile-container">
            <div className="details-Box">
              <h2 className="mentor-profile-pic-details-name">Title</h2>
              <input
                className="mentor-persnol-details-box"
                name="title"
                type="text"
                value={data?.user?.first_name}
                onChange={handleInput}
                required
                // required
              />
            </div>
            <div className="details-Box">
              <h2 className="mentor-profile-pic-details-name">First Name</h2>
              <input
                className="mentor-persnol-details-box"
                name="first_name"
                type="text"
                placeholder="First Name"
                value={data?.user?.first_name}
                onChange={handleInput}
                required
              />
            </div>
            <div className="details-Box">
              <h2 className="mentor-profile-pic-details-name">Last Name</h2>
              <input
                className="mentor-persnol-details-box"
                name=" last_name"
                type="text"
                placeholder="Last Name"
                // value={data?.user?.first_name}
                onChange={handleInput}
                required
              />
            </div>
            <div className="details-Box">
              <h2
                className="mentor-profile-pic-details-name"
                name="key_skills"
                onChange={handleInput}
              >
                Industry
              </h2>
              <MultiSelect
                className="mentor-persnol-details-box"
                onChange={handleOnchange}
                options={skill}
              />
            </div>
            <div className="details-Box">
              <h2 className="mentor-profile-pic-details-name">Date of Birth</h2>

              <input
                className="mentor-persnol-details-box"
                type="date"
                name="dob"
                // value={data?.dob}
                placeholder="DD/MM/YYYY"
                onChange={handleInput}
                required
              />
            </div>
          </div>
          {/* second div */}
          <div className="mentor-second-container">
            <div className="details-Box">
              <h2 className="ment-profile-pic-details-name">Phone Number</h2>
              <input
                className="ment-persnol-details-box"
                name="phone_number"
                type="text"
                // value={data?.phone_numer}
                onChange={handleInput}
                required
              />
            </div>
            <div className="details-Box">
              <h2 className="ment-profile-pic-details-name">Email Address</h2>
              <input
                className="ment-persnol-details-box"
                type="email"
                name="email"
                placeholder="Email"
                onChange={handleInput}
              />
            </div>
            <div className="details-Box">
              <h2 className="ment-profile-pic-details-name">
                Applicable Job Title
              </h2>
              <input
                className="ment-persnol-details-box"
                type="text"
                name="applicable_job_titles"
                placeholder="Applicable Job Titles"
                onChange={handleInput}
              />
            </div>
            <div className="details-Box">
              <h2 className="ment-profile-pic-details-name">Company</h2>
              <input
                className="ment-persnol-details-box"
                type="text"
                name="company"
                placeholder="company"
                // value={data?.area}
                onChange={handleInput}
                required
              />
            </div>

            <div className="details-Box">
              <h2 className="ment-profile-pic-details-name">Location</h2>
              <select
                className="ment-persnol-details-box"
                name="location"
                onChange={handleInput}
              >
                {address.map((value) => {
                  return <option value={value?.id}>{value?.city_name}</option>;
                })}
              </select>
            </div>
          </div>
        </div>
        <button type="submit" className="save-persnol-details">
          Save & Continue
        </button>
      </form>
    </div>
  );
};

export default MentorSkills;
