import React, { useState, useEffect } from "react";
import "../../Style/MentorAvailbility/MentorSlotBooking.css";
import LocationIcon from "../../Image/location.svg";
import { bookSlot, mentorSlotBooking } from "../../Api/Mentor";
import Linkdin from "../../Image/linkdin-blue.svg";
import Twiter from "../../Image/twiter-blue.svg";
import SlotBook from "./SlotBook";
import Review from "../MentorDashBoard/Review";
import IntroductoryCallModel from "./IntroductoryCallModel";
import { Modal } from "react-bootstrap";
const MentorSlotBooking = ({ data }) => {
  const [slot, setSlot] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isIntroductory, setIsIntroductory] = useState(false);
  const handleSuceesClose = () => setIsSuccess(false);
  const handleSuceesShow = () => setIsSuccess(true);
  const handleIntroductoryClose = () => setIsIntroductory(false);
  const handleIntroductory = () => setIsIntroductory(true);
  // Slot Booking
  useEffect(() => {
    const loadData = () => {
      bookSlot()?.then((res) => {
        const response = res?.data;

        setSlot(response);
      });
    };
    loadData();
  }, []);
  // const handleShow = () => setIsSuccess(true);
  return (
    <>
      <div className="book-mantor-list-box">
        <div className="book-list-mentor-list-container">
          <div className="book-list-image-container">
            <div className="mentor-slot-img">
              <img
                className="book-list-mentor-image"
                src={data?.image}
                alt="mentor-pic"
              />
            </div>
            <div className="book-user-icons">
              <img
                className=""
                src={Linkdin}
                alt="linkdin"
                // onClick={() => {
                //   window.open(personalData.linkedin_profile);
                // }}
              />
              <img
                className=""
                src={Twiter}
                alt="twiter"
                // onClick={() => {
                //   window.open(personalData.twitter);
                // }}
              />
            </div>
          </div>

          <div className="mentor-content-box1">
            <div className="btnWithcontent">
              <div className="book-list-mentor">
                <h1 className="book-list-mentor-head">
                  {data?.first_name} {data?.last_name}
                </h1>
                <h6 className="book-list-mentor-title">
                  CEO at {data?.company}
                </h6>

                <div className="mode-container2">
                  <img
                    className="location-mode"
                    src={LocationIcon}
                    alt="location"
                  />
                  <h6 className="mode">{data?.location?.city_name}</h6>
                </div>
                <div className="mob-book-user-icons">
                  <img
                    className=""
                    src={Linkdin}
                    alt="linkdin"
                    // onClick={() => {
                    //   window.open(personalData.linkedin_profile);
                    // }}
                  />
                  <img
                    className=""
                    src={Twiter}
                    alt="twiter"
                    // onClick={() => {
                    //   window.open(personalData.twitter);
                    // }}
                  />
                </div>
                <div className="book-slot-skill">
                  <div className="Mentor-Core-skill">
                    <h6 className="skill-head">Skills:</h6>
                  </div>
                  <div className="mentor-skill-core">
                    {data?.key_skills_names?.map((value) => {
                      return <h6 className="mentors-skill">{value?.name},</h6>;
                    })}
                  </div>
                </div>
              </div>
            </div>
            <p className="book-list-mentor-para">{data?.references}</p>
          </div>
          <div className=" mentor-btn-group">
            <button
              className="book-list-mentor-button"
              onClick={handleSuceesShow}
            >
              View all slot
            </button>

            <div className="introductory-box">
              <h6 className="introductory">Introductory Call</h6>
              <button className="mentor-booking" onClick={handleIntroductory}>
                Book Now
              </button>
            </div>
          </div>
        </div>

        <Modal centered={true} show={isSuccess}>
          <SlotBook closed={handleSuceesClose} />
        </Modal>
        <Modal centered={true} show={isIntroductory}>
          <IntroductoryCallModel closed={handleIntroductoryClose} />
        </Modal>
      </div>
      <Review />
    </>
  );
};

export default MentorSlotBooking;
