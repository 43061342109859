import React from "react";
import { NavLink } from "react-router-dom";
const CourseDesp = ({ featuredDetails }) => {
  return (
    <div>
      <>
        <div className="Description-container">
          <h1 className="desp-header">Course Description</h1>
          <p className="desp-para">{featuredDetails?.description}</p>
        </div>
        <div className="abt-down-btn">
          <NavLink to="/courses">
            <button
              className="GoEvent2"
              onClick={() => window.open(featuredDetails?.website)}
            >
              Go to Course
            </button>
          </NavLink>
        </div>
      </>
    </div>
  );
};

export default CourseDesp;
