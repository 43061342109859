import React, { useState, useEffect, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { NavLink, useParams, useNavigate } from "react-router-dom";
let FilterCategory = () => {
  const [selected, setSelected] = useState("JOB");

  const navigate = useNavigate();

  const handleChange = (value) => {
    setSelected(value);
    let slug;
    if (value === "JOB") {
      slug = "Job";
      navigate(`/${slug}`);
    } else if (value === "CONSULTING") {
      slug = "Consultings";
      navigate(`/${slug}`);
    } else if (value === "VOUNTEER") {
      slug = "Volunteers";
      navigate(`/${slug}`);
    } else if (value === "EVENT") {
      slug = "Event";
      navigate(`/${slug}`);
    } else if (value === "COURSE") {
      slug = "Course";
      navigate(`/${slug}`);
    } else if (value === "MENTOR") {
      slug = "Mentors";
      navigate(`/${slug}`);
    } else if (value === "PRODUCT") {
      slug = "Products";
      navigate(`/${slug}`);
    } else if (value === "SERVICE") {
      slug = "Services";
      navigate(`/${slug}`);
    } else {
      <h1>No records found</h1>;
    }
  };

  useEffect(() => {
    setSelected("JOB");
    // setJobData(jobData);
  }, []);

  return (
    <>
      {/* <div className="Filter-Category">
        <div className="category">
          <h4 className="filter">Category</h4>
        </div>
        <FormControl
          fullWidth
          className="FilterCategory-box"
          variant="outlined"
        >
          <InputLabel id="demo-simple-select">Select</InputLabel>
          <Select
            id="demo-simple-select"
            label="selecttttt"
            onChange={(e) => handleChange(e.target.value)}
            className="selectedValue"
          >
            <MenuItem value="JOB" className="selectedValue">
              Post Job
            </MenuItem>
            <MenuItem value="CONSULTING" className="selectedValue">
              Consulting Jobs
            </MenuItem>
            <MenuItem value="VOUNTEER" className="selectedValue">
              Volunteer Job
            </MenuItem>
            <MenuItem value="EVENT" className="selectedValue">
              Event
            </MenuItem>
            <MenuItem value="COURSE" className="selectedValue">
              Course
            </MenuItem>
            <MenuItem value="MENTOR" className="selectedValue">
              Mentor
            </MenuItem>
            <MenuItem value="PRODUCT" className="selectedValue">
              Product
            </MenuItem>
            <MenuItem value="SERVICE" className="selectedValue">
              Service
            </MenuItem>
          </Select>
        </FormControl>
      </div> */}
      {/* <div>{getComponent()}</div> */}
    </>
  );
};

export default FilterCategory;
