import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { mentorShortlist } from "../../Api/Mentor";
import moment from "moment";
const ShowSlot = ({ applicant, slug, closed }) => {
  const slot_slug = applicant?.slot_slug;
  const handleClick = (id, act) => {
    const data = {
      action: act,
      application_id: id,
      slug: slot_slug,
    };

    mentorShortlist(data).then((res) => {});
  };

  return (
    <div>
      <h1 className="close" onClick={closed}>
        X
      </h1>

      <div className="table-wrapper-scroll-y my-custom-scrollbar ">
        <div className="list-group">
          {applicant?.map((value) => {
            return (
              <>
                <div className="row no-gutters p-2 m-1 border ">
                  <div className="col-sm-2">
                    <img
                      src={value?.talent?.image}
                      className="scrollbar-image"
                      alt="Rounded"
                      width="80"
                      height="100"
                    />
                  </div>
                  <div className="col-sm-6">
                    <div className="card-body">
                      <h5 className="card-title">
                        {value?.talent?.user?.first_name}{" "}
                        {value?.talent?.user?.last_name}
                      </h5>

                      <p className="card-text">{value?.talent?.user?.email}</p>
                      <p className="card-text">{value?.talent?.phone_numer}</p>
                    </div>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">Slot Booking Time </h5>

                    <p className="card-text">
                      Date:{" "}
                      {moment(value?.short_listed_at).format("DD-MM-YYYY")}
                    </p>
                    <p className="card-text">
                      Time: {moment(value?.short_listed_at).format("HH:MM A")}
                    </p>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ShowSlot;
