import React, { useState, useEffect } from "react";
import { OrganizationBanner, OrganizationIcon } from "../../Api/api";
import OrgNav from "../../Component/Organisation/OrgNav";
import OrgService from "../../Component/Organisation/OrgService";
import BlankShadow from "../../Component/BlankShadow";
import OrgList from "../../Component/Organisation/OrgList";
import OpportunityBox from "../../Component/TalentFile/OpportunityBox";
import OrgListTitle from "../../Component/Organisation/OrgListTitle";
import FeaturedListProfile from "../../Component/FeaturedProfile/FeaturedListProfile";
import OrgOppertunity from "../../Component/Organisation/OrgOppertunity";
import { Helmet } from "react-helmet";
const Organisation = () => {
  const [orgBanner, setOrgBanner] = useState({});
  useEffect(() => {
    const loadData = () => {
      OrganizationBanner().then((res) => {
        const response = res;
        setOrgBanner(response.data);
      });
    };
    loadData();
  }, {});
  const [orgIcon, setOrgIcon] = useState([]);
  useEffect(() => {
    const loadData = () => {
      OrganizationIcon().then((res) => {
        const response = res;
        setOrgIcon(response.data);
      });
    };
    loadData();
  }, []);
  return (
    <div>
      <Helmet>
        <title>55notout | Organisation</title>
        <meta
          name="description"
          content="Post Jobs,Course,Events.Product and Service"
        />
      </Helmet>
      <OrgNav />
      <OrgOppertunity data={orgBanner} />
      <OrgService />
      <BlankShadow />
      <OrgList />
      <FeaturedListProfile />
      <OrgListTitle list={orgIcon} />
    </div>
  );
};

export default Organisation;
