import React, { useState, useEffect } from "react";
import "./Search.css";
import { AllEvents } from "../../Api/EventApi";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Slider from "@mui/material/Slider";
// import Listing from "../FullTime-jobs/Listing";
import RealatedJob from "../JobDetails/RealatedJob";
import { Address } from "../../Api/OrgApi";
import { AllMentor } from "../../Api/Mentor";
import { skillNames } from "../../Api/TalentsApi";
import Listing from "./Listing";
import MentorListing from "./MentorListing";
import FilterCategory from "./FilterCategory";
function valuetext(value) {
  return `${value}°C`;
}

const MentorFilter = () => {
  const [list, setList] = useState([]);
  const [address, setAddress] = useState([]);
  const [filterValue, setFilterValue] = React.useState({});
  const [filterValuemin, setFilterValuemax] = React.useState({});
  const [skill, setSkill] = useState([]);

  const [value, setValue] = React.useState([0, 100000]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const sendData = {
      salary_min: value[0],
      salary_max: value[1],
    };

    setFilterValuemax({ ...filterValuemin, ...sendData });
  };

  const handleChange2 = (e, newValue) => {
    const { name, value } = e.target;

    setFilterValue({ ...filterValue, [name]: value });
    // setFilterValue({ ...filterValue, [name]: sendData });
  };
  useEffect(() => {
    const loadData = () => {
      AllMentor(filterValue).then((res) => {
        setList(res);
      });
    };
    loadData();
  }, [filterValue]);

  useEffect(() => {
    const loadData = () => {
      Address().then((res) => {
        const response = res;
        const data = response.data;
        // let temp = { ...edit, location: data[0]?.id + "" };
        // setEdit(temp);
        setAddress(data);
      });
    };
    loadData();
  }, []);

  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 20000,
      label: "20k",
    },
    {
      value: 50000,
      label: "40k",
    },
    {
      value: 100000,
      label: "100k",
    },
  ];
  useEffect(() => {
    const loadData = () => {
      skillNames().then((res) => {
        const response = res;

        setSkill(response.results);
      });
    };
    loadData();
  }, []);
  return (
    <div className="SearchMainContainer">
      <div className="filterSection">
        <div className="filter-section">
          <h4 className="filter">Filter</h4>
          <span className="clear">Clear</span>
        </div>

        <div>
          <h4 className="filter">First Name</h4>
          <FormControl fullWidth>
            <TextField
              label="Name"
              variant="outlined"
              name="first_name"
              onChange={handleChange2}
              id="Book Name"
            />
          </FormControl>
        </div>
        <div>
          <h4 className="filter">Last Name</h4>
          <FormControl fullWidth>
            <TextField
              label="Last Name"
              variant="outlined"
              name="last_name"
              onChange={handleChange2}
              id="Book Name"
            />
          </FormControl>
        </div>

        <div>
          <h4 className="filter">Location</h4>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="location"
              label="Age"
              onChange={handleChange2}
              defaultValue=""
            >
              {address.map((value, key) => {
                return (
                  <MenuItem value={value?.id}>{value?.city_name}</MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div>
          <h4 className="filter">Industry</h4>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="key_skills"
              label="Selecttttt"
              onChange={handleChange2}
            >
              {skill.map((value) => {
                return <MenuItem value={value?.id}>{value?.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </div>
        <div>
          <h4 className="filter">Featured</h4>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              //   value={age}
              name="is_featured_post"
              label="Age"
              onChange={handleChange2}
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="listing">
        {/* <FilterCategory /> */}
        <MentorListing list={list} />
      </div>
    </div>
  );
};

export default MentorFilter;
