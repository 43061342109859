import React, { useState, useEffect } from "react";
import "../../Style/featuredProfile/FeatureProfileDetails.css";
import ProfileDetails from "./ProfileDetails";
import { useParams, useLocation } from "react-router-dom";
import Fb from "../../Image/fb.png";
import Twiter from "../../Image/twiter.png";
import { getMentorsDetails } from "../../Api/Mentor";
import LocationIcon from "../../Image/location.svg";
import Linkdin from "../../Image/linkdin-blue.svg";
import Linkdin2 from "../../Image/Linkdin.png";
import Twitter from "../../Image/twiter-blue.svg";
import { persnol, getTalentPublicsDetails } from "../../Api/TalentsApi";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
const FeaturesProfileDetails = () => {
  let state = useLocation();
  const [profile, setProfile] = useState({});
  const [personalData, setPersonalData] = useState({});
  let { slug } = useParams();
  useEffect(() => {
    const loadData = () => {
      getTalentPublicsDetails({ slug }).then((res) => {
        const response = res.data;

        setProfile(response);
      });
    };
    loadData();
  }, [slug]);

  return (
    <div className="feature-user-details">
      <div className="feature-display-img-name">
        <div className="feature-user-profile-container ">
          <img
            className="feature-user-profile"
            src={profile?.image}
            alt="profile"
          />
          <div className="feature-edit-profile">
            <h4
              className="feature-user-gmail"
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => window.open(`mailto:${profile?.user?.email}`)}
            >
              Contact
            </h4>
          </div>
          <div className="feature-user-icons">
            <img
              className="feature-profile-linkdin"
              src={Linkdin}
              alt="linkdin"
              onClick={() => {
                window.open(profile?.linkedin_profile);
              }}
            />
            <img
              className="feature-profile-twiter"
              src={Twitter}
              alt="twiter"
              onClick={() => {
                window.open(profile?.twitter);
              }}
            />
          </div>
          <div className="profile-social-container">
            <h1 className="profile-social-media">Share profile</h1>
            <div className="about-icon-container">
              <FacebookShareButton
                url={`http://55notout.com//${state?.pathname}`}
                quote={"Hey Check This Job"}
                hashtag={"55NotOut"}
                description={""}
              >
                <img className="f-about-social-icons" src={Fb} alt="fb" />
              </FacebookShareButton>
              <TwitterShareButton
                url={`http://55notout.com//${state?.pathname}`}
                quote={"Hey Check This Job"}
                hashtag={"55NotOut"}
                description={""}
              >
                <img
                  className="f-about-social-icons"
                  src={Twiter}
                  alt="twitter"
                />
              </TwitterShareButton>
              <LinkedinShareButton
                url={`http://55notout.com//${state?.pathname}`}
                quote={"Hey Check This Job"}
                hashtag={"55NotOut"}
                description={""}
              >
                <img
                  className="f-about-social-icons"
                  src={Linkdin2}
                  alt="linkdin"
                />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        <div className="feature-profile-name-location">
          <h4 className="feature-profile-name">
            {profile?.user?.first_name} {profile?.user?.last_name}
          </h4>
          <div className="feature-location-details">
            <img src={LocationIcon} alt="location" />
            <span className="listing-place-details">
              {profile?.current_location?.city}, India
            </span>
          </div>
          <div className="feature-user-skill-container">
            <div>
              <h2 className="feature-user-skill">Skills: </h2>
            </div>
            <div className="f-profile-skills">
              {profile?.skills?.map((value) => {
                return <p className="feature-Profile-skill"> {value?.name},</p>;
              })}
            </div>
          </div>
          <ProfileDetails profile={profile} />
        </div>
      </div>
    </div>
  );
};

export default FeaturesProfileDetails;
