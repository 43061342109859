import React from "react";
import CourseDesp from "../../Component/CourseDetails.jsx/CourseDesp";
import Fb from "../../Image/fb.png";
import Twiter from "../../Image/twiter.png";
import Linkdin from "../../Image/Linkdin.png";
import { NavLink } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import { useLocation } from "react-router-dom";
import courseDefault from "../../Image/courseDefault.jpg";
const AboutDetails = ({ featuredDetails }) => {
  let state = useLocation();
  return (
    <div>
      <div className="about-main">
        <div className="About-event">
          <img
            className="about-img"
            src={
              featuredDetails?.thumbnail_images === null
                ? courseDefault
                : featuredDetails?.thumbnail_images
            }
            alt="Course Thumbnails"
          />
          <h1 className="about-title">{featuredDetails?.name}</h1>
        </div>
      </div>
      <div className="about-second-container">
        <div className="about-temp">
          <div className="about-img-container">
            <img
              className="about-img1"
              src={
                featuredDetails?.banner_image === null
                  ? courseDefault
                  : featuredDetails?.banner_image
              }
              alt="Course Banner"
            />
          </div>
          <div className="Desktop">
            <CourseDesp featuredDetails={featuredDetails} />
          </div>
        </div>
        <div className="right-side-container">
          <NavLink to="/courses">
            <button
              className="GoEvent"
              onClick={() => window.open(featuredDetails?.website)}
            >
              Go to Course
            </button>
          </NavLink>
          <div className="date-box">
            <h1 className="date-time">Organization</h1>
            <div className="date-Container">
              <img
                src={featuredDetails?.organization_details?.image}
                alt="logo"
                style={{ width: "5rem", borderRadius: "50%" }}
              />
              <h4 className="start-end-time">
                {featuredDetails?.organization_details?.organization_name}
              </h4>
            </div>
          </div>

          <div className="location-container">
            <div className="mode-container">
              <h1 className="about-loacation">Course Fee:</h1>
              <h1 className="mode">{featuredDetails?.fee}</h1>
            </div>
          </div>
          <div className="location-container">
            <div className="mode-container">
              <h1 className="about-loacation">Course Type:</h1>
              <h1 className="mode">{featuredDetails?.delivery_mode}</h1>
            </div>
          </div>
          <div className="date-box">
            <h1 className="date-time">Uploded By</h1>
            <div className="date-Container">
              <h1>Phone:</h1>
              <h4 className="start-end-time">
                {featuredDetails?.phone_number}
              </h4>
            </div>
            <div className="date-Container">
              <h1>Email:</h1>
              <h4 className="start-end-time">{featuredDetails?.email}</h4>
            </div>
            <div className="date-Container">
              <h1 className="webSite">Website:</h1>
              <h4 className="start-end-time">{featuredDetails?.website}</h4>
            </div>
          </div>

          <div className="social-container">
            <h1 className="about-social-media">Share across social media</h1>
            <div className="about-icon-container">
              <FacebookShareButton
                url={`http://55notout.com//${state?.pathname}`}
                quote={"Hey Check This Job"}
                hashtag={"55NotOut"}
                description={""}
              >
                <img className="about-social-icons" src={Fb} alt="fb" />
              </FacebookShareButton>
              <TwitterShareButton
                url={`http://55notout.com//${state?.pathname}`}
                quote={"Hey Check This Job"}
                hashtag={"55NotOut"}
                description={""}
              >
                <img
                  className="about-social-icons"
                  src={Twiter}
                  alt="twitter"
                />
              </TwitterShareButton>
              <LinkedinShareButton
                url={`http://55notout.com//${state?.pathname}`}
                quote={"Hey Check This Job"}
                hashtag={"55NotOut"}
                description={""}
              >
                <img
                  className="about-social-icons"
                  src={Linkdin}
                  alt="linkdin"
                />
              </LinkedinShareButton>
            </div>
          </div>
          <div className="mob-response">
            <CourseDesp featuredDetails={featuredDetails} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutDetails;
