import React from "react";
import "../../Style/Service/SpecialService.css";
import { NavLink } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
const SpecialService = ({ specialService, services, navigateTo, paginat }) => {
  const popUp = () => {
    toast("Please Login To Continue", {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });

    <ToastContainer />;
  };
  const executeOnClick = (isExpanded) => {};
  return (
    <>
      <div>
        <h4 className="lists">{services.head}</h4>
      </div>
      <div className="feature-special-service">
        {specialService
          ?.slice(paginat?.start, paginat?.end)
          .map((listValue) => {
            return (
              <div className="feature-special-service-listing-job-main ">
                {localStorage.getItem("TalentToken") ? (
                  <NavLink
                    // to={`${navigateTo}${listValue.slug}`}
                    to={{
                      pathname: `${navigateTo}${listValue.slug}`,
                      state: { listValue },
                    }}
                  >
                    <div className="feature-special-service-image-container">
                      <img
                        className="feature-service-listing-Job-Img"
                        src={listValue.thumbnail_images}
                        alt="Find Jobs"
                      />
                      <div className="feature-service-listing-Job">
                        <img
                          className="services-logo"
                          src={listValue.organization_details.image}
                          alt="logo"
                          style={{ width: "5rem", borderRadius: "50%" }}
                        />
                      </div>

                      <div className="feature-special-service-listing-Job-left">
                        <h1 className="feature-service-listing-Job-Title">
                          {listValue.name}
                        </h1>

                        {
                          <ShowMoreText
                            lines={3}
                            more="Show more"
                            less="Show less"
                            className="feature-service-listing-Job-para"
                            anchorclassName="my-anchor-css-class"
                            onClick={
                              localStorage.getItem("TalentToken")
                                ? executeOnClick
                                : ""
                            }
                            expanded={false}
                            expandByClick={false}
                            width={0}
                            truncatedEndingComponent={"... "}
                          >
                            {listValue?.description}
                          </ShowMoreText>
                        }
                        <div className="feature-special-service-listing-rate-percent">
                          <h4 className="feature-special-service-listing-rate">
                            <FontAwesomeIcon
                              className="cross-Icon"
                              icon={faIndianRupeeSign}
                              color="red"
                            />
                            {listValue.fee}
                          </h4>
                          <h4 className="feature-special-service-listing-percent">
                            {listValue.delivery_mode}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                ) : (
                  <NavLink to="" onClick={popUp}>
                    <div className="feature-special-service-image-container">
                      <img
                        className="feature-service-listing-Job-Img"
                        src={listValue.thumbnail_images}
                        alt="Find Jobs"
                      />
                      <div className="feature-service-listing-Job">
                        <img
                          className="services-logo"
                          src={listValue.organization_details.image}
                          alt="logo"
                          style={{ width: "5rem", borderRadius: "50%" }}
                        />
                      </div>

                      <div className="feature-special-service-listing-Job-left">
                        <h1 className="feature-service-listing-Job-Title">
                          {listValue.name}
                        </h1>
                        {
                          <ShowMoreText
                            lines={3}
                            more="Show more"
                            less="Show less"
                            className="feature-service-listing-Job-para"
                            anchorclassName="my-anchor-css-class"
                            onClick={
                              localStorage.getItem("TalentToken")
                                ? executeOnClick
                                : ""
                            }
                            expanded={false}
                            expandByClick={false}
                            width={0}
                            truncatedEndingComponent={"... "}
                          >
                            {listValue?.description}
                          </ShowMoreText>
                        }
                        <div className="feature-special-service-listing-rate-percent">
                          <h4 className="feature-special-service-listing-rate">
                            <FontAwesomeIcon
                              className="cross-Icon"
                              icon={faIndianRupeeSign}
                              color="red"
                            />
                            {listValue.fee}
                          </h4>
                          <h4 className="feature-special-service-listing-percent">
                            {listValue.delivery_mode}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                )}
              </div>
            );
          })}
      </div>
    </>
  );
};

export default SpecialService;
