import React from "react";
import "../../Style/JobDetails/jobInfo.css";
import moment from "moment";
import Veiw from "../../Image/eye.png";
import Insta from "../../Image/insta.png";
import Fb from "../../Image/fb.png";
import Twiter from "../../Image/twiter.png";
import Linkdin from "../../Image/Linkdin.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
} from "react-share";
import { FacebookIcon, TwitterIcon } from "react-share";
const Jobinfo = ({ details, apply, loadData }) => {
  let state = useLocation();

  const applyhandle = () => {
    loadData();

    toast(apply?.msg);
    toast(apply?.data);
  };

  return (
    <div>
      <div>
        <div className=" Job-details">
          <h1 classname="job-post">{details?.job_title}</h1>
          <button className="Apply-btn" onClick={applyhandle}>
            Apply Now
            <ToastContainer />
          </button>
        </div>
        <div className="job-info">
          <div className="job-btn-mob">
            <h4 className="info">Job Info</h4>
            <button className="Apply-btn-mob" onClick={applyhandle}>
              Apply Now
              <ToastContainer />
            </button>
          </div>
          <div className="App-details">
            <h4 className="application">
              No of Application:{details?.application_count}
            </h4>

            <img className="view-icon" src={Veiw} alt="eye" />
            <h4 className="views"> views:{details?.views}</h4>
          </div>
        </div>
      </div>

      <div className="detailw-container">
        <div className="deatail-main">
          <h4 className="details">Details</h4>
          <div className="detail-icon">
            <FacebookShareButton
              url={`http://55notout.com//${state?.pathname}`}
              quote={"Hey Check This Job"}
              hashtag={"55NotOut"}
              description={""}
              // className="Demo__some-network__share-button"
            >
              <img className="detail-view-icon" src={Fb} alt="eye" />
            </FacebookShareButton>
            <TwitterShareButton
              url={`http://55notout.com//${state?.pathname}`}
              quote={"Hey Check This Job"}
              hashtag={"55NotOut"}
              description={""}
            >
              <img className="detail-view-icon" src={Twiter} alt="eye" />
            </TwitterShareButton>
            <LinkedinShareButton
              url={`http://55notout.com//${state?.pathname}`}
              quote={"Hey Check This Job"}
              hashtag={"55NotOut"}
              description={""}
            >
              <img className="detail-view-icon" src={Linkdin} alt="eye" />
            </LinkedinShareButton>
          </div>
        </div>
        <div className="detailsContainer">
          <div className="dets">
            <h6 className="Experience">
              Experience :
              <span className="exp-year">
                {details?.min_exp}-{details?.max_exp} Years
              </span>
            </h6>
            <h6 className="Experience">
              Job Location :
              <span className="exp-year">
                {details?.location_details?.city_name}
              </span>
            </h6>
            <h6 className="Experience">
              Salary :
              <span className="exp-year">
                {details?.salary_min}-{details?.salary_max} LPA
              </span>
            </h6>
            <h6 className="Experience">
              Apply by :
              <span className="exp-year">
                {moment(details?.created_at).format("DD/MM/YYYY")}
              </span>
            </h6>
            <h6 className="Experience">
              Mode :<span className="exp-year"> {details?.job_mode}</span>
            </h6>
          </div>
          <div className="detailsContainer-left">
            <h6 className="company">
              Company :
              <span className="com-name">
                {details?.organization_details?.organization_name}
              </span>
            </h6>
            <h6 className="company">
              Job Type :<span className="com-name">{details?.job_type}</span>
            </h6>
            <h6 className="company">
              Education :
              <span className="com-name">{details?.qualification}</span>
            </h6>
            <h6 className="company">
              No of Openings :
              <span className="com-name">{details?.number_of_opening}</span>
            </h6>
            <h6 className="company">
              Industry :
              {details?.skill_name?.map((value) => {
                return <span className="com-name">{value?.name},</span>;
              })}
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Jobinfo;
