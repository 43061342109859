import React from "react";

const MentorButton = ({ selected, setSelected }) => {
  const navs = [
    { key: "PROFILE", text: "Profile" },
    { key: "APPLICATION", text: "Application" },
    { key: "MENTOR", text: "Create Session" },
  ];
  return (
    <div className="MentorButton">
      {navs.map(({ key, text }) => (
        <div
          key={key}
          id={selected === key ? "selected-btn" : null}
          className="mentorDashBoard-btn"
          onClick={() => setSelected(key)}
        >
          {text}
        </div>
      ))}
    </div>
  );
};

export default MentorButton;
