import React from "react";
import OrgSignup from "../../Component/OrgSignup/OrgSignup";
const OrgSignUpPage = () => {
  return (
    <div>
      <OrgSignup />
    </div>
  );
};

export default OrgSignUpPage;
