import React from "react";
import "../../Style/Events/RightEvent.css";
import { useNavigate } from "react-router-dom";
const RightEvents = ({ event }) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="Events-FullTime-Main">
        <div className="Events-Left-part">
          <h1 className="Events-Full-time-Head">{event.title}</h1>

          <p className="Events-Full-time-Paragraph">{event.content}</p>
          <button
            className="Events-full-time-SignUp-btn"
            onClick={() => window.open(event?.url)}
          >
            {event.button_text}
          </button>
          <button
            className="full-time-SignUp-btn"
            onClick={() => navigate("/Event")}
          >
            Full Event Listing
          </button>
        </div>
        <div>
          <img
            className="Events-fullTime-Main-Img"
            src={event.image}
            alt="fullTimeJobs"
          />
        </div>
      </div>
    </div>
  );
};

export default RightEvents;
