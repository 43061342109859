import axios from "axios";
const baseUrl = "https://admin.55notout.com/";

const mentorRegister = (data) => {
  data["key_skills"] = [data["key_skills"]];
  const slug = "authentication/register_mentor/";
  const url = `${baseUrl}${slug}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
    },
  };

  try {
    const response = axios.post(url, data, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const mentorBanner = () => {
  const urlPath = `mentorModule/page_banner/`;
  const url = `${baseUrl}${urlPath}`;
  try {
    const response = axios.get(url).then((res) => {
      return res.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};

const featureMentor = () => {
  const slug = "mentorModule/mentor_list/?is_featured=true";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const listMentors = () => {
  const slug = "mentorModule/mentor_list/?is_featured=false";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const getMentorsDetails = ({ slug }) => {
  const urlPath = `mentorModule/MentorPublicDetailsView/`;
  const url = `${baseUrl}${urlPath}${slug}`;

  try {
    const response = axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "TalentToken"
          )}||Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        return response.data;
      });
    return response;
  } catch (error) {
    return error;
  }
};
const getReview = ({ slug }) => {
  const urlPath = `mentorModule/mentor_review_list/`;
  const url = `${baseUrl}${urlPath}${slug}`;

  try {
    const response = axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
        },
      })
      .then((response) => {
        return response.data;
      });
    return response;
  } catch (error) {
    return error;
  }
};
// 2nd
const deleteReview = (formData) => {
  // const slug = localStorage.getItem("mentor_slug");
  const urlPath = `mentorModule/delete_mentor_review/`;
  const url = `${baseUrl}${urlPath}${formData}/`;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
    },
    // data: { id: formData },
  };

  try {
    const response = axios.delete(url, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
// 4th
const getApplicants = ({ slug }) => {
  const urlPath = `mentorModule/mentor_review_list/`;
  const url = `${baseUrl}${urlPath}${slug}`;

  try {
    const response = axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
        },
      })
      .then((response) => {
        return response.data;
      });
    return response;
  } catch (error) {
    return error;
  }
};

// 7th
const getUpdateDetails = () => {
  const slug = localStorage.getItem("mentor_slug");
  const urlPath = `mentorModule/get_update_delete/`;
  const url = `${baseUrl}${urlPath}${slug}`;

  try {
    const response = axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
        },
      })
      .then((response) => {
        return response.data;
      });
    return response;
  } catch (error) {
    return error;
  }
};
// 8th
const putUpdateDetails = (formData, IsImage) => {
  const slug = localStorage.getItem("mentor_slug");
  const urlPath = "mentorModule/get_update_delete/";
  const url = `${baseUrl}${urlPath}${slug}/`;

  const config = {
    headers: {
      "content-type": IsImage ? "multipart/form-data" : "application/json",
      Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
    },
  };
  delete formData.image;
  try {
    const response = axios.put(url, formData, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
// 9th
const deleteUpdateDetail = (formData) => {
  const slug = localStorage.getItem("mentor_slug");
  const urlPath = `mentorModule/get_update_delete/`;
  const url = `${baseUrl}${urlPath}${slug}/`;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
    },
    data: { id: formData },
  };

  try {
    const response = axios.delete(url, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
// 10th
const giveMentorReviewPost = (data, slug) => {
  // const slug = localStorage.getItem("mentor_slug");
  const urlPath = `mentorModule/give_mentor_review/`;
  const url = `${baseUrl}${urlPath}${slug}/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem(
        "TalentToken"
      )}||Bearer ${localStorage.getItem("token")}`,
    },
  };

  try {
    const response = axios.post(url, data, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
// 11th
const joinSlotPost = ({ slug }, data) => {
  const urlPath = `mentorModule/join_slot/`;
  const url = `${baseUrl}${urlPath}${slug}/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
    },
  };

  try {
    const response = axios.post(url, data, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
// 15th
const reportReview = (formData) => {
  const slug = "mentorModule/report_review/";
  const url = `${baseUrl}${slug}`;
  const config = {
    headers: {
      // "content-type": IsImage ? "multipart/form-data" : "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  try {
    const response = axios.post(url, formData, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
// 16th
const mentorShortlist = (data) => {
  const urlPath = `mentorModule/short_list_rejected/`;
  const url = `${baseUrl}${urlPath}${data.slug}/`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
    },
  };
  try {
    const response = axios.post(url, data, config).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
// 17th
const retriveSlot = ({ slug }) => {
  const urlPath = `mentorModule/slot_retrive_update_delete/`;
  const url = `${baseUrl}${urlPath}${slug}`;

  try {
    const response = axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
        },
      })
      .then((response) => {
        return response.data;
      });
    return response;
  } catch (error) {
    return error;
  }
};
// 18th
const putRetriveSlot = (formData) => {
  const urlPath = "mentorModule/slot_retrive_update_delete";
  const url = `${baseUrl}${urlPath}${formData.slug}/`;

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  delete formData.image;
  try {
    const response = axios.put(url, formData, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
// 19th
const deleteRetriveSlot = (formData) => {
  const urlPath = "mentorModule/slot_retrive_update_delete";
  const url = `${baseUrl}${urlPath}${formData.slug}/`;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
    },
    data: { id: formData },
  };

  try {
    const response = axios.delete(url, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};

const createSlot = (data) => {
  const urlPath = `mentorModule/get_or_create_slot/`;
  const url = `${baseUrl}${urlPath}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
    },
  };
  try {
    const response = axios.post(url, data, config).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const bookSlot = () => {
  const urlPath = `mentorModule/get_or_create_slot/`;
  const url = `${baseUrl}${urlPath}`;

  try {
    const response = axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
        },
      })
      .then((response) => {
        return response.data;
      });
    return response;
  } catch (error) {
    return error;
  }
};
const mentorSlotList = ({ slug }) => {
  const urlPath = `mentorModule/mentor_slot_list/`;
  const url = `${baseUrl}${urlPath}${slug}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
    },
  };
  try {
    const response = axios.get(url, config).then((res) => {
      return res.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const aplicantsDetails = ({ value }) => {
  const urlPath = `mentorModule/get_applicant_details/`;
  const url = `${baseUrl}${urlPath}${value}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("TalentToken")}`,
    },
  };
  try {
    const response = axios.get(url, config).then((res) => {
      return res.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const AllMentor = (filter) => {
  const slug = `mentorModule/mentor_list/`;
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios
      .get(url, {
        params: filter,
      })
      .then((response) => {
        return response.data;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export {
  mentorRegister,
  mentorBanner,
  featureMentor,
  listMentors,
  getMentorsDetails,
  getReview,
  deleteReview,
  getApplicants,
  getUpdateDetails,
  putUpdateDetails,
  deleteUpdateDetail,
  giveMentorReviewPost,
  joinSlotPost,
  reportReview,
  mentorShortlist,
  retriveSlot,
  putRetriveSlot,
  deleteRetriveSlot,
  createSlot,
  bookSlot,
  mentorSlotList,
  aplicantsDetails,
  AllMentor,
};
