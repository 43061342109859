import React, { useState, useEffect } from "react";
import Carousel from "react-elastic-carousel";
import "../../Style/Orgn/listedProfile.css";
import { OrganizationIcon } from "../../Api/api";
import FeaturedListProfile from "../../Component/FeaturedProfile/FeaturedListProfile";
const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 250, itemsToShow: 2, itemsToScroll: 2 },
  { width: 500, itemsToShow: 3 },
  { width: 750, itemsToShow: 4 },
];

const OrgList = () => {
  const [orgIcon, setOrgIcon] = useState([]);
  useEffect(() => {
    const loadData = () => {
      OrganizationIcon().then((res) => {
        const response = res;
        setOrgIcon(response.data);
      });
    };
    loadData();
  }, []);

  const myArrow = ({ onClick, isEdge }) => {
    return <button onClick={onClick} disabled={isEdge}></button>;
  };

  return (
    <div>
      <h4 className="ListOrag1">Listed Profiles</h4>
      <h1 className="org-ListHead">
        Sign up to check out highly curated profiles
      </h1>
    </div>
  );
};

export default OrgList;
