import React, { useEffect, useState } from "react";
import "../../Style/MentorDashBoard/Availibilty.css";
import moment from "moment";
import { getUpdateDetails, createSlot, bookSlot } from "../../Api/Mentor";
import { Modal } from "react-bootstrap";
import GetAvailbilty from "./GetAvailbilty";
import CallSlotModal from "./CallSlotModal";
import { ToastContainer, toast } from "react-toastify";
const Availbility = () => {
  const [upDate, setUpDate] = useState(false);
  const [isIntroductry, setisIntroductry] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleShow = () => setIsOpen(true);
  const handleClose = () => {
    setIsOpen(false);
  };

  const [data, setData] = useState({
    name: "",
    start_date: "",
    end_date: "",
    slug: "",
    link: "",
    price: "",
  });
  const updateData = (formData) => {
    createSlot(formData)
      .then((res) => {
        const response = res;

        if (response?.status === 200) {
          toast("create slot Succesfull", {
            autoClose: 500,
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setUpDate(!upDate);
      })
      .catch((e) =>
        toast(e.response.data.msg, {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        })
      );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const date = moment(data.date).format("YYYY-MM-DD");

    const enddate = moment(data.enddate).format("YYYY-MM-DD");

    const start_date = `${date} ${data.time}`;

    const end_date = `${enddate} ${data.endtime}`;
    data["end_date"] = end_date;
    data["start_date"] = start_date;

    const formData = { ...data };

    updateData(formData);
  };
  const handleInput = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };
  const handleChecked = (e) => {
    setisIntroductry(!isIntroductry);
  };

  useEffect(() => {
    const loadData = () => {
      getUpdateDetails().then((res) => {
        const response = res.data;
      });
    };
    loadData();
  }, [data, upDate]);

  // {moment(detail.start_date).format("DD/MM/YYYY")}
  return (
    <>
      <div>
        <form onSubmit={handleSubmit}>
          <div>
            <h1 className="Availibility">Availbility</h1>
            <input
              className="Avail-input"
              type="text"
              placeholder="Name of slot"
              name="name"
              onChange={handleInput}
              required
            />
          </div>
          <div>
            <input
              className="Avail-input"
              type="text"
              placeholder="Enter Your Link"
              name="link"
              onChange={handleInput}
              required
            />
          </div>
          <div className="event-posting-input-start">
            <div>
              <h1 className="event-posting-input-title">Start Date & Time</h1>
            </div>
            <div className="event-just-check1">
              <input
                className="mentor-posting-input-box-min "
                placeholder="12/12/2021"
                type="date"
                name="date"
                onChange={handleInput}
              />

              <input
                className="mentor-posting-input-box-max "
                placeholder="12:30AM"
                type="time"
                name="time"
                onChange={handleInput}
              />
            </div>
          </div>
          <div className="event-posting-input-start">
            <h1 className="event-posting-input-title">End Date & Time</h1>
            <div className="event-just-check1">
              <input
                className="mentor-posting-input-box-min "
                placeholder="12/12/2021"
                type="date"
                name="enddate"
                onChange={handleInput}
              />

              <input
                className="mentor-posting-input-box-max "
                placeholder="12:30AM"
                type="time"
                name="endtime"
                onChange={handleInput}
              />
            </div>
          </div>
          {isIntroductry === true ? (
            <div>
              <div className="Availbility-price-container">
                <div className="price-container">
                  <h1 className="Availbility-price">Session Fees (INR)</h1>
                </div>

                <input
                  className="Availbility-price-input"
                  placeholder="Enter Session Fees"
                  type="text"
                  name="price"
                  onChange={handleInput}
                  value="0"
                />
              </div>
              <div className="introductury-slot-booked">
                <input
                  className="container-checkbox1"
                  type="checkbox"
                  name="introductry"
                  onChange={handleChecked}
                  value={isIntroductry === true ? false : true}
                  checked={isIntroductry}

                  // ref={isFree}
                />
                <span className="checkmark1" onClick={handleShow}>
                  is Introductory Call
                </span>
              </div>
            </div>
          ) : (
            <div>
              <div className="Availbility-price-container">
                <div className="price-container">
                  <h1 className="Availbility-price">Session Fees (INR)</h1>
                </div>

                <input
                  className="Availbility-price-input"
                  placeholder="Enter Session Fees"
                  type="text"
                  name="price"
                  onChange={handleInput}
                />
              </div>
              <div className="introductury-slot-booked">
                <input
                  className="container-checkbox1"
                  type="checkbox"
                  name="introductry"
                  onChange={handleChecked}
                  value={isIntroductry === true ? false : true}
                  checked={isIntroductry}
                  onClick={handleShow}
                  // ref={isFree}
                />
                <span className="checkmark1">is Introductory Call</span>
              </div>
            </div>
          )}

          <div>
            <button className="Avail-save-btn" type="submit">
              Save
              <ToastContainer />
            </button>
          </div>
        </form>

        <Modal centered={true} show={isOpen} onHide={handleClose}>
          <CallSlotModal close={handleClose} />
        </Modal>
      </div>
    </>
  );
};

export default Availbility;
