import React from "react";
import JobFilter from "../../Component/Search/JobFilter";
import EventFilter from "../../Component/Search/EventFilter";
import CourseFilter from "../../Component/Search/CourseFilter";
import MentorFilter from "../../Component/Search/MentorFilter";
import ProductFilter from "../../Component/Search/ProductFilter";
import ServiceFilter from "../../Component/Search/ServiceFilter";
import { Navbar } from "../../Component/Navbar";
import { useParams } from "react-router-dom";
import FilterCategory from "../../Component/Search/FilterCategory";
const Filters = () => {
  const { slug } = useParams();
  console.log("slug", slug);
  return (
    <div>
      <Navbar />
      <FilterCategory />
      {slug === "Job" ? (
        <JobFilter />
      ) : slug === "Consultings" ? (
        <JobFilter />
      ) : slug === "Volunteers" ? (
        <JobFilter />
      ) : slug === "Event" ? (
        <EventFilter />
      ) : slug === "Course" ? (
        <CourseFilter />
      ) : slug === "Mentors" ? (
        <MentorFilter />
      ) : slug === "Products" ? (
        <ProductFilter />
      ) : slug === "Services" ? (
        <ServiceFilter />
      ) : (
        ""
      )}
    </div>
  );
};

export default Filters;
