import React, { useState, useEffect } from "react";
import "../Style/Service.css";
import { OrganizationMidBanner } from "../Api/api";

const BlankShadow = () => {
  const [midBanner, setMidBanner] = useState({});
  useEffect(() => {
    const load = () => {
      OrganizationMidBanner().then((res) => {
        const response = res;

        setMidBanner(response.data);
      });
    };
    load();
  }, []);

  return (
    <div>
      <img
        className="shadow"
        src={midBanner.image}
        alt="midBanner"
        onClick={() => window.open(midBanner.url)}
      />
    </div>
  );
};

export default BlankShadow;
