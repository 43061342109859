import axios from "axios";
const baseUrl = "https://admin.55notout.com/";
const ProductBanner = () => {
  const urlPath = "productListingAndDetailsModule/banner/";
  const url = `${baseUrl}${urlPath}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const FeatureProductsDetails = () => {
  const urlPath =
    "productListingAndDetailsModule/all_products_list/?is_featured_post=true";
  const url = `${baseUrl}${urlPath}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const SpecialProducts = () => {
  const urlPath =
    "productListingAndDetailsModule/all_products_list/?is_featured_post=false";
  const url = `${baseUrl}${urlPath}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const Productdetails = ({ slug }) => {
  const urlPath = "productListingAndDetailsModule/product_details/";
  const url = `${baseUrl}${urlPath}${slug}`;
  try {
    const response = axios
      .get(url, {
        headers: {
          // Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MywiZXhwIjoxNjU3MjgwNjcxLCJ1c2VybmFtZSI6InB5c2F1bmRhcnlfeWFob28ifQ.GWab7rOFGcfXVVARrDKVuDXCH0ObaNzNfCXeiuCNwoQ"}`,
          Authorization: `bearer ${localStorage.getItem("TalentToken")}`,
        },
      })
      .then((response) => {
        return response.data;
      });
    return response;
  } catch (error) {
    return error;
  }
};
const FeatureProductApply = ({ slug }) => {
  const urlPath = "productListingAndDetailsModule/apply_on_product/";
  const url = `${baseUrl}${urlPath}${slug}`;
  const response = fetch(url, {
    headers: {
      // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MywiZXhwIjoxNjU3MjgwNjcxLCJ1c2VybmFtZSI6InB5c2F1bmRhcnlfeWFob28ifQ.GWab7rOFGcfXVVARrDKVuDXCH0ObaNzNfCXeiuCNwoQ`,
      Authorization: `bearer ${localStorage.getItem("TalentToken")}`,
    },
  })
    .then((e) => e.json())
    .then((e) => e);
  return response;
};
const AllProduct = (filter) => {
  const slug = `productListingAndDetailsModule/all_products_list/`;
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios
      .get(url, {
        params: filter,
      })
      .then((response) => {
        return response.data;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export {
  ProductBanner,
  FeatureProductApply,
  FeatureProductsDetails,
  SpecialProducts,
  Productdetails,
  AllProduct,
};
