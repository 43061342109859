import React from "react";
import "../../Style/FullTimeJobs/fullTime.css";
import { useNavigate } from "react-router-dom";
const RightJobs = ({ jobs }) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="FullTime-Main">
        <div className="Left-part">
          <h1 className="Full-time-Head">{jobs.title}</h1>
          <p className="Full-time-Paragraph">{jobs.content}</p>
          <button
            className="full-time-SignUp-btn"
            onClick={() => window.open("/signUp")}
          >
            {jobs.button_text}
          </button>
          <button
            className="full-time-SignUp-btn"
            onClick={() => navigate("/Consultings")}
          >
            All Job Listing
          </button>
        </div>
        <div>
          <img
            className="fullTime-Main-Img"
            src={jobs.image}
            alt="fullTimeJobs"
          />
        </div>
      </div>
    </div>
  );
};

export default RightJobs;
