import React, { useState, useEffect } from "react";
import ProductPosts from "../../Component/ProductPost/ProductPosts";
import { productPostings } from "../../Api/OrgApi";
import { productCategory } from "../../Api/OrgApi";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet";
import OrgNav from "../../Component/Organisation/OrgNav";
const dataPost = {
  title: "Product Posting",
  titleHead: "Product Heading/Title",
  desp: "Product Description",
  catgory: "Product Category",
  price: "Product Price (INR)",
  phone: "Phone Number",
  email: "Email",
  web: "Website",
  link: "Link To Your Product",
};

const data3 = {
  title: "Free or Featured Posting?",
  free: "Free Posting",
  features: "Featured Posting",
};
const ProductPost = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const handleSuceesClose = () => setIsSuccess(false);
  const handleSuceesShow = () => {
    setIsSuccess(true);
  };
  const [category, setCategory] = useState([]);
  const [data, setData] = useState({
    name: "",
    description: "",
    // delivery_mode: "",
    category: category[0]?.id,
    fee: "",
    is_free: "false",
    thumbnail_images: "",
    banner_image: "",
    phone_number: "",
    email: "",
    website: "",
    link: "",
    location: "",
  });
  const [thumbimg, setThumbImg] = useState({});
  const [thumbimg2, setThumbImg2] = useState();
  const [bannerimg, setBannerImg] = useState({});
  const [bannerimg2, setBannerImg2] = useState();
  const notify = () => {
    // Calling toast method by passing string

    toast("submit SuccesFull", {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("category", data.category);
    formData.append("banner_image", bannerimg);
    formData.append("thumbnail_images", thumbimg);
    formData.append("phone_number", data.phone_number);
    formData.append("email", data.email);
    formData.append("website ", data.website);
    formData.append("link", data.link);
    formData.append("fee", data.fee);
    formData.append("is_free", data.is_free);
    formData.append("location", "1882");
    // formData.append(" thumbnail_images", image);
    // formData.append(" banner_image", bimage);
    productPostings(formData)
      .then((res) => {
        const response = res;
        localStorage.setItem("product_id", response?.data?.data?.id);
        if (response?.status === 200) {
          notify();
          handleSuceesShow();
          // setTimeout(() => {
          //   window.location.href = "/orgdashboard";
          // }, 1000);
        }
      })
      .catch((e) =>
        e.response.status === 400
          ? toast(" Bad Request", {
              autoClose: 500,
              position: toast.POSITION.TOP_RIGHT,
            })
          : ""
      );
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };
  const handleThumbImage = (e) => {
    const image = e.target.files[0];
    setThumbImg(image);
    setThumbImg2(URL.createObjectURL(e.target.files[0]));
  };
  const handleBannerImage = (e) => {
    const bimage = e.target.files[0];
    setBannerImg(bimage);
    setBannerImg2(URL.createObjectURL(e.target.files[0]));
  };

  useEffect(() => {
    const loadData = () => {
      productCategory().then((res) => {
        const response = res;
        const data1 = response;
        let temp = { ...data, category: data1[0]?.id + "" };
        setData(temp);
        setCategory(data1);
        // setData({ ...data, category: category[1]?.id });
      });
    };
    loadData();
  }, []);
  return (
    <div>
      <Helmet>
        <title>55notout | Post Products</title>
        <meta name="description" content="Post A New Products" />
      </Helmet>
      <OrgNav />
      <ProductPosts
        data={data}
        dataPost={dataPost}
        data3={data3}
        handleInput={handleInput}
        handleBannerImage={handleBannerImage}
        handleThumbImage={handleThumbImage}
        handleSubmit={handleSubmit}
        category={category}
        thumbimg={thumbimg2}
        bannerimg={bannerimg2}
        handleSuceesClose={handleSuceesClose}
        isSuccess={isSuccess}
      />
    </div>
  );
};

export default ProductPost;
