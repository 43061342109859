import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import "../../Style/Pagination/pagination.css";

const Pagination = ({ showPerPage, PaginationHandle, total }) => {
  const [counter, setCounter] = useState(1);
  const [numberOfButtuns, setNumberOfButtuns] = useState(1);
  useEffect(() => {
    const value = showPerPage * counter;
    PaginationHandle(value - showPerPage, value);
    setNumberOfButtuns(Math.ceil(total / showPerPage));
  }, [counter, showPerPage, total]);
  const onButtonClick = (type) => {
    if (type === "prev") {
      if (counter === 1) {
        setCounter(1);
      } else {
        setCounter(counter - 1);
      }
    } else if (type === "next") {
      if (numberOfButtuns === counter) {
        setCounter(counter);
      } else {
        setCounter(counter + 1);
      }
    }
  };
  return (
    <div className="pagination" style={{}}>
      <nav aria-label="Page navigation example">
        <ul className="pagination">
          <li className="page-item">
            <FontAwesomeIcon
              className="page-link"
              icon={faAngleLeft}
              onClick={() => onButtonClick("prev")}
            />
          </li>

          {new Array(numberOfButtuns).fill("").map((el, index) => {
            return (
              <li
                className={`page-item ${
                  index + 1 === counter ? "active" : "none"
                }`}
              >
                <p className="page-link1" onClick={() => setCounter(index + 1)}>
                  {index + 1}
                </p>
              </li>
            );
          })}
          <li className="page-item">
            <FontAwesomeIcon
              className="page-link"
              icon={faAngleRight}
              onClick={() => onButtonClick("next")}
            />
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
