import React from "react";
import MainSearch from "../../Component/SearchMain/MainSearch";
const Search = () => {
  return (
    <div>
      <MainSearch />
    </div>
  );
};

export default Search;
