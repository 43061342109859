import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { deleteJob } from "../../Api/OrgApi";
import { Modal, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
const DeleteJob = ({ data }) => {
  const [show, setShow] = useState(false);

  const deleteData = (formData) => {
    deleteJob(formData)
      .then((res) => {
        const response = res;
        toast("Job delete Succesfull", {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          setShow(false);
        }, 2000);
      })

      .catch((e) =>
        toast(e?.response?.data?.msg, {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        })
      );
  };
  const deleteHandle = () => {
    const formData = data;

    deleteData(formData);
  };
  useEffect(() => {}, []);
  return (
    <div>
      <FontAwesomeIcon
        className="cross-circle-xIcon"
        icon={faCircleXmark}
        color="red"
        onClick={() => {
          setShow(true);
        }}
      />

      <Modal show={show} centered={true}>
        <Modal.Header>
          <FontAwesomeIcon
            className="cross-circle-xIcon"
            icon={faCircleXmark}
            color="red"
            onClick={() => {
              setShow(false);
            }}
          />
        </Modal.Header>
        <Modal.Body>Do you want to delete</Modal.Body>
        <Modal.Footer onClick={deleteHandle}>
          Delete
          <ToastContainer />;
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeleteJob;
