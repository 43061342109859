import React from "react";
import { Link } from "react-router-dom";
import "../Style/Navbar.css";
import { ToastContainer, toast } from "react-toastify";
export const Navbar = () => {
  const popUp = () => {
    toast("Please Login To Continue", {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const popUp2 = () => {
    toast("Oops; you are Already Mentor", {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });
  };
  return (
    <div className="navbar">
      <div className="mainNav">
        <div className="left-nav">
          <Link to={"/fulltimejobs"}>Find Full-Time Jobs </Link>
          <Link to={"/consulting"}>Find Consulting Jobs</Link>
          <Link to={"/volunteer"}>Volunteer</Link>
          <Link to={"/mentorLists"}>Find a Mentor </Link>

          <Link to={"/Mentor55notout"}>Become a Mentor </Link>

          <Link to={"/events"}>Events </Link>
          <Link to={"/courses"}>Courses </Link>
          {localStorage.getItem("mentor_slug") === "null" ||
          !localStorage.getItem("mentor_slug") ? (
            ""
          ) : (
            <Link to={"/mentor"}>Mentor DashBoard </Link>
          )}
        </div>
        <div className="right-nav">
          {localStorage.getItem("TalentToken") ? (
            <Link to={"/dashboard"}>Dashboard </Link>
          ) : (
            ""
          )}
          {localStorage.getItem("TalentToken") ? (
            <Link to={"/featuredprofile"}>Profile </Link>
          ) : (
            ""
          )}

          <Link to={"/product"}>Products </Link>
          <Link to={"/service"}>Services </Link>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};
