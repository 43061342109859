import React, { useState, useEffect } from "react";
import { HomeBanner } from "../Api/api";
import { useNavigate } from "react-router-dom";
const NavDownImages = () => {
  const [banner, setBanner] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    const loadData = () => {
      HomeBanner().then((res) => {
        const response = res;
        setBanner(response.data);
        if (localStorage.getItem("TalentToken")) {
          window.location.href = "/dashboard";
        } else if (localStorage.getItem("token")) {
          window.location.href = "/orgdashboard";
        } else {
          navigate(`/`);
        }
      });
    };

    loadData();
  }, []);
  return (
    <div>
      <div>
        <img
          className="Navigation-image"
          src={banner.image}
          alt="background"
          width="100%"
        />
      </div>
    </div>
  );
};

export default NavDownImages;
