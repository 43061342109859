import React from "react";
import personalDetails from "../../Image/personalDetails.svg";
import Eduction from "../../Image/EducationDetails.jpg";
import Other from "../../Image/otherDetails.jpg";
import moment from "moment";
const ProfileDetails = ({ profile }) => {
  return (
    <div>
      <div className="personal-details">
        <img
          className="icon-details"
          src={personalDetails}
          alt="personalDetails"
        />

        <h2 className="personal-details-title">Professional Details</h2>
      </div>
      {profile?.jobs?.map((value) => {
        return (
          <>
            <div className="border-line"></div>
            <h4 className="user-designation">{value?.title}</h4>
            <h4 className="user-company">{value?.company_name}</h4>
            <h4 className="user-work-time">
              {" "}
              {moment(value?.start_from).format("MMM YY")} to{" "}
              {moment(value?.end_from).format("MMM YY")}
            </h4>

            {/* Add Education Details */}
          </>
        );
      })}
      <div className="personal-details">
        <img className="icon-details" src={Eduction} alt="personalDetails" />

        <h2 className="personal-details-title">Education Details</h2>
      </div>
      {profile?.education?.map((value) => {
        return (
          <>
            <div className="border-line"></div>
            <h4 className="user-designation">{value?.course}</h4>
            <h4 className="user-company">{value?.university_name}</h4>
            <h4 className="user-work-time">
              {" "}
              {moment(value?.start_from).format("MMM YY")} to{" "}
              {moment(value?.end_from).format("MMM YY")}
            </h4>
          </>
        );
      })}

      {/* Other Details */}

      <div className="personal-details">
        <img className="icon-details" src={Other} alt="personalDetails" />

        <h2 className="personal-details-title">Others Details</h2>
      </div>
      <div className="border-line"></div>
      <div className="other-details">
        <h4 className="dob">Date of Birth:</h4>
        <h4 className="dob-date">
          {profile?.dob === null
            ? ""
            : moment(profile?.dob).format("DD/MM/YYYY")}
        </h4>
      </div>
      <div className="other-details">
        <h4 className="details-loaction">Gender :</h4>
        <h4 className="gender-details">
          {profile?.gender === "male"
            ? "Male"
            : profile?.user?.gender === "female"
            ? "FeMale"
            : profile?.user?.gender === "other"
            ? "Other"
            : ""}
        </h4>
      </div>
      <div className="other-details">
        <h4 className="details-loaction">Current Location :</h4>
        <h4 className="other-details-page">
          {profile?.current_location?.city}
        </h4>
      </div>
      <div className="other-details">
        <h4 className="other-details-industry">Industry :</h4>
        <h4 className="other-details-industry-name">{profile?.industry}</h4>
      </div>
      <div className="other-details">
        <h4 className="other-details-annual">Annual Salary (INR) :</h4>
        <h4 className="other-details-salary">{profile?.annual_salary}</h4>
      </div>
      <div className="other-details">
        <h4 className="other-details-expected">Expected Salary (INR):</h4>
        <h4 className="other-details-expected-salary">
          {profile?.expected_salary}
        </h4>
      </div>
    </div>
  );
};

export default ProfileDetails;
