import React, { useEffect, useState } from "react";
import "../../Style/JobDetails/about.css";
import { advertising, advertising2 } from "../../Api/JobApi";
const AboutCompany = ({ details }) => {
  const [advrt, setAdvrt] = useState({});
  useEffect(() => {
    const loadData = () => {
      advertising().then((res) => {
        const response = res;

        setAdvrt(response.data);
      });
    };
    loadData();
  }, []);
  const [advrt2, setAdvrt2] = useState({});
  useEffect(() => {
    const loadData = () => {
      advertising2().then((res) => {
        const response = res;

        setAdvrt2(response.data);
      });
    };
    loadData();
  }, []);
  return (
    <div className="about-com-main">
      <div className="About-page">
        <h2 className="about">About The Company</h2>
        <div className="img-text-container">
          <img
            className="job-info-logo"
            src={details?.organization_details?.image}
            alt="logo"
          />
          <p className="job-text">{details.about_company}</p>
        </div>
        <div className="job-border1"></div>
        <h2 className="about">Responsibilities</h2>
        <div className="img-text-container">
          <p className="response-job-text">{details.responsibilities}</p>
        </div>
        <div className="job-border1"></div>
        <h2 className="about">Other Skills and Experience</h2>
        <div className="img-text-container">
          <p className="response-job-text">{details.other}</p>
        </div>
        <div className="job-border1"></div>
        <h2 className="about">Perks</h2>
        <div className="img-text-container">
          <p className="response-job-text">{details.perks}</p>
        </div>
      </div>
      <div>
        <div>
          {advrt?.image === undefined ? (
            ""
          ) : (
            <img className="about-shadow" src={advrt?.image} alt="rectangle" />
          )}
        </div>

        <div>
          {advrt2.image === undefined ? (
            ""
          ) : (
            <img className="about-shadow" src={advrt2?.image} alt="rectangle" />
          )}
        </div>
      </div>
    </div>
  );
};

export default AboutCompany;
