import React, { useState, useEffect } from "react";
import { bookSlot, deleteUpdateDetail } from "../../Api/Mentor";
import moment from "moment";
const GetAvailbilty = ({ setUpDate, upDate }) => {
  const [slot, setSlot] = useState([]);
  useEffect(() => {
    const loadData = () => {
      bookSlot()?.then((res) => {
        const response = res?.data;

        setSlot(response);
        // setUpDate(!upDate);
      });
    };
    loadData();
  }, [upDate]);
  return (
    <div>
      <div className="my-4">
        <h1>My Slot</h1>
        {slot
          ?.slice(0)
          .reverse()
          .map((value) => {
            return (
              <div className="card mb-4 mx-2">
                <div className="card-body d-flex justify-content-between">
                  <div>
                    <h5 className="card-title">{value?.name}</h5>
                    <p className="card-text">
                      Start From:
                      {moment(value?.start_date).format("DD/MM/YYYY HH:MM")}
                    </p>

                    <p className="card-text">
                      End From:
                      {moment(value?.start_date).format("DD/MM/YYYY HH:MM")}
                    </p>
                    <p className="card-text">
                      Session Fees (INR):
                      {value?.price === null ? "0" : value?.price}
                    </p>
                  </div>
                  <div></div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default GetAvailbilty;
