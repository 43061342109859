import React, { useState, useEffect } from "react";

import { staicPageDetails } from "../../Api/api";

import { useParams } from "react-router-dom";

const Static = () => {
  let { slug } = useParams();

  const [statices, setStatices] = useState("");

  useEffect(() => {
    const loadData = () => {
      staicPageDetails(slug).then((res) => {
        const response = res;

        setStatices(response.data);
      });
    };
    loadData();
  }, [slug]);
  return <div dangerouslySetInnerHTML={{ __html: statices?.content }} />;
};

export default Static;
