import React from "react";
import FeaturesProfileDetails from "../../Component/FeaturesProfiledetails/FeaturesProfileDetails";
import { Helmet } from "react-helmet";
const FeaturesDetails = () => {
  return (
    <div>
      <Helmet>
        <title>55notout | Talent Featured Profile Details</title>
        <meta name="description" content="Talents Featured Profile Details" />
      </Helmet>
      <FeaturesProfileDetails />
    </div>
  );
};

export default FeaturesDetails;
