import React from "react";
import LocationIcon from "../../Image/location.svg";
import "../../Style/EventsDetails/AboutEvent.css";
import EventDesp from "./EventDesp";
import Insta from "../../Image/insta.png";
import Fb from "../../Image/fb.png";
import Twiter from "../../Image/twiter.png";
import Linkdin from "../../Image/Linkdin.png";
import clander from "../../Image/clender.svg";
import { ToastContainer, toast } from "react-toastify";
import Motivation from "../../Image/Motivation.svg";
import moment from "moment";
import { useLocation } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
} from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FacebookIcon, TwitterIcon } from "react-share";
const AboutEvent = ({ detail, apply, loadData }) => {
  let state = useLocation();
  const applyhandle = () => {
    loadData();

    toast(apply?.msg);
    toast(apply?.data);
  };

  return (
    <>
      <div className="about-main">
        <div className="About-event">
          <img
            className="about-img"
            src={detail?.thumbnail_images}
            alt="AboutEvent"
          />
          <h1 className="about-title">{detail?.name}</h1>
        </div>
      </div>
      <div className="about-second-container">
        <div className="about-temp">
          <div className="about-img-container">
            <img
              className="about-img1"
              src={detail?.banner_image}
              alt="AboutEvent"
            />
          </div>
          <div className="Desktop">
            <EventDesp detail={detail} />
          </div>
        </div>

        <div className="right-side-container">
          <button
            className="GoEvent"
            onClick={() => window.open(detail?.website)}
          >
            Go to Event
            <ToastContainer />
          </button>

          <div className="date-box">
            <h1 className="date-time">Date & Time</h1>
            <div className="date-Container">
              <h1 className="start">Start:</h1>
              <h4 className="start-end-date">
                {moment(detail.start_date).format("DD/MM/YYYY")}
              </h4>
              <img className="clender-icon" src={clander} alt="info" />
              <h4 className="start-end-time">
                {moment(detail.start_date).format("hh:mm a")}
              </h4>
              <FontAwesomeIcon className="clockIcon" icon={faClock} />
            </div>
            <div className="date-Container">
              <h1 className="end">End:</h1>
              <h4 className="start-end-date">
                {moment(detail.end_date).format("DD/MM/YYYY")}
              </h4>
              <img className="clender-icon" src={clander} alt="info" />
              <h4 className="start-end-time">
                {moment(detail.end_date).format("hh:mm a")}
              </h4>
              <FontAwesomeIcon className="clockIcon" icon={faClock} />
            </div>
          </div>

          <div className="location-container">
            <h1 className="about-loacation">Location</h1>
            <div className="mode-container">
              <img
                className="location-mode"
                src={LocationIcon}
                alt="location"
              />
              {detail?.is_online === true ? (
                <h1 className="mode">Online</h1>
              ) : (
                <h1 className="mode">{detail?.location_details?.city_name}</h1>
              )}
            </div>
          </div>

          <div className="location-container">
            <h1 className="about-loacation">Event Entry Type</h1>
            <div className="mode-container">
              <h1 className="mode">
                {detail?.is_free === false ? "Paid" : "free"}
              </h1>
            </div>
          </div>

          <div className="social-container">
            <h1 className="about-social-media">Share across social media</h1>
            <div className="about-icon-container">
              <FacebookShareButton
                url={`http://55notout.com//${state?.pathname}`}
                quote={"Hey Check This Job"}
                hashtag={"55NotOut"}
                description={""}
              >
                <img className="about-social-icons" src={Fb} alt="fb" />
              </FacebookShareButton>
              <TwitterShareButton
                url={`http://55notout.com//${state?.pathname}`}
                quote={"Hey Check This Job"}
                hashtag={"55NotOut"}
                description={""}
              >
                <img
                  className="about-social-icons"
                  src={Twiter}
                  alt="twitter"
                />
              </TwitterShareButton>
              <LinkedinShareButton
                url={`http://55notout.com//${state?.pathname}`}
                quote={"Hey Check This Job"}
                hashtag={"55NotOut"}
                description={""}
              >
                <img
                  className="about-social-icons"
                  src={Linkdin}
                  alt="linkdin"
                />
              </LinkedinShareButton>
            </div>
          </div>
          <div className="mob-response">
            <EventDesp detail={detail} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutEvent;
