import React, { useRef } from "react";
import { changePassword } from "../../Api/api";
import { ToastContainer, toast } from "react-toastify";
const ForgetPassword = ({ closed }) => {
  const emails = useRef();
  const handleSubmit = (e) => {
    e.preventDefault();
    const userEmail = emails.current.value;
    const data = {
      email: userEmail,
    };
    changePassword(data)
      .then((res) => {
        const response = res;
        if (response?.status === 200) {
          toast("suceesfull", {
            autoClose: 500,
            position: toast.POSITION.TOP_RIGHT,
          });

          setTimeout(() => {
            window.location.href = "/";
          }, 1000);
        }
      })
      .catch((e) =>
        e.response.status === 400
          ? toast(" Bad Request", {
              autoClose: 500,
              position: toast.POSITION.TOP_RIGHT,
            })
          : ""
      );
  };
  return (
    <div className="change-pswrd-header-main">
      <h1 onClick={closed} style={{ padding: "5px", float: "right" }}>
        X
      </h1>
      <h1 className="login">Forgot Password</h1>
      <form onSubmit={handleSubmit}>
        <div className="Input_box">
          <input
            className="email"
            type="text"
            placeholder="Enter Your Email Address"
            ref={emails}
            required
          />
        </div>
        <button type="submit" className="loginPage-btn">
          Send
        </button>
      </form>
    </div>
  );
};

export default ForgetPassword;
