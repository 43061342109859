import React, { useState, useEffect } from "react";
import "./Search.css";
import { AllJob } from "../../Api/JobApi";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Slider from "@mui/material/Slider";
// import Listing from "../FullTime-jobs/Listing";
import RealatedJob from "../JobDetails/RealatedJob";
import { Address } from "../../Api/OrgApi";
import { ListJob } from "../../Api/JobApi";
import { skillNames } from "../../Api/TalentsApi";
import Listing from "./Listing";
import FilterImage from "../../Image/filters.png";
import Drawer from "@mui/material/Drawer";
import { useParams } from "react-router-dom";
function valuetext(value) {
  return `${value}°C`;
}
const JobFilter = () => {
  const { slug } = useParams();
  const [list, setList] = useState([]);
  const [address, setAddress] = useState([]);
  const [appDrawer, setAppDrawer] = useState(false);
  const [filterValue, setFilterValue] = React.useState({
    title: "",
    job_type:
      slug === "Job"
        ? "full_time"
        : slug === "Consultings"
        ? "Consulting"
        : slug === "Volunteers"
        ? "Volunteering"
        : "",
    job_mode: "",
    location: "",
    is_featured_post: "",
    key_skills: "",
  });
  const [filterValuemin, setFilterValuemax] = React.useState({});
  const [skill, setSkill] = useState([]);

  const [value, setValue] = React.useState([0, 100000]);

  const handleChange2 = (e) => {
    const { name, value } = e.target;

    setFilterValue({ ...filterValue, [name]: value });
  };
  const handleClear = () => {
    setFilterValue({
      title: "",
      job_type: "full_time",
      job_mode: "",
      location: "",
      is_featured_post: "",
      key_skills: "",
      // salary_min: "",
    });
  };

  useEffect(() => {
    const loadData = () => {
      AllJob(filterValue).then((res) => {
        setList(res);
      });
    };
    loadData();
  }, [filterValue]);

  useEffect(() => {
    const loadData = () => {
      Address().then((res) => {
        const response = res;
        const data = response.data;
        // let temp = { ...edit, location: data[0]?.id + "" };
        // setEdit(temp);
        setAddress(data);
      });
    };
    loadData();
  }, []);

  useEffect(() => {
    const loadData = () => {
      skillNames().then((res) => {
        const response = res;

        setSkill(response.results);
      });
    };
    loadData();
  }, []);

  const minMarks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 20000,
      label: "20k",
    },
    {
      value: 40000,
      label: "40k",
    },
    {
      value: 100000,
      label: "100k",
    },
  ];
  const maxMarks = [
    {
      value: 150000,
      label: "1.5L",
    },
    {
      value: 250000,
      label: "2.5L",
    },
    {
      value: 350000,
      label: "3.5L",
    },
    {
      value: 500000,
      label: "5L",
    },
  ];

  const handleOpen = () => {
    setAppDrawer(!appDrawer);
  };

  return (
    <>
      <img
        src={FilterImage}
        alt=""
        onClick={handleOpen}
        className="responsiveFilterIcon"
        // style={{ display: "none" }}
      />
      <div className="SearchMainContainer">
        <div className="filterSection">
          <div className="filter-section">
            <h4 className="filter">Filter</h4>
            <span className="clear" onClick={handleClear}>
              Clear
            </span>
          </div>
          <form>
            <div>
              <h4 className="filter">Title</h4>
              <FormControl fullWidth>
                <TextField
                  // id="outlined-basic"
                  label="Job Title"
                  variant="outlined"
                  value={filterValue?.title}
                  name="title"
                  onChange={handleChange2}
                  id="Book Name"
                  className="inputBox"
                />
              </FormControl>
            </div>
            <div>
              <h4 className="filter">Job Types</h4>
              <FormControl fullWidth>
                <TextField
                  // id="outlined-basic"
                  label="Job Title"
                  variant="outlined"
                  value={
                    slug === "Job"
                      ? "Full Time"
                      : slug === "Consultings"
                      ? "Consulting"
                      : slug === "Volunteers"
                      ? "Volunteering"
                      : ""
                  }
                  name="job_type"
                  id="Book Name"
                  className="selectedValue"
                />
              </FormControl>
            </div>
            <div>
              <h4 className="filter">Job Mode</h4>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={filterValue?.job_mode}
                  label="Selecttttt"
                  name="job_mode"
                  onChange={handleChange2}
                  className="selectedValue"
                >
                  <MenuItem value="Work from Home" className="selectedValue">
                    Work from Home
                  </MenuItem>
                  <MenuItem value="Work from Office" className="selectedValue">
                    Work from Office
                  </MenuItem>
                  <MenuItem value="Hybrid" className="selectedValue">
                    Hybrid
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <h4 className="filter">Location</h4>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="location"
                  label="Selecttttt"
                  onChange={handleChange2}
                  defaultValue=""
                  value={filterValue?.location}
                  className="selectedValue"
                >
                  {address.map((value, key) => {
                    return (
                      <MenuItem value={value?.id} className="selectedValue">
                        {value?.city_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div>
              <h4 className="filter">Featured</h4>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  //   value={age}
                  name="is_featured_post"
                  label="Selecttttt"
                  onChange={handleChange2}
                  value={filterValue?.is_featured_post}
                  className="selectedValue"
                >
                  <MenuItem value="true" className="selectedValue">
                    Yes
                  </MenuItem>
                  <MenuItem value="false" className="selectedValue">
                    No
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <h4 className="filter">Industry</h4>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="key_skills"
                  label="Selecttttt"
                  value={filterValue?.key_skills}
                  onChange={handleChange2}
                  className="selectedValue"
                >
                  {skill.map((value) => {
                    return (
                      <MenuItem value={value?.id} className="selectedValue">
                        {value?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <div>
                  <h4 className="filter">Salary</h4>
                  <div className="filter-section">
                    <h4 className="filter">Min</h4>
                  </div>
                  <Slider
                    getAriaLabel={() => "Temperature range"}
                    value={filterValue?.salary_min}
                    onChange={handleChange2}
                    step={10000}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                    marks={minMarks}
                    min={0}
                    max={100000}
                    name="salary_min"
                  />
                  <div className="filter-section">
                    <h4 className="filter">Max</h4>
                  </div>
                  <Slider
                    getAriaLabel={() => "Temperature range"}
                    // value={value}
                    onChange={handleChange2}
                    step={10000}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                    marks={maxMarks}
                    min={120000}
                    max={500000}
                    name="salary_max"
                  />
                </div>
              </FormControl>
            </div>
          </form>
        </div>
        {/* Mobile Application */}
        <Drawer
          anchor={"left"}
          open={appDrawer}
          onClose={() => setAppDrawer(false)}
          // className="filterDrawer"
        >
          <div className="filterDrawer">
            <div className="filter-section">
              <h4 className="filter">Filter</h4>
              <span className="clear">Clear</span>
            </div>

            <div>
              <h4 className="filter">Title</h4>
              <FormControl fullWidth>
                <TextField
                  // id="outlined-basic"
                  label="Job Title"
                  variant="outlined"
                  className="inputBox"
                  name="title"
                  onChange={handleChange2}
                  id="Book Name"
                />
              </FormControl>
            </div>
            <div>
              <h4 className="filter">Job Types</h4>
              <FormControl fullWidth>
                <TextField
                  // id="outlined-basic"
                  label="Job Title"
                  variant="outlined"
                  value={
                    slug === "Job"
                      ? "Full Time"
                      : slug === "Consultings"
                      ? "Consulting"
                      : slug === "Volunteers"
                      ? "Volunteering"
                      : ""
                  }
                  name="job_type"
                  id="Book Name"
                  className="selectedValue"
                />
              </FormControl>
            </div>
            <div>
              <h4 className="filter">Job Mode</h4>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  //   value={age}
                  label="Selecttttttttttttttt"
                  name="job_mode"
                  onChange={handleChange2}
                  className="selectedValue"
                >
                  <MenuItem value="Work from Home" className="selectedValue">
                    Work from Home
                  </MenuItem>
                  <MenuItem value="Work from Office" className="selectedValue">
                    Work from Office
                  </MenuItem>
                  <MenuItem value="Hybrid" className="selectedValue">
                    Hybrid
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <h4 className="filter">Location</h4>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="location"
                  label="Selecttttttttttttttt"
                  onChange={handleChange2}
                  defaultValue=""
                  className="selectedValue"
                >
                  {address.map((value, key) => {
                    return (
                      <MenuItem value={value?.id} className="selectedValue">
                        {value?.city_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div>
              <h4 className="filter">Featured</h4>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  //   value={age}
                  name="is_featured_post"
                  label="Selecttttttttttttttt"
                  onChange={handleChange2}
                  className="selectedValue"
                >
                  <MenuItem value="true" className="selectedValue">
                    Yes
                  </MenuItem>
                  <MenuItem value="false" className="selectedValue">
                    No
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <h4 className="filter">Key Skills</h4>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="key_skills"
                  label="Selecttttttttttttttt"
                  onChange={handleChange2}
                  className="selectedValue"
                >
                  {skill.map((value) => {
                    return (
                      <MenuItem value={value?.id} className="selectedValue">
                        {value?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <div>
                  <h4 className="filter">Salary</h4>
                  <div className="filter-section">
                    <h4 className="filter">Min</h4>
                  </div>
                  <Slider
                    getAriaLabel={() => "Temperature range"}
                    // value={value}
                    onChange={handleChange2}
                    step={10000}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                    marks={minMarks}
                    min={0}
                    max={100000}
                    name="salary_min"
                  />
                  <div className="filter-section">
                    <h4 className="filter">Max</h4>
                  </div>
                  <Slider
                    getAriaLabel={() => "Temperature range"}
                    // value={value}
                    onChange={handleChange2}
                    step={10000}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                    marks={maxMarks}
                    min={120000}
                    max={500000}
                    name="salary_max"
                  />
                </div>
              </FormControl>
            </div>
          </div>
        </Drawer>

        <div className="listing">
          <Listing listJob={list} />
        </div>
      </div>
    </>
  );
};

export default JobFilter;
