import React, { useState, useEffect } from "react";
import { heroHeadline, HomeService, HomeCard } from "../Api/api";
import "../Style/platforms.css";
import Insta from "../Image/Finsta.jpg";
import Fb from "../Image/Fbook.jpg";
import Twiter from "../Image/Ftwitter.jpg";
import Linkdin from "../Image/Flinkdin.jpg";
const Platforms = () => {
  const [head, setHead] = useState({});
  const [card, setCard] = useState([]);
  const [headLine, setHeadLine] = useState({});

  useEffect(
    () => {
      const loadData = () => {
        HomeService().then((res) => {
          const response = res;

          setHead(response.data);
        });
      };

      const HomeCardData = () => {
        HomeCard().then((res) => {
          const response = res;

          setCard(response.data);
        });
      };
      const HeroLine = () => {
        heroHeadline().then((res) => {
          const response = res;
          setHeadLine(response.data);
        });
      };

      loadData();
      HomeCardData();
      HeroLine();
    },
    [],
    {}
  );
  return (
    <>
      <div className="platformContaier">
        <div className="Platform-details">
          <h1 className="Phead">{headLine?.title}</h1>
          <p className="para">{headLine?.content}</p>
        </div>
        <div className="overlay-img">
          {card.slice(0, 2).map((value, index) => {
            return (
              <div>
                <img className="overley" src={value?.image} alt="imgs" />

                <h1 className="overText">{value?.title}</h1>

                <button
                  onClick={() => window.open("/signUp")}
                  className="signUp"
                >
                  {value.button_text}
                </button>
              </div>
            );
          })}
        </div>
      </div>

      <div className="platformContaier2">
        <h1 className="plat2text">{head.title_1}</h1>
        <h1 className="platText">{head.title_2}</h1>
        <div className="icons">
          <div className="iconText">
            <img src={head.icon_1} alt="imgs" />
            <h6>Jobs</h6>
          </div>

          <div className="iconText">
            <img src={head.icon_2} alt="imgs" />
            <h6>Volunteering</h6>
          </div>
          <div className="iconText">
            <img src={head.icon_3} alt="imgs" />
            <h6>Mentoring</h6>
          </div>
          <div className="iconText">
            <img src={head.icon_4} alt="imgs" />
            <h6>Events</h6>
          </div>
          <div className="iconText">
            <img src={head.icon_5} alt="imgs" />
            <h6>Community</h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default Platforms;
