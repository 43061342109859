import React, { useState, useEffect } from "react";
import "../../Style/ListOrag.css";
import { TalentsIcon } from "../../Api/api";

const ListOraginsation = () => {
  const [talentsIcon, setTalentsIcon] = useState([]);
  useEffect(() => {
    const loadData = () => {
      TalentsIcon().then((res) => {
        const response = res;

        setTalentsIcon(response.data);
      });
    };
    loadData();
  }, []);
  return (
    <div>
      <div>
        <h4 className="ListOrag1">Listed Organisations</h4>
        <h1 className="ListHead">
          Sign up to check out the listings & opportunities
        </h1>
      </div>
      <div className="TsocialIcon">
        {talentsIcon.map((value) => {
          return (
            <img
              className="sIcon"
              src={value?.image}
              alt="Google"
              onClick={() => {
                window.open(value?.url);
              }}
            />
          );
        })}
      </div>

      <div className="ListOrgtBtn" onClick={() => window.open("/signUp")}>
        SIGNUP NOW
      </div>
    </div>
  );
};

export default ListOraginsation;
