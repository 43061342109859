import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
// import { Carousel } from "react-responsive-carousel";
import Carousel from "react-elastic-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import "../../Style/featuredProfile/Feature.css";

import { listMentors } from "../../Api/Mentor";
import { listTalentProfile } from "../../Api/TalentsApi";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 250, itemsToShow: 2, itemsToScroll: 2 },
  { width: 500, itemsToShow: 3 },
  { width: 1000, itemsToShow: 4 },
];
const FeaturedListProfile = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    listTalentProfile().then((res) => {
      const response = res;

      setData(response);
    });
  }, []);
  const carouselProp = {
    showStatus: true,
    useKeyboardArrows: true,
    infiniteLoop: true,
    autoPlay: true,
    stopOnHover: true,
    emulateTouch: true,
    transitionTime: 400,
    showArrows: true,
    swipeable: true,
    renderArrowPrev: (clickHandler, hasPrev, label) => {
      return (
        <span className="arrow-left" onClick={clickHandler}>
          <span className="icon-keyboard_arrow_left">
            <FontAwesomeIcon icon={faAngleLeft} color="red" />
          </span>
        </span>
      );
    },
    renderArrowNext: (clickHandler, hasNext, label) => {
      return (
        <span className="arrow-right" onClick={clickHandler}>
          <span className="icon-keyboard_arrow_right">
            <FontAwesomeIcon icon={faAngleRight} color="red" />
          </span>
        </span>
      );
    },
  };
  return (
    <div>
      <Carousel
        {...carouselProp}
        showThumbs={false}
        infiniteLoop={false}
        centerMode
        centerSlidePercentage={25}
        breakPoints={breakPoints}
        // renderPagination={myArrow}
        className="realted-crousel"
      >
        {data.map((value) => {
          return (
            <div className="Feature-job-main2">
              <NavLink to={`/featuredprofiledetails/${value.slug}`}>
                <img
                  className="Feature-Job-Img"
                  src={value?.image}
                  alt="Find Jobs"
                />
                <h1 className="Feature-Job-curated-name">
                  {value?.user?.first_name} {value?.user?.last_name}
                </h1>
                <h4 className="Feature-field">{value?.industry}</h4>
              </NavLink>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default FeaturedListProfile;
