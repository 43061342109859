import React, { useState, useEffect } from "react";
import Jobinfo from "../../Component/JobDetails/Jobinfo";
import AboutCompany from "../../Component/JobDetails/AboutCompany";
import RealatedJob from "../../Component/JobDetails/RealatedJob";
import RealatedCourse from "../../Component/JobDetails/RealatedCourse";
import CourseMentors from "../../Component/JobDetails/CourseMentors";
import SignupBtn from "../../Component/JobDetails/SignupBtn";
import { DetailJob, ApplyJob } from "../../Api/JobApi";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
const JobsDetails = () => {
  let { slug } = useParams();
  const [featuredJobDetails, setFeaturedJobDetails] = useState({});
  const [apply, setApply] = useState({});
  useEffect(() => {
    const loadData = () => {
      DetailJob({ slug }).then((res) => {
        const response = res;

        setFeaturedJobDetails(response.data);
      });
    };
    loadData();
  }, [slug]);

  const loadData = async () => {
    const response = await ApplyJob({ slug });

    setApply(response);
  };

  return (
    <div>
      <Helmet>
        <title>55notout | Job Details</title>
        <meta name="description" content="Jobs" />
      </Helmet>
      <Jobinfo details={featuredJobDetails} apply={apply} loadData={loadData} />
      <AboutCompany details={featuredJobDetails} />
      <RealatedJob />
      <RealatedCourse />
      <CourseMentors />
      <SignupBtn />
    </div>
  );
};

export default JobsDetails;
