import React, { useState, useEffect } from "react";
import moment from "moment";
import LocationIcon from "../../Image/location.svg";
import "../../Style/OrgAppProfile/Application.css";
import { NavLink, useLocation } from "react-router-dom";
import { jobShortlist } from "../../Api/OrgApi";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faClock } from "@fortawesome/free-solid-svg-icons";
const OrgApplication = ({ details, slug }) => {
  const [apply, setApply] = useState({});
  const [shortlist, setShortlist] = useState({});
  const navigate = useNavigate();
  const { state } = useLocation();

  const [selected, setSelected] = useState("ALL");
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(details);
    setSelected("ALL");
  }, [details]);

  useEffect(() => {
    const loadData = async () => {
      const response = await jobShortlist();

      setApply(response);
    };
    loadData();
  }, [slug]);

  const handleChange = (value) => {
    if (value === "ALL") {
      setList(details);
      setSelected(value);
      return;
    } else if (value === "SHORT") {
      setList(() => details.filter((e) => e.is_shortlisted));
      setSelected(value);
      return;
    } else if (value === "REJECT") {
      setList(() => details.filter((e) => e.is_rejeted));
      setSelected(value);
      return;
    } else {
      return;
    }
  };

  const handleClick = (id, act) => {
    const data = {
      action: act,
      application_id: id,
      slug: slug,
    };
    jobShortlist(data);
  };
  return (
    <>
      <div className="org-f-profile">
        <div className="filter-container">
          <h1 className="org-Personal-detail">{`${state?.job_title}`}</h1>
          <div>
            <select
              value={selected}
              onChange={(e) => handleChange(e.target.value)}
            >
              <option value={"ALL"}>All Aplicants</option>
              <option value={"SHORT"}>Shortlist</option>
              <option value={"REJECT"}>Rejected</option>
            </select>
          </div>
        </div>
        <div className="org-down-text">
          <div className="org-icon-down">
            <img
              className="org-down-social-icons"
              src={LocationIcon}
              alt="location"
            />
            <h1 className="down-icon-text">
              {state?.location_details?.city_name}
            </h1>
          </div>
          <div className="org-icon-down">
            <img
              className="org-down-social-icons"
              src="/Icons/Office.svg"
              alt="location"
            />
            <h1 className="down-icon-text">
              {moment(state.created_at).format("DD/MM/YYYY")}
            </h1>
          </div>
          <div className="org-icon-down">
            <FontAwesomeIcon
              className="clockIcon"
              icon={faClock}
              style={{ color: "#F66048" }}
            />
            <h1 className="down-icon-text">
              {moment(state?.created_at).format("hh:mm")}
            </h1>
          </div>
        </div>
      </div>
      <div className="orgn-app-container">
        {list.map((value) => {
          return (
            <div>
              <div
                className="org-user-profile-container"
                onClick={() =>
                  navigate(`/applicationView/${value?.talent_details?.slug}`, {
                    state: value,
                  })
                }
              >
                <img
                  className="org-user-profile"
                  src={value?.talent_details?.image}
                  alt="profile"
                />

                <div className="orgn-app-details">
                  <h1 className="org-app-name">{value?.talentDetails?.name}</h1>
                  <div className="org-app-det">
                    <h1 className="org-app-details">Experience:</h1>
                    <h1 className="org-app-details">
                      {value?.talent_details?.experience_in_years} Years{" "}
                      {value?.talent_details?.experience_in_months}
                      Months
                    </h1>
                  </div>
                  <div className="org-app-det">
                    <h1 className="org-app-details">Location:</h1>
                    <h1 className="org-app-details">
                      {value?.job_details?.location}
                    </h1>
                  </div>
                  <div className="org-app-det">
                    <h1 className="org-app-details">Applied On:</h1>
                    <h1 className="org-app-details">
                      {moment(value.created_at).format("DD/MM/YYYY")}
                    </h1>
                  </div>
                </div>

                <div className="org-app-buttun">
                  <div
                    className="org-shortlist-btn"
                    onClick={() => {
                      handleClick(value.id, 1);
                    }}
                  >
                    Shortlist
                  </div>
                  <div
                    className="org-reject-btn"
                    onClick={() => {
                      handleClick(value.id, 2);
                    }}
                  >
                    Reject
                  </div>
                  <a href={value?.talent_details?.resume}>
                    <h1 className="org-download-btn">Download CV</h1>
                  </a>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default OrgApplication;
