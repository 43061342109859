import React from "react";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
const EventListing = ({ list }) => {
  const popUp = () => {
    toast("Please Login To Continue", {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });

    <ToastContainer />;
  };
  return (
    <div>
      <div className="filter-listing-container">
        {list?.map((value) => {
          return (
            <div className="filter-Events-listing-job-main">
              {localStorage.getItem("TalentToken") ? (
                <NavLink
                  to={{
                    pathname: `/eventsdetails/${value?.slug}`,
                    state: { value },
                  }}
                >
                  <img
                    className="Events-listing-Job-Img"
                    src={value?.thumbnail_images}
                    alt="Find Jobs"
                  />
                  <h1 className="Events-listing-Job-Title">{value?.name}</h1>
                  <div className="Events-info-field">
                    <img src="Icons/Motivation.svg" alt="info" />
                    <span className="Events-Listing-field">
                      {value?.category}
                    </span>
                  </div>
                  <div className="Events-place-field">
                    <img src="Icons/location.png" alt="info" />
                    <span className="Events-listing-place">
                      {value?.location_details?.city_name}
                    </span>
                  </div>
                  <div className="Events-year-field">
                    <img src="Icons/clander.svg" alt="info" />
                    <span className="Events-listing-year">
                      {moment(value?.start_date).format("ll")}
                    </span>
                  </div>
                </NavLink>
              ) : (
                <NavLink to="" onClick={popUp}>
                  <img
                    className="Events-listing-Job-Img"
                    src={value?.thumbnail_images}
                    alt="Find Jobs"
                  />
                  <h1 className="Events-listing-Job-Title">{value?.name}</h1>
                  <div className="Events-info-field">
                    <img src="Icons/Motivation.svg" alt="info" />
                    <span className="Events-Listing-field">
                      {value?.category}
                    </span>
                  </div>
                  <div className="Events-place-field">
                    <img src="Icons/location.png" alt="info" />
                    <span className="Events-listing-place">
                      {value?.location_details?.city_name}
                    </span>
                  </div>
                  <div className="Events-year-field">
                    <img src="Icons/clander.svg" alt="info" />
                    <span className="Events-listing-year">
                      {moment(value?.start_date).format("ll")}
                    </span>
                  </div>
                </NavLink>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EventListing;
