import React, { useState, useEffect } from "react";
import "../../Style/MentorDashBoard/Review.css";
import { giveMentorReviewPost } from "../../Api/Mentor";
import Upload from "../../Image/upload.svg";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";

const Review = () => {
  let { slug } = useParams();

  const [bannerimg, setBannerImg] = useState();
  const [priview, setPreview] = useState();
  const [data, setData] = useState({
    name: "",
    review: "",
    image: "",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append(" review", data.review);

    giveMentorReviewPost(formData, slug)
      .then((res) => {
        const response = res;
        toast("Review Add SuccesFull", {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        });
        window.location.reload(false);
      })
      .catch((e) =>
        toast(e?.response?.data?.msg.non_field_errors[0], {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        })
      );
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };
  const handleThumbImage = (e) => {
    const image = e.target.files[0];
    setBannerImg(image);
  };
  useEffect(() => {
    const objectUrl = bannerimg;
    setPreview(objectUrl);
  }, [bannerimg]);
  return (
    <form onSubmit={handleSubmit} className="Review-main">
      <h1 className="Review-title">Review</h1>
      <div className="Availbility-price-container">
        <div className="price-container">
          <h1 className="Availbility-price">Name</h1>
        </div>

        <input
          className="Availbility-price-input"
          placeholder="Enter Your Name"
          type="text"
          name="name"
          onChange={handleInput}
          required
        />
      </div>
      <div className="Review-box-title-container">
        <h1 className="Review-box-title">Review</h1>
        <textarea
          className="Review-box"
          type="text"
          placeholder="Enter Review"
          name="review"
          onChange={handleInput}
          required
        />
      </div>

      <div>
        <button type="submit" className="Avail-save-btn">
          Save
          <ToastContainer />
        </button>
      </div>
    </form>
  );
};

export default Review;
