import OrgList from "./OrgList";
const OrgListTitle = ({ list }) => {
  return (
    <div>
      <button
        className="OrgtListOrgBtn"
        onClick={() => window.open("/orgSignUp")}
      >
        SIGNUP NOW
      </button>
    </div>
  );
};

export default OrgListTitle;
