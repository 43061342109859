import React, { useState, useEffect } from "react";
import ProfileDetils from "../DashBoard/ProfileDetils";
import { NavLink } from "react-router-dom";
import ApplicationDetails from "./ApplicationDetails";
import MentorButton from "./MentorButton";
import Availbility from "./Availbility";
import MentorProfileDetails from "./MentorProfileDetails";
import MentorProfile from "./MentorProfile";
import { showPrice } from "../../Api/api";
import {
  offerData,
  postCreateOrder,
  createPayment,
} from "../../Api/PaymentGetway";
import { displayRazorpay } from "../../Utill/Utility";
const jobsData = [{ title: "jobs1" }];
const eventsData = [{ title: "events1" }];

const MentorFeaturesProfile = ({ slug }) => {
  const [offer, setOffer] = useState([]);
  const [price, setPrice] = useState([]);
  const [selected, setSelected] = useState("PROFILE");
  const [profile, setProfile] = useState([]);
  const [application, setApplication] = useState([]);
  const [Mentor, setMentor] = useState([]);

  const handleChange = (key) => {
    setSelected(key);
    switch (key) {
      case "PROFILE":
        setProfile(jobsData);
        break;
      case "APPLICATION":
        setApplication(eventsData);
        break;
      case "MENTOR":
        setMentor(eventsData);

        break;

      default:
        break;
    }
  };

  useEffect(() => {
    setSelected("PROFILE");
    setProfile(jobsData);
  }, []);
  const getComponent = () => {
    switch (selected) {
      case "PROFILE":
        return profile.map((data, key) => <MentorProfileDetails />);

      case "APPLICATION":
        return application.map((data, key) => <ApplicationDetails />);

      case "MENTOR":
        return Mentor.map((data, key) => <Availbility />);
      default:
        return <h1>No records found</h1>;
    }
  };
  useEffect(() => {
    const loadData = () => {
      showPrice().then((res) => {
        setPrice(res?.data);
      });
    };
    loadData();
  }, []);
  useEffect(() => {
    const loadData = () => {
      offerData().then((res) => {
        const response = res.data;
        setOffer(response);
      });
    };
    loadData();
  }, []);

  var api_1_data = {};
  var api_2_data = {};

  async function handleClick(id, ammount) {
    const data = {
      offer: id,
      ammount: ammount,
    };

    await postCreateOrder(data)
      .then((res) => {
        const response = res;

        api_1_data = response.data;
      })
      .catch((e) => console.log("e", e));

    const data1 = {
      amount: ammount,
      order_id: api_1_data.order_number,
      currency: "INR",
    };

    await createPayment(data1).then((resp) => {
      const response2 = resp.data;
      api_2_data = response2;

      displayRazorpay(api_2_data.data);
    });
  }

  return (
    <div>
      <div className="f-profile">
        <h1 className="dash-title">Dashboard</h1>
        <NavLink to={`/p/faq`} className="become-mentor-btn">
          <h1>Mentor FAQs</h1>
        </NavLink>

        {localStorage.getItem("feature_mentor") === "false" ? (
          <button
            className="dash-btn"
            onClick={() => handleClick(offer[0]?.id, offer[0]?.ammount)}
          >
            Feature your Profile
          </button>
        ) : (
          ""
        )}
      </div>
      <div className="user-details">
        <MentorProfile />

        <div className="m-dash-btn-container">
          <MentorButton selected={selected} setSelected={handleChange} />
          <div>{getComponent()}</div>
        </div>
      </div>
      ;
    </div>
  );
};

export default MentorFeaturesProfile;
