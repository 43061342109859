import React from "react";
import OpportunityBox from "../../Component/TalentFile/OpportunityBox";
import FeaturedListProfile from "../../Component/FeaturedProfile/FeaturedListProfile";
import Listed from "../../Component/FeaturedProfile/Listed";
import { Helmet } from "react-helmet";
import { Navbar } from "../../Component/Navbar";
const FeaturedProfile = () => {
  const data = {
    title: "Handpicked Profiles. Personalised Experiences. ",
    content:
      "Welcome to our Hall of Skills curated by 50+ professionals! With different sets of skills and expertise, their profiles are categorised and featured for you to find the right match for your organisation. Have a look at some of these profiles by signing up with 55NotOut below!",
    url: "signUp",
    image: "Images/talent.png",
    button_text: "SIGNUP NOW",
  };
  return (
    <div>
      <Helmet>
        <title>55notout | Talent Featured Profile</title>
        <meta name="description" content="Talents Featured Profile" />
      </Helmet>
      <Navbar />
      <OpportunityBox data={data} />
      <Listed />
      <FeaturedListProfile />
    </div>
  );
};

export default FeaturedProfile;
