import React, { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faXmark } from "@fortawesome/free-solid-svg-icons";
// import { AuthTalentLogin } from "../../Api/api";
import { ToastContainer, toast } from "react-toastify";
import { AuthOrgLogin } from "../../Api/OrgApi";
const OrgLogin = ({ setShowSignUpModel, close, setForget }) => {
  const email = useRef();
  const password = useRef();
  const submit = (e) => {
    e.preventDefault();
    const loginData = {
      email: email.current.value,
      password: password.current.value,
    };

    AuthOrgLogin(loginData)
      .then((res) => {
        const response = res;
        if (response?.status === 200) {
          // navigate(`/dashboard`);

          toast("Login SuccesFull", {
            autoClose: 1000,
            position: toast.POSITION.TOP_RIGHT,
          });
          // notify();
          setTimeout(() => {
            window.location.href = "/orgdashboard";
          }, 1000);
        }

        localStorage.removeItem("TalentToken");
        localStorage.removeItem("mentor_slug");
        localStorage.removeItem(
          "feature_talent",
          response?.data?.is_feature_talent
        );
        localStorage.removeItem(
          "feature_mentor",
          response?.data?.is_feature_mentor
        );
        localStorage.setItem("token", response.data.token);
      })
      .catch((e) =>
        toast(e.response.data.non_field_errors[0], {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
        })
      );
  };
  return (
    <div>
      <div className="Login-display">
        <div className="Login-Container">
          <FontAwesomeIcon
            className="close"
            onClick={close}
            icon={faXmark}
            style={{ borderRadius: "50%", padding: "2%", cursor: "pointer" }}
          />
          <NavLink to="/">
            <img
              className="login-page-header"
              src="Icons/logo.png"
              alt="logo"
            />
          </NavLink>

          <div className="header-main"></div>
          <h1 className="login">Organization Login</h1>
          <form onSubmit={submit}>
            <div className="Input_box">
              <input
                className="email"
                type="text"
                placeholder="Enter your email address"
                ref={email}
                required
              />
              <input
                className="password"
                type="password"
                placeholder="Enter your password"
                ref={password}
                required
              />
            </div>
            <h6 className="forget" onClick={setForget}>
              Forget Password
            </h6>
            <button type="submit" className="loginPage-btn">
              LOGIN
              <FontAwesomeIcon className="icon" icon={faAngleRight} />
              {window.innerWidth < "720" ? <ToastContainer /> : ""}
            </button>

            <div className="resgister-box">
              <h6 className="register">Not Registered yet?</h6>
              <h6 onClick={setShowSignUpModel} className="create-link">
                Create Your Organization's Account.
              </h6>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OrgLogin;
