import React, { useState } from "react";
import "../../Style/Orgn/orgNav.css";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
const OrgNav = () => {
  const popUp = () => {
    toast("Please Login To Continue", {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const [toggle, setToggle] = useState(false);
  function handleClick() {
    setToggle(!toggle);
  }
  const OrgNavbar = () => {
    return (
      <div className="org-navbar">
        <div
          style={{
            display: window.innerWidth < "720" && !toggle ? "none" : "flex",
          }}
          className="org-mainNav"
        >
          <div className="org-left-nav">
            {localStorage.getItem("token") ? (
              <Link to={"/postjob"}>Post Opportunities </Link>
            ) : (
              <Link to={""} onClick={popUp}>
                Post Opportunities
              </Link>
            )}
            {localStorage.getItem("token") ? (
              <Link to={"/mentorLists"}>Find a Mentor</Link>
            ) : (
              <Link to={"/mentorLists"}>Find a Mentor</Link>
            )}

            {localStorage.getItem("token") ? (
              <Link to={"/eventPosting"}>List an Event</Link>
            ) : (
              <Link to={""} onClick={popUp}>
                List an Event{" "}
              </Link>
            )}
            {localStorage.getItem("token") ? (
              <Link to={"/coursePosting"}>List a Course</Link>
            ) : (
              <Link to={""} onClick={popUp}>
                List a Course
              </Link>
            )}
            {localStorage.getItem("token") ? (
              <Link to={"/productPosting"}>List a Product</Link>
            ) : (
              <Link to={""} onClick={popUp}>
                List a Product
              </Link>
            )}
            {localStorage.getItem("token") ? (
              <Link to={"/servicePosting"}>List a Service</Link>
            ) : (
              <Link to={""} onClick={popUp}>
                List a Service
              </Link>
            )}
          </div>
          <div className="org-right-nav">
            {localStorage.getItem("token") ? (
              <Link to={"/orgdashboard"}>Dashboard </Link>
            ) : (
              ""
            )}
            {localStorage.getItem("token") ? (
              <Link to={""}>List Company </Link>
            ) : (
              ""
            )}
            {localStorage.getItem("token") ? (
              <Link to={"/BrowserFeaturedProfiles"}>
                Browse Featured Profile{" "}
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
        <ToastContainer />;
      </div>
    );
  };

  return (
    <div className=" org-navbar">
      <div
        className={toggle ? `filter_btn filter_btn_active` : "filter_btn "}
        onClick={handleClick}
      >
        <img
          src={toggle ? "/images/close.png" : "/images/filter.png"}
          alt="list"
          title="Filter"
        />
      </div>
      <OrgNavbar />
    </div>
  );
};

export default OrgNav;
