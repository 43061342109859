import React, { useState, useEffect } from "react";
import Carousel from "react-elastic-carousel";
import { Testimonials } from "../Api/api";
import "../Style/Testimonial.css";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 2500, itemsToShow: 2, itemsToScroll: 2 },
  { width: 3500, itemsToShow: 3 },
  { width: 4500, itemsToShow: 4 },
];
const TestimonialBox = () => {
  const [testi, setTesti] = useState([]);
  useEffect(() => {
    const loadData = () => {
      Testimonials().then((res) => {
        const response = res;

        setTesti(response.data);
      });
    };
    loadData();
  }, []);
  const myArrow = ({ onClick, isEdge }) => {
    return <button onClick={onClick} disabled={isEdge}></button>;
  };
  return (
    <div>
      <div className="Appp">
        <hr className="seperator" />
        <div className="carousel-wrapper">
          <Carousel breakPoints={breakPoints} renderArrow={myArrow}>
            {testi.map((value) => {
              return (
                <div className="commentBox">
                  <img className="cmtDp" src={value.image} alt="comDp" />
                  <h4 className="commentName">{value.title}</h4>
                  <p className="comments">{value.description}</p>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default TestimonialBox;
