import React, { useState, useEffect } from "react";
import { mentorSlotList, aplicantsDetails } from "../../Api/Mentor";
import ShowSlot from "./ShowSlot";
import moment from "moment";
import { Modal } from "react-bootstrap";
const ApplicationDetails = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const handleSuceesClose = () => setIsSuccess(false);
  const handleSuceesShow = () => setIsSuccess(true);
  const [book, setBook] = useState([]);
  const [applicant, setApplicant] = useState([]);
  const slug = localStorage.getItem("mentor_slug");

  useEffect(() => {
    const loadData = () => {
      mentorSlotList({ slug }).then((res) => {
        const response = res.data;
        setBook(response);
      });
    };
    loadData();
  }, [slug]);
  const handleClick = (value) => {
    aplicantsDetails({ value }).then((res) => {
      const response = res.data;

      response["slot_slug"] = value;
      setApplicant(response);
      handleSuceesShow(true);
    });
  };
  //   useEffect(() => {
  //     const loadData = () => {
  //       aplicantsDetails({ slug1 }).then((res) => {
  //         const response = res;
  //       });
  //     };
  //     loadData();
  //   }, [slug1]);
  return (
    <div>
      <div>
        <div className="my-4">
          {book
            ?.slice(0)
            .reverse()
            ?.map((value) => {
              return (
                <div className="card mb-4 mx-2">
                  <div className="card-body d-flex justify-content-between">
                    <div>
                      <h5 className="card-title">{value?.name}</h5>
                      <p className="card-text">
                        Start From:
                        {moment(value?.start_date).format("DD/MM/YYYY HH:MM")}
                      </p>

                      <p className="card-text">
                        End From:
                        {moment(value?.start_date).format("DD/MM/YYYY HH:MM")}
                      </p>
                    </div>
                    <div>
                      {value?.is_booked === true ? (
                        <button
                          className="view-slot"
                          onClick={() => handleClick(value?.slug)}
                        >
                          Booked
                        </button>
                      ) : (
                        <button
                          className="view-slot"
                          onClick={() => handleClick(value?.slug)}
                        >
                          Details
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <Modal centered={true} show={isSuccess}>
        <ShowSlot applicant={applicant} closed={handleSuceesClose} />
      </Modal>
    </div>
  );
};

export default ApplicationDetails;
