import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "../../Style/AllDetails/Personal.css";
import { persnolPut } from "../../Api/TalentsApi";
import { skillNames } from "../../Api/TalentsApi";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";
import { Address } from "../../Api/OrgApi";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
import MultipleSelect from "./Multiselect";
import moment from "moment";
const Personal = ({ dataP, closeModal }) => {
  const [value, setvalue] = useState("");
  const [address, setAddress] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [data, setData] = useState({});

  const [img, setImg] = useState();

  const [skillList, setSkillList] = useState([]);
  const handleOnchange = (val) => {
    setvalue(val);
    setData({ ...data, ["skills"]: [value] });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };

  const handleImage = (e) => {
    const image = e.target.files[0];
    const formData1 = new FormData();
    formData1.append("image", image);
    persnolPut(formData1, true).then((res) => {
      setImg(res.data?.image);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let temp = { ...data };
    temp.skills = selectedSkills.toString();
    persnolPut(temp, false)
      .then((res) => {
        const response = res;

        if (response.status === 200) {
          toast("Profile Detail Update suceessfull", {
            autoClose: 500,
            position: toast.POSITION.TOP_RIGHT,
          });
          setTimeout(() => {
            window.location.href = "/dashboard";
          }, 1000);
        }
      })
      .catch((e) =>
        toast(e.response.data.error.experience_in_years[0], {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        })
      );
  };
  const cancel = () => {
    closeModal();
  };
  useEffect(() => {
    const loadData = () => {
      skillNames().then((res) => {
        const response = res;
        let temp = response.results;

        const newArrayOfObj = temp.map(({ name: label, id: value }) => ({
          label,
          value,
        }));
        setSkillList(newArrayOfObj);
      });
    };
    loadData();
  }, []);

  useEffect(() => {
    const loadData = () => {
      Address().then((res) => {
        const response = res;
        const data1 = response?.data;
        let temp1 = { ...data, current_location: data1[0]?.id + "" };

        setAddress(data1);
      });
    };
    loadData();
  }, []);

  useEffect(() => {
    let skillFromBackend = dataP.skills || [];
    let temp2 = [];
    skillFromBackend.map((skill) => {
      temp2.push(skill?.id);
    });
    setSelectedSkills(temp2);

    const temp = {
      industry: dataP?.industry,
      expected_salary: dataP?.expected_salary,
      dob: dataP?.dob,
      current_location: dataP?.current_location?.id + "",
      annual_salary: dataP?.annual_salary,
      phone_numer: dataP?.phone_numer,
      experience_in_years: dataP?.experience_in_years,
      experience_in_months: dataP?.experience_in_months,
      area: dataP?.area,
      gender: dataP?.gender,
      user: dataP?.user,
      linkedin_profile: dataP?.linkedin_profile,
      twitter: dataP?.twitter,
    };

    setImg(dataP?.image);
    setData(temp);
  }, [dataP]);
  // setState(data?.skills);

  return (
    <form onSubmit={handleSubmit}>
      <h6 className="cancelModel" onClick={cancel}>
        x
      </h6>
      <div className="f-profile">
        <h1 className="Personal-detail">Personal Details</h1>

        <div className="pro-details-w-arrow">
          <h4 className="Pro-details">Professional Details</h4>
          <img
            className="side-arrow"
            src="Images/SideArrow.svg"
            alt="sideArrow"
          />
        </div>
      </div>
      <div className="personal-profile-container">
        <div>
          <div className="details-Box">
            <h2 className="profile-pic-details-name">Profile Picture</h2>
            <img className="profile-pic" src={img} alt="" />
            <input
              type="file"
              name="image"
              id="file-input"
              onChange={handleImage}
              style={{ display: "none" }}
            />
            <label for="file-input" className="add-icon-details">
              <img src="Images/add-item.svg" alt="add-more-details" />
            </label>
          </div>
          <div className="details-Box">
            <h2 className="profile-pic-details-name">Name</h2>
            <input
              className="persnol-details-box"
              name="user"
              type="text"
              value={data?.user?.first_name}
              onChange={handleInput}
              required
            />
          </div>
          <div className="details-Box" onChange={handleInput}>
            <h2 className="profile-pic-details-name">Gender</h2>
            <input
              className="persnol-details-radio"
              type="radio"
              name="gender"
              value={"male"}
              checked={data?.gender === "male"}
            />
            <span className="gender">Male</span>
            <input
              className="persnol-details-radio"
              type="radio"
              name="gender"
              value={"female"}
              checked={data?.gender === "female"}
            />
            <span className="gender">Female</span> <br />
            <input
              className="persnol-details-radio"
              type="radio"
              name="gender"
              value={"other"}
              checked={data?.gender === "other"}
            />
            <span className="gender">Other</span> <br />
          </div>
          <div className="details-Box">
            <h2 className="profile-pic-details-name">Date of Birth</h2>

            <input
              className="persnol-details-box"
              type="date"
              name="dob"
              value={data?.dob}
              placeholder="DD/MM/YYYY"
              onChange={handleInput}
            />
          </div>
          <div className="details-Box">
            <h2 className="profile-pic-details-name">Current Location</h2>

            <select
              className="persnol-details-box"
              name="current_location"
              onChange={handleInput}
              value={data?.current_location}
            >
              {address.map((value) => {
                return <option value={value?.id}>{value?.city_name}</option>;
              })}
            </select>
          </div>
          <div className="details-Box">
            <h2 className="profile-pic-details-name">Phone Number</h2>
            <input
              className="persnol-details-box"
              name="phone_numer"
              type="text"
              value={data?.phone_numer}
              onChange={handleInput}
              required
            />
          </div>
        </div>
        {/* second div */}
        <div>
          <div className="details-Box">
            <h2 className="profile-pic-details-name">Industry</h2>
            <input
              className="persnol-details-box"
              type="text"
              name="industry"
              placeholder="Select"
              value={data?.industry}
              onChange={handleInput}
              required
            />
          </div>

          <div className="details-Box">
            <h2 className="profile-pic-details-name">Experience</h2>
            <input
              className="persnol-details-box"
              type="text"
              name="experience_in_years"
              placeholder="YY"
              value={data?.experience_in_years}
              onChange={handleInput}
              required
            />
            <input
              className="persnol-details-box"
              type="text"
              name="experience_in_months"
              value={data?.experience_in_months}
              placeholder="MM"
              onChange={handleInput}
              required
            />
          </div>
          <div className="details-Box">
            <h2 className="profile-pic-details-name">Annual Salary (INR)</h2>

            <input
              className="persnol-details-box"
              name="annual_salary"
              type="text"
              value={data?.annual_salary}
              onChange={handleInput}
              required
            />
          </div>
          <div className="details-Box">
            <h2 className="profile-pic-details-name">Expected Salary (INR)</h2>

            <input
              className="persnol-details-box"
              name="expected_salary"
              type="text"
              value={data?.expected_salary}
              onChange={handleInput}
              // required
            />
          </div>
          <div className="details-Box">
            <h2 className="profile-pic-details-name">Key Skills</h2>
            <MultipleSelect
              className="persnol-details-box"
              list={skillList}
              value={selectedSkills}
              setvalue={setSelectedSkills}
            />
          </div>
          <div className="details-Box">
            <h2 className="profile-pic-details-name">Linkedin link</h2>
            <input
              className="persnol-details-box"
              type="text"
              name="linkedin_profile"
              placeholder="Linkedin link"
              value={data?.linkedin_profile}
              onChange={handleInput}
              required
            />
          </div>
          <div className="details-Box">
            <h2 className="profile-pic-details-name">Twitter link</h2>
            <input
              className="persnol-details-box"
              type="text"
              name="twitter"
              placeholder="Twitter link"
              value={data?.twitter}
              onChange={handleInput}
              required
            />
          </div>
        </div>
      </div>
      <button type="submit" className="save-persnol-details">
        Save & Continue
        <ToastContainer />
      </button>
    </form>
  );
};

export default Personal;
