import React from "react";
import MentorOulification from "../../Component/Mentor/MentorOulification";
const MentorQulification = () => {
  return (
    <div>
      <MentorOulification />
    </div>
  );
};

export default MentorQulification;
