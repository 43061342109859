import React, { useState } from "react";
import moment from "moment";
import LocationIcon from "../../Image/location.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faClock } from "@fortawesome/free-solid-svg-icons";
import { NavLink, useNavigate } from "react-router-dom";
import EditEventPage from "../OrgEdit/EditEventPage";

import DeleteJob from "../OrgEdit/DeleteJob";
import DeleteEventsProductService from "./DeleteEventsProductService";

import Modal from "react-modal";
const EventsDetails = ({ data, dataPost }) => {
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    // <NavLink to={`/applicationDetails/${data.slug}`}>
    <>
      <div className="org-all-details">
        <div className="jobApplication">
          <div className="orgJobDetails">
            <h1 className="orgPost">{data?.name}</h1>
          </div>
          <div
            className="down-text"
            // onClick={() =>
            //   navigate(`/applicationDetails/${data.slug}`, {
            //     state: data,
            //   })
            // }
            // when need to show Event Application than it is open for redirect another page
          >
            <div className="org-icon-down">
              <img
                className="org-down-social-icons"
                src={LocationIcon}
                alt="location"
              />
              <h1 className="down-icon-text">
                {data?.location_details?.city_name}
              </h1>
            </div>
            <div className="org-icon-down">
              <img
                className="org-down-social-icons"
                src="/Icons/Office.svg"
                alt="location"
              />
              <h1 className="down-icon-text">
                {moment(data.created_at).format("DD/MM/YYYY")}
              </h1>
            </div>
            <div className="org-icon-down">
              <FontAwesomeIcon
                className="clockIcon"
                icon={faClock}
                style={{ color: "#F66048" }}
              />
              <h1 className="down-icon-text">
                {moment(data.created_at).format("hh:mm ")}
              </h1>
            </div>
            <h1 className="org-Application">veiws :{data.views}</h1>
          </div>
        </div>
        <div className="org-left-details">
          <div>
            <DeleteEventsProductService data={data} />
          </div>

          <button className="extend" onClick={openModal}>
            Extend Availability
          </button>

          <img
            className="org-social-icons"
            src="Icons/insta.svg"
            alt="insta"
            onClick={() => window.open(data.insta_link)}
          />
          <img
            className="org-social-icons"
            src="Icons/fb.svg"
            alt="fb"
            onClick={() => window.open(data.fb_link)}
          />
          <img
            className="org-social-icons"
            src="Icons/twiter.svg"
            alt="twitter"
            onClick={() => window.open(data.twitter_link)}
          />
          <img
            className="org-social-icons"
            src="Icons/linkdin.svg"
            alt="linkdin"
            onClick={() => window.open(data.linkedin_link)}
          />
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <EditEventPage
          data={data}
          dataPost={dataPost}
          closeModal={closeModal}
        />
      </Modal>
    </>
    // </NavLink>
  );
};

export default EventsDetails;
