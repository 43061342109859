import React from "react";
import Header from "./Component/Header";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Talent from "./Pages/Talent/Talents";
import Organisation from "./Pages/Orag/Organisation";
import Footer from "./Component/Footer";
import FullTimeJobs from "./Pages/Full-time-jobs/FullTimeJobs";
import AllEvents from "./Pages/Events-page/AllEvents";
import JobsDetails from "./Pages/JobDetails/JobsDetails";
import EventsDetails from "./Pages/EventDetails/EventsDetails";
import Static from "./Component/StaicPage/Static";
import Dashboard from "./Pages/DashBoard/Dashboard";
import FeaturedProfile from "./Pages/FeaturedProfile/FeaturedProfile";
import FeaturesDetails from "./Pages/FeturesprofileDetails/FeaturesDetails";
import OurCourses from "./Pages/Courses/OurCourses";
import Service from "./Pages/Service/Service";
import Product from "./Pages/Products/Product";
import PersanolDetails from "./Pages/UserDetails/PersanolDetails";
import CourseDetails from "./Pages/CourseDetails/CourseDetails";
import ServiceDetails from "./Pages/ServiceDetails/ServiceDetails";
import ProductDetails from "./Pages/ProductDetails/ProductDetails";
import OrgDashboard from "./Component/OrganizationDashboard/OrgDashboard";
import OrgDashDetails from "./Pages/OrganizationDetails/OrgDashDetails";
import PostJob from "./Pages/PostJob/PostJob";
import EventPosting from "./Pages/EventPosting/EventsPosting";
import CoursePostings from "./Pages/CoursePosting/CoursePostings";
import ProductPost from "./Pages/ProductPosting/ProductPost";
import ServicePost from "./Pages/ServicePosting/ServicePost";
import Mentor from "./Pages/Mentor/Mentor";
import OrgApplicationDetails from "./Pages/OrgApplication/OrgApplicationDetails";
import ApplicationView from "./Pages/OrgApplicationView/ApplicationView";
import EventsEdit from "./Pages/OrgEditPage/EventsEdit";
import ConfirmPassword from "./Pages/CnfrmPassword/ConfirmPassword";
import MentorDetails from "./Pages/MentorDetails/MentorDetails";
import MentorQulification from "./Pages/MentorQulification/MentorQulification";
import MentorQuestionaire from "./Component/Mentor/MentorQuestionaire";
import MentorLists from "./Pages/MentorList/MentorLists";
import MentorAvailbilies from "./Pages/MentorAvailbility/MentorAvailbilies";
import SignUPPage from "./Pages/SignUp/SignUpPage";
import OrgSignUpPage from "./Pages/OrgSign/OrgSignUpPage";
import StaticsPages from "./Pages/Statics/StaticsPages";
import Offers from "./Pages/OffersPage/Offers";
import LoginPage from "./Pages/Login/LoginPage";
import ConsultingJobs from "./Pages/Consulting/ConsultingJobs";
import Volunteer from "./Pages/Volenteer/Volunteer";
import { useContext } from "react";
import { AuthContext } from "./Utill/Contexts/AuthContext.jsx";
import BrowserFeatured from "./Pages/BrowserFeaturedProfile/BrowserFeatured";
import Contact from "./Pages/ContactUsPAge/Contact";
import { Helmet } from "react-helmet";
import Logo from "./Image/logo.png";
import Search from "./Pages/Search/Search";
import Filters from "./Pages/FilterPage/Filters";
import MentorCruises from "./Pages/MentorCruisePage/MentorCruises";
const App = () => {
  const { isLoading, isOrgAuthed, isTalentAuthed } = useContext(AuthContext);

  return (
    <>
      <BrowserRouter>
        <>
          <Header />
          <Helmet>
            <title>55notout</title>
            <meta property="og:title" content="55notout" />
            <meta
              property="og:description"
              content="How much does culture influence creative thinking?"
            />
            <meta property="og:image" content={Logo} />
          </Helmet>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/talents" element={<Talent />} />
            <Route path="/organisation" element={<Organisation />} />
            <Route path="/signUp" element={<SignUPPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/orgSignUp" element={<OrgSignUpPage />} />
            <Route path="/fulltimejobs" element={<FullTimeJobs />} />
            <Route path="/events" element={<AllEvents />} />
            <Route path="/jobsdetails/:slug" element={<JobsDetails />} />
            <Route path="/eventsDetails/:slug" element={<EventsDetails />} />
            <Route path="/statics" element={<Static />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/featuredprofile" element={<FeaturedProfile />} />
            <Route
              path="/featuredprofiledetails/:slug"
              element={<FeaturesDetails />}
            />
            <Route path="/courses" element={<OurCourses />} />
            <Route path="/service" element={<Service />} />
            <Route path="/product" element={<Product />} />
            <Route path="/persanoldetails" element={<PersanolDetails />} />
            <Route path="/coursedetails/:slug" element={<CourseDetails />} />
            <Route path="/servicedetails/:slug" element={<ServiceDetails />} />
            <Route path="/productdetails/:slug" element={<ProductDetails />} />
            <Route path="/orgdashboard" element={<OrgDashboard />} />
            <Route path="/dashboarddetail" element={<OrgDashDetails />} />
            <Route path="/postjob" element={<PostJob />} />
            <Route path="/eventPosting" element={<EventPosting />} />
            <Route path="/coursePosting" element={<CoursePostings />} />
            <Route path="/productPosting" element={<ProductPost />} />
            <Route path="/servicePosting" element={<ServicePost />} />
            <Route path="/mentor" element={<Mentor />} />
            <Route path="/Mentor55notout" element={<MentorCruises />} />
            <Route
              path="/applicationDetails/:slug"
              element={<OrgApplicationDetails />}
            />
            <Route
              path="/applicationView/:slug"
              element={<ApplicationView />}
            />
            <Route path="/eventEdit" element={<EventsEdit />} />
            <Route path="/confirmPassword" element={<ConfirmPassword />} />
            {isTalentAuthed && (
              <Route path="/MentorDetails" element={<MentorDetails />} />
            )}
            <Route path="/mentorLists" element={<MentorLists />} />
            <Route
              path="/mentorQulification"
              element={<MentorQulification />}
            />
            <Route
              path="/mentorQuestionaire"
              element={<MentorQuestionaire />}
            />
            <Route
              path="/mentorSlotBooking/:slug"
              element={<MentorAvailbilies />}
            />
            <Route path="/p/:slug" element={<StaticsPages />} />
            <Route path="/p/our-plans" element={<Offers />} />
            <Route path="/consulting" element={<ConsultingJobs />} />
            <Route path="/volunteer" element={<Volunteer />} />
            <Route
              path="/BrowserFeaturedProfiles"
              element={<BrowserFeatured />}
            />
            <Route path="/Contact_us" element={<Contact />} />
            <Route path="/:slug" element={<Filters />} />
            <Route path="/search" element={<Search />} />

            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>

          <Footer />
        </>
      </BrowserRouter>
    </>
  );
};

export default App;
