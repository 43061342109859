import React, { useState, useEffect } from "react";
import "../../Style/Dashboard/profile.css";
import ProfileDetils from "./ProfileDetils";
import ApplicationDetails from "./ApplicationDetails";
import RealatedJob from "../../Component/JobDetails/RealatedJob";
import RealatedCourse from "../../Component/JobDetails/RealatedCourse";
import CourseMentors from "../../Component/JobDetails/CourseMentors";
import RealatedEvents from "../../Component/JobDetails/RealatedEvents";
import PersnolDetails from "./PersnolDetails";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  offerData,
  postCreateOrder,
  createPayment,
} from "../../Api/PaymentGetway";
import { displayRazorpay } from "../../Utill/Utility";
const FeaturesProfile = () => {
  const [offer, setOffer] = useState([]);
  const [isProfile, setIsProfile] = useState(true);
  const popUp2 = () => {
    toast("You Are Already Mentor", {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });
  };
  useEffect(() => {
    const loadData = () => {
      offerData().then((res) => {
        const response = res.data;

        setOffer(response);
      });
    };
    loadData();
  }, []);

  var api_1_data = {};
  var api_2_data = {};

  async function handleClick(id, ammount) {
    const data = {
      offer: id,
      ammount: ammount,
    };

    await postCreateOrder(data)
      .then((res) => {
        const response = res;

        api_1_data = response.data;
      })
      .catch((e) => console.log("e", e));

    const data1 = {
      amount: ammount,
      order_id: api_1_data.order_number,
      currency: "INR",
    };

    await createPayment(data1).then((resp) => {
      const response2 = resp.data;
      api_2_data = response2;

      displayRazorpay(api_2_data.data);
    });
  }

  return (
    <>
      <div className="f-profile">
        <h1 className="dash-title">Dashboard</h1>

        <Link className="become-mentor-btn" to={"/Mentor55notout"}>
          Become a Mentor
        </Link>

        {localStorage.getItem("feature_talent") === "true" ? (
          ""
        ) : (
          <button
            className="dash-btn"
            onClick={() => handleClick(offer[1]?.id, offer[1]?.ammount)}
          >
            Feature your Profile
          </button>
        )}
      </div>
      <div className="user-details">
        <PersnolDetails />
        <div className="dash-btn-container">
          <button
            id="selected-prf-btn"
            className="profile-btn"
            onClick={() => {
              setIsProfile(true);
            }}
          >
            Profile
          </button>

          <button
            id="selected-app-btn"
            className="applications-btn"
            onClick={() => {
              setIsProfile(false);
            }}
          >
            Applications
          </button>
          {isProfile ? <ProfileDetils /> : <ApplicationDetails />}
        </div>
      </div>
      <RealatedJob />
      <RealatedCourse />
      <CourseMentors />
      <RealatedEvents />
    </>
  );
};

export default FeaturesProfile;
