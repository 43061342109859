import React, { useState, useEffect } from "react";
import FeaturesPosting from "../PostJob/FeaturesPosting";
import { eventPut, Address } from "../../Api/OrgApi";
import "../../Style/ApplicationDetails/EventEdit.css";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import FeatureModal from "../EventPosting/FeatureModal";
const EditEventPage = ({ data, closeModal }) => {
  const date = moment(data.date).format("YYYY-MM-DD");
  const time = moment(data.time).format("hh:mm");

  const data3 = {
    title: "Free or Featured Event Listing?",
    free: "Free Event Posting",
    features: "Featured Event Posting",
  };
  const [address, setAddress] = useState([]);
  const [edit, setEdit] = useState({});
  const [thumbImg, setThumbImg] = useState({});
  const [bannerImg, setBannerImg] = useState({});
  const updateData = (formData) => {
    eventPut(formData)
      .then((res) => {
        toast("Event Edit Succesfull", {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          closeModal();
        }, 1000);
      })

      .catch((e) =>
        toast(e?.response?.data?.msg, {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        })
      );
  };
  const handleImage = (e) => {
    const image = e.target.files[0];
    const formData1 = new FormData();
    formData1.append("thumbnail_images", image);

    eventPut(formData1, true).then((res) => {
      setThumbImg(res.data?.image);
    });
  };
  const handleImage2 = (e) => {
    const image2 = e.target.files[0];
    const formData2 = new FormData();
    formData2.append("banner_image", image2);
    eventPut(formData2, true).then((res) => {
      setBannerImg(res.data?.image);
    });
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    setEdit({ ...edit, ...{ [name]: value } });
  };

  const handleRadio = (e) => {
    const { name, checked } = e.target;
    setEdit({ ...edit, ...{ [name]: checked } });
  };

  const handleSubmitData = (e) => {
    e.preventDefault();
    const startdate = moment(edit.startdate).format("YYYY-MM-DD");
    const start_date = `${startdate} ${edit.startTime}`;
    // const enddate = moment(edit.endDate).format("YYYY-MM-DD");
    // const end_date = `${enddate} ${edit.endTime}`;

    edit["end_date"] = `${edit.end_date} ${edit.end_time}`;
    edit["start_date"] = `${edit.start_date} ${edit.start_time}`;
    const formData = { ...edit };

    updateData(formData);
  };

  useEffect(() => {
    const temp = {
      name: data?.name,
      description: data?.description,
      // start_date: data?.start_date,
      fee: data?.fee,
      is_free: data?.is_free,
      is_featured_post: data?.is_featured_post,
      start_date: moment(data.end_date).format("YYYY-MM-DD"),
      start_time: moment(data.end_date).format("HH:MM"),
      end_date: moment(data.end_date).format("YYYY-MM-DD"),
      end_time: moment(data.end_date).format("HH:MM"),
      location: data?.location,
      website: data?.website,
      // course_link: "",
      slug: data?.slug,
    };
    setThumbImg(data?.thumbnail_images);
    setBannerImg(data?.banner_image);
    setEdit(temp);
  }, [data]);
  useEffect(() => {
    const loadData = () => {
      Address().then((res) => {
        const response = res;
        setAddress(response.data);
      });
    };
    loadData();
  }, []);
  localStorage.setItem("event_id", data?.id);
  return (
    <div>
      <h1 className="close" onClick={closeModal} style={{ fontSize: "4rem" }}>
        x
      </h1>
      <div>
        <h1 className="jobs-header">Event Posting</h1>
      </div>
      <form onSubmit={handleSubmitData}>
        <div className="event-posting-input-main">
          <div className="event-posting-input-container">
            <div className="event-posting-input">
              <div>
                <h1 className="event-posting-input-title">Event Name/Title</h1>
              </div>
              <div className="event-just-check">
                <input
                  className="event-posting-input-box"
                  placeholder="Enter The Name and Title of event"
                  type="text"
                  height="40px"
                  name="name"
                  onChange={handleInput}
                  value={edit?.name}
                  required
                />
              </div>
            </div>

            <div className="event-posting-input-start">
              <div>
                <h1 className="event-posting-input-title">Start Date & Time</h1>
              </div>
              <div className="event-just-check1">
                <input
                  className="event-posting-input-box-min"
                  placeholder="Min"
                  type="date"
                  name="start_date"
                  value={edit?.start_date}
                  onChange={handleInput}
                  // value={moment(edit?.start_date).format("YYYY-MM-DD")}
                />

                <input
                  className="event-posting-input-box-max"
                  placeholder="Max"
                  type="time"
                  name="start_time"
                  value={edit?.start_time}
                  onChange={handleInput}
                />
              </div>
            </div>
            <div className="event-posting-input-start">
              <h1 className="event-posting-input-title">End Date & Time</h1>
              <div className="event-just-check1">
                <input
                  className="event-posting-input-box-min"
                  placeholder="Min"
                  type="date"
                  name="end_date"
                  value={edit?.end_date}
                  onChange={handleInput}
                />

                <input
                  className="event-posting-input-box-max"
                  placeholder="Max"
                  type="time"
                  name="end_time"
                  value={edit.end_time}
                  onChange={handleInput}
                />
              </div>
            </div>
            <div className="event-posting-input">
              <h1 className="event-posting-input-title">Location</h1>
              <div className="event-just-check">
                <select
                  className="event-posting-input-box"
                  value={edit?.location}
                  name="location"
                  onChange={handleInput}
                >
                  {address.map((value) => {
                    return <option value={value.id}>{value.city_name}</option>;
                  })}
                </select>
              </div>
            </div>
            <div className="event-desp-box">
              <div className="event-posting-input-con">
                <h1 className="event-posting-input-title">Event Description</h1>
              </div>
              <div className="about-event-content-box">
                <textarea
                  className="about-company-desps"
                  name="description"
                  onChange={handleInput}
                  value={edit?.description}
                  required
                />
              </div>
            </div>

            <div>
              <div className="event-border"></div>
              <div>
                <h1>Event Images</h1>
                <div>
                  <div className="courseUploadComponent">
                    <div className="upload-main">
                      <div className="thumbnailsContainer">
                        <img
                          className="edit-thumbnails"
                          src={thumbImg}
                          alt=""
                        />
                        <input
                          type="file"
                          name="thumbnail_image1"
                          id="file-input"
                          onChange={handleImage}
                          className="thumbnails"
                          placeholder="upload-thumbnails"
                          style={{ display: "none" }}
                        />

                        <label
                          for="file-input"
                          className="edit-event-post-upload"
                        >
                          <img
                            className="upload-icon"
                            src="Icons/upload.svg"
                            alt="edit"
                          />
                          <p>Upload</p>
                        </label>
                      </div>
                      <div className="bannerContainer">
                        <img
                          //   className="bannerContainer"
                          className="edit-banner"
                          src={bannerImg}
                          alt="temp"
                        />
                        <input
                          type="file"
                          id="file-input"
                          name="banner_image"
                          onChange={handleImage2}
                          className="banner"
                          placeholder="Upload Banner"
                          style={{ display: "none" }}
                        />

                        <div>
                          <label
                            for="file-input"
                            className="edit-event-post-upload2"
                          >
                            <img
                              className="upload-icon"
                              src="Icons/upload.svg"
                              alt="edit"
                            />
                            <p>Upload</p>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="event-border"></div>
              <div className="Event-fee-mode">
                <div>
                  <h1 className="Event-entry">Event Entry Type</h1>
                </div>
                <div>
                  <input
                    onChange={handleRadio}
                    className="container-checkbox"
                    type="radio"
                    name="is_free"
                    checked={edit?.is_free}
                  />
                  <span className="checkmark">Free</span>
                </div>
                <div>
                  <input
                    onChange={handleRadio}
                    className="container-checkbox"
                    type="radio"
                    name="is_free"
                    checked={!edit?.is_free}
                  />
                  <span className="checkmark">Paid</span>
                </div>
              </div>
              <div className="link-web">
                <h2 className="Event-entry">Link to your ticketing system</h2>
                <input
                  className="ticketing-system"
                  type="text"
                  placeholder="Enter the link to your website or external ticketing system."
                  name="website"
                  onChange={handleInput}
                  value={edit?.website}
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <FeaturesPosting
          data3={data3}
          data={data}
          edit={edit}
          handleInput={handleInput}
        />
        <button type="submit" className="publish-btn">
          Publish
          <ToastContainer />
        </button>
      </form>
    </div>
  );
};

export default EditEventPage;
