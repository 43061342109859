import React from "react";
import "../Style/AlllistingBtn.css";
import { useNavigate } from "react-router-dom";
const AllListingBtn = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div className="AllListing">
      <button className="allListingBtn" onClick={() => navigate(data?.link)}>
        {data?.text}
      </button>
    </div>
  );
};

export default AllListingBtn;
