import React from "react";

const MentoringText = () => {
  return (
    <div className="MentoringMain">
      <div className="MentoringContainer">
        <h6 className="MentoringTitle">MENTORING ON 55NOTOUT</h6>
        <h1 className="MentoringSubTitle">
          Let's hit the ground running, we're here to support you
        </h1>
        <h2 className="MentoringSubTitlePargraph">
          Partnering with 55notout means you won't have to take care of anything
          other than what you care about most.
        </h2>
      </div>
    </div>
  );
};

export default MentoringText;
