import React, { useState, useEffect } from "react";
import "../Style/Footer.css";
import Logo from "../Image/hLogo.png";
import Insta from "../Image/Finsta.jpg";
import Fb from "../Image/Fbook.jpg";
import Twiter from "../Image/Ftwitter.jpg";
import Linkdin from "../Image/Flinkdin.jpg";
import { NavLink } from "react-router-dom";
import { Dynamic } from "../Api/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faFacebookSquare,
  faInstagram,
  faTwitterSquare,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import FormControl from "@mui/material/FormControl";
import TextField from "@material-ui/core/TextField";
import { emailInFooter } from "../Api/api";
const Footer = () => {
  const [data, setData] = useState([]);
  const [email, setEmail] = useState();
  useEffect(() => {
    const loadData = () => {
      Dynamic().then((res) => {
        const response = res.data;
        setData(response);
      });
    };
    loadData();
  }, []);
  const submit = (e) => {
    e.preventDefault();
    const data = {
      email: email,
    };
    emailInFooter(data).then((res) => {});
  };
  return (
    <>
      <div className="footerMain">
        <div className="Footer-socialIcon">
          <img className="footer-img" src={Logo} alt="logo" width="200px" />
          <h6 className="footerP">
            Trademark © 2022 55NOTOUT <br></br>(An entity of SkillsKarma
            Services)
          </h6>
        </div>
        <div className="FooterDetails">
          <NavLink to={`/p/about`}>
            <h6 className="details"> About Us </h6>
          </NavLink>
          <NavLink to={`/p/our-plans`}>
            <h6 className="details">Plans</h6>
          </NavLink>
          <NavLink to={`/p/advertise`}>
            <h6 className="details"> Advertise</h6>
          </NavLink>
          <NavLink to={`/p/teams`}>
            <h6 className="details"> Team</h6>
          </NavLink>
          <NavLink to={`Contact_us`}>
            <h6 className="details">Contact Us</h6>
          </NavLink>
        </div>
        <div className="FooterDetails">
          <NavLink to={`/p/blogs`}>
            <h6 className="details">Blog</h6>
          </NavLink>
          <NavLink to={`/p/terms-condition`}>
            <h6 className="details">Terms & Condition</h6>
          </NavLink>

          <NavLink to={`/p/privacy-policy`}>
            <h6 className="details">Privacy Policy</h6>
          </NavLink>
          <NavLink to={`/p/faq`}>
            <h6 className="details">Mentor FAQs</h6>
          </NavLink>
        </div>

        <div className="FooterDetails">
          <a href=" mailto:contact@55notout.com">
            <h6 className="details">contact@55notout.com</h6>
          </a>

          <h6 className="details">
            <FontAwesomeIcon
              className=""
              icon={faWhatsapp}
              onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send/?phone=919810206966&text=55notout- A one-stop platform to find jobs, consulting, volunteering, mentoring, events, courses, products and services for age 50+ Senior Professionals.&type=phone_number&app_absent=0"
                )
              }
              style={{ cursor: "pointer" }}
            />
            {"    "}+91-9810206966
          </h6>
          <div className="Footer-socialIconsxsx">
            <FontAwesomeIcon
              className="footer-social-media-Icon"
              icon={faFacebookSquare}
              onClick={() => window.open("https://www.facebook.com/55notout")}
            />

            <FontAwesomeIcon
              className="footer-social-media-Icon"
              icon={faInstagram}
              onClick={() =>
                window.open("https://www.instagram.com/the55notout/")
              }
            />

            <FontAwesomeIcon
              className="footer-social-media-Icon"
              icon={faTwitterSquare}
              onClick={() => window.open("https://twitter.com/tweet55notout")}
            />

            <FontAwesomeIcon
              className="footer-social-media-Icon"
              icon={faLinkedin}
              onClick={() =>
                window.open("https://www.linkedin.com/company/55notout")
              }
            />
          </div>
          <div>
            <form className="footerEmailBox" onSubmit={submit}>
              <h6 className="emailText">Subscribe to our newsletter</h6>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                id="Book Name"
              />
            </form>
          </div>
        </div>
      </div>
      <div className="madeBy">
        Made with ❤️ by{" "}
        <a href="https://foreantech.com/" className="madeBytext">
          Foreantech Labs
        </a>
      </div>
    </>
  );
};
export default Footer;
