import React, { useState, useEffect } from "react";
import Carousel from "react-elastic-carousel";
import { OrganizationIcon } from "../../Api/api";
import "../../Style/JobDetails/courseMentors.css";
import { featureMentor } from "../../Api/Mentor";
import { NavLink } from "react-router-dom";
const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 250, itemsToShow: 2, itemsToScroll: 2 },
  { width: 500, itemsToShow: 3 },
  { width: 1000, itemsToShow: 4 },
];

const CourseMentors = () => {
  // const [orgIcon, setOrgIcon] = useState([]);
  // useEffect(() => {
  //   const loadData = () => {
  //     OrganizationIcon().then((res) => {
  //       const response = res;
  //       setOrgIcon(response.data);
  //     });
  //   };
  //   loadData();
  // }, []);
  const myArrow = ({ onClick, isEdge }) => {
    return <button onClick={onClick} disabled={isEdge}></button>;
  };
  const [features, setFeatures] = useState([]);
  useEffect(() => {
    const loadData = () => {
      featureMentor().then((res) => {
        const response = res;
        setFeatures(response);
      });
    };
    loadData();
  }, []);
  return (
    <div>
      <h1 className="mentor-job">Curated Mentors</h1>
      <div className="mentor-job-border"></div>
      <Carousel
        breakPoints={breakPoints}
        renderPagination={myArrow}
        className="crousel"
      >
        {features.map((value) => {
          return (
            <div className="mentor-listing-job-main">
              <NavLink
                to={`/mentorSlotBooking/${value?.slug}`}
                style={{ color: "black" }}
              >
                <img
                  className="mentor-listing-Job-Img"
                  src={value?.image}
                  alt="comDp"
                  width="250px"
                />
                <h4 className="mentor-listing-Job-Title">
                  {value?.first_name}
                </h4>
                <p className="mentor-list-work">{value?.company}</p>
              </NavLink>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default CourseMentors;
