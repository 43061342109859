import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { FeaturesStep1, FeaturesStep2, FeaturesStep3 } from "../../Api/api";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  faHandBackPointDown,
  faHandPointDown,
} from "@fortawesome/free-solid-svg-icons";
import { displayRazorpay } from "../../Utill/FeaturesUtility";
import { showPrice } from "../../Api/api";
const FeatureModal = ({ closed }) => {
  const navigate = useNavigate();
  const [features, setFeatures] = useState({
    price_for: "course",
    organization_item_id: localStorage.getItem("course_id"),
    order_type: "featureCourse",
  });
  const [price, setPrice] = useState([]);
  useEffect(() => {
    const loadData = () => {
      showPrice().then((res) => {
        setPrice(res?.data);
      });
    };
    loadData();
  }, []);
  var api_1_data = {};
  var api_2_data = {};
  async function clickFeatures() {
    closed();
    await FeaturesStep1(features).then((res) => {
      if (res?.status === 200) {
        toast("Please Wait", {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      const response = res.data;

      api_2_data = response;
    });

    const data = {
      payment_type: api_2_data?.data?.order_type,
      amount: api_2_data?.data?.ammount,
      currency: "INR",
      order_id: api_2_data?.data?.order_number,
    };

    await FeaturesStep2(data).then((res) => {
      const response = res;

      api_1_data = response.data;
      displayRazorpay(api_1_data.data);
    });

    // const data1 = {
    //   // razorpay_payment_id: "",
    //   razorpay_order_id: api_1_data?.data?.id,
    //   razorpay_payment_id: "",
    //   razorpay_signature: "",
    //   // razorpay_signature: "",
    //   status: "SUCCESS",
    // };

    // await FeaturesStep3(data1).then((resp) => {
    //   const response2 = resp.data;
    //   api_2_data = response2;

    //   // displayRazorpay(api_2_data.data);
    // });
  }

  return (
    <div>
      <h4 className="close" onClick={closed}>
        x
      </h4>
      <div className="my-4">
        <div className="card mb-2 mx-4">
          <div className="card-body">
            <h2 className="card-title text-center">
              Do You Want To Feature Your Posting ?
            </h2>
            <h5 className="text-center">
              <p className="featuresCost">
                Cost:{" "}
                <FontAwesomeIcon
                  className=""
                  icon={faIndianRupeeSign}
                  color="black"
                  style={{ width: "1rem", height: "1.5rem" }}
                />{" "}
                {price[3]?.price} /-
              </p>
            </h5>

            <div className="feauters-condition">
              <div className="FeaturesPrice">
                <button
                  className="mentor-booking"
                  value={"false"}
                  onClick={() => navigate(`/orgdashboard`)}
                >
                  No
                </button>
              </div>
              <div className="FeaturesPrice">
                <button className="mentor-booking" onClick={clickFeatures}>
                  Yes
                  <ToastContainer />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureModal;
