import React from "react";
import Expertise from "../../Component/MentorCruise/Expertise";
import MentorProfileInfo from "../../Component/MentorCruise/MentorProfileInfo";
import MentoringText from "../../Component/MentorCruise/MentoringText";
import Mentorning from "../../Component/MentorCruise/Mentorning";
import TrustedMentees from "../../Component/MentorCruise/TrustedMentees";
import Faq from "../../Component/MentorCruise/Faq";
import BecomePart from "../../Component/MentorCruise/BecomePart";
import { BrowserRouter, Routes, Route, Router } from "react-router-dom";
import { Navbar } from "../../Component/Navbar";
import MentorBanner from "../../Component/MentorListing/MentorBanner";
import { Helmet } from "react-helmet";
const MentorCruises = () => {
  return (
    <div>
      <Helmet>
        <title>55notout | Find A Mentor</title>
        <meta name="description" content="Find A Mentor" />
      </Helmet>
      <Navbar />
      <MentorBanner />
      <MentoringText />
      <MentorProfileInfo />
      <Mentorning />
      <TrustedMentees />
      <Faq />
      <BecomePart />
    </div>
  );
};

export default MentorCruises;
