import React, { useState, useEffect, useRef, forwardRef } from "react";
import "../../Style/Jobposting/jobPosting.css";
import { Address } from "../../Api/OrgApi";
import { JobPostings } from "../../Api/OrgApi";
import { skillNames } from "../../Api/TalentsApi";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import FeatureModal from "./FeatureModal";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";
import { Modal } from "react-bootstrap";

const JobPosting = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const handleSuceesClose = () => setIsSuccess(false);
  const handleSuceesShow = () => {
    setIsSuccess(true);
  };
  const navigate = useNavigate();
  const [skill, setSkill] = useState([]);
  const [address, setAddress] = useState([]);

  const [value, setvalue] = useState("");

  const handleOnchange = (val) => {
    setvalue(val);
  };
  const [edit, setEdit] = useState({
    job_title: "",
    job_type: "full_time",
    job_mode: "Work from Home",
    min_exp: "",
    max_exp: "",
    qualification: "",
    number_of_opening: "",
    apply_before: "",
    salary_min: "",
    salary_max: "",
    disclose_later: "false",
    location: "",
    key_skills: value,
    about_company: "",
    other: "",
    perks: "",
    responsibilities: "",
    // is_featured_post: "",
    // slug: data.slug,
  });
  const updateData = (formData) => {
    JobPostings(formData)
      .then((res) => {
        const response = res;

        localStorage.setItem("job_id", response?.data?.data?.id);
        if (response?.status === 200) {
          handleSuceesShow();
          toast("Job Posting succesfull", {
            autoClose: 500,
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((e) =>
        e.response.status === 400
          ? toast(" Bad Request", {
              autoClose: 500,
              position: toast.POSITION.TOP_RIGHT,
            })
          : ""
      );
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEdit({ ...edit, ...{ [name]: value } });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = { ...edit };
    formData.key_skills = value;
    updateData(formData);
  };

  useEffect(() => {
    const loadData = () => {
      Address().then((res) => {
        const response = res;
        const data = response.data;
        let temp = { ...edit, location: data[0]?.id + "" };
        setEdit(temp);
        setAddress(data);
      });
    };
    loadData();
  }, []);

  useEffect(() => {
    const loadData = () => {
      skillNames().then((res) => {
        const response = res;
        let temp = response.results;

        const newArrayOfObj = temp.map(({ name: label, id: value }) => ({
          label,
          value,
        }));

        setSkill(newArrayOfObj);
      });
    };
    loadData();
  }, []);

  return (
    <>
      <div>
        <h1 className="jobs-header">Job Posting</h1>
      </div>
      <form
        onSubmit={handleSubmit}
        //  ref={form_reset}
      >
        <div className="Job-posting-input-main">
          <div className="Job-posting-input-container">
            <div className="Job-posting-input">
              <h1 className="Job-posting-input-title">Job Title</h1>

              <input
                className="posting-input-box"
                placeholder="Type here"
                type="text"
                height="30px"
                name="job_title"
                value={edit?.job_title}
                onChange={handleInput}
                required
              />
            </div>
            <div className="Job-posting-input">
              <h1 className="Job-posting-input-title">Location</h1>

              <select
                className="posting-input-box"
                value={edit?.location}
                name="location"
                onChange={handleInput}
              >
                {address.map((value, key) => {
                  return (
                    <option key={key} value={value?.id}>
                      {value?.city_name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="Job-posting-input">
              <h1 className="Job-posting-input-title">Job Type</h1>

              <select
                className="posting-input-box"
                // ref={type}
                placeholder="Job Type"
                name="job_type"
                value={edit?.job_type}
                onChange={handleInput}
              >
                <option value="full_time" selected>
                  Full time
                </option>
                <option value="consulting">Consulting</option>
                <option value="volunteering">Volunteering</option>
              </select>
            </div>
            <div className="Job-posting-input">
              <h1 className="Job-posting-input-title">Mode</h1>

              <select
                className="posting-input-box-mode"
                name="job_mode"
                value={edit?.job_mode}
                onChange={handleInput}
                selected="Work from Home"
              >
                <option value="Work from Home">Work from Home</option>
                <option value="Work from Office">Work from Office</option>
                <option value="Hybrid">Hybrid</option>
              </select>
            </div>
            <div className="Job-posting-input">
              <h1 className="Job-posting-input-title">
                Experience{" "}
                <span style={{ fontSize: "1rem", color: "grey" }}>
                  (In Years)
                </span>
              </h1>
              <div className="just-check1">
                <input
                  className="posting-input-box-min"
                  placeholder="Min"
                  type="text"
                  name="min_exp"
                  value={edit?.min_exp}
                  onChange={handleInput}
                  required
                  // ref={exp}
                />
              </div>
              <div className="just-check1">
                <input
                  className="posting-input-box-max"
                  placeholder="Max"
                  name="max_exp"
                  value={edit?.max_exp}
                  type="text"
                  onChange={handleInput}
                  required
                  // ref={expes}
                />
              </div>
            </div>
          </div>

          <div className="Job-posting-input-container">
            <div className="Job-posting-input">
              <h1 className="Job-posting-input-title">Education</h1>

              <input
                className="posting-input-box-qul"
                placeholder="BTech"
                type="text"
                name="qualification"
                value={edit?.qualification}
                onChange={handleInput}
                required
                // ref={qual}
              />
            </div>

            <div className="Job-posting-input">
              <h2 className="Job-posting-input-title">Industry</h2>

              <MultiSelect
                className="posting-input-box-skill"
                onChange={handleOnchange}
                options={skill}
              />
            </div>
            <div className="Job-posting-input">
              <h1 className="Job-posting-input-title">No. of Openings</h1>

              <input
                className="posting-input-box-opening"
                type="text"
                placeholder="50"
                name="number_of_opening"
                value={edit?.number_of_opening}
                onChange={handleInput}
                // ref={opening}
                required
              />
            </div>
            <div className="Job-posting-input">
              <h1 className="Job-posting-input-title">Apply By</h1>

              <input
                className="posting-input-box-apply"
                type="date"
                name="apply_before"
                value={edit?.apply_before}
                onChange={handleInput}
                placeholder="dd-mm-yyyy"
                // ref={before}
              />
            </div>
            <div className="Job-posting-input">
              <h1 className="Job-posting-input-title">Salary </h1>

              <input
                className="posting-input-box-salary"
                placeholder="Min (LPA)"
                type="text"
                name="salary_min"
                value={edit?.salary_min}
                onChange={handleInput}
                required
                // ref={salaryMin}
              />

              <input
                className="posting-input-box-salar"
                placeholder="Max (LPA)"
                type="text"
                name="salary_max"
                value={edit?.salary_max}
                onChange={handleInput}
                required
              />
            </div>
            <div className="job-check-box-container">
              <input
                className="job-check-box"
                type="checkbox"
                id="vehicle1"
                name="disclose_later"
                onChange={handleInput}
                value={edit?.disclose_later === "true" ? "false" : "true"}
                checked={edit?.disclose_later === "true" ? true : false}
              />
              <span className="job-checkBox">Disclosed latter</span>
            </div>
          </div>
        </div>
        <div className="job-border"></div>
        <div className="course-desp-box">
          <div className="course-posting-input-con">
            <h1 className="course-posting-input-title">About The Company</h1>
          </div>
          <div className="about-course-content-box">
            <textarea
              className="about-compan-desp"
              name="about_company"
              // ref={aboutTheCompany}
              value={edit?.about_company}
              onChange={handleInput}
              required
            />
          </div>
        </div>

        <div className="course-desp-box">
          <div>
            <h1 className="about-company-Responsibilities">Responsibilities</h1>
          </div>
          <div className="about-course-content-box">
            <textarea
              className="about-compan-desp"
              name="responsibilities"
              value={edit?.responsibilities}
              onChange={handleInput}
              required
              //  ref={responseb}
            />
          </div>
        </div>
        <div className="course-desp-box">
          <div>
            <h1 className="about-company-title">Other Skills & Experiences</h1>
          </div>
          <div className="about-course-content-box">
            <textarea
              className="about-compan-desp"
              name="other"
              value={edit?.other}
              onChange={handleInput}
              required
              // ref={otherSkill}
            />
          </div>
        </div>
        <div className="course-desp-box">
          <div>
            <h1 className="about-company-title">Perks</h1>
          </div>
          <div className="about-course-content-box">
            <textarea
              className="about-compan-desp"
              name="perks"
              value={edit?.perks}
              onChange={handleInput}
              required
              //  ref={perk}
            />
          </div>
        </div>
        <div className="features-posting"></div>
        <button type="submit" className="publish-btn">
          Publish
        </button>
      </form>
      <Modal centered={true} show={isSuccess}>
        <FeatureModal closed={handleSuceesClose} />
      </Modal>
    </>
  );
};

export default JobPosting;
