import React, { useState, useRef, useEffect } from "react";
import { Address } from "../../Api/OrgApi";
import { jobPut } from "../../Api/OrgApi";
import { skillNames } from "../../Api/TalentsApi";
import "../../Style/ApplicationDetails/jobEdit.css";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import FeatureModal from "../PostJob/FeatureModal";
const JobEdit = ({ data, closeModal }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const handleSuceesClose = () => setIsSuccess(false);
  const handleSuceesShow = () => {
    setIsSuccess(true);
  };
  const [skill, setSkill] = useState([]);
  const [address, setAddress] = useState([]);
  const [edit, setEdit] = useState({
    job_title: "",
    job_type: "",
    job_mode: "",
    min_exp: "",
    max_exp: "",
    qualification: "",
    number_of_opening: "",
    apply_before: "",
    salary_min: "",
    salary_max: "",
    disclose_later: "",
    location: address[0]?.id || "",
    key_skills: "",
    about_company: "",
    other: "",
    perks: "",
    responsibilities: "",
    slug: data.slug,
  });

  const updateData = (formData) => {
    jobPut(formData)
      .then((res) => {
        const response = res;
        toast("Job Edit Succesfull", {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          closeModal();
        }, 1000);
      })

      .catch((e) =>
        toast(e?.response?.data?.msg, {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        })
      );
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEdit({ ...edit, ...{ [name]: value } });
  };
  const handleSubmitData = (e) => {
    e.preventDefault();
    const formData = { ...edit };
    updateData(formData);
  };

  useEffect(() => {
    setEdit(data);
  }, [data]);
  useEffect(() => {
    const loadData = () => {
      Address().then((res) => {
        const response = res;
        setAddress(response.data);
      });
    };
    loadData();
  }, []);
  useEffect(() => {
    const loadData = () => {
      skillNames().then((res) => {
        const response = res;

        setSkill(response.results);
      });
    };
    loadData();
  }, []);
  localStorage.setItem("job_id", data?.id);

  return (
    <>
      <h1 className="close" onClick={closeModal} style={{ fontSize: "4rem" }}>
        x
      </h1>
      <div>
        <h1 className="edit-jobs-header">JobPosting</h1>
      </div>
      <form onSubmit={handleSubmitData}>
        <div className="edit-Job-posting-input-main">
          <div className="edit-Job-posting-input-container">
            <div className="edit-Job-posting-input">
              <h1 className="edit-Job-posting-input-title">Job Title</h1>

              <div className="edit-just-check">
                <input
                  className="edit-posting-input-box"
                  placeholder="Type here"
                  type="text"
                  height="40px"
                  value={edit?.job_title}
                  name="job_title"
                  onChange={handleInput}
                  required

                  //   ref={title}
                />
              </div>
            </div>
            <div className="edit-Job-posting-input">
              <h1 className="edit-Job-posting-input-title">Location</h1>
              <div className="edit-just-check">
                <select
                  className="edit-posting-input-box"
                  // value={edit?.location}
                  name="location"
                  onChange={handleInput}
                >
                  {address.map((value) => {
                    return <option value={value.id}>{value.city_name}</option>;
                  })}
                </select>
              </div>
            </div>
            <div className="edit-Job-posting-input">
              <h1 className="edit-Job-posting-input-title">Job Type</h1>
              <div className="edit-just-check">
                <select
                  className="edit-posting-input-box"
                  placeholder="Job Type"
                  name="job_type"
                  value={edit?.job_type}
                  onChange={handleInput}
                >
                  <option>full_time</option>
                  <option>consulting</option>
                  <option>volunteering</option>
                </select>
              </div>
            </div>
            <div className="edit-Job-posting-input">
              <h1 className="edit-Job-posting-input-title">Mode</h1>
              <div className="edit-just-check">
                <select
                  className="edit-posting-input-box-mode"
                  name="job_mode"
                  onChange={handleInput}
                  value={edit?.job_mode}
                >
                  <option>Work from Home</option>
                  <option>Work from Office</option>
                  <option>Hybrid</option>
                </select>
              </div>
            </div>
            <div className="edit-Job-posting-input">
              <h1 className="edit-Job-posting-input-title">Experince</h1>
              <div className="edit-just-check1">
                <input
                  className="edit-posting-input-box-min"
                  placeholder="Min"
                  type="text"
                  name="min_exp"
                  onChange={handleInput}
                  value={edit?.min_exp}
                />
              </div>
              <div className="edit-just-check1">
                <input
                  className="edit-posting-input-box-max"
                  placeholder="Max"
                  name="max_exp"
                  type="text"
                  onChange={handleInput}
                  value={edit?.max_exp}
                />
              </div>
            </div>
          </div>

          <div className="edit-Job-posting-input-container">
            <div className="edit-Job-posting-input">
              <h1 className="edit-Job-posting-input-title">Education</h1>
              <div className="edit-just-check">
                <input
                  className="edit-posting-input-box-qul"
                  placeholder="BTech"
                  type="text"
                  name="qualification"
                  onChange={handleInput}
                  value={edit?.qualification}
                  required
                />
              </div>
            </div>

            <div className="edit-Job-posting-input">
              <h1 className="edit-Job-posting-input-title">Industry</h1>
              <div className="edit-just-check">
                <select
                  name="key_skills"
                  onChange={handleInput}
                  className="edit-posting-input-box-skill"
                  value={edit?.key_skills}
                >
                  {skill.map((value) => {
                    return <option value={value?.id}>{value?.name}</option>;
                  })}
                </select>
              </div>
            </div>
            <div className="edit-Job-posting-input">
              <h1 className="edit-Job-posting-input-title">No. of Openings</h1>
              <div className="edit-just-check">
                <input
                  className="edit-posting-input-box-opening"
                  type="text"
                  placeholder="50"
                  name="number_of_opening"
                  value={edit?.number_of_opening}
                  onChange={handleInput}
                  required
                />
              </div>
            </div>
            <div className="edit-Job-posting-input">
              <h1 className="edit-Job-posting-input-title">Apply By</h1>
              <div className="edit-just-check">
                <input
                  className="edit-posting-input-box-apply"
                  type="date"
                  name="apply_before"
                  value={edit?.apply_before}
                  onChange={handleInput}
                  required
                />
              </div>
            </div>
            <div className="edit-Job-posting-input">
              <h1 className="edit-Job-posting-input-title">Salary (LPA)</h1>
              <div className="edit-just-check">
                <input
                  className="edit-posting-input-box-salary"
                  placeholder="Min"
                  type="text"
                  name="salary_min"
                  value={edit?.salary_min}
                  onChange={handleInput}
                  required
                />
              </div>
              <div className="edit-just-check">
                <input
                  className="edit-posting-input-box-salar"
                  placeholder="Max"
                  type="text"
                  name="salary_max"
                  value={edit?.salary_max}
                  onChange={handleInput}
                  required
                />
              </div>
            </div>
            <div
              className="edit-job-check-box-container"
              onChange={handleInput}
            >
              <input
                className="edit-job-check-box"
                type="checkbox"
                id="vehicle1"
                name="disclose_laters"
                onChange={handleInput}
              />

              <span className="edit-job-checkBox">Disclosed latter</span>
            </div>
          </div>
        </div>
        <div className="job-border"></div>
        <div className="course-desp-box">
          <div>
            <h1 className="about-company-title">About The Company</h1>
          </div>

          <div className="about-course-content-box">
            <textarea
              className="about-compan-desp"
              name="about_company"
              value={edit?.about_company}
              onChange={handleInput}
              required
            />
          </div>
        </div>
        <div className="course-desp-box">
          <div>
            <h1 className="about-company-Responsibilities">Responsibilities</h1>
          </div>
          <div className="about-course-content-box">
            <textarea
              className="about-compan-desp"
              name="responsibilities"
              value={edit?.responsibilities}
              onChange={handleInput}
              required
            />
          </div>
        </div>
        <div className="course-desp-box">
          <div>
            <h1 className="about-company-title">Other Skills & Experiences</h1>
          </div>
          <div className="about-course-content-box">
            <textarea
              className="about-compan-desp"
              name="other"
              value={edit?.other}
              onChange={handleInput}
              required
            />
          </div>
        </div>
        <div className="course-desp-box">
          <div>
            <h1 className="about-company-title">Perks</h1>
          </div>
          <div className="about-course-content-box">
            <textarea
              className="about-compan-desp"
              name="perks"
              value={edit?.perks}
              onChange={handleInput}
              required
            />
          </div>
        </div>
        <div className="features-posting">
          <div className="features-posting-container">
            <h1 className="features-posting-title">
              Free or Featured Posting?
            </h1>
          </div>
          <div className="features-check-box" onChange={handleInput}>
            <div className="free">
              <input
                className="f-check-box"
                type="checkbox"
                name="is_featured_post"
                value={edit?.is_featured_post}
                checked={edit?.is_featured_post === true ? false : true}
                // checked={edit?.is}
              />
              <span className="f-checkBox">Free Posting</span>
            </div>
            <div className="feature">
              <input
                className="f-check-box"
                type="checkbox"
                name="is_featured_post"
                value={edit?.is_featured_post}
                onClick={handleSuceesShow}
                checked={edit?.is_featured_post === true ? true : false}
                // checked={edit?.is_featured_post === "false"}
              />
              <span className="f-checkBox">Featured Posting</span>
            </div>
          </div>
        </div>
        <button type="submit" className="publish-btn">
          Publish
          <ToastContainer />
        </button>
      </form>
      <Modal centered={true} show={isSuccess}>
        <FeatureModal closed={handleSuceesClose} />
      </Modal>
    </>
  );
};

export default JobEdit;
