import React from "react";
import "../../Style/TalentOppertunity.css";
import { useNavigate } from "react-router-dom";
const OpportunityBox = ({ data, dataBtn }) => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="TalentMain">
        <div className="talent">
          <h1 className="TalentHead">{data?.title}</h1>
          <p className="TalentPara">{data?.content}</p>
          <button className="SignUp-btn" onClick={() => window.open("/signUp")}>
            {data?.button_text}
          </button>
          {dataBtn === undefined ? (
            ""
          ) : (
            <button
              className="full-time-SignUp-btn"
              onClick={() => navigate(dataBtn?.link)}
            >
              {dataBtn?.text}
            </button>
          )}
        </div>
        <div className="banner-img">
          <img className="Main-Img" src={data?.image} alt="talent" />
        </div>
      </div>
    </div>
  );
};

export default OpportunityBox;
