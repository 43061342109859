import React from "react";
import MentorFeaturesProfile from "../../Component/MentorDashBoard/MentorFeaturesProfile";
import { Helmet } from "react-helmet";
const Mentor = () => {
  return (
    <div>
      <Helmet>
        <title>55notout | Mentor DashBoard</title>
        <meta name="description" content="Mentor DashBoard" />
      </Helmet>
      <MentorFeaturesProfile />
    </div>
  );
};

export default Mentor;
