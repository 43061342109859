import React from "react";
import BrowserFeaturesProfiles from "../../Component/OrgBrowserFeatures/BrowserFeaturesProfiles";
import { Helmet } from "react-helmet";
import OrgNav from "../../Component/Organisation/OrgNav";
const BrowserFeatured = () => {
  return (
    <div>
      <Helmet>
        <title>55notout | Browser Featured Profile</title>
        <meta name="description" content="Browser Featured Profile" />
      </Helmet>
      <OrgNav />
      <BrowserFeaturesProfiles />
    </div>
  );
};

export default BrowserFeatured;
