import React, { useState, useRef } from "react";
import { userPassword } from "../../Api/api";
import { ToastContainer, toast } from "react-toastify";
const ChangePassword = ({ close }) => {
  const [error, setError] = useState({ open: false, msg: "" });
  const [passwordMsg, setPasswordMsg] = useState();
  const passwords = useRef();
  const newPasswords = useRef();
  const cnfrmPasswords = useRef();
  const handleSubmit = (e) => {
    e.preventDefault();
    setError({ open: false, msg: "" });
    const currentPassword = passwords.current.value;
    const newPassword = newPasswords.current.value;
    const cnfrmPassword1 = cnfrmPasswords.current.value;

    if (newPassword !== cnfrmPassword1) {
      setError({ open: true, msg: "Password does not match " });
      return;
    } else if (newPassword.length < 8) {
      setError({ open: true, msg: "Password must shoud be 8 digit" });
      return;
    }
    const data = {
      current_password: currentPassword,
      new_password: newPassword,
      cnfrmPassword: cnfrmPassword1,
    };
    userPassword(data)
      .then((res) => {
        const response = res;
        if (response.status === 200) {
          toast(response.data.msg, {
            autoClose: 500,
            position: toast.POSITION.TOP_RIGHT,
          });
          setTimeout(() => {
            // window.location.href = "/dashboard";
            close();
          }, 1000);
        }

        setPasswordMsg(response.data);
      })
      .catch((e) =>
        toast(e.response.data.non_field_errors[0], {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        })
      );

    // alert(passwordMsg.msg);
  };

  return (
    <div className="change-pswrd-header-main">
      <h1
        className="close"
        onClick={close}
        style={{ padding: "5px", float: "right" }}
      >
        X
      </h1>
      <h1 className="login">Change Password</h1>
      <form onSubmit={handleSubmit}>
        <div className="Input_box">
          <input
            className="password"
            type="password"
            placeholder="Enter Your Password"
            ref={passwords}
            required
          />
          <input
            className="password"
            type="password"
            placeholder="Enter Your New Password"
            ref={newPasswords}
            required
          />
          <input
            className="password"
            type="password"
            placeholder="Confirm Your Password"
            ref={cnfrmPasswords}
            required
          />
          {error.open && (
            <p className="error" style={{ color: "red" }}>
              {error.msg}
            </p>
          )}
        </div>
        <button type="submit" className="loginPage-btn">
          Reset Password
          <ToastContainer />
        </button>
      </form>
    </div>
  );
};

export default ChangePassword;
