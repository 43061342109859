import React from "react";
import Offer from "../../Component/OfferComponent/Offer";
import { Helmet } from "react-helmet";
const Offers = () => {
  return (
    <div>
      <Helmet>
        <title>55notout | Plans</title>
        <meta name="description" content="Check Our New Offers" />
      </Helmet>
      <Offer />
    </div>
  );
};

export default Offers;
