import React from "react";
import "../../Style/OrgDashboard/Org-content.css";
import { NavLink } from "react-router-dom";
const OrgContent = () => {
  return (
    <div className="Content-Main">
      <div className="Content-Box">
        <NavLink to="/postjob">
          <img className="content-img" src="Images/Post.png" alt="Post-job" />
          <h4 className="content-box-text"> Post Job</h4>
        </NavLink>
      </div>
      <div className="Content-Box">
        <NavLink to="/postjob">
          <img
            className="content-img"
            src="Images/Volentier.png"
            alt="Post-job"
          />
          <h4 className="content-box-text">Post Volunteer Jobs</h4>
        </NavLink>
      </div>
      <div className="Content-Box">
        <NavLink to="/postjob">
          <img
            className="content-img"
            src="Images/Consulting.png"
            alt="Post-job"
          />
          <h4 className="content-box-text">Post Consulting Job</h4>
        </NavLink>
      </div>

      <div className="Content-Box">
        <NavLink to="/eventPosting">
          <img className="content-img" src="Images/List.png" alt="Post-job" />
          <h4 className="content-box-text">List an Event</h4>
        </NavLink>
      </div>
      <div className="Content-Box">
        <NavLink to="/coursePosting">
          <img
            className="content-img"
            src="Images/List-Course.png"
            alt="Post-job"
          />
          <h4 className="content-box-text">List a Course</h4>
        </NavLink>
      </div>
      <div className="Content-Box">
        <NavLink to="/productPosting">
          <img
            className="content-img"
            src="Images/Product.png"
            alt="Post-job"
          />
          <h4 className="content-box-text">List Products</h4>
        </NavLink>
      </div>
      <div className="Content-Box">
        <NavLink to="/servicePosting">
          <img
            className="content-img"
            src="Images/Service.png"
            alt="Post-job"
          />
          <h4 className="content-box-text">List Services</h4>
        </NavLink>
      </div>
    </div>
  );
};

export default OrgContent;
