import React, { useState, useEffect } from "react";
import "../../Style/Orgn/org-service.css";
import { OrganizationService } from "../../Api/api";
const OrgService = () => {
  const [orgService, setOrgService] = useState([]);
  useEffect(() => {
    const loadData = () => {
      OrganizationService().then((res) => {
        const response = res;
        setOrgService(response.data);
      });
    };
    loadData();
  }, []);
  return (
    <div>
      <div>
        <h4 className="org-service">Our Services</h4>
        <h1 className="org-provide">What We Provide</h1>
      </div>
      <div className="org-serviceMain">
        {orgService.map((value, index) => {
          return (
            <div className="org-serviceContainer">
              <div className="org-iconContainer">
                <img className="org-icon" src={value.image} alt="icons" />
                <h1 className="org-num">0{index + 1}</h1>
              </div>
              <h1 className="org-head">{value.title}</h1>

              <p className="org-p">{value.content}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OrgService;
