import React, { useState, useEffect } from "react";

import { servicePostings } from "../../Api/OrgApi";
import ServicePosts from "../../Component/ServicePost/ServicePosts";
// import { productCategory } from "../../Api/OrgApi";
import { serviceCategory } from "../../Api/OrgApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import OrgNav from "../../Component/Organisation/OrgNav";
const dataPost = {
  title: "Service Posting",
  titleHead: "Service Heading/Title",
  desp: "Service Description",
  catgory: "Service Category",
  price: "Service Price (INR)",
  phone: "Phone Number",
  email: "Email",
  web: "Website",
  link: "Link To Your Service",
};
const data3 = {
  title: "Free or Featured service Listing?",
  free: "Free service Listing",
  features: "Featured service Listing",
};
const ServicePost = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const handleSuceesClose = () => setIsSuccess(false);
  const handleSuceesShow = () => {
    setIsSuccess(true);
  };
  const [data, setData] = useState({
    name: "",
    description: "",
    // delivery_mode: "",
    category: "",
    fee: "",
    is_free: "",
    thumbnail_images: "",
    banner_image: "",
    phone_number: "",
    email: "",
    website: "",
    link: "",
    location: "1882",
  });
  const [thumbimg, setThumbImg] = useState({});
  const [thumbimg2, setThumbImg2] = useState();
  const [bannerimg, setBannerImg] = useState({});
  const [bannerimg2, setBannerImg2] = useState();
  const [category, setCategory] = useState([]);
  const notify = () => {
    // Calling toast method by passing string

    toast("submit SuccesFull", {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("category", data.category);
    formData.append("banner_image", bannerimg);
    formData.append("thumbnail_images", thumbimg);
    formData.append(" phone_number", data.phone_number);
    formData.append(" email", data.email);
    formData.append(" website ", data.website);
    formData.append(" link", data.link);
    formData.append(" fee", data.fee);
    formData.append(" is_free", data.is_free);
    formData.append(" location", "1882");
    // formData.append(" thumbnail_images", image);
    // formData.append(" banner_image", bimage);
    servicePostings(formData)
      .then((res) => {
        const response = res;
        localStorage.setItem("service_id", response?.data?.data?.id);
        if (response?.status === 200) {
          handleSuceesShow();
          notify();
          // setTimeout(() => {
          //   window.location.href = "/orgdashboard";
          // }, 1000);
        }
      })
      .catch((e) =>
        e.response.status === 400
          ? toast(" Bad Request", {
              autoClose: 500,
              position: toast.POSITION.TOP_RIGHT,
            })
          : ""
      );
  };
  // const handleCheck = (e) => {
  //   const { name, checked } = e.target;

  //   setData({ ...data, [name]: checked });
  // };
  const handleInput = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };
  const handleThumbImage = (e) => {
    const image = e.target.files[0];
    setThumbImg(image);
    setThumbImg2(URL.createObjectURL(e.target.files[0]));
  };
  const handleBannerImage = (e) => {
    const bimage = e.target.files[0];
    setBannerImg(bimage);
    setBannerImg2(URL.createObjectURL(e.target.files[0]));
  };
  useEffect(() => {
    const loadData = () => {
      serviceCategory().then((res) => {
        const response = res;
        const data1 = response;

        let temp = { ...data, category: data1[0]?.id + "" };
        setData(temp);
        setCategory(data1);
        // setData({ ...data, category: category[1]?.id });
      });
    };
    loadData();
  }, []);
  return (
    <div>
      <Helmet>
        <title>55notout | Post Service</title>
        <meta name="description" content="Post A New Service" />
      </Helmet>
      <OrgNav />
      <ServicePosts
        data={data}
        dataPost={dataPost}
        data3={data3}
        // handleCheck={handleCheck}
        handleInput={handleInput}
        handleBannerImage={handleBannerImage}
        handleThumbImage={handleThumbImage}
        handleSubmit={handleSubmit}
        category={category}
        thumbimg={thumbimg2}
        bannerimg={bannerimg2}
        handleSuceesClose={handleSuceesClose}
        isSuccess={isSuccess}
      />
    </div>
  );
};

export default ServicePost;
