import React, { useState, useEffect } from "react";
import MentorSlotBooking from "../../Component/MentorAvailable/MentorSlotBooking";
import MentorStudent from "../../Component/MentorAvailable/MentorStudent";
import { useParams } from "react-router-dom";
import { getMentorsDetails, getReview } from "../../Api/Mentor";
import { Helmet } from "react-helmet";
const MentorAvailbilies = () => {
  let { slug } = useParams();
  const [data, setData] = useState({});
  useEffect(() => {
    const loadData = () => {
      getMentorsDetails({ slug }).then((res) => {
        const response = res.data;
        setData(response);
      });
    };
    loadData();
  }, [slug]);

  return (
    <div>
      <Helmet>
        <title>55notout | Mentor Availability</title>
        <meta name="description" content=" Mentor Availability" />
      </Helmet>
      <MentorSlotBooking data={data} />
      <MentorStudent slug={slug} data={data} />
    </div>
  );
};

export default MentorAvailbilies;
