import React, { useState, useEffect } from "react";
import { TalentsBanner } from "../../Api/api";
import { Navbar } from "../../Component/Navbar";
import OpportunityBox from "../../Component/TalentFile/OpportunityBox";
import Services from "../../Component/TalentFile/Services";
import BlankShadow from "../../Component/BlankShadow";
import ListOraginsation from "../../Component/TalentFile/ListOraginsation";
import { Helmet } from "react-helmet";

const Talents = () => {
  const [talentsData, setTalentsData] = useState({});
  useEffect(() => {
    const loadData = () => {
      TalentsBanner().then((res) => {
        const response = res;

        setTalentsData(response.data);
      });
    };
    loadData();
  }, []);

  return (
    <div>
      <Helmet>
        <title>55notout | Talents</title>
        <meta
          name="description"
          content="Explore Our New Jobs,Course,Events,Product,Service and more"
        />
      </Helmet>
      <Navbar />
      <OpportunityBox data={talentsData} />
      <Services />
      <BlankShadow />
      <ListOraginsation />
    </div>
  );
};

export default Talents;
