import axios from "axios";
const baseUrl = "https://admin.55notout.com/";
const ServiceBanner = () => {
  const urlPath = "serviceListingAndDetailsModule/banner/";
  const url = `${baseUrl}${urlPath}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const FeatureServiceDetails = () => {
  const urlPath =
    "serviceListingAndDetailsModule/all_services_list/?is_featured_post=true";
  const url = `${baseUrl}${urlPath}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const SpecialServices = () => {
  const urlPath =
    "serviceListingAndDetailsModule/all_services_list/?is_featured_post=false";
  const url = `${baseUrl}${urlPath}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const details = ({ slug }) => {
  const urlPath = "serviceListingAndDetailsModule/service_details/";
  const url = `${baseUrl}${urlPath}${slug}`;
  try {
    const response = axios
      .get(url, {
        headers: {
          // Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MywiZXhwIjoxNjU3MjgwNjcxLCJ1c2VybmFtZSI6InB5c2F1bmRhcnlfeWFob28ifQ.GWab7rOFGcfXVVARrDKVuDXCH0ObaNzNfCXeiuCNwoQ"}`,
          Authorization: `bearer ${localStorage.getItem("TalentToken")}`,
        },
      })
      .then((response) => {
        return response.data;
      });
    return response;
  } catch (error) {
    return error;
  }
};
const FeatureServiceApply = ({ slug }) => {
  const urlPath = "serviceListingAndDetailsModule/apply_on_service/";
  const url = `${baseUrl}${urlPath}${slug}`;
  const response = fetch(url, {
    headers: {
      // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MywiZXhwIjoxNjU3MjgwNjcxLCJ1c2VybmFtZSI6InB5c2F1bmRhcnlfeWFob28ifQ.GWab7rOFGcfXVVARrDKVuDXCH0ObaNzNfCXeiuCNwoQ`,
      Authorization: `bearer ${localStorage.getItem("TalentToken")}`,
    },
  })
    .then((e) => e.json())
    .then((e) => e);
  return response;
};
const AllService = (filter) => {
  const slug = `serviceListingAndDetailsModule/all_services_list/`;
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios
      .get(url, {
        params: filter,
      })
      .then((response) => {
        return response.data;
      });
    return response;
  } catch (error) {
    return error;
  }
};
export {
  ServiceBanner,
  FeatureServiceDetails,
  SpecialServices,
  details,
  FeatureServiceApply,
  AllService,
};
