import React, { useEffect } from "react";
import { paymentFinalStep, slotPaymentFinalStep } from "../Api/PaymentGetway";
import { ToastContainer, toast } from "react-toastify";
export function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const token = JSON.parse(localStorage?.getItem("LoginData"))?.token;

const handlePaymentProcessing = (data) => {
  slotPaymentFinalStep(data, token)
    .then((res) => {
      const response = res;

      toast(response?.data?.msg, {
        autoClose: 500,
        position: toast.POSITION.TOP_RIGHT,
      });
      if (response?.status === 200) {
        setTimeout(() => {
          window.location.reload(false);
        }, 1000);
        // window.location.href = `/mentorSlotBooking/${value?.slug}`;
      } else {
        window.location.href = `/order-failed/`;
      }
    })

    .catch((e) =>
      toast(e?.data?.msg, {
        autoClose: 500,
        position: toast.POSITION.TOP_RIGHT,
      })
    );

  <ToastContainer />;
};

const handleBookingPaymentResponse = (res) => {
  let paymetStatus = {
    status: "SUCCESS",
    razorpay_order_id: res?.razorpay_order_id,
    razorpay_payment_id: res?.razorpay_payment_id,
    razorpay_signature: res?.razorpay_signature,
  };

  handlePaymentProcessing(paymetStatus);
};

export async function displayRazorpay(data) {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  const options = {
    key: "rzp_live_QtxRph4NV7qHji",
    // key:"rzp_live_QtxRph4NV7qHji",
    currency: "INR",
    amount: data?.amount.toString(),
    order_id: data?.id,
    name: "55notout",
    description: "Thank you for nothing",
    image: "http://localhost:1337/logo.svg",
    handler: handleBookingPaymentResponse,
  };
  var rzp1 = new window.Razorpay(options);
  rzp1.on("payment.failed", function (response) {
    let paymetStatus = {
      status: "FAILED",
      description: response.error.description,
      code: response.error.code,
      source: response.error.source,
      reason: response.error.reason,
      order_id: response.error.metadata.order_id,
      payment_id: response.error.metadata.payment_id,
    };
    handlePaymentProcessing(paymetStatus);
  });

  rzp1.open();
}
