import React from "react";
import "../../Component/MentorCruise/MentorCruise.css";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
const Expertise = () => {
  const navigate = useNavigate();
  const popUp = () => {
    toast("Please Login To Continue", {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const popUp2 = () => {
    toast("Oops; you are Already Mentor", {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });
  };
  return (
    <div className="expertiseMain">
      <div className="expertiseDetails">
        <h1 className="expertiseText">
          Share your expertise, grow,
          <h1 className="expertiseText2">make a difference</h1>
        </h1>
        <p className="expertiseTextParagraph">
          Mentoring is a two-way street. Let us take care of the boring parts so
          you can concentrate on personal and professional growth for both you
          and your mentees.
        </p>
      </div>
      <div className="expertiseButtonContainer">
        {localStorage.getItem("TalentToken") &&
        localStorage.getItem("mentor_slug") === "null" ? (
          <button
            className="expertiseMentor"
            onClick={() => navigate(`/mentorDetails`)}
          >
            Become a mentor
          </button>
        ) : (
          <button
            className="expertiseMentor"
            onClick={localStorage.getItem("mentor_slug") ? popUp2 : popUp}
          >
            Become a mentor
            <ToastContainer />
          </button>
        )}
        <a href="#FAQs" className="expertiseFaq">
          Frequently asked questions
        </a>
      </div>
    </div>
  );
};

export default Expertise;
