import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "../../Style/Service/FeatureServece.css";
import { NavLink, useNavigate } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../Component/FullTime-jobs/Pagination";
const FeatureService = ({ dataService, fService, navigateTo }) => {
  const navigate = useNavigate();
  const popUp = () => {
    toast("Please Login To Continue", {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const featurePopup = () => {
    toast("you are not Feature Account", {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const [showPerPage, setShowPerPage] = useState(4);
  const [paginat, setPaginat] = useState({
    start: 0,
    end: showPerPage,
  });
  const PaginationHandle = (start, end) => {
    setPaginat({ start: start, end: end });
  };
  const executeOnClick = (isExpanded) => {};
  return (
    <>
      <div>
        <h4 className="FeatureListOrag">{fService.Head}</h4>
      </div>
      <div className="feature-service">
        {dataService?.slice(paginat?.start, paginat?.end)?.map((value) => {
          return (
            <div className="feature-service-listing-job-main">
              <div className="feature-service-image-container">
                <img
                  className="feature-service-listing-Job-Img"
                  src={value?.thumbnail_images}
                  alt="Find Jobs"
                />
                <div className="feature-service-listing-Job">
                  <img
                    className="service-logo"
                    src={value?.organization_details?.image}
                    alt="logo"
                    style={{ width: "25%" }}
                  />
                </div>

                <h1 className="feature-service-listing-company"></h1>
              </div>
              {localStorage.getItem("TalentToken") ? (
                <div className="feature-service-listing-Job-left">
                  <NavLink
                    to={{
                      pathname: `${navigateTo}${value?.slug}`,
                      state: { value },
                    }}
                  >
                    <h1 className="feature-service-listing-Job-Title">
                      {value?.name}
                    </h1>

                    {localStorage.getItem("TalentToken") ? (
                      <ShowMoreText
                        lines={3}
                        more="Show more"
                        less="Show less"
                        className="feature-service-listing-Job-para"
                        anchorclassName="my-anchor-css-class"
                        onClick={() => navigate(`${navigateTo}${value?.slug}`)}
                        // onClick={executeOnClick}
                        expanded={false}
                        expandByClick={false}
                        width={0}
                        truncatedEndingComponent={"... "}
                      >
                        {value?.description}
                      </ShowMoreText>
                    ) : (
                      <ShowMoreText
                        lines={3}
                        more="Show more"
                        less="Show less"
                        className="Job-Desp"
                        anchorclassName="my-anchor-css-class"
                        onClick={() =>
                          localStorage.getItem("TalentToken")
                            ? navigate(`/servicedetails/${value?.slug}`)
                            : popUp()
                        }
                        // onClick={() =>  navigate(`/coursedetails/${value?.slug}`)}
                        expanded={false}
                        expandByClick={false}
                        width={0}
                        truncatedEndingComponent={"..."}
                      >
                        {value?.description}
                      </ShowMoreText>
                    )}
                    <div className="feature-service-listing-rate-percent">
                      <h4 className="feature-service-listing-rate">
                        <FontAwesomeIcon
                          className="cross-Icon"
                          icon={faIndianRupeeSign}
                          color="red"
                        />
                        {value?.fee}
                      </h4>

                      <h4 className="feature-service-listing-percent">
                        View Listing
                      </h4>
                    </div>
                  </NavLink>
                </div>
              ) : (
                <div className="feature-service-listing-Job-left">
                  <NavLink to="">
                    <h1 className="feature-service-listing-Job-Title">
                      {value?.name}
                    </h1>

                    {
                      <ShowMoreText
                        lines={3}
                        more="Show more"
                        less="Show less"
                        className="feature-service-listing-Job-para"
                        anchorclassName="my-anchor-css-class"
                        onClick={() =>
                          localStorage.getItem("TalentToken")
                            ? executeOnClick()
                            : popUp()
                        }
                        // onClick={executeOnClick}
                        expandByClick={false}
                        expanded={false}
                        width={0}
                        truncatedEndingComponent={"... "}
                      >
                        {value?.description}
                      </ShowMoreText>
                    }
                    <div className="feature-service-listing-rate-percent">
                      <h4 className="feature-service-listing-rate">
                        <FontAwesomeIcon
                          className="cross-Icon"
                          icon={faIndianRupeeSign}
                          color="red"
                        />
                        {value?.fee}
                      </h4>

                      <h4
                        className="feature-service-listing-percent"
                        onClick={
                          localStorage.getItem("TalentToken")
                            ? featurePopup
                            : popUp
                        }
                      >
                        View Listing
                      </h4>
                    </div>
                    <ToastContainer />
                  </NavLink>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {dataService.length >= 4 ? (
        <Pagination
          showPerPage={showPerPage}
          PaginationHandle={PaginationHandle}
          total={dataService?.length}
        />
      ) : (
        ""
      )}
      <Carousel className="mob-response">
        {dataService?.map((value) => {
          return (
            <div className="mob-featureService">
              <div className="feature-service-image-container">
                <img
                  className="feature-service-listing-Job-Img"
                  src={value.thumbnail_images}
                  alt="Find Jobs"
                />
                <div className="feature-service-listing-Job">
                  <img
                    className="service-logo"
                    src={value.organization_details.image}
                    alt="logo"
                    style={{ width: "15%" }}
                  />
                </div>
              </div>
              {localStorage.getItem("TalentToken") ? (
                <div className="feature-service-listing-Job-left">
                  <NavLink to={`${navigateTo}${value.slug}`}>
                    <h1 className="feature-service-listing-Job-Title">
                      {value.name}
                    </h1>

                    <ShowMoreText
                      lines={3}
                      more="Show more"
                      less="Show less"
                      className="feature-service-listing-Job-para"
                      anchorclassName="my-anchor-css-class"
                      onClick={executeOnClick}
                      expanded={false}
                      width={0}
                      truncatedEndingComponent={"... "}
                    >
                      {value?.description}
                    </ShowMoreText>

                    <div className="feature-service-listing-rate-percent">
                      <h4 className="feature-service-listing-rate">
                        <FontAwesomeIcon
                          className="cross-Icon"
                          icon={faIndianRupeeSign}
                          color="red"
                        />
                        {value.fee}
                      </h4>
                      <h4 className="feature-service-listing-percent">
                        View Listing
                      </h4>
                    </div>
                  </NavLink>
                </div>
              ) : (
                <div className="feature-service-listing-Job-left">
                  <NavLink to="" onClick={popUp}>
                    <h1 className="feature-service-listing-Job-Title">
                      {value.name}
                    </h1>

                    <ShowMoreText
                      lines={3}
                      more="Show more"
                      less="Show less"
                      className="feature-service-listing-Job-para"
                      anchorclassName="my-anchor-css-class"
                      onClick={executeOnClick}
                      expanded={false}
                      width={0}
                      truncatedEndingComponent={"... "}
                    >
                      {value?.description}
                      <ToastContainer />
                    </ShowMoreText>

                    <div className="feature-service-listing-rate-percent">
                      <h4 className="feature-service-listing-rate">
                        {value.fee}
                      </h4>
                      <h4 className="feature-service-listing-percent">
                        View Listing
                      </h4>
                    </div>
                  </NavLink>
                </div>
              )}
            </div>
          );
        })}
      </Carousel>
    </>
  );
};

export default FeatureService;
