import React from "react";
import "../SearchMain/SearchMain.css";
import Job from "../../Image/Fulltime.jpeg";
import Consulting from "../../Image/consulting.jpeg";
import Volunteer from "../../Image/Volenteer.jpeg";
import Events from "../../Image/Events.jpeg";
import Course from "../../Image/Courses.jpeg";
import Mentor from "../../Image/Mentors.jpeg";
import Product from "../../Image/Products.jpeg";
import Service from "../../Image/Services.jpeg";
import { useNavigate } from "react-router-dom";
const MainSearch = () => {
  const navigate = useNavigate();
  return (
    <div className="searchMain">
      <div className="searchText">
        <h1 className="searchKeyWord">Are you looking for...</h1>
      </div>
      <div className="searchItems">
        <div className="searchCategory" onClick={() => navigate(`/Job`)}>
          <img className="categoryImage" src={Job} alt="Job" />
        </div>
        <div
          className="searchCategory"
          onClick={() => navigate(`/Consultings`)}
        >
          <img
            className="categoryImage"
            src={Consulting}
            alt=" Consulting Job"
          />
        </div>
        <div className="searchCategory" onClick={() => navigate(`/Volunteers`)}>
          <img className="categoryImage" src={Volunteer} alt="Volunteer Job" />
        </div>
        <div className="searchCategory" onClick={() => navigate(`/Event`)}>
          <img className="categoryImage" src={Events} alt="Event" />
        </div>
        <div className="searchCategory" onClick={() => navigate(`/Course`)}>
          <img className="categoryImage" src={Course} alt="Course" />
        </div>
        <div className="searchCategory" onClick={() => navigate(`/Mentors`)}>
          <img className="categoryImage" src={Mentor} alt="Mentors" />
        </div>
        <div className="searchCategory" onClick={() => navigate(`/Products`)}>
          <img className="categoryImage" src={Product} alt="Products" />
        </div>
        <div className="searchCategory" onClick={() => navigate(`/Services`)}>
          <img className="categoryImage" src={Service} alt="Services" />
        </div>
      </div>
    </div>
  );
};

export default MainSearch;
