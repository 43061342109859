import React, { useState, useEffect } from "react";
import { mentorBanner } from "../../Api/Mentor";
import { useNavigate } from "react-router-dom";
const MentorBanner = () => {
  const [mentorbanner, setMentorbanner] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    const loadData = () => {
      mentorBanner().then((res) => {
        const response = res;

        setMentorbanner(response.data);
      });
    };
    loadData();
  }, []);
  return (
    <div>
      <div className="TalentMain">
        <div className="talent">
          <h1 className="TalentHead">{mentorbanner?.title}</h1>
          <p className="TalentPara">{mentorbanner?.content}</p>
          <button className="SignUp-btn" onClick={() => window.open("/signUp")}>
            {mentorbanner?.button_text}
          </button>
          <button
            className="full-time-SignUp-btn"
            onClick={() => navigate("/Mentors")}
          >
            All Mentors Listing
          </button>
        </div>
        <div className="banner-img">
          <img
            className="Main-Img"
            src={mentorbanner?.image}
            //    src={data?.image}
            alt="talent"
          />
        </div>
      </div>
    </div>
  );
};

export default MentorBanner;
