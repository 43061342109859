import React, { useState, useEffect } from "react";
import AboutService from "../../Component/ServiceDetails.jsx/AboutService";
import { details, FeatureServiceApply } from "../../Api/ServiceApi";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
const ServiceDetails = () => {
  let { slug } = useParams();
  const [detail, setDetail] = useState({});
  const [applyDetail, setApplyDetail] = useState({});
  useEffect(() => {
    const loadData = () => {
      details({ slug }).then((res) => {
        const response = res;

        setDetail(response.data);
      });
    };
    loadData();
  }, [slug]);
  useEffect(() => {
    const loadData = async () => {
      const response = await FeatureServiceApply({ slug });

      setApplyDetail(response);
    };
    loadData();
  }, [slug]);
  return (
    <div>
      <Helmet>
        <title>55notout | Services Details</title>
        <meta name="description" content="Services Details page" />
      </Helmet>
      <AboutService detail={detail} applyDetail={applyDetail} />
    </div>
  );
};

export default ServiceDetails;
