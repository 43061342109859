import React, { useState, useEffect, useRef } from "react";
import "../../Style/Login/Login.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faXmark } from "@fortawesome/free-solid-svg-icons";
import { AuthTalentLogin } from "../../Api/api";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import GoogleLogin from "react-google-login";
import { gapi } from "gapi-script";
import { socialMediaLogin } from "../../Api/api";
import "react-toastify/dist/ReactToastify.css";

// toast.configure();
const LogInPage = ({ setShowSignUpModel, close, setForget }) => {
  const navigate = useNavigate();

  const [login, setLogin] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const email = useRef();
  const password = useRef();

  const submit = (e) => {
    e.preventDefault();
    const loginData = {
      email: email.current.value,
      password: password.current.value,
    };

    AuthTalentLogin(loginData)
      .then((res) => {
        setLogin(res);
        const response = res;

        if (response?.status === 200) {
          // navigate(`/dashboard`);

          toast("Login Succesfull", {
            autoClose: 500,
            position: toast.POSITION.TOP_RIGHT,
          });
          // notify();
          setTimeout(() => {
            window.location.href = "/dashboard";
          }, 1000);
        }
        localStorage.removeItem("token");
        localStorage.setItem("TalentToken", response?.data?.token);
        localStorage.setItem("mentor_slug", response?.data?.mentor_slug);
        localStorage.setItem(
          "feature_talent",
          response?.data?.is_feature_talent
        );
        localStorage.setItem(
          "feature_mentor",
          response?.data?.is_feature_mentor
        );
      })
      .catch((e) =>
        toast(" Bad Request", {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        })
      );
  };
  const clientId =
    "432970784783-73rgpbmppspm4gvk4t2jd4nngvo7m9te.apps.googleusercontent.com";
  // useEffect(() => {

  // }, []);

  const responseGoogle = (response) => {
    gapi.load("client:auth2", () => {
      gapi.auth2.init({ clientId: clientId });
    });

    const data = {
      email: response.profileObj.email,
      username: response.profileObj.name,
      provider: "google",
      token: response.accessToken,
    };

    socialMediaLogin(data).then((res) => {
      const response = res;
      if (response?.status === 200) {
        toast("Login Succesfull", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
        });

        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 1000);
      }
      localStorage.removeItem("token");
      localStorage.setItem("TalentToken", response?.data?.token);
      localStorage.setItem("mentor_slug", response?.data?.mentor_slug);
      localStorage.setItem("feature_talent", response?.data?.is_feature_talent);
      localStorage.setItem("feature_mentor", response?.data?.is_feature_mentor);
    });
  };

  return (
    <>
      <div className="Signin-display-page">
        <div className="Sign-Container">
          <NavLink to="/">
            <img
              className="login-page-header"
              src="Icons/logo.png"
              alt="logo"
            />
          </NavLink>

          <div className="header-main"></div>
          <h1 className="login">Login </h1>
          <form onSubmit={submit}>
            <div className="Input_box">
              <input
                className="email"
                type="text"
                placeholder="Enter Your Email Address"
                ref={email}
                required
              />
              <input
                className="password"
                type="password"
                placeholder="Enter Your Password"
                ref={password}
                required
              />
            </div>
            <h6 className="forget" onClick={setForget}>
              Forget Password
            </h6>

            <button type="submit" className="loginPage-btn">
              LOGIN
              <FontAwesomeIcon className="icon" icon={faAngleRight} />
            </button>
            <div style={{ textAlign: "center", marginTop: "5%" }}>
              <GoogleLogin
                clientId={clientId}
                buttonText="Sign in with Google"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                // cookiePolicy={"single_host_origin"}
                className="l-signin-btn"
              ></GoogleLogin>
            </div>
            <div className="resgister-box">
              <h6 className="register">Not Registered Yet?</h6>
              <h6 onClick={() => navigate(`/signUp`)} className="create-link">
                Create Your Account
              </h6>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default LogInPage;
