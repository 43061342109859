import React from "react";
import EventsDetails from "../../Component/OrganizationDetails/EventsDetails";
const EventsEdit = () => {
  const dataPost = {
    title: "Event Posting",
    titleHead: "Event Heading/Title",
    desp: "Event Description",
    catgory: "Delivery Mode",
    price: "Event Price (INR)",
    phone: "Phone Number",
    email: "Email",
    web: "Website",
    link: "Link To Your Event",
  };

  return <div></div>;
};

export default EventsEdit;
