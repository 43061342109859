import { FeaturesStep1, FeaturesStep2, FeaturesStep3 } from "../Api/api";
import { ToastContainer, toast } from "react-toastify";
export function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
const token = JSON.parse(localStorage?.getItem("LoginData"))?.token;
const handlePaymentProcessing = (data) => {
  FeaturesStep3(data, token)
    .then((res) => {
      console.log("res", res);

      if (res.status === 200) {
        toast("Feature Services Add SuccesFully", {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          window.location.href = "/orgdashboard";
        }, 1000);
        // window.location.href = `/orgdashboard`;
      } else {
        window.location.href = `/order-failed/`;
      }
    })
    .catch((e) => console.log("error"));
};

const handleBookingPaymentResponse = (res) => {
  let paymetStatus = {
    status: "SUCCESS",
    razorpay_order_id: res?.razorpay_order_id,
    razorpay_payment_id: res?.razorpay_payment_id,
    razorpay_signature: res?.razorpay_signature,
  };

  handlePaymentProcessing(paymetStatus);
};

export async function displayRazorpay(data) {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  const options = {
    key: "rzp_live_QtxRph4NV7qHji",
    // key:"rzp_live_QtxRph4NV7qHji",
    currency: "INR",
    amount: data?.amount.toString(),
    order_id: data?.id,
    name: "55notout",
    description: "Thank you for nothing",
    image: "http://localhost:1337/logo.svg",
    handler: handleBookingPaymentResponse,
  };
  var rzp1 = new window.Razorpay(options);
  rzp1.on("payment.failed", function (response) {
    let paymetStatus = {
      status: "FAILED",
      description: response.error.description,
      code: response.error.code,
      source: response.error.source,
      reason: response.error.reason,
      order_id: response.error.metadata.order_id,
      payment_id: response.error.metadata.payment_id,
    };
    handlePaymentProcessing(paymetStatus);
  });

  rzp1.open();
}
