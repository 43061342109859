import axios from "axios";
const baseUrl = "https://admin.55notout.com/";
const CourseBanner = () => {
  const slug = "courseListAndDetailsModule/banner/";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const FeatureCourse = () => {
  const slug =
    "courseListAndDetailsModule/all_course_list/?is_featured_post=true";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};

const ListCourse = () => {
  const slug =
    "courseListAndDetailsModule/all_course_list/?is_featured_post=false";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const FeatureCourseDetails = ({ slug }) => {
  const urlPath = "courseListAndDetailsModule/course_details/";
  const url = `${baseUrl}${urlPath}${slug}`;
  try {
    const response = axios
      .get(url, {
        headers: {
          // Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MywiZXhwIjoxNjU3MjgwNjcxLCJ1c2VybmFtZSI6InB5c2F1bmRhcnlfeWFob28ifQ.GWab7rOFGcfXVVARrDKVuDXCH0ObaNzNfCXeiuCNwoQ"}`,
          Authorization: `bearer ${localStorage.getItem("TalentToken")}`,
        },
      })
      .then((response) => {
        return response.data;
      });
    return response;
  } catch (error) {
    return error;
  }
};
const FeatureCourseApply = ({ slug }) => {
  const urlPath = "courseListAndDetailsModule/apply_on_course/";
  const url = `${baseUrl}${urlPath}${slug}`;
  try {
    const response = axios
      .get(url, {
        headers: {
          // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MywiZXhwIjoxNjU3MjgwNjcxLCJ1c2VybmFtZSI6InB5c2F1bmRhcnlfeWFob28ifQ.GWab7rOFGcfXVVARrDKVuDXCH0ObaNzNfCXeiuCNwoQ`,
          Authorization: `bearer ${localStorage.getItem("TalentToken")}`,
        },
      })
      .then((response) => {
        return response.data;
      });
    return response;
  } catch (error) {
    return error;
  }
};
const AllCourse = (filter) => {
  const slug = `courseListAndDetailsModule/all_course_list/`;
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios
      .get(url, {
        params: filter,
      })
      .then((response) => {
        return response.data;
      });
    return response;
  } catch (error) {
    return error;
  }
};
export {
  CourseBanner,
  FeatureCourse,
  ListCourse,
  FeatureCourseDetails,
  FeatureCourseApply,
  AllCourse,
};
