import axios from "axios";
const baseUrl = "https://admin.55notout.com/";

const JobBanner = () => {
  const slug = "jobListingAndDetailsModule/banner/";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const JobBanner2 = () => {
  const slug = "jobListingAndDetailsModule/banner/?job_type=consulting";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const JobBanner3 = () => {
  const slug = "jobListingAndDetailsModule/banner/?job_type=volunteering";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const advertising = () => {
  const slug = "advertisement/ad_1_banner/";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const advertising2 = () => {
  const slug = "advertisement/ad_2_banner/";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const FeatureJob = () => {
  const slug =
    "jobListingAndDetailsModule/all_jobs_list/?is_featured_post=true";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const ListJob = () => {
  const slug =
    "jobListingAndDetailsModule/all_jobs_list/?is_featured_post=false";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const consultingJob = () => {
  const slug = "jobListingAndDetailsModule/all_jobs_list/?job_type=consulting";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const DetailJob = ({ slug }) => {
  const urlPath = "jobListingAndDetailsModule/job_details/";
  const url = `${baseUrl}${urlPath}${slug}`;
  try {
    const response = axios
      .get(url, {
        headers: {
          // Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MywiZXhwIjoxNjU3MjgwNjcxLCJ1c2VybmFtZSI6InB5c2F1bmRhcnlfeWFob28ifQ.GWab7rOFGcfXVVARrDKVuDXCH0ObaNzNfCXeiuCNwoQ"}`,
          Authorization: `bearer ${localStorage.getItem("TalentToken")}`,
        },
      })
      .then((response) => {
        return response.data;
      });
    return response;
  } catch (error) {
    return error;
  }
};
const ApplyJob = ({ slug }) => {
  const urlPath = "jobListingAndDetailsModule/apply_on_job/";
  const url = `${baseUrl}${urlPath}${slug}`;

  const token = localStorage.getItem("TalentToken");

  const response = fetch(url, {
    headers: {
      Authorization: `bearer ${localStorage.getItem("TalentToken")}`,
    },
  })
    .then((e) => e.json())
    .then((e) => e);

  return response;
};
const AllJob = (filter) => {
  const slug = `jobListingAndDetailsModule/all_jobs_list/`;
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios
      .get(url, {
        params: filter,
      })
      .then((response) => {
        return response.data;
      });
    return response;
  } catch (error) {
    return error;
  }
};
export {
  JobBanner,
  JobBanner2,
  JobBanner3,
  FeatureJob,
  ListJob,
  DetailJob,
  ApplyJob,
  advertising,
  advertising2,
  AllJob,
};
