import React, { useState, useEffect } from "react";
import { mentorSlotList } from "../../Api/Mentor";
import { useParams } from "react-router-dom";
import moment from "moment";
import { createSlotPayment, slotBookingOrder } from "../../Api/PaymentGetway";
import { displayRazorpay } from "../../Utill/slotUtility";
const IntroductoryCallModel = ({ closed }) => {
  let { slug } = useParams();
  const [book, setBook] = useState([]);
  var api_1_data = {};
  var api_2_data = {};
  async function handleClick(value) {
    const data = {
      slot: value,
    };
    await slotBookingOrder(data).then((res) => {
      const response = res;

      api_1_data = response.data;
    });

    const data1 = {
      amount: api_1_data.ammount,
      order_id: api_1_data.order_number,
      currency: "INR",
    };

    await createSlotPayment(data1).then((resp) => {
      const response2 = resp.data;
      api_2_data = response2;

      displayRazorpay(api_2_data.data);
    });
  }
  useEffect(() => {
    const loadData = () => {
      mentorSlotList({ slug }).then((res) => {
        const response = res.data;

        setBook(response);
      });
    };
    loadData();
  }, [slug]);
  return (
    <div>
      <h4 className="close" onClick={closed}>
        x
      </h4>
      <div className="my-4">
        {book?.map((value) => {
          return (
            <>
              {value?.price === "0.00" ? (
                <div className="card mb-2 mx-4">
                  <h1>This is free slot, it can only be 15 minutes</h1>
                  <div className="card-body">
                    <h5 className="card-title font-weight-bold">
                      {value?.name}
                    </h5>
                    <p className="card-text font-weight-bold">
                      Start From:
                      {moment(value?.start_date).format("DD/MM/YYYY HH:MM")}
                    </p>

                    <p className="card-text font-weight-bold">
                      End From:
                      {moment(value?.start_date).format("DD/MM/YYYY HH:MM")}
                    </p>
                    <p className="card-text font-weight-bold">
                      Price (INR):
                      {value?.price === null ? "0" : value?.price}
                    </p>
                    {value?.is_booked === false ? (
                      <button
                        className="mentor-booking"
                        onClick={() => handleClick(value?.id)}
                      >
                        Book Now
                      </button>
                    ) : (
                      <h1 className="text-center text-danger display-3">
                        Already Booked
                      </h1>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default IntroductoryCallModel;
