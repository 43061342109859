import React, { useEffect, useState } from "react";
import "../../Style/ConfirmPassword/cnfrmPassword.css";
import { getToken, confirmPassword } from "../../Api/api";
import { ToastContainer, toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
// import { confirmPassword } from "../../Api/api";
const CnfrmPassword = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [fault, setFault] = useState(false);
  const [success, setSuccess] = useState(false);
  const [response_data, setResponse_data] = useState({});
  const [confirm, setConfirm] = useState({
    password: "",
    email: response_data.email,
    token: response_data.token,
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setConfirm({ ...confirm, ...{ [name]: value } });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = { ...confirm, ...response_data };
    confirmPassword(formData).then((res) => {
      const response = res;
      if (response?.status === 200) {
        toast("succesfuul", {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        });

        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
      } else {
        alert("Login failed");
      }
    });
  };

  //getToken
  const loadData = (token) => {
    getToken(token).then((res) => {
      setResponse_data(res.data);
      if (res.status === 400) {
        setFault(true);
      } else if (res.status === 200) {
        setSuccess(true);
      } else {
        <h1>nothing</h1>;
      }

      const response = res;
    });
  };

  useEffect(() => {
    const token = searchParams.get("token");

    !token ? alert("No Tokrn found") : loadData(token);
  }, [fault, success, searchParams]);

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className="Input_box">
          <label className="Change-password">Enter Your New Password</label>

          <input
            className="c-password"
            type="password"
            placeholder="Enter your New password"
            //   ref={newPasswords}
            name="password"
            required
            onChange={handleInput}
          />
          <label className="Change-password">Confirm Your Password</label>
          <input
            className="c-password"
            type="password"
            placeholder="Enter confirm password"
            //   ref={cnfrmPasswords}
            required
            onChange={handleInput}
          />
        </div>
        <button type="submit" className="c-loginPage-btn">
          Reset
          <ToastContainer />
        </button>
      </form>
    </div>
  );
};

export default CnfrmPassword;
