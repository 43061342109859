import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "../../Style/Dashboard/profileDetails.css";
import Modal from "react-modal";
import ProfesonalDetails from "../../Component/PersonalDetails/ProfesonalDetails";
import EducationaDetailsl from "../../Component/PersonalDetails/EducationaDetailsl";
import EditEducation from "../PersonalDetails/EditEducation";
import EditProfesonalDetails from "../PersonalDetails/EditProfesonalDetails";
import { Education, createJob, persnol } from "../../Api/TalentsApi";
import Eduction from "../../Image/EducationDetails.jpg";
import personalDetails from "../../Image/personalDetails.svg";
import {} from "../../Image/DashboardIcon3.jpeg";
import Other from "../../Image/otherDetails.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
const ProfileDetils = () => {
  const [personalData, setPersonalData] = useState({});
  const [profeshionalDetails, setProfeshionalDetails] = useState([]);
  const [educationDetails, setEducationDetails] = useState([]);
  const [editEducation, setEditEducation] = useState({
    open: false,
    data: undefined,
  });
  const [editProfesnol, setEditProfesnol] = useState({
    openPro: false,
    dataPro: undefined,
  });

  useEffect(() => {
    const loadData = () => {
      createJob().then((res) => {
        const response = res;

        setProfeshionalDetails(response.results);
      });
    };
    loadData();
  }, []);
  useEffect(() => {
    const loadData = () => {
      Education().then((res) => {
        const response = res;

        setEducationDetails(response.results);
      });
    };
    loadData();
  }, []);
  useEffect(() => {
    const loadData = () => {
      persnol().then((res) => {
        const response = res;

        setPersonalData(response.data);
      });
    };
    loadData();
  }, []);

  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [eduModalIsOpen, setEduIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function openEduModal() {
    setEduIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }
  function closeEduModal() {
    setEduIsOpen(false);
  }
  function closeEditEduModal() {
    setEditEducation({ open: false, data: undefined });
  }
  function closeEditProModal() {
    setEditProfesnol({ openPro: false, dataPro: undefined });
  }
  return (
    <div>
      <div className="personal-details">
        <img
          className="icon-details"
          src={personalDetails}
          alt="personalDetails"
        />

        <h2 className="personal-details-title">Professional Details</h2>
      </div>
      {profeshionalDetails?.map((value) => {
        return (
          <div>
            <div className="border-line"></div>
            <h4 className="user-designation">{value?.title}</h4>
            <h4 className="user-company">{value?.company_name}</h4>
            <h4 className="user-work-time">
              {moment(value?.start_from).format("MMM YY")} To{" "}
              {value?.end_from === "Present"
                ? "Present"
                : moment(value?.end_from).format("MMM YY")}
            </h4>
            <img
              className="peronal-details-edit"
              src="Icons/edit.svg"
              alt="edit"
              onClick={() =>
                setEditProfesnol({ openPro: true, dataPro: value })
              }
            />
          </div>
        );
      })}

      <div className="add-details">
        <img
          className="icon-detail"
          src="Images/add-item.svg"
          alt="add-more-details"
        />
        <h6 onClick={openModal} className="add-more">
          Add Professional Details
        </h6>
      </div>

      {/* Add Education Details */}
      <div className="personal-details">
        <img className="icon-details" src={Eduction} alt="personalDetails" />

        <h2 className="personal-details-title">Education Details</h2>
      </div>
      {educationDetails?.map((value) => {
        return (
          <div>
            <div className="border-line"></div>
            <h4 className="user-designation">{value?.title}</h4>
            <h4 className="user-company">{value?.university_name}</h4>
            <h4 className="user-company">
              {value?.course === "Full_time "
                ? "Full Time"
                : value?.course === "part_time"
                ? "Part Time"
                : value?.course === "Distance"
                ? "Distance Learning Program"
                : value?.course === "Executive_Program"
                ? "Executive Program"
                : value?.course === "Certification"
                ? "Certification"
                : ""}
            </h4>
            <h4 className="user-work-time">
              {moment(value?.start_from).format("MMM YY")} to{" "}
              {moment(value?.end_from).format("MMM YY")}
            </h4>
            <img
              className="peronal-details-edit"
              src="Icons/edit.svg"
              alt="edit"
              onClick={() => setEditEducation({ open: true, data: value })}
            />
          </div>
        );
      })}
      <div className="border-line"></div>
      <div className="add-details">
        <img
          className="icon-detail"
          src="Images/add-item.svg"
          alt="add-more-details"
        />
        <h6 onClick={openEduModal} className="add-more">
          Add Education Details
        </h6>
      </div>

      {/* Other Details */}

      <div className="personal-details">
        <img className="icon-details" src={Other} alt="personalDetails" />

        <h2 className="personal-details-title">Other Details</h2>
      </div>
      <div className="border-line"></div>
      <div className="other-details">
        <h4 className="dob">Date of Birth:</h4>
        <h4 className="dob-date">
          {personalData?.dob === null
            ? ""
            : moment(personalData?.dob).format("DD/MM/YYYY")}
        </h4>
      </div>
      <div className="other-details">
        <h4 className="pGender">Gender :</h4>
        <h4 className="gender-details">
          {personalData?.gender === "male"
            ? "Male"
            : personalData?.gender === "female"
            ? "Female"
            : personalData?.gender === "other"
            ? "Other"
            : ""}
        </h4>
      </div>
      <div className="other-details">
        <h4 className="details-loaction">Current Location:</h4>
        <h4 className="other-details-page">
          {personalData?.current_location?.city}
        </h4>
      </div>
      <div className="other-details">
        <h4 className="other-details-industry">Industry:</h4>
        <h4 className="other-details-industry-name">{personalData.industry}</h4>
      </div>
      <div className="other-details">
        <h4 className="other-details-annual">Annual Salary (INR):</h4>
        <h4 className="other-details-salary">{personalData?.annual_salary}</h4>
      </div>
      <div className="other-details">
        <h4 className="other-details-expected">Expected Salary (INR):</h4>
        <h4 className="other-details-expected-salary">
          {personalData?.expected_salary}
        </h4>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <ProfesonalDetails closeModal={closeModal} />
      </Modal>
      <Modal
        isOpen={eduModalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeEduModal}
        contentLabel="Example Modal"
      >
        <EducationaDetailsl closeModal={closeEduModal} />
      </Modal>
      {/* EditEducationalDetails */}
      <Modal
        isOpen={editEducation.open}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeEditEduModal}
        contentLabel="Example Modal"
      >
        <EditEducation
          data={editEducation?.data}
          closeModal={closeEditEduModal}
        />
      </Modal>
      {/* EditProfesonalDetails */}
      <Modal
        isOpen={editProfesnol.openPro}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeEditProModal}
        contentLabel="Example Modal"
      >
        <EditProfesonalDetails
          data={editProfesnol?.dataPro}
          closeModal={closeEditProModal}
        />
      </Modal>
    </div>
  );
};

export default ProfileDetils;
