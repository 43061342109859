import React from "react";
import "../../Style/EventPosting/EventImages.css";

const EventImages = () => {
  return (
    <div>
      <div>
        <h1>Event Images</h1>
      </div>
      <div className="Event-fee-mode">
        <div>
          <h1 className="Event-entry">Event Entry Type</h1>
        </div>
        <div>
          <input
            className="container-checkbox"
            type="checkbox"
            checked="checked"
          />
          <span className="checkmark">Free</span>
        </div>
        <div>
          <input
            className="container-checkbox"
            type="checkbox"
            checked="checked"
          />
          <span className="checkmark">Paid</span>
        </div>
      </div>
      <div className="link-web">
        <h2 className="Event-entry">Link to your ticketing system</h2>
        <input
          className="ticketing-system"
          type="text"
          placeholder="Enter the link to your website or external ticketing system."
        />
      </div>
    </div>
  );
};

export default EventImages;
