import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { contactWithUs } from "../../Api/api";
import GoogleMap from "../../Image/googleMap.png";
import GoogleMapReact from "google-map-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
const AnyReactComponent = ({ text }) => <div>{text}</div>;
const ContactUs = () => {
  const [contact_us, setContact_us] = useState({
    name: "",
    mobile_number: "",
    email: "",
    subject: "",
    message: "",
    // address: "",
  });
  const handleInput = (e) => {
    const { name, value } = e.target;
    setContact_us({ ...contact_us, ...{ [name]: value } });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    contactWithUs(contact_us)
      .then((res) => {
        const response = res;
        if (response?.status === 200) {
          // navigate(`/dashboard`);
          toast("Data Sending SuccesFull", {
            autoClose: 1000,
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);

        setContact_us("");
      })
      .catch((e) =>
        e.response.status === 400
          ? toast(" Please Enter Correct Details", {
              autoClose: 500,
              position: toast.POSITION.TOP_RIGHT,
            })
          : ""
      );
  };
  const defaultProps = {
    center: {
      lat: 12.972996,
      lng: 77.616706,
    },
    zoom: 11,
  };
  return (
    <div className="contactUs-display">
      <div className="contactUs-Container">
        <NavLink to="">
          <img className="Sign-page-header" src="Icons/logo.png" alt="logo" />
        </NavLink>

        <div className="sign-header-main"></div>
        <h1 className="Sign-in">Contact Us</h1>
        <div id="warning" className="form"></div>

        <form onSubmit={handleSubmit} className="Contact-us-btn">
          <div className="Name-box1">
            <div className="Sign-Input_box">
              <input
                className="Sign-email"
                type="text"
                name="name"
                placeholder="Name"
                //   ref={name}
                onChange={handleInput}
                required
              />

              <input
                className="Sign-password"
                type="text"
                placeholder="Phone"
                //   ref={phone}
                name="mobile_number"
                onChange={handleInput}
                required
              />
              <input
                className="Sign-password"
                type="email"
                placeholder="Work Email Address"
                name="email"
                //   ref={email}
                onChange={handleInput}
                required
              />
              <input
                className="Sign-password"
                type="text"
                name="subject"
                placeholder="Subject"
                onChange={handleInput}
                //   ref={designation}
                required
              />
              <textarea
                className="contact-us-msg-box"
                type="text"
                placeholder="Message"
                name="message"
                onChange={handleInput}
                //   ref={organisation}
                required
              />
            </div>
          </div>

          <button type="submit" className="SignPage-btn">
            Send Now
            <ToastContainer />
          </button>
        </form>
      </div>
      <div className="googleMap">
        <div className="GoogleMap-container">
          <img className="googleMapImage" src={GoogleMap} alt="" />
        </div>
        <div className="contact-address-details">
          <h1 className="addressHeader">Address</h1>
          <h4 className="Address">
            📃 Kundan House, Bhauwala Road, Sudhowala, Dehradun, Uttarakhand-
            248007
          </h4>
          <h4 className="Address">
            📞 +91-9810206966{" "}
            <FontAwesomeIcon
              className=""
              icon={faWhatsapp}
              onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send/?phone=919810206966&text=55notout- A one-stop platform to find jobs, consulting, volunteering, mentoring, events, courses, products and services for age 50+ Senior Professionals.&type=phone_number&app_absent=0"
                )
              }
              style={{ cursor: "pointer" }}
            />
          </h4>
          <h4 className="Address">📧 contact@55notout.com</h4>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
