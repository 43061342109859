import React from "react";
import { Navbar } from "../../Component/Navbar";
import Platforms from "../../Component/Platforms";
import TestimonialBox from "../../Component/TestimonialBox";
import Availability from "../../Component/Availability";
import NavDownImages from "../../Component/NavDownImages";
import { Helmet } from "react-helmet";
import Logo from "../../Image/logo.png";
export default function Home() {
  return (
    <div>
      <Helmet>
        <title>55notout</title>
        <meta property="og:title" content="55notout" />
        <meta
          property="og:description"
          content="How much does culture influence creative thinking?"
        />
        <meta property="og:image" content={Logo} />
      </Helmet>
      <Navbar />
      <NavDownImages />
      <Platforms />
      <TestimonialBox />
      <Availability />
    </div>
  );
}
