import React from "react";
import "../../Style/EventsDetails/EventDesp.css";

const EventDesp = ({ detail }) => {
  return (
    <>
      <div className="Description-container">
        <h1 className="desp-header">Event Description</h1>
        <p className="desp-para">{detail?.description}</p>
      </div>
      <div className="abt-down-btn">
        <button className="GoEvent2">Go to Event</button>
      </div>
    </>
  );
};

export default EventDesp;
