import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToggleNavBar from "./ToggleNavBar";
import { Modal } from "react-bootstrap";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import Login from "./Login/LogIn";
import SignUp from "./Login/SignUp";
import OrgLogin from "./OrganisationLogin/OrgLogin";
import OrgSigup from "./OrganisationLogin/OrgSignUp";
import ChangePassword from "./Login/ChangePassword";
import Logo from "../Image/hLogo.png";
import Signup from "./SignUpPage/Signup";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "../Style/header.css";
import ForgetPassword from "./Login/ForgetPassword";
const Header = () => {
  const [selected, setSelected] = useState("LogOut");
  const [selected2, setSelected2] = useState("LogIn");
  const [isLogin, setIsLogin] = useState(false);
  const [isTempLogin, setIsTempLogin] = useState(false);
  const [isSignup, setIsSignup] = useState(false);
  const [isTempSignup, setIsTempSignup] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const handleSuceesClose = () => setIsSuccess(false);
  const handleSuceesShow = () => setIsSuccess(true);

  const handleClose = () => {
    setIsLogin(false);
    setIsTempLogin(false);
    setIsSignup(false);
    setIsTempSignup(false);
    setIsShow(false);
    setIsSuccess(false);
  };

  const handleShow = () => setIsLogin(true);
  const handleShow2 = () => setIsTempLogin(true);
  const handleChangeShow = () => setIsShow(true);
  const logOut = () => {
    localStorage.removeItem(isOrg() ? "token" : "TalentToken");
    localStorage.removeItem("mentor_slug");
    // localStorage.clear();

    window.location.reload(false);
    window.location.href = "/";
  };
  const handleNavClick = (route) => {
    localStorage.setItem("route", route);
  };

  const isOrg = () => localStorage.getItem("PATH") === "Organisation Login";

  const handleChange = (value) => {
    if (value === "LogOut") {
      logOut();
      setSelected(value);
      return;
    } else if (value === "Change Password") {
      handleChangeShow();
      setSelected(value);
      return;
    } else if (value === "Dashboard") {
      if (localStorage.getItem("PATH") === "Organisation Login") {
        window.location.href = "/orgdashboard";
        setSelected(value);
      } else {
        window.location.href = "/dashboard";
        setSelected(value);
      }

      return;
    } else if (value === "Profile") {
      if (localStorage.getItem("PATH") === "Organisation Login") {
        window.location.href = "";
        setSelected(value);
      } else {
        window.location.href = "/featuredprofile";
        setSelected(value);
      }

      return;
    } else {
      return;
    }
  };
  const handleChangeLogin = (value) => {
    localStorage.setItem("PATH", value);
    if (value === "LogIn") {
      logOut();
      setSelected2(value);
      return;
    } else if (value === "Talent Login") {
      // handleChangeShow();

      handleShow();
      setSelected2(value);
      return;
    } else if (value === "Organisation Login") {
      // handleChangeShow();
      handleShow2();
      setSelected2(value);
      return;
    } else {
      return;
    }
  };
  const values = localStorage.getItem("filterSlug");

  useEffect(() => {
    setSelected("Dashboard");
  }, [values]);
  const options = ["LogOut", "Change Password", "Profile", "Dashboard"];
  const options2 = ["Talent Login", "Organisation Login"];

  return (
    <>
      <div className="L-Screen-Navbar">
        <div className="header">
          <div className="hContainer">
            <NavLink
              to="/"
              onClick={() =>
                handleNavClick("HOME", (window.location.href = "/"))
              }
            >
              <img className="header-logo" src={Logo} alt="logo" />
            </NavLink>

            <NavLink to="/talents">
              <h4
                onClick={() =>
                  handleNavClick("TALENT", (window.location.href = "/talents"))
                }
                className="header-pages"
                style={{ color: "#2E598C" }}
              >
                Talent
              </h4>
            </NavLink>
            <NavLink to="/Organisation">
              <h4
                className="header-pages"
                onClick={() =>
                  handleNavClick(
                    "ORG",
                    (window.location.href = "/Organisation")
                  )
                }
              >
                Organisation
              </h4>
            </NavLink>
          </div>

          <div className="right-div">
            <NavLink to={`/search`}>
              <input
                className="inp"
                type="text"
                placeholder="&#xf002; Search"
              />
            </NavLink>
            <div>
              {!localStorage.getItem(isOrg() ? "token" : "TalentToken") && (
                <div className="login-btn">
                  <Dropdown
                    options={options2}
                    seleted={"LogIn"}
                    onChange={(e) => handleChangeLogin(e.value)}
                    value={selected2}
                    className="drop-down-2"
                    controlClassName="drop-down-container"
                  />
                </div>
              )}

              {localStorage.getItem(isOrg() ? "token" : "TalentToken") && (
                <div>
                  <Dropdown
                    options={options}
                    seleted={"Dashboard"}
                    onChange={(e) => handleChange(e.value)}
                    value={selected}
                    // className="drop-down"
                  />
                </div>
              )}

              <Modal centered={true} show={isTempLogin} onHide={handleClose}>
                <OrgLogin
                  setShowSignUpModel={() => {
                    setIsTempLogin(false);
                    setIsTempSignup(true);
                  }}
                  setForget={() => {
                    setIsTempLogin(false);
                    setIsSuccess(true);
                  }}
                  close={handleClose}
                />
              </Modal>
              <Modal centered={true} show={isLogin} onHide={handleClose}>
                <Login
                  setShowSignUpModel={() => {
                    setIsLogin(false);
                    setIsSignup(true);
                    setIsSuccess(false);
                  }}
                  setShowLoginModel={() => {
                    setIsLogin(false);
                  }}
                  close={handleClose}
                  setForget={() => {
                    setIsLogin(false);
                    setIsSuccess(true);
                  }}
                />
              </Modal>

              <Modal centered={true} show={isTempSignup} onHide={handleClose}>
                <OrgSigup
                  setShowLoginModel={() => {
                    setIsTempLogin(true);
                    setIsTempSignup(false);
                  }}
                  close={handleClose}
                />
              </Modal>
              <Modal centered={true} show={isSignup} onHide={handleClose}>
                <SignUp
                  setShowLoginModel={() => {
                    setIsLogin(true);
                    setIsSignup(false);
                  }}
                  close={handleClose}
                />
              </Modal>

              <Modal centered={true} show={isShow} onHide={handleClose}>
                <ChangePassword close={handleClose} />
              </Modal>

              <Modal
                centered={true}
                show={isSuccess}
                onHide={handleSuceesClose}
              >
                <ForgetPassword closed={handleSuceesClose} />
              </Modal>
            </div>
          </div>
        </div>
      </div>

      <ToggleNavBar />
    </>
  );
};

export default Header;
