import React, { useState, useEffect } from "react";
import ProfileDetails from "../FeaturesProfiledetails/ProfileDetails";
import { getTalentPublicsDetails } from "../../Api/TalentsApi";
import { jobShortlist } from "../../Api/OrgApi";
import { useParams, useLocation } from "react-router-dom";
import LocationIcon from "../../Image/location.svg";
import Linkdin from "../../Image/linkdin-blue.svg";

const ApplicationVeiw = () => {
  let { slug } = useParams();
  const state = useLocation();

  const [profile, setprofile] = useState({});
  const [apply, setApply] = useState({});
  useEffect(() => {
    const loadData = () => {
      getTalentPublicsDetails({ slug }).then((res) => {
        const response = res;

        setprofile(response?.data);
      });
    };
    loadData();
  }, []);
  useEffect(() => {
    const loadData = async () => {
      const response = await jobShortlist();

      setApply(response);
    };
    loadData();
  }, [slug]);
  const handleClick = (id, act) => {
    const data = {
      action: act,
      application_id: id,
      slug: state?.state?.job_details?.slug,
    };
    jobShortlist(data);
  };

  return (
    <div className="feature-user-details">
      <div className="feature-display-img-name">
        <div className="feature-user-profile-container ">
          <img
            className="feature-user-profile"
            src={profile?.image}
            alt="profile"
          />
          <div className="feature-edit-profile">
            <h4 className="feature-user-gmail">{profile?.user?.email}</h4>
            <h4 className="feature-user-phone">{profile?.phone_numer}</h4>
          </div>
          <div className="feature-user-icons">
            <img
              className="feature-profile-linkdin"
              src={Linkdin}
              alt="linkdin"
            />

            <h1
              style={{
                fontFamily: "OpenSans",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "18px",
                lineHeight: "25px",
                color: "blue",
                marginTop: "2%",
                marginLeft: "5%",
              }}
            >
              Linkdin
            </h1>
          </div>
        </div>
        <div className="feature-profile-name-location">
          <div className="org-app-container">
            <h4 className="feature-profile-name">
              {profile?.user?.first_name} {profile?.user?.last_name}
            </h4>
            <div className="org-app-buttun1">
              <div
                className="org-shortlist-btn1"
                onClick={() => {
                  handleClick(profile.id, 1);
                }}
              >
                Shortlist
              </div>
              <div
                className="org-reject-btn1"
                onClick={() => {
                  handleClick(profile.id, 2);
                }}
              >
                Reject
              </div>
              <div className="org-download-btn1">Download CV</div>
            </div>
          </div>

          <div className="feature-location-details">
            <img src={LocationIcon} alt="location" />
            <span className="listing-place-details">
              {profile?.current_location?.city}, India
            </span>
          </div>
          <div className="feature-user-skill-container">
            <h2 className="feature-user-skill">Skills : </h2>
            {profile?.skills?.map((value) => {
              return <p className="feature-Profile-skill"> {value?.name},</p>;
            })}
          </div>

          <ProfileDetails profile={profile} />
        </div>
      </div>
    </div>
  );
};

export default ApplicationVeiw;
