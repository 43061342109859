import React from "react";
import "../../Component/MentorCruise/MentorCruise.css";
import google from "../../Image/google.svg";
import ReactPlayer from "react-player";
const MentorProfileInfo = () => {
  return (
    <div className="MentorProfileInfo">
      <div>
        <div className="AmazingThings">
          <h1 className="AmazingThingsText">
            The amazing things that mentoring can do for you
          </h1>
          <h6 className="AmazingThingsParagraph">
            Managers, executives and leaders agree that mentoring is one of the
            most impactful ways to spend your time. In return, you're building
            crucial leadership qualities.
          </h6>
        </div>
      </div>
    </div>
  );
};

export default MentorProfileInfo;
