import React, { useState, useEffect } from "react";
import { updatePut } from "../../Api/OrgApi";
import { NavLink } from "react-router-dom";
import { Address } from "../../Api/OrgApi";
import { ToastContainer, toast } from "react-toastify";
const OrgPersnolData = (dataP, closeModal) => {
  const [address, setAddress] = useState([]);
  const [data, setData] = useState({});
  const [img, setImg] = useState();

  const handleInput = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };
  const handleImage = (e) => {
    const image = e.target.files[0];
    const formData1 = new FormData();
    formData1.append("image", image);
    updatePut(formData1, true).then((res) => {
      setImg(res.data?.image);
      // localStorage.setItem("token", response.data.token);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    updatePut(data, false).then((res) => {
      const response = res;

      toast("Profile Detail Update suceessfull", {
        autoClose: 500,
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        window.location.href = "/orgdashboard";
      }, 500);

      closeModal();
    });
    // .catch((e) =>
    //   toast(" Bad Request", {
    //     autoClose: 500,
    //     position: toast.POSITION.TOP_RIGHT,
    //   })
    // );
  };
  const cancel = () => {
    dataP.closeModal();
  };

  useEffect(() => {
    const temp = {
      organization_name: dataP?.dataP?.organization_name,
      location: dataP?.dataP?.location?.id + "",
      designation: dataP?.dataP?.designation,
      phone_number: dataP?.dataP?.phone_number,
      experience: dataP?.dataP?.experience,
      user: dataP?.dataP?.user,
    };

    setImg(dataP?.dataP?.image);
    setData(temp);
  }, [dataP]);
  useEffect(() => {
    const loadData = () => {
      Address().then((res) => {
        const response = res;
        setAddress(response.data);
      });
    };
    loadData();
  }, []);
  return (
    <form onSubmit={handleSubmit}>
      <h6 className="cancelModel" onClick={cancel}>
        x
      </h6>

      <div className="personal-profile-container">
        <div>
          <div className="details-Box">
            <h2 className="profile-pic-details-name">Profile Picture</h2>
            <img className="profile-pic" src={img} alt="" />
            <input
              type="file"
              name="image"
              id="file-input"
              onChange={handleImage}
              style={{ display: "none" }}
            />
            <label for="file-input" className="add-icon-details">
              <img src="Images/add-item.svg" alt="add-more-details" />
            </label>
          </div>
          <div className="details-Box">
            <h2 className="profile-pic-details-name">Name</h2>
            <input
              className="persnol-details-box"
              name="user"
              type="text"
              value={data?.user?.first_name}
              onChange={handleInput}
              required
            />
          </div>
        </div>
        {/* second div */}
        <div>
          <div className="details-Box">
            <h2 className="profile-pic-details-name">Phone Number</h2>
            <input
              className="persnol-details-box"
              name="phone_number"
              type="text"
              value={data?.phone_number}
              onChange={handleInput}
              required
            />
          </div>
          <div className="details-Box">
            <h2 className="profile-pic-details-name">Current Location</h2>

            <select
              className="persnol-details-box"
              name="location"
              onChange={handleInput}
              value={data?.location}
            >
              {address.map((value) => {
                return <option value={value?.id}>{value?.city_name}</option>;
              })}
            </select>
          </div>
          <div className="details-Box">
            <h2 className="profile-pic-details-name">Organization</h2>
            <input
              className="persnol-details-box"
              type="text"
              name="organization_name"
              placeholder="organization_name"
              value={data?.organization_name}
              onChange={handleInput}
              required
            />
          </div>

          <div className="details-Box">
            <h2 className="profile-pic-details-name">Designation</h2>
            <input
              className="persnol-details-box"
              name="designation"
              type="text"
              value={data?.designation}
              onChange={handleInput}
              required
            />
          </div>
        </div>
      </div>
      <button type="submit" className="save-persnol-details">
        Save & Continue
        <ToastContainer />
      </button>
    </form>
  );
};

export default OrgPersnolData;
