import React from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
const BecomePart = () => {
  const navigate = useNavigate();
  const popUp = () => {
    toast("Please Login To Continue", {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const popUp2 = () => {
    toast("Oops; you are Already Mentor", {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });
  };
  return (
    <div className="trustedMentess_container">
      <div className="trustedMentessMain">
        <h1
          className="trustedMentessTitle"
          style={{
            width: "50%",
            margin: "0 25%",
          }}
        >
          Become part of the leading mentoring community
        </h1>
        <p
          className="trustedMentessParagrph"
          style={{
            width: "60%",
            margin: "0 20%",
          }}
        >
          Thousands of mentees and mentors can't be wrong – with a 97%
          satisfaction rate, 55notout is one of the hiquest quality mentoring
          communities out there.
        </p>
      </div>
      <div className="expertiseButtonContaine">
        {localStorage.getItem("TalentToken") &&
        localStorage.getItem("mentor_slug") === "null" ? (
          <button
            className="expertiseMentor2"
            onClick={() => navigate(`/mentorDetails`)}
          >
            Become a mentor
          </button>
        ) : (
          <button
            className="expertiseMentor2"
            onClick={localStorage.getItem("mentor_slug") ? popUp2 : popUp}
          >
            Become a mentor
            <ToastContainer />
          </button>
        )}
      </div>
    </div>
  );
};

export default BecomePart;
