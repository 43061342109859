import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Style/ToggleNavBar.css";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Login from "./Login/LogIn";
import SignUp from "./Login/SignUp";
import OrgLogin from "./OrganisationLogin/OrgLogin";
import OrgSigup from "./OrganisationLogin/OrgSignUp";
import ChangePassword from "./Login/ChangePassword";
import { ToastContainer, toast } from "react-toastify";
import Dropdown from "react-dropdown";
import {
  faUserPlus,
  faMagnifyingGlass,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import Hamburg from "../Image/filter.png";
import Close from "../Image/close.png";
import Arrow from "../Image/LoginArrow.svg";
import Dp from "../Image/dp.jpg";
import Logo from "../Image/hLogo.png";
const ToggleNavBar = () => {
  const [selected, setSelected] = useState("LogOut");
  const [selected2, setSelected2] = useState("LogIn");
  const [isLogin, setIsLogin] = useState(false);
  const [isSignup, setIsSignup] = useState(false);
  const [isTempLogin, setIsTempLogin] = useState(false);
  const [isTempSignup, setIsTempSignup] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [search, setSearch] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [talent, setTalent] = useState(false);
  const [org, setOrg] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const handleSuceesClose = () => setIsSuccess(false);
  const handleSuceesShow = () => setIsSuccess(true);
  const navigate = useNavigate();
  function handleClick() {
    setToggle(!toggle);
  }
  const SearchBar = () => {
    setSearch(!search);
  };
  const talentBar = () => {
    setTalent(!talent);
  };
  const OrgnBar = () => {
    setOrg(!org);
  };

  // login/signup
  const handleClose = () => {
    setIsLogin(false);
    setIsSignup(false);
    setIsShow(false);
    setIsSuccess(false);
    setIsTempLogin(false);
    setIsTempSignup(false);
  };

  const handleShow = (value) => {
    setIsLogin(true);
  };
  const handleShow2 = () => {
    setIsTempLogin(true);
  };
  const handleChangeShow = () => setIsShow(true);
  const logOut = () => {
    localStorage.removeItem(isOrg() ? "token" : "TalentToken");
    localStorage.removeItem("mentor_slug");

    window.location.reload(false);
    window.location.href = "/";
  };
  const handleNavClick = (path) => {
    localStorage.setItem("PATH", path);
  };

  const isOrg = () => localStorage.getItem("PATH") === "Organisation Login";

  const popUp = () => {
    toast("Please Login To Continue", {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const popUp2 = () => {
    toast("Oops; you are Already Mentor", {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const handleChange = (value) => {
    if (value === "LogOut") {
      logOut();
      setSelected(value);
      return;
    } else if (value === "Change Password") {
      handleChangeShow();
      setSelected(value);
      return;
    } else if (value === "Dashboard") {
      if (localStorage.getItem("PATH") === "Organisation Login") {
        window.location.href = "/orgdashboard";
        setSelected(value);
      } else {
        window.location.href = "/dashboard";
        setSelected(value);
      }

      return;
    } else if (value === "Profile") {
      if (localStorage.getItem("PATH") === "Organisation Login") {
        window.location.href = "";
        setSelected(value);
      } else {
        window.location.href = "/featuredprofile";
        setSelected(value);
      }

      return;
    } else {
      return;
    }
  };
  const handleChangeLogin = (value) => {
    localStorage.setItem("PATH", value);
    if (value === "LogIn") {
      logOut();
      setSelected2(value);
      return;
    } else if (value === "Talent Login") {
      // handleChangeShow();

      handleShow();

      setSelected2(value);
      return;
    } else if (value === "Organisation Login") {
      // handleChangeShow();
      handleShow2();
      setSelected2(value);
      return;
    } else {
      return;
    }
  };
  useEffect(() => {
    setSelected("Dashboard");
  }, []);
  const options = ["LogOut", "Change Password", "Profile", "Dashboard"];
  const options2 = ["Talent Login", "Organisation Login"];
  return (
    <div className="toogle-navbar">
      <div
        className={
          toggle ? `t-filter_btn t-filter_btn_active` : "t-filter_btn "
        }
        onClick={handleClick}
      >
        <img
          src={toggle ? Close : Hamburg}
          alt="list"
          title="Filter"
          className="cross-X"
        />
      </div>

      <div className="t-navbar">
        <div
          className="t-mainNav"
          style={{
            display: window.innerWidth < "720" && !toggle ? "none" : "flex",
          }}
        >
          <div></div>
          <img className="togaleDp" src={Dp} alt="dp" />

          {!localStorage.getItem(isOrg() ? "token" : "TalentToken") && (
            <div className="login-btn">
              <Dropdown
                options={options2}
                seleted={"LogIn"}
                onChange={(e) => handleChangeLogin(e.value)}
                value={selected2}
                className="drop-down-2"
                controlClassName="drop-down-container"
              />
            </div>
          )}

          {localStorage.getItem(isOrg() ? "token" : "TalentToken") && (
            <div>
              <Dropdown
                options={options}
                seleted={"Dashboard"}
                onChange={(e) => handleChange(e.value)}
                value={selected}
              />
            </div>
          )}
          <div className="toggleArrow">
            <Link
              to={"/talents"}
              className="TalentsText"
              onClick={() => handleNavClick("TALENT")}
            >
              Talents
            </Link>
            <img
              className={!talent ? "arrowImg" : "downArrow"}
              src={Arrow}
              alt="Arrow"
              width="5%"
              onClick={talentBar}
            />
          </div>
          <div
            className="toggleThumb"
            style={{
              display: window.innerWidth < "720" && !talent ? "none" : "flex",
            }}
          >
            <div className="t-left-nav">
              <Link to={"/fulltimejobs"}>Find Full-Time Jobs </Link>
              <Link to={"/consulting"}>Find Consulting Jobs </Link>

              <Link to={"/Mentor55notout"}>Become a Mentor</Link>

              <Link to={"/volunteer"}>Volunteer </Link>
              <Link to={"/mentorLists"}>Find a Mentor </Link>
              <Link to={"/events"}>Events </Link>
              <Link to={"/courses"}>Courses </Link>

              {localStorage.getItem("TalentToken") &&
              localStorage.getItem("mentor_slug") ? (
                <Link to={"/mentor"}>Mentor DashBoard </Link>
              ) : (
                ""
              )}

              {localStorage.getItem("TalentToken") ? (
                <Link to={"/dashboard"}>Dashboard </Link>
              ) : (
                <Link to={""} onClick={popUp}>
                  Dashboard
                </Link>
              )}

              {localStorage.getItem("TalentToken") ? (
                <Link to={"/featuredprofile"}>Profile </Link>
              ) : (
                <Link to={""} onClick={popUp}>
                  Profile
                </Link>
              )}
              <Link to={"/product"}>Products </Link>
              <Link to={"/service"}>Services </Link>
              <ToastContainer />
            </div>
          </div>
          <div className="toggleArrow" onClick={OrgnBar}>
            <Link
              to={"/Organisation"}
              className="TalentsText"
              onClick={() => handleNavClick("ORG")}
            >
              Organisation
            </Link>
            <img
              className={!org ? "arrowImg" : "downArrow"}
              src={Arrow}
              alt="Arrow"
              width="5%"
            />
          </div>
          <div
            style={{
              display: window.innerWidth < "720" && !org ? "none" : "flex",
            }}
          >
            <div className="t-org-left-nav">
              {localStorage.getItem("token") ? (
                <Link to={"/postjob"}>Post Opportunities </Link>
              ) : (
                <Link to={""} onClick={popUp}>
                  Post Opportunitiess
                </Link>
              )}
              {localStorage.getItem("token") ? (
                <Link to={""}>Find a Mentor</Link>
              ) : (
                <Link to={""}>Find a Mentor</Link>
              )}
              {localStorage.getItem("token") ? (
                <Link to={""}>Post Volunteer Opportunity</Link>
              ) : (
                <Link to={""}>Post Volunteer Opportunity</Link>
              )}
              {localStorage.getItem("token") ? (
                <Link to={"/eventPosting"} onClick={popUp}>
                  List an Event
                </Link>
              ) : (
                <Link to={""}>List an Event </Link>
              )}
              {localStorage.getItem("token") ? (
                <Link to={"/coursePosting"}>List a Course</Link>
              ) : (
                <Link to={""} onClick={popUp}>
                  List a Course
                </Link>
              )}
              {localStorage.getItem("token") ? (
                <Link to={"/productPosting"}>List a Product</Link>
              ) : (
                <Link to={""} onClick={popUp}>
                  List a Product
                </Link>
              )}
              {localStorage.getItem("token") ? (
                <Link to={"/servicePosting"}>List a Service</Link>
              ) : (
                <Link to={""} onClick={popUp}>
                  List a Service
                </Link>
              )}
              {localStorage.getItem("token") ? (
                <Link to={"/orgdashboard"}>Dashboard </Link>
              ) : (
                <Link to={""} onClick={popUp}>
                  Dashboard{" "}
                </Link>
              )}
              {localStorage.getItem("token") ? (
                <Link to={""}>List Company </Link>
              ) : (
                <Link to={""}>List Company </Link>
              )}
              {localStorage.getItem("token") ? (
                <Link to={"/BrowserFeaturedProfiles"}>
                  Browse Featured Profile{" "}
                </Link>
              ) : (
                <Link to={"/Browser-Featured-Profiles"} onClick={popUp}>
                  Browse Featured Profile{" "}
                </Link>
              )}

              <ToastContainer />
            </div>
          </div>
        </div>
      </div>

      <NavLink to="/">
        <img className="Phone-header-logo" src={Logo} alt="logo" />
      </NavLink>
      <div className="SeachContainer">
        <NavLink to={`/Search`}>
          <FontAwesomeIcon
            className={search ? `searchIcon searchIcon_active` : "searchIcon "}
            onClick={SearchBar}
            icon={faMagnifyingGlass}
            color="black"
          />
        </NavLink>
      </div>

      <Modal centered={true} show={isTempLogin} onHide={handleClose}>
        <OrgLogin
          setShowSignUpModel={() => {
            setIsTempLogin(false);
            setIsTempSignup(true);
          }}
          setForget={() => {
            setIsTempLogin(false);
            setIsSuccess(true);
          }}
          close={handleClose}
        />
      </Modal>
      <Modal centered={true} show={isLogin} onHide={handleClose}>
        <Login
          setShowSignUpModel={() => {
            setIsLogin(false);
            setIsSignup(true);
            setIsSuccess(false);
          }}
          setShowLoginModel={() => {
            setIsLogin(false);
          }}
          close={handleClose}
          setForget={() => {
            setIsLogin(false);
            setIsSuccess(true);
          }}
        />
      </Modal>

      <Modal centered={true} show={isTempSignup} onHide={handleClose}>
        <OrgSigup
          setShowLoginModel={() => {
            setIsTempLogin(true);
            setIsTempSignup(false);
          }}
          close={handleClose}
        />
      </Modal>
      <Modal centered={true} show={isSignup} onHide={handleClose}>
        <SignUp
          setShowLoginModel={() => {
            setIsLogin(true);
            setIsSignup(false);
          }}
          close={handleClose}
        />
      </Modal>
      <Modal centered={true} show={isShow} onHide={handleClose}>
        <ChangePassword close={handleClose} />
      </Modal>
    </div>
  );
};

export default ToggleNavBar;
