import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
const CourseListing = ({ list }) => {
  const navigate = useNavigate();
  const popUp = () => {
    toast("Please Login To Continue", {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });

    <ToastContainer />;
  };
  return (
    <div className="filter-listing-container">
      {list?.map((listValue) => {
        return (
          <div className="filter-feature-special-course-listing-job-main">
            {localStorage.getItem("TalentToken") ? (
              <NavLink
                to={{
                  pathname: `/coursedetails/${listValue?.slug}`,
                  state: { listValue },
                }}
              >
                <div className="feature-special-course-image-container">
                  <img
                    className="feature-special-course-listing-Job-Img"
                    src={listValue?.thumbnail_images}
                    alt="Find Jobs"
                  />
                  <div className="feature-course-listing-Job">
                    <img
                      className="s-course-log"
                      src={listValue?.organization_details?.image}
                      alt="logo"
                      style={{ width: "25%" }}
                    />
                  </div>
                </div>
                <div className="feature-special-course-listing-Job-left1">
                  <h1 className="feature-course-listing-Job-Title">
                    {listValue?.name}
                  </h1>

                  {
                    <ShowMoreText
                      lines={3}
                      more="Show more"
                      less="Show less"
                      className="feature-course-listing-Job-para"
                      anchorclassName="my-anchor-css-class"
                      onClick={() =>
                        navigate(
                          localStorage.getItem("TalentToken")
                            ? `/coursedetails/${listValue?.slug}`
                            : popUp
                        )
                      }
                      expanded={false}
                      width={0}
                      truncatedEndingComponent={"... "}
                    >
                      {listValue?.description}
                    </ShowMoreText>
                  }

                  <div className="feature-special-course-listing-rate-percent">
                    <h4 className="feature-special-course-listing-rate">
                      {listValue?.fee}
                    </h4>
                  </div>
                </div>
              </NavLink>
            ) : (
              <NavLink to={""} onClick={popUp}>
                <div className="feature-special-course-image-container">
                  <img
                    className="feature-special-course-listing-Job-Img"
                    src={listValue?.thumbnail_images}
                    alt="Find Jobs"
                  />
                  <div className="feature-course-listing-Job">
                    <img
                      className="s-course-log"
                      src={listValue?.organization_details?.image}
                      alt="logo"
                      style={{ width: "25%" }}
                    />
                  </div>
                </div>
                <div className="feature-special-course-listing-Job-left">
                  <h1 className="feature-course-listing-Job-Title">
                    {listValue?.name}
                  </h1>

                  {
                    <ShowMoreText
                      lines={3}
                      more="Show more"
                      less="Show less"
                      className="feature-course-listing-Job-para"
                      anchorclassName="my-anchor-css-class"
                      onClick={() =>
                        navigate(`/coursedetails/${listValue?.slug}`)
                      }
                      expanded={false}
                      width={0}
                      truncatedEndingComponent={"... "}
                    >
                      {listValue?.description}
                    </ShowMoreText>
                  }

                  <div className="feature-special-course-listing-rate-percent">
                    <h4 className="feature-special-course-listing-rate">
                      <FontAwesomeIcon
                        className="cross-Icon"
                        icon={faIndianRupeeSign}
                        color="red"
                      />
                      {listValue?.fee}
                    </h4>
                  </div>
                </div>
              </NavLink>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default CourseListing;
