import React from "react";
import SignUp from "../../Component/Login/SignUp";
import Signup from "../../Component/SignUpPage/Signup";

const SignUPPage = () => {
  return (
    <div>
      <Signup />
    </div>
  );
};

export default SignUPPage;
