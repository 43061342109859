import React, { useState, useEffect } from "react";
import RightJobs from "../../Component/Volnteer/RightJobs";
import FeaturedJobsProfile from "../../Component/Volnteer/FeaturedJobsProfile";
import Listing from "../../Component/Volnteer/Listing";
import Pagination from "../../Component/Volnteer/Pagination";
import { JobBanner3, FeatureJob, ListJob } from "../../Api/JobApi";
import { Helmet } from "react-helmet";
import { Navbar } from "../../Component/Navbar";
import AllListingBtn from "../../Component/AllListingBtn";

const Volunteer = () => {
  const [jobsBanner, setJobsBanner] = useState({});

  useEffect(() => {
    const loadData = () => {
      JobBanner3().then((res) => {
        const response = res;
        setJobsBanner(response.data);
      });
    };
    loadData();
  }, []);
  const [featured, setFeatured] = useState([]);
  useEffect(() => {
    const loadData = () => {
      FeatureJob().then((res) => {
        const response = res;

        setFeatured(response);
      });
    };
    loadData();
  }, []);
  const [list, setList] = useState([]);
  useEffect(() => {
    const loadData = () => {
      ListJob().then((res) => {
        const response = res;
        setList(response);
      });
    };
    loadData();
  }, []);
  const [showPerPage, setShowPerPage] = useState(12);
  const [paginat, setPaginat] = useState({
    start: 0,
    end: showPerPage,
  });
  const PaginationHandle = (start, end) => {
    setPaginat({ start: start, end: end });
  };
  const data = {
    text: "View All Job",
    link: `/Volunteers`,
  };
  return (
    <div>
      <div>
        <Helmet>
          <title>55notout | Volenteer Jobs</title>
          <meta name="description" content="Volenteer Jobs" />
        </Helmet>
        <Navbar />
        <RightJobs jobs={jobsBanner} />
        <FeaturedJobsProfile featured={featured} paginat={paginat} />

        <Listing listJob={list} paginat={paginat} />
        {list.length >= 13 ? (
          <Pagination
            showPerPage={showPerPage}
            PaginationHandle={PaginationHandle}
            total={list?.length}
          />
        ) : (
          ""
        )}
        <AllListingBtn data={data} />
      </div>
    </div>
  );
};

export default Volunteer;
