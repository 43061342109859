import React, { useState, useEffect } from "react";
import OpportunityBox from "../../Component/TalentFile/OpportunityBox";
import FeatureCourses from "../../Component/Courses/FeatureCourses";
import SpecialCourse from "../../Component/Courses/SpecialCourse";
import { CourseBanner, FeatureCourse, ListCourse } from "../../Api/Course";
import Pagination from "../../Component/FullTime-jobs/Pagination";
import { Helmet } from "react-helmet";
import { Navbar } from "../../Component/Navbar";
import AllListingBtn from "../../Component/AllListingBtn";
const OurCourses = () => {
  const [courseBanner, setCourseBanner] = useState({});
  useEffect(() => {
    const loadData = () => {
      CourseBanner().then((res) => {
        const response = res;
        setCourseBanner(response.data);
      });
    };
    loadData();
  }, {});
  const [featured, setFeatured] = useState([]);
  useEffect(() => {
    const loadData = () => {
      FeatureCourse().then((res) => {
        const response = res;

        setFeatured(response);
      });
    };
    loadData();
  }, []);
  const [list, setList] = useState([]);
  useEffect(() => {
    const loadData = () => {
      ListCourse().then((res) => {
        const response = res;

        setList(response);
      });
    };
    loadData();
  }, []);
  const [showPerPage, setShowPerPage] = useState(12);
  const [paginat, setPaginat] = useState({
    start: 0,
    end: showPerPage,
  });
  const PaginationHandle = (start, end) => {
    setPaginat({ start: start, end: end });
  };
  const data = {
    text: "View all Courses",
    link: `/Course`,
  };
  return (
    <div>
      <Helmet>
        <title>55notout | Courses</title>
        <meta name="description" content="Our Courses" />
      </Helmet>
      <Navbar />
      <OpportunityBox data={courseBanner} dataBtn={data} />
      <FeatureCourses featured={featured} />
      <SpecialCourse list={list} paginat={paginat} />
      {list.length >= 13 ? (
        <Pagination
          showPerPage={showPerPage}
          PaginationHandle={PaginationHandle}
          total={list?.length}
        />
      ) : (
        ""
      )}
      <AllListingBtn data={data} />
    </div>
  );
};

export default OurCourses;
