import React, { useState } from "react";
import MentorSkills from "../../Component/Mentor/MentorSkills";
import { mentorRegister } from "../../Api/Mentor";
import MentorQuestionaire from "../../Component/Mentor/MentorQuestionaire";
import { toast } from "react-toastify";
import MentorOulification from "../../Component/Mentor/MentorOulification";
import { Navbar } from "../../Component/Navbar";
import { Helmet } from "react-helmet";
const MentorDetails = () => {
  const [step, setStep] = useState(0);
  const [data, setData] = useState({});

  const handleApi = (data) => {
    mentorRegister(data)
      .then((res) => {
        toast("Data Submit SuccesFull", {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        });

        setTimeout(() => {
          window.location.href = "/featuredprofile";
        }, 1000);
      })
      .catch((e) =>
        toast(e.response.data.msg, {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        })
      );

    setData(data);
  };
  return (
    <div>
      <Helmet>
        <title>55notout | Become A Mentor</title>
        <meta name="description" content=" Become A Mentor" />
      </Helmet>
      <Navbar />
      {step === 0 && (
        <MentorSkills
          saveData={(e) => {
            setData({ ...data, ...e });
            setStep(1);
          }}
        />
      )}
      {step === 1 && (
        <MentorOulification
          saveData={(e) => {
            setData({ ...data, ...e });
            setStep(2);
          }}
        />
      )}
      {step === 2 && (
        <MentorQuestionaire saveData={(e) => handleApi({ ...data, ...e })} />
      )}
    </div>
  );
};

export default MentorDetails;
