import React, { useState, useEffect } from "react";
import "./Search.css";
import { AllEvents } from "../../Api/EventApi";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Slider from "@mui/material/Slider";
// import Listing from "../FullTime-jobs/Listing";
import RealatedJob from "../JobDetails/RealatedJob";
import { Address, productCategory } from "../../Api/OrgApi";
import { AllProduct } from "../../Api/ProductApi";
import { skillNames } from "../../Api/TalentsApi";
import ProductListing from "./ProductListing";
import FilterCategory from "./FilterCategory";
import FilterImage from "../../Image/filters.png";
import Drawer from "@mui/material/Drawer";
function valuetext(value) {
  return `${value}°C`;
}

const ProductFilter = () => {
  const [list, setList] = useState([]);
  const [address, setAddress] = useState([]);
  const [category, setCategory] = useState([]);
  const [filterValue, setFilterValue] = React.useState({});
  const [filterValuemin, setFilterValuemax] = React.useState({});
  const [skill, setSkill] = useState([]);
  const [appDrawer, setAppDrawer] = useState(false);
  const [value, setValue] = React.useState([0, 100000]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const sendData = {
      salary_min: value[0],
      salary_max: value[1],
    };

    setFilterValuemax({ ...filterValuemin, ...sendData });
  };

  const handleChange2 = (e, newValue) => {
    const { name, value } = e.target;

    setFilterValue({ ...filterValue, [name]: value });
    // setFilterValue({ ...filterValue, [name]: sendData });
  };
  useEffect(() => {
    const loadData = () => {
      AllProduct(filterValue).then((res) => {
        setList(res);
      });
    };
    loadData();
  }, [filterValue]);

  useEffect(() => {
    const loadData = () => {
      Address().then((res) => {
        const response = res;
        const data = response.data;
        // let temp = { ...edit, location: data[0]?.id + "" };
        // setEdit(temp);
        setAddress(data);
      });
    };
    loadData();
  }, []);

  const minMarks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 20000,
      label: "20k",
    },
    {
      value: 40000,
      label: "40k",
    },
    {
      value: 100000,
      label: "100k",
    },
  ];
  const maxMarks = [
    {
      value: 150000,
      label: "1.5L",
    },
    {
      value: 250000,
      label: "2.5L",
    },
    {
      value: 350000,
      label: "3.5L",
    },
    {
      value: 500000,
      label: "5L",
    },
  ];
  const handleOpen = () => {
    setAppDrawer(!appDrawer);
  };
  useEffect(() => {
    const loadData = () => {
      productCategory().then((res) => {
        const response = res;
        setCategory(response);
      });
    };
    loadData();
  }, []);
  return (
    <>
      <img
        src={FilterImage}
        alt=""
        onClick={handleOpen}
        className="responsiveFilterIcon"
        // style={{ display: "none" }}
      />
      <div className="SearchMainContainer">
        <div className="filterSection">
          <div className="filter-section">
            <h4 className="filter">Filter</h4>
            <span className="clear">Clear</span>
          </div>

          <div>
            <h4 className="filter"> Title</h4>
            <FormControl fullWidth>
              <TextField
                // id="outlined-basic"
                label="Title"
                variant="outlined"
                // className="inputBox"
                name="title"
                onChange={handleChange2}
                id="Book Name"
              />
            </FormControl>
          </div>
          <div>
            <h4 className="filter">Category</h4>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="category"
                label="Selecttttttttttttt"
                onChange={handleChange2}
                defaultValue=""
                className="selectedValue"
              >
                {category?.map((value, key) => {
                  return (
                    <MenuItem value={value?.id} className="selectedValue">
                      {value?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>

          <div>
            <h4 className="filter">Free</h4>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                //   value={age}
                name="is_free"
                label="Selectttttt"
                onChange={handleChange2}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <h4 className="filter"> Featured</h4>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                //   value={age}
                name="is_featured_post"
                label="Selectttttt"
                onChange={handleChange2}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl fullWidth>
              <div>
                <h4 className="filter"> Fees</h4>
                <div className="filter-section">
                  <h4 className="filter">Min</h4>
                </div>
                <Slider
                  getAriaLabel={() => "Temperature range"}
                  // value={value}
                  onChange={handleChange2}
                  step={10000}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  marks={minMarks}
                  min={0}
                  max={100000}
                  name="min_fee"
                />
                <div className="filter-section">
                  <h4 className="filter">Max</h4>
                </div>
                <Slider
                  getAriaLabel={() => "Temperature range"}
                  // value={value}
                  onChange={handleChange2}
                  step={10000}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  marks={maxMarks}
                  min={120000}
                  max={500000}
                  name="max_fee"
                />
              </div>
            </FormControl>
          </div>
        </div>
        <Drawer
          anchor={"left"}
          open={appDrawer}
          onClose={() => setAppDrawer(false)}
          // className="filterDrawer"
        >
          <div className="filterDrawer">
            <div className="filter-section">
              <h4 className="filter">Filter</h4>
              <span className="clear">Clear</span>
            </div>

            <div>
              <h4 className="filter"> Title</h4>
              <FormControl fullWidth>
                <TextField
                  // id="outlined-basic"
                  label="Title"
                  variant="outlined"
                  // className="inputBox"
                  name="title"
                  onChange={handleChange2}
                  id="Book Name"
                />
              </FormControl>
            </div>
            <div>
              <h4 className="filter">Category</h4>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="category"
                  label="Selecttttttttttttt"
                  onChange={handleChange2}
                  defaultValue=""
                  className="selectedValue"
                >
                  {category?.map((value, key) => {
                    return (
                      <MenuItem value={value?.id} className="selectedValue">
                        {value?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>

            <div>
              <h4 className="filter">Free</h4>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  //   value={age}
                  name="is_free"
                  label="Selectttttt"
                  onChange={handleChange2}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <h4 className="filter"> Featured</h4>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  //   value={age}
                  name="is_featured_post"
                  label="Selectttttt"
                  onChange={handleChange2}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl fullWidth>
                <div>
                  <h4 className="filter"> Fees</h4>
                  <div className="filter-section">
                    <h4 className="filter">Min</h4>
                  </div>
                  <Slider
                    getAriaLabel={() => "Temperature range"}
                    // value={value}
                    onChange={handleChange2}
                    step={10000}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                    marks={minMarks}
                    min={0}
                    max={100000}
                    name="min_fee"
                  />
                  <div className="filter-section">
                    <h4 className="filter">Max</h4>
                  </div>
                  <Slider
                    getAriaLabel={() => "Temperature range"}
                    // value={value}
                    onChange={handleChange2}
                    step={10000}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                    marks={maxMarks}
                    min={120000}
                    max={500000}
                    name="max_fee"
                  />
                </div>
              </FormControl>
            </div>
          </div>
        </Drawer>
        <div className="listing">
          <ProductListing list={list} />
        </div>
      </div>
    </>
  );
};

export default ProductFilter;
