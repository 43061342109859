import axios from "axios";
const baseUrl = "https://admin.55notout.com/";

const Address = () => {
  const urlPath = "address/citi_state_list";
  const url = `${baseUrl}${urlPath}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch {}
};

const eventCategory = () => {
  const urlPath = "miscellaneous/get_all_event_categories/";
  const url = `${baseUrl}${urlPath}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch {}
};
const productCategory = () => {
  const urlPath = "miscellaneous/get_all_product_categories]/";
  const url = `${baseUrl}${urlPath}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch {}
};
const serviceCategory = () => {
  const urlPath = "miscellaneous/get_all_service_categories/";
  const url = `${baseUrl}${urlPath}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch {}
};

const AuthOrgLogin = (loginData) => {
  const slug = "authentication/organization_login/";
  const url = `${baseUrl}${slug}`;
  // const headers = {
  //   "Content-Type": "application/json",
  //   Authorization: `bearer ${localStorage.getItem("token")}`,
  // };
  try {
    const response = axios.post(url, loginData).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const AuthOrgRegister = (data) => {
  const slug = "authentication/register_organization/";
  const url = `${baseUrl}${slug}`;
  // const headers = {
  //   "Content-Type": "application/json",
  //   Authorization: `bearer ${localStorage.getItem("token")}`,
  // };

  try {
    const response = axios.post(url, data).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const applicationJobDetails = ({ slug }) => {
  const urlPath = "organizationJobAndOther/get_all_job_applicants/";
  const url = `${baseUrl}${urlPath}${slug}`;
  try {
    const response = axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        return response.data;
      });
    return response;
  } catch (error) {
    return error;
  }
};
const applicationEventDetails = ({ slug }) => {
  const urlPath = "organizationJobAndOther/get_all_even_applicants/";
  const url = `${baseUrl}${urlPath}${slug}`;
  try {
    const response = axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        return response.data;
      });
    return response;
  } catch (error) {
    return error;
  }
};
const applicationCourseDetails = ({ slug }) => {
  const urlPath = "organizationJobAndOther/get_all_course_applicants/";
  const url = `${baseUrl}${urlPath}${slug}`;
  try {
    const response = axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        return response.data;
      });
    return response;
  } catch (error) {
    return error;
  }
};
const applicationProductDetails = ({ slug }) => {
  const urlPath = "organizationJobAndOther/get_all_product_applicants/";
  const url = `${baseUrl}${urlPath}${slug}`;
  try {
    const response = axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        return response.data;
      });
    return response;
  } catch (error) {
    return error;
  }
};
const applicationServiceDetails = ({ slug }) => {
  const urlPath = "organizationJobAndOther/get_all_service_applicants/";
  const url = `${baseUrl}${urlPath}${slug}`;
  try {
    const response = axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        return response.data;
      });
    return response;
  } catch (error) {
    return error;
  }
};

const jobGet = () => {
  const urlPath = "organizationJobAndOther/get_create_job/";
  const url = `${baseUrl}${urlPath}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  try {
    const response = axios.get(url, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};

const JobPostings = (data) => {
  const slug = "organizationJobAndOther/get_create_job/";
  const url = `${baseUrl}${slug}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  try {
    const response = axios.post(url, data, config).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const jobPut = (formData) => {
  const urlPath = "organizationJobAndOther/get_update_delete_job/";
  const url = `${baseUrl}${urlPath}${formData.slug}/`;

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  delete formData.image;
  try {
    const response = axios.put(url, formData, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const deleteJob = (formData) => {
  const urlPath = `organizationJobAndOther/get_update_delete_job/`;
  const url = `${baseUrl}${urlPath}${formData.slug}/`;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: { id: formData },
  };

  try {
    const response = axios.delete(url, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};

const eventGet = () => {
  const urlPath = "organizationJobAndOther/get_create_event/";
  const url = `${baseUrl}${urlPath}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  try {
    const response = axios.get(url, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};

const eventPostings = (formData) => {
  const slug = "organizationJobAndOther/get_create_event/";
  const url = `${baseUrl}${slug}`;
  const config = {
    headers: {
      // "content-type": IsImage ? "multipart/form-data" : "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  try {
    const response = axios.post(url, formData, config).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const eventPut = (formData, formData1) => {
  const urlPath = "organizationJobAndOther/get_update_delete_event/";
  const url = `${baseUrl}${urlPath}${formData.slug}/`;

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  delete formData.image;
  try {
    const response = axios.put(url, formData, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const deleteEvent = (formData) => {
  const urlPath = `organizationJobAndOther/get_update_delete_event/`;
  const url = `${baseUrl}${urlPath}${formData.slug}/`;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: { id: formData },
  };

  try {
    const response = axios.delete(url, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const courseGet = () => {
  const urlPath = "organizationJobAndOther/get_create_courses/";
  const url = `${baseUrl}${urlPath}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  try {
    const response = axios.get(url, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const coursePostings = (formData) => {
  const slug = "organizationJobAndOther/get_create_courses/";
  const url = `${baseUrl}${slug}`;
  const config = {
    headers: {
      // "content-type": IsImage ? "multipart/form-data" : "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  try {
    const response = axios.post(url, formData, config).then((response) => {
      // return response.data;
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const coursePut = (formData, IsImage, slug) => {
  const urlPath = "organizationJobAndOther/get_update_delete_course/";
  const url = `${baseUrl}${urlPath}${slug}/`;

  const config = {
    headers: {
      "content-type": IsImage ? "multipart/form-data" : "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  delete formData.image;
  try {
    const response = axios.put(url, formData, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const deleteCourse = (formData) => {
  const urlPath = `organizationJobAndOther/get_update_delete_course/`;
  const url = `${baseUrl}${urlPath}${formData.slug}/`;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: { id: formData },
  };

  try {
    const response = axios.delete(url, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};

const productGet = () => {
  const urlPath = "organizationJobAndOther/get_create_products/";
  const url = `${baseUrl}${urlPath}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  try {
    const response = axios.get(url, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const productPostings = (data) => {
  const slug = "organizationJobAndOther/get_create_products/";
  const url = `${baseUrl}${slug}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  try {
    const response = axios.post(url, data, config).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const productPut = (formData) => {
  const urlPath = "organizationJobAndOther/get_update_delete_products/";
  const url = `${baseUrl}${urlPath}${formData.slug}/`;

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  delete formData.image;
  try {
    const response = axios.put(url, formData, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const deleteProduct = (formData) => {
  const urlPath = `organizationJobAndOther/get_update_delete_products/`;
  const url = `${baseUrl}${urlPath}${formData.slug}/`;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: { id: formData },
  };

  try {
    const response = axios.delete(url, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const serviceGet = () => {
  const urlPath = "organizationJobAndOther/get_create_services/";
  const url = `${baseUrl}${urlPath}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  try {
    const response = axios.get(url, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const servicePostings = (data) => {
  const slug = "organizationJobAndOther/get_create_services/";
  const url = `${baseUrl}${slug}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  try {
    const response = axios.post(url, data, config).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const servicePut = (formData) => {
  const urlPath = "organizationJobAndOther/get_update_delete_services/";
  const url = `${baseUrl}${urlPath}${formData.slug}/`;

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  delete formData.image;
  try {
    const response = axios.put(url, formData, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const deleteService = (formData) => {
  const urlPath = `organizationJobAndOther/get_update_delete_services/`;
  const url = `${baseUrl}${urlPath}${formData.slug}/`;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: { id: formData },
  };

  try {
    const response = axios.delete(url, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};

const updateOrgDetails = () => {
  const urlPath = `organizationJobAndOther/organization_details/`;
  const url = `${baseUrl}${urlPath}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  try {
    const response = axios.get(url, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const updatePut = (data, IsImage) => {
  const urlPath = "organizationJobAndOther/organization_details/";
  const url = `${baseUrl}${urlPath}`;

  const config = {
    headers: {
      "content-type": IsImage ? "multipart/form-data" : "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  try {
    const response = axios.put(url, data, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const updatePut1 = (data, IsImage) => {
  const urlPath = "organizationJobAndOther/organization_details/";
  const url = `${baseUrl}${urlPath}`;

  const config = {
    headers: {
      "content-type": IsImage ? "multipart/form-data" : "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  try {
    const response = axios.put(url, data, config).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const jobShortlist = (data) => {
  const urlPath = `organizationJobAndOther/reject_or_short_list/`;
  const url = `${baseUrl}${urlPath}${data.slug}/`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  try {
    const response = axios.post(url, data, config).then((res) => {
      return response.data;
    });
  } catch (error) {
    return error;
  }
};

export {
  eventCategory,
  productCategory,
  Address,
  AuthOrgLogin,
  AuthOrgRegister,
  applicationJobDetails,
  applicationEventDetails,
  applicationCourseDetails,
  applicationProductDetails,
  applicationServiceDetails,
  jobGet,
  JobPostings,
  eventGet,
  eventPostings,
  eventPut,
  deleteEvent,
  courseGet,
  coursePostings,
  coursePut,
  deleteCourse,
  productGet,
  productPostings,
  productPut,
  deleteProduct,
  serviceGet,
  servicePostings,
  servicePut,
  deleteService,
  jobPut,
  deleteJob,
  updateOrgDetails,
  updatePut,
  jobShortlist,
  serviceCategory,
};
