import React, { useState, useEffect } from "react";
import MentorPersnolData from "./MentorPersnolData";
import { putUpdateDetails } from "../../Api/Mentor";
import { getUpdateDetails } from "../../Api/Mentor.js";
import Dp from "../../Image/dp.jpg";
import Modal from "react-modal";

const MentorProfile = () => {
  const [personalData, setPersonalData] = useState({});
  const [cv, setCv] = useState();
  const [data, setData] = useState(false);
  let subtitle;

  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }
  function closeModal() {
    setIsOpen(false);
    // loadData();
  }
  useEffect(() => {
    const loadData = () => {
      getUpdateDetails().then((res) => {
        const response = res;

        setPersonalData(response.data);
      });
    };
    loadData();
  }, [modalIsOpen, data]);

  // const loadData = () => {
  //   getUpdateDetails().then((res) => {
  //     const response = res;

  //     setPersonalData(response.data);
  //   });
  // };
  const handleImage = (e) => {
    const user_cv = e.target.files[0];
    const formData1 = new FormData();
    formData1.append("user_cv", user_cv);
    putUpdateDetails(formData1, true).then((res) => {
      setCv(res);
      setData(!data);
    });
  };

  const resume = personalData?.user_cv?.split("?")[0]?.split("/")[8];

  return (
    <>
      <div className="user-profile-container">
        <img
          className="user-profile"
          src={personalData?.image === null ? Dp : personalData?.image}
          alt="profile"
        />

        <div className="m-edit-profile">
          <h4 className="m-profile-name">
            {personalData?.first_name} {personalData?.last_name}
          </h4>
          <img
            className="user-edit"
            src="Icons/edit.svg"
            alt="edit"
            onClick={openModal}
          />
        </div>
        <h4 className="user-gmail">{personalData?.email}</h4>
        <h4 className="user-phone">{personalData?.phone_number}</h4>
        <div className="m-user-icons">
          <img
            className=""
            src="Icons/linkdin-blue.svg"
            alt="linkdin"
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open(personalData?.linkedin_profile);
            }}
          />
          <img
            className=""
            src="Icons/twiter-blue.svg"
            alt="twiter"
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open(personalData?.twitter);
            }}
          />
        </div>

        <div className="resume-upload">
          <a href={personalData?.user_cv} style={{ textDecoration: "none" }}>
            <h1 className="resume">{resume}</h1>
          </a>
          <input
            type="file"
            id="file-input2"
            style={{ display: "none" }}
            name="user_cv"
            onChange={handleImage}
          />
        </div>
        <div className="upload">
          <img className="upload-icon" src="Icons/upload.svg" alt="edit" />

          <label for="file-input2">Upload</label>
        </div>
        <div className="user-skill-container">
          <div className="Mentor-profile-skill">
            <h2 className="user-skill">Skills :</h2>
          </div>
          <div>
            {personalData?.key_skills_names?.map((value) => {
              return (
                <div>
                  <h4 className="user-company">{value?.name}</h4>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <MentorPersnolData dataP={personalData} closeModal={closeModal} />
      </Modal>
    </>
  );
};

export default MentorProfile;
