import React from "react";
import Icon from "../../Image/icon.png";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
const Mentorning = () => {
  const navigate = useNavigate();
  const popUp = () => {
    toast("Please Login To Continue", {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const popUp2 = () => {
    toast("Oops; you are Already Mentor", {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });
  };
  return (
    <>
      <div className="secondContainer">
        <div className="supportiveContainer">
          <div className="imageContainer">
            <img src={Icon} alt="icon" />
          </div>
          <div className="supportiveText">
            <h1 className="supportiveTextTitle">Showcase your achievements</h1>
            <p className="supportiveTextParagraph">
              Your mentoring profile does not only include your mentoring
              booking packages, but also verified testimonials, blog posts and
              performance data. The perfect way to showcase your development as
              leader.
            </p>
          </div>
        </div>
        <div className="supportiveContainer">
          <div className="imageContainer">
            <img src={Icon} alt="icon" />
          </div>
          <div className="supportiveText">
            <h1 className="supportiveTextTitle">Showcase your achievements</h1>
            <p className="supportiveTextParagraph">
              Your mentoring profile does not only include your mentoring
              booking packages, but also verified testimonials, blog posts and
              performance data. The perfect way to showcase your development as
              leader.
            </p>
          </div>
        </div>
        <div className="supportiveContainer">
          <div className="imageContainer">
            <img src={Icon} alt="icon" />
          </div>
          <div className="supportiveText">
            <h1 className="supportiveTextTitle">Showcase your achievements</h1>
            <p className="supportiveTextParagraph">
              Your mentoring profile does not only include your mentoring
              booking packages, but also verified testimonials, blog posts and
              performance data. The perfect way to showcase your development as
              leader.
            </p>
          </div>
        </div>
        <div className="supportiveContainer">
          <div className="imageContainer">
            <img src={Icon} alt="icon" />
          </div>
          <div className="supportiveText">
            <h1 className="supportiveTextTitle">Showcase your achievements</h1>
            <p className="supportiveTextParagraph">
              Your mentoring profile does not only include your mentoring
              booking packages, but also verified testimonials, blog posts and
              performance data. The perfect way to showcase your development as
              leader.
            </p>
          </div>
        </div>
        <div className="supportiveContainer">
          <div className="imageContainer">
            <img src={Icon} alt="icon" />
          </div>
          <div className="supportiveText">
            <h1 className="supportiveTextTitle">Showcase your achievements</h1>
            <p className="supportiveTextParagraph">
              Your mentoring profile does not only include your mentoring
              booking packages, but also verified testimonials, blog posts and
              performance data. The perfect way to showcase your development as
              leader.
            </p>
          </div>
        </div>
        <div className="supportiveContainer">
          <div className="imageContainer">
            <img src={Icon} alt="icon" />
          </div>
          <div className="supportiveText">
            <h1 className="supportiveTextTitle">Showcase your achievements</h1>
            <p className="supportiveTextParagraph">
              Your mentoring profile does not only include your mentoring
              booking packages, but also verified testimonials, blog posts and
              performance data. The perfect way to showcase your development as
              leader.
            </p>
          </div>
        </div>
      </div>
      <div className="expertiseButtonContainer" style={{ marginTop: "2%" }}>
        {localStorage.getItem("TalentToken") &&
        localStorage.getItem("mentor_slug") === "null" ? (
          <button
            className="expertiseMentor"
            onClick={() => navigate(`/mentorDetails`)}
          >
            Become a mentor
          </button>
        ) : (
          <button
            className="expertiseMentor"
            onClick={localStorage.getItem("mentor_slug") ? popUp2 : popUp}
          >
            Become a mentor
            <ToastContainer />
          </button>
        )}
        <a href="#FAQs" className="expertiseFaq">
          Frequently asked questions
        </a>
      </div>
    </>
  );
};

export default Mentorning;
