import axios from "axios";
const baseUrl = "https://admin.55notout.com/";

const HomeBanner = () => {
  const slug = "homepage/get_homepage_banner/";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};

const heroHeadline = () => {
  const slug = "homepage/get_heroheadlines/";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const Highlights = () => {
  const slug = "homepage/get_homepage_highlights/";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const HomeCard = () => {
  const slug = "homepage/get_homepage_card/";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const HomeService = () => {
  const slug = "homepage/get_homepage_service/";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const Testimonials = () => {
  const slug = "homepage/get_homepage_testimonials";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const TalentsBanner = () => {
  const slug = "homepage_talents/hero_banner";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const TalentsIcon = () => {
  const slug = "homepage_talents/hero_icons";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const TalentService = () => {
  const slug = "homepage_talents/hero_services";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const OrganizationBanner = () => {
  const slug = "homepage_organizations/hero_banner";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const OrganizationIcon = () => {
  const slug = "homepage_organizations/hero_icons";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const OrganizationMidBanner = () => {
  const slug = "homepage_organizations/hero_middle_banner";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const OrganizationService = () => {
  const slug = "homepage_organizations/hero_services";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};

// Authentication

const AuthTalentLogin = (loginData) => {
  const slug = "authentication/talent_login/";
  const url = `${baseUrl}${slug}`;

  try {
    const response = axios.post(url, loginData).then((response) => {
      return response;
    });

    return response;
  } catch (error) {
    return error;
  }
};
const socialMediaLogin = (data) => {
  const link = "authentication/talent_socialLogin/";
  const url = `${baseUrl}${link}`;
  try {
    const response = axios.post(url, data).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const AuthTalentRegister = (data) => {
  const slug = "authentication/register_talent/";
  const url = `${baseUrl}${slug}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `bearer ${localStorage.getItem("TalentToken")}`,
  };

  try {
    const response = axios.post(url, data, headers).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const staicPageDetails = (slug) => {
  const link = `staticpages/staticpage/${slug}`;
  const url = `${baseUrl}${link}`;
  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const Dynamic = () => {
  const slug = "staticpages/get_static_pages_list/";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const changePassword = (data) => {
  const urlPath = `authentication/change_password_request/`;
  const url = `${baseUrl}${urlPath}`;

  try {
    const response = axios.post(url, data).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const userPassword = (data) => {
  var path = localStorage.getItem("PATH");
  const urlPath = `authentication/change_user_password/`;
  const url = `${baseUrl}${urlPath}`;
  const config = {
    headers: {
      Authorization: `Bearer ${
        localStorage.getItem("PATH") === "ORG"
          ? localStorage.getItem("token")
          : localStorage.getItem("TalentToken")
      }`,
    },
  };

  try {
    const response = axios.post(url, data, config).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const confirmPassword = (formData) => {
  const urlPath = `authentication/confirm_password/`;
  const url = `${baseUrl}${urlPath}`;

  try {
    const response = axios.post(url, formData).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const forgetPasswordToken = () => {
  const urlPath = `authentication/get_forget_password_token/`;
  const url = `${baseUrl}${urlPath}`;
  try {
    const response = axios.get(url).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const getToken = (token) => {
  const slug = `authentication/get_forget_password_token/?token=${token}`;
  const url = `${baseUrl}${slug}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const contactWithUs = (data) => {
  const slug = "contact_uscreate/";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.post(url, data).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const FeaturesStep1 = (formData) => {
  const urlPath = `payment_module/create_job_and_other_order/`;
  const url = `${baseUrl}${urlPath}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  try {
    const response = axios.post(url, formData, config).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const FeaturesStep2 = (formData) => {
  const urlPath = `payment_module/create_job_and_other_payment/`;
  const url = `${baseUrl}${urlPath}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  try {
    const response = axios.post(url, formData, config).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const FeaturesStep3 = (formData) => {
  const urlPath = `payment_module/job_and_other_payment_status/`;
  const url = `${baseUrl}${urlPath}`;
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  try {
    const response = axios.post(url, formData, config).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const showPrice = () => {
  const slug = "payment_module/all_prices/";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const emailInFooter = (data) => {
  const slug = "home_page/add_mail_to_subscribeus/";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.post(url, data).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
export {
  HomeBanner,
  heroHeadline,
  Highlights,
  HomeCard,
  HomeService,
  Testimonials,
  TalentsBanner,
  TalentsIcon,
  TalentService,
  OrganizationBanner,
  OrganizationIcon,
  OrganizationMidBanner,
  OrganizationService,
  AuthTalentLogin,
  socialMediaLogin,
  AuthTalentRegister,
  staicPageDetails,
  Dynamic,
  changePassword,
  userPassword,
  confirmPassword,
  forgetPasswordToken,
  getToken,
  contactWithUs,
  FeaturesStep1,
  FeaturesStep2,
  FeaturesStep3,
  showPrice,
  emailInFooter,
};
