import React, { useState, useEffect } from "react";
import OrgApplication from "../../Component/OrgApplication/OrgApplication";
// import { applicationJobDetails } from "../../Api/OrgApi";
import { applicationJobDetails } from "../../Api/OrgApi";
import { jobShortlist } from "../../Api/OrgApi";
import { useParams } from "react-router-dom";
const OrgApplicationDetails = () => {
  let { slug } = useParams();
  const [app, setApp] = useState([]);

  useEffect(() => {
    const loadData = () => {
      applicationJobDetails({ slug }).then((res) => {
        const response = res;

        setApp(response.data);
      });
    };
    loadData();
  }, []);
  // useEffect(() => {
  //   jobShortlist({ slug }).then((res) => {
  //     const response = res;
  //   });
  // }, []);

  return (
    <div>
      <OrgApplication details={app} slug={slug} />
    </div>
  );
};

export default OrgApplicationDetails;
