import React, { useState } from "react";
import Modal from "react-modal";
import moment from "moment";
import { NavLink, useNavigate } from "react-router-dom";
import EditService from "../OrgEdit/EditService";
import DeleteService from "../OrgEdit/DeleteService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
const ServiceDetails = ({ data, dataPost }) => {
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <div className="org-all-details">
        <div className="jobApplication">
          <div className="orgJobDetails">
            <h1 className="orgPost">{data?.name}</h1>
          </div>
          <div className="down-text">
            <div className="org-icon-down">
              <img
                className="org-down-social-icons"
                src="/Icons/Office.svg"
                alt="location"
              />
              <h1 className="down-icon-text">
                {moment(data.created_at).format("DD/MM/YYYY")}
              </h1>
            </div>
            <div className="org-icon-down">
              <FontAwesomeIcon
                className="clockIcon"
                icon={faClock}
                style={{ color: "#F66048" }}
              />
              <h1 className="down-icon-text">
                {moment(data.created_at).format("hh:mm ")}
              </h1>
            </div>
            <h1 className="org-Application">veiws :{data.views}</h1>
          </div>
        </div>
        <div className="org-left-details">
          <div>
            <DeleteService data={data} />
          </div>

          <button className="extend" onClick={openModal}>
            Extend Availability
          </button>

          <img
            className="org-social-icons"
            src="Icons/insta.svg"
            alt="insta"
            onClick={() => window.open(data.insta_link)}
          />
          <img
            className="org-social-icons"
            src="Icons/fb.svg"
            alt="fb"
            onClick={() => window.open(data.fb_link)}
          />
          <img
            className="org-social-icons"
            src="Icons/twiter.svg"
            alt="twitter"
            onClick={() => window.open(data.twitter_link)}
          />
          <img
            className="org-social-icons"
            src="Icons/linkdin.svg"
            alt="linkdin"
            onClick={() => window.open(data.linkedin_link)}
          />
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <EditService data={data} dataPost={dataPost} closeModal={closeModal} />
      </Modal>
    </>
  );
};

export default ServiceDetails;
