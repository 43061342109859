import JobsDetails from "./JobsDetails";
import { NavLink, Route, Routes } from "react-router-dom";
import EventsDetails from "./EventsDetails";
import React from "react";
import DashBoardDetails from "./DashBoardDetails";
const OrgRoutes = () => {
  return (
    <div>
      <DashBoardDetails />
    </div>
  );
};

export default OrgRoutes;
