import React, { useState, useEffect } from "react";
import FeaturesPosting from "../CoursePost/FeaturesPosting";
import { coursePut } from "../../Api/OrgApi";
import { ToastContainer, toast } from "react-toastify";
const EditCoursePage = ({ data, dataPost, closeModal }) => {
  const slug = data.slug;
  const data3 = {
    title: "Free or Featured Course Listing?",
    free: "Free Course Posting",
    features: "Featured Course Posting",
  };
  //   const date
  const [edit, setEdit] = useState({});
  const [thumbImg, setThumbImg] = useState({});
  const [bannerImg, setBannerImg] = useState({});
  const updateData = (formData) => {
    coursePut(formData, false, slug)
      .then((res) => {
        toast("Course Edit Succesfull", {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          closeModal();
        }, 1000);
      })

      .catch((e) =>
        toast(e?.response?.data?.msg, {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        })
      );
  };
  const handleImage = (e) => {
    const image = e.target.files[0];
    const formData = new FormData();
    formData.append("thumbnail_images", image);

    coursePut(formData, true, slug).then((res) => {
      setThumbImg(res.data?.image);
    });
  };
  const handleImage2 = (e) => {
    const image2 = e.target.files[0];
    const formData2 = new FormData();
    formData2.append("banner_image", image2);
    coursePut(formData2, true, slug).then((res) => {
      setBannerImg(res.data?.image);
    });
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    setEdit({ ...edit, ...{ [name]: value } });
  };

  const handleSubmitData = (e) => {
    e.preventDefault();
    const formData = { ...edit };
    updateData(formData);
  };

  useEffect(() => {
    const temp = {
      name: data.name,
      description: data.description,
      delivery_mode: data.delivery_mode,
      fee: data.fee,
      is_free: data.is_free,

      phone_number: data.phone_number,
      email: data.email,
      website: data.website,
      link: data.link,
      location: "1882",
      is_featured_post: data.is_featured_post,
      slug: data.slug,
    };
    setThumbImg(data?.thumbnail_images);
    setBannerImg(data?.banner_image);
    setEdit(temp);
  }, [data, thumbImg]);
  localStorage.setItem("course_id", data?.id);
  return (
    <div>
      <h1 className="close" onClick={closeModal} style={{ fontSize: "4rem" }}>
        x
      </h1>
      <div className="course-posting-input-container">
        <h1 className="jobs-header">Course Posting</h1>
      </div>
      <form onSubmit={handleSubmitData}>
        <div className="course-posting-input">
          <div>
            <h1 className="course-posting-input-title">
              {dataPost?.titleHead}
            </h1>
          </div>
          <div className="course-just-check">
            <input
              className="course-posting-input-box"
              placeholder="Enter the name or title of the course"
              type="text"
              name="name"
              onChange={handleInput}
              height="40px"
              value={edit?.name}
              required
              // ref={names}
            />
          </div>
        </div>
        <div className="course-desp-box">
          <div className="course-posting-input-con">
            <h1 className="course-posting-input-title">{dataPost?.desp}</h1>
          </div>
          <div className="about-course-content-box">
            <textarea
              className="about-company-desps"
              name="description"
              onChange={handleInput}
              value={edit.description}
              required
            />
          </div>
        </div>
        <div className="course-posting-input">
          <div>
            <h1 className="course-posting-input-title">{dataPost?.catgory}</h1>
          </div>
          <div className="course-just-check">
            <select
              className="course-posting-input-box2"
              name="delivery_mode"
              onChange={handleInput}
              value={edit.delivery_mode}
            >
              <option>online</option>
              <option>offline</option>
              <option>hybrid</option>
            </select>{" "}
            <img
              className="course-drop-down2"
              src="Icons/Sidemenu.svg"
              alt="DropDown-Arrow"
            />
          </div>
        </div>

        <div className="course-posting-input">
          <div>
            <h1 className="course-posting-input-title">{dataPost?.price}</h1>
          </div>
          <div className="course-just-check">
            <input
              className="course-posting-input-box2"
              placeholder="Enter amount"
              name="fee"
              type="text"
              onChange={handleInput}
              value={edit.fee}
              required
              // ref={courseFee}
            />
            <input
              className="container-checkbox1"
              type="checkbox"
              name="isFree"
              onChange={handleInput}
              checked={data?.is_featured_post === "false"}
              value={edit.is_free}
            />
            <span className="checkmark1"> Is Free</span>
          </div>
        </div>
        <div className="courseUploadComponent">
          <div className="upload-main" style={{ marginTop: "5%" }}>
            <h1 className="upload-header">Course Image</h1>
            <div className="thumbnailsContainer">
              <img className="edit-thumbnails" src={thumbImg} alt="" />
              <input
                type="file"
                name="thumbnail_image1"
                id="file-input"
                onChange={handleImage}
                className="thumbnails"
                placeholder="upload-thumbnails"
                style={{ display: "none" }}
              />
              <div>
                <label for="file-input" className="edit-event-post-upload">
                  <img
                    className="upload-icon"
                    src="Icons/upload.svg"
                    alt="edit"
                  />
                  <p>Upload</p>
                </label>
              </div>
            </div>
            <div className="bannerContainer">
              <img
                //   className="bannerContainer"
                className="edit-banner"
                src={bannerImg}
                alt="temp"
              />
              <input
                type="file"
                id="file-input"
                name="banner_image"
                onChange={handleImage2}
                className="course-banner"
                placeholder="Upload Banner"
                style={{ display: "none" }}
              />

              <label for="file-input" className="edit-event-post-upload2">
                <img
                  className="upload-icon"
                  src="Icons/upload.svg"
                  alt="edit"
                />
                <p>Upload</p>
              </label>
            </div>
          </div>
        </div>
        <div className="course-posting-input">
          <div>
            <h1 className="course-posting-input-title">{dataPost?.phone}</h1>
          </div>
          <div className="course-just-check">
            <input
              className="course-posting-input-box2"
              placeholder="Enter phone number"
              type="text"
              name="phone_number"
              onChange={handleInput}
              height="40px"
              value={edit.phone_number}
              required
              // ref={phone}
            />
          </div>
        </div>
        <div className="course-posting-input">
          <div>
            <h1 className="course-posting-input-title">{dataPost?.email}</h1>
          </div>
          <div className="course-just-check">
            <input
              className="course-posting-input-box2"
              placeholder="Enter email address"
              type="email"
              name="email"
              onChange={handleInput}
              height="40px"
              value={edit.email}
              required
              // ref={emails}
            />
          </div>
        </div>
        <div className="course-posting-input">
          <div>
            <h1 className="course-posting-input-title">{dataPost?.web}</h1>
          </div>
          <div className="course-just-check">
            <input
              className="course-posting-input-box2"
              placeholder="Enter your website"
              type="text"
              name="website"
              onChange={handleInput}
              height="40px"
              value={edit.website}
              required
              // ref={web}
            />
          </div>
        </div>
        <div className="course-link-web">
          <h2 className="course-entry">{dataPost?.link}</h2>
          <input
            className="course-ticketing-system"
            type="text"
            name="link"
            onChange={handleInput}
            placeholder="Enter the link to your website or external course."
            value={edit.link}
            required
            // ref={courseLink}
          />
        </div>
        <FeaturesPosting
          data3={data3}
          data={data}
          edit={edit}
          handleInput={handleInput}
        />
        <button type="submit" className="publish-btn">
          Publish
          <ToastContainer />
        </button>
      </form>
    </div>
  );
};

export default EditCoursePage;
