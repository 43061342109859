import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "../../Style/AllDetails/Educational.css";
import { EductionPost } from "../../Api/TalentsApi";
import { ToastContainer, toast } from "react-toastify";
const EducationaDetailsl = ({ closeModal }) => {
  const [data, setData] = useState({
    university_name: "",
    start_from: "",
    end_from: "",
    title: "",
    course: "",
    user: 0,
  });

  const AddEducationalData = (formData) => {
    EductionPost(formData)
      .then((res) => {
        const response = res;

        // navigate(`/dashboard`);
        toast("Educational Detail update suceessfull", {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 1000);
      })
      .catch((e) =>
        e.response.status === 400
          ? toast(" Bad Request", {
              autoClose: 500,
              position: toast.POSITION.TOP_RIGHT,
            })
          : ""
      );
  };
  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setData({ ...data, [name]: value });
  };
  // let name, value;
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = { ...data };

    AddEducationalData(formData);
  };
  const close = () => {
    closeModal();
  };
  return (
    <div>
      <h6 className="cancelModel" onClick={close}>
        x
      </h6>
      <div className="f-profile">
        <h1 className="Personal-detail">Educational Details</h1>
        <NavLink to="/ProfesonalDetails">
          <div className="pro-details-w-arrow">
            <h4 className="Pro-details">DashBoard</h4>
            <img
              className="side-arrow"
              src="Images/SideArrow.svg"
              alt="sideArrow"
            />
          </div>
        </NavLink>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="edu-details-Box">
          <h2 className="profile-pic-details-name">Institute</h2>
          <input
            value={data.university_name}
            className="educational-details-box"
            type="text"
            name="university_name"
            placeholder="Type here"
            onChange={handleInput}
            required
          />
        </div>
        <div className="edu-details-Box">
          <h2 className="profile-pic-details-name">Batch</h2>
          <input
            className="educational-details-box1"
            type="date"
            name="start_from"
            value={data.start_from}
            onChange={handleInput}
            required
          />

          <h2 className="to">To</h2>
          <input
            className="educational-details-box2"
            value={data.end_from}
            type="date"
            name="end_from"
            onChange={handleInput}
            required
          />
        </div>
        <div className="edu-details-Box">
          <h2 className="profile-pic-details-name">Course Type</h2>

          <input
            className="educational-details-box"
            type="text"
            name="course"
            value={data.course}
            onChange={handleInput}
          />
        </div>
        <div className="edu-details-Box">
          <h2 className="profile-pic-details-name">Degree</h2>
          <input
            className="educational-details-box"
            type="text"
            name="title"
            value={data.title}
            onChange={handleInput}
            required
          />
        </div>
        <div className="educational-btn">
          <h4>Delete</h4>
        </div>
        <button type="submit" className="save-persnol-details">
          Save & Continue
          <ToastContainer />
        </button>
      </form>
    </div>
  );
};

export default EducationaDetailsl;
