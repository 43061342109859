import React, { useState, useEffect } from "react";
import { ProfessionPut, ProfessionDelete } from "../../Api/TalentsApi";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
const EditProfesonalDetails = ({ data, closeModal }) => {
  const navigate = useNavigate();
  const [pro, setPro] = useState({
    company_name: "",
    start_from: "",
    end_from: "",
    title: "",
    course: "",
    // salary: "",
    salary_disclose: false,
    is_current_organization: true,
    user: 0,
  });
  const updateProfessionalData = (formData) => {
    ProfessionPut(formData).then((res) => {
      const response = res;

      if (response.status === 200) {
        toast("Professional Detail Update suceessfull", {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 1000);
      } else {
        alert("failled");
      }
    });
  };
  const deleteProfessionalData = (formData) => {
    ProfessionDelete(formData)
      .then((res) => {
        const response = res;

        toast("Professional Detail Delete suceessfull", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 1000);
      })
      .catch(
        (e) => e
        // toast(e.msg, {
        //   autoClose: 500,
        //   position: toast.POSITION.TOP_RIGHT,
        // })
      );
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = { ...pro };
    updateProfessionalData(formData);
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    setPro({ ...pro, ...{ [name]: value } });
  };
  const deleteHandle = () => {
    const formData = pro.id;

    deleteProfessionalData(formData);
  };
  const cancel = () => {
    closeModal();
  };
  useEffect(() => {
    setPro(data);
  }, [data]);
  return (
    <div>
      <h6 className="cancelModel" onClick={cancel}>
        x
      </h6>
      <div>
        <div className="f-profile">
          <h1 className="Personal-detail">Professional Details</h1>
          <div className="pro-details-w-arrow">
            <h4 className="Pro-details">Educational Details</h4>
            <img
              className="side-arrow"
              src="Images/SideArrow.svg"
              alt="sideArrow"
            />
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="designation-box1">
            <h2 className="designation-name">Designation</h2>
            <input
              className="designation-box"
              placeholder="Type here"
              type="text"
              name="title"
              size="50"
              value={pro.title}
              onChange={handleInput}
              required
            />
          </div>
          <div className="designation-box1">
            <h2 className="designation-name">Organization</h2>
            <input
              className="designation-box"
              placeholder="Type here"
              type="text"
              name="company_name"
              size="50"
              value={pro.company_name}
              onChange={handleInput}
              required
            />
          </div>

          <div className="designation-box2">
            <h2 className="designation-name">Time Period</h2>
            <input
              // className="designation-box"
              className="time-designation-box"
              // placeholder="DD/MM/YYYY"
              type="date"
              name="start_from"
              size="20"
              value={pro.start_from}
              onChange={handleInput}
            />

            <h2 className="designation-name-exp">To</h2>
            <input
              className="to-designation-box"
              placeholder="DD/MM/YYYY"
              type="date"
              name="end_from"
              size="20"
              value={pro.end_from}
              onChange={handleInput}
            />
            <input
              className="designation-check"
              type="checkbox"
              id="vehicle1"
              name="is_current_organization"
              onChange={handleInput}
              value={pro?.is_current_organization === true ? false : true}
              // checked={pro?.is_current_organization === true ? true : false}
            />
            <span className="checkBox"> I currently work here</span>
          </div>
          <div className="designation-box2"></div>
          <div className="educational-btn">
            <h4 onClick={deleteHandle} style={{ cursor: "pointer" }}>
              Delete
            </h4>
            <ToastContainer />
          </div>
          <button
            // onClick={closeModal}
            type="submit"
            className="save-persnol-details"
          >
            Save & Continue
            <ToastContainer />
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditProfesonalDetails;
