import React, { useState, useEffect } from "react";
import { putUpdateDetails } from "../../Api/Mentor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Address } from "../../Api/OrgApi";
import { Modal, Button } from "react-bootstrap";
import { deleteUpdateDetail } from "../../Api/Mentor";
import { ToastContainer, toast } from "react-toastify";
import MultiSelect from "react-multiple-select-dropdown-lite";
import MultipleSelect from "../PersonalDetails/Multiselect";
import "react-multiple-select-dropdown-lite/dist/index.css";
import { skillNames } from "../../Api/TalentsApi";
const MentorPersnolData = (dataP, closeModal) => {
  const [skill, setSkill] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [address, setAddress] = useState([]);
  const [data, setData] = useState({});
  const [img, setImg] = useState();
  const [value, setvalue] = useState("");

  const handleOnchange = (val) => {
    setvalue(val);

    setData({ ...data, ["key_skills"]: [value] });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };

  const handleImage = (e) => {
    const image = e.target.files[0];
    const formData1 = new FormData();
    formData1.append("image", image);
    putUpdateDetails(formData1, true).then((res) => {
      setImg(res.data?.image);
      // localStorage.setItem("token", response.data.token);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let temp = { ...data };

    temp.skills = selectedSkills.toString();

    putUpdateDetails(temp, false)
      .then((res) => {
        const response = res;
        toast("Mentor Profile Detail Update suceessfull", {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          window.location.href = "/mentor";
        }, 1000);
      })
      .catch((e) =>
        toast(e.response.data.msg, {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        })
      );
  };
  const cancel = () => {
    dataP.closeModal();
  };

  useEffect(() => {
    let skillFromBackend = dataP.dataP.key_skills || [];
    let temp2 = [];
    skillFromBackend.map((skill) => {
      temp2.push(skill);
    });
    setSelectedSkills(temp2);
    const temp = {
      // title: dataP?.dataP?.title,
      first_name: dataP?.dataP?.first_name,
      last_name: dataP?.dataP?.last_name,
      dob: dataP?.dataP?.dob,
      applicable_job_titles: dataP?.dataP?.applicable_job_titles,
      company: dataP?.dataP?.company,
      phone_number: dataP?.dataP?.phone_number,
      higher_education: dataP?.dataP?.higher_education,
      name_of_academy: dataP?.dataP?.name_of_academy,
      preferred_organisation: dataP?.dataP?.preferred_organisation,
      previous_employer: dataP?.dataP?.previous_employer,
      references: dataP?.dataP?.references,
      // key_skills: [dataP.dataP?.key_skills],
    };

    setImg(dataP?.dataP?.image);

    setData(temp);
  }, [dataP]);

  useEffect(() => {
    const loadData = () => {
      Address().then((res) => {
        const response = res;
        setAddress(response.data);
      });
    };
    loadData();
  }, []);
  useEffect(() => {
    const loadData = () => {
      skillNames().then((res) => {
        const response = res;
        let temp = response.results;

        const newArrayOfObj = temp.map(({ name: label, id: value }) => ({
          label,
          value,
        }));
        setSkillList(newArrayOfObj);
      });
    };
    loadData();
  }, []);

  const [show, setShow] = useState(false);
  const deleteData = (formData) => {
    deleteUpdateDetail(formData)
      .then((res) => {
        const response = res;
        toast("Delete suceessfull", {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 1000);
      })
      .catch((e) =>
        toast(e.response.data.msg, {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        })
      );
  };
  const deleteHandle = () => {
    const formData = data;

    deleteUpdateDetail(formData);
  };

  return (
    <div>
      <h1 className="close" onClick={cancel}>
        X
      </h1>
      <form onSubmit={handleSubmit}>
        <div className="f-profile">
          <h1 className="Personal-detail">Update Mentor Profile</h1>

          <div className="pro-details-w-arrow">
            <h4 className="Pro-details">Education</h4>

            <FontAwesomeIcon
              className="cross-circle-xIcon"
              color="red"
              icon={faTrash}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShow(true);
              }}
            />
          </div>
        </div>
        <div className="mentor-personal-profile-container">
          <div>
            <div className="details-Box">
              <h2 className="mentor-profile-pic-details-name">
                Profile Picture
              </h2>
              <img className="mentor-profile-pic" src={img} alt="" />
              <input
                type="file"
                name="image"
                id="file-input"
                onChange={handleImage}
                style={{ display: "none" }}
              />
              <label for="file-input" className="add-icon-details">
                <img src="Images/add-item.svg" alt="add-more-details" />
              </label>
            </div>

            <div className="details-Box">
              <h2 className="mentor-profile-pic-details-name">First Name</h2>
              <input
                className="mentor-persnol-details-box"
                name="first_name"
                type="text"
                value={data?.first_name}
                onChange={handleInput}
                required
              />
            </div>
            <div className="details-Box">
              <h2 className="mentor-profile-pic-details-name">Last Name</h2>
              <input
                className="mentor-persnol-details-box"
                name="last_name"
                type="text"
                value={data?.last_name}
                onChange={handleInput}
                required
              />
            </div>
          </div>
          {/* second div */}
          <div className="mentor-second-container">
            <div className="details-Box">
              <h2
                className="ment-profile-pic-details-name"
                name="key_skills"
                onChange={handleInput}
              >
                Industry
              </h2>
              <MultipleSelect
                className="persnol-details-box"
                list={skillList}
                value={selectedSkills}
                setvalue={setSelectedSkills}
              />
            </div>
            <div className="details-Box">
              <h2 className="ment-profile-pic-details-name">Date of Birth</h2>

              <input
                className="ment-persnol-details-box"
                type="date"
                name="dob"
                value={data?.dob}
                placeholder="DD/MM/YYYY"
                onChange={handleInput}
              />
            </div>
            <div className="details-Box">
              <h2 className="ment-profile-pic-details-name">Phone Number</h2>
              <input
                className="ment-persnol-details-box"
                name="phone_number"
                type="text"
                value={data?.phone_number}
                onChange={handleInput}
                required
              />
            </div>

            <div className="details-Box">
              <h2 className="ment-profile-pic-details-name">
                Applicable Job Title
              </h2>
              <input
                className="ment-persnol-details-box"
                type="text"
                name="applicable_job_titles"
                placeholder="Select"
                value={data?.applicable_job_titles}
                onChange={handleInput}
                required
              />
            </div>
            <div className="details-Box">
              <h2 className="ment-profile-pic-details-name">Company</h2>
              <input
                className="ment-persnol-details-box"
                type="text"
                name="company"
                value={data?.company}
                onChange={handleInput}
                required
              />
            </div>

            <div className="details-Box">
              <h2 className="ment-profile-pic-details-name">Location</h2>
              <select
                className="ment-persnol-details-box"
                name="location"
                value={data?.location}
                onChange={handleInput}
              >
                {address.map((value) => {
                  return <option value={value?.id}>{value?.city_name}</option>;
                })}
              </select>
            </div>
          </div>
        </div>

        <div className="mentor-qulification-container">
          <label className="mentor-qulification">
            Highest Level of Education
          </label>
          <select
            className="ment-quli-details-box"
            name="higher_education"
            value={data?.higher_education}
            onChange={handleInput}
          >
            <option value="" selected>
              Select a Education
            </option>
            <option value="10th">10th</option>
            <option value="12th">12th</option>
            <option value="diploma">Diploma</option>
            <option value="graduate">Graduate</option>
            <option value="pg">PG</option>
            <option value="doctrate">Doctrate</option>
            <option value="other">Other</option>
          </select>
        </div>

        <div className="mentor-qulification-container">
          <label className="mentor-qulification">
            Name of Academic Establishment
          </label>
          <input
            className="ment-quli-details-box"
            name="name_of_academy"
            value={data?.name_of_academy}
            onChange={handleInput}
          />
        </div>
        <div className="mentor-qulification-container">
          <label className="mentor-qulification">
            Previous Place of Employment and Job Title
          </label>
          <input
            className="ment-quli-details-box"
            name="previous_employer"
            value={data?.previous_employer}
            onChange={handleInput}
          />
        </div>

        <div className="mentor-qulification-container">
          <label className="mentor-qulification">References</label>
          <input
            className="ment-quli-details-box"
            name="references"
            value={data?.references}
            onChange={handleInput}
            required
          />
        </div>
        <button type="submit" className="save-persnol-details">
          Save & Continue
          <ToastContainer />
        </button>
      </form>
      <Modal show={show} centered={true}>
        <Modal.Header>
          <FontAwesomeIcon
            className="cross-circle-xIcon"
            icon={faCircleXmark}
            color="red"
            onClick={() => {
              setShow(false);
            }}
          />
        </Modal.Header>
        <Modal.Body>Do you want to delete</Modal.Body>
        <Modal.Footer onClick={deleteHandle}>Delete</Modal.Footer>
      </Modal>
    </div>
  );
};

export default MentorPersnolData;
