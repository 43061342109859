import React from "react";

const DetailsNavbar = ({ selected, setSelected }) => {
  const navs = [
    { key: "JOB", text: "Jobs" },
    { key: "EVENT", text: "Events" },
    { key: "COURSE", text: "Course" },
    { key: "PRODUCT", text: "Product" },
    { key: "SERVICE", text: "Service" },
    // { key: "VOLENTIER", text: "VolenTier" },
    // { key: "CONSULTING", text: "Consulting" },
  ];

  return (
    <div className="DashBoard-button">
      {navs.map(({ key, text }) => (
        <div
          key={key}
          id={selected === key ? "selected-btn" : null}
          className="DashDetails-btn"
          onClick={() => setSelected(key)}
        >
          {text}
        </div>
      ))}
    </div>
  );
};

export default DetailsNavbar;
