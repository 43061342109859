import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
const MentorOulification = ({ saveData }) => {
  const [data, setData] = useState({});
  const handleSubmit = (e) => {
    e.preventDefault();
    saveData(data);
  };
  const handleInput = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="f-profile">
        <h1 className="Personal-detail">Apply as a Mentor</h1>

        <div className="pro-details-w-arrow">
          <h4 className="Pro-details">Questionaire</h4>
          <img
            className="side-arrow"
            src="Images/SideArrow.svg"
            alt="sideArrow"
          />
        </div>
      </div>
      <div className="mentor-qulification-container">
        <label className="mentor-qulification">
          Highest Level of Education
        </label>
        <select
          className="ment-quli-details-box"
          name="higher_education"
          // value={data?.higher_education}
          onChange={handleInput}
        >
          <option value="10th">10th</option>
          <option value="12th">12th</option>
          <option value="diploma">diploma</option>
          <option value="graduate">graduate</option>
          <option value="pg">pg</option>
          <option value="doctrate">doctrate</option>
          <option value="other">other</option>
          <option value="" selected>
            Select a Education
          </option>
        </select>
      </div>

      <div className="mentor-qulification-container">
        <label className="mentor-qulification">
          Name of Academic Establishment
        </label>
        <input
          className="ment-quli-details-box"
          name="name_of_academy"
          onChange={handleInput}
          required
        />
      </div>
      <div className="mentor-qulification-container">
        <label className="mentor-qulification">
          Previous Place of Employment and Job Title
        </label>
        <input
          className="ment-quli-details-box"
          name="previous_employer"
          onChange={handleInput}
          required
        />
      </div>

      <div className="mentor-qulification-container">
        <label className="mentor-qulification">References</label>
        <input
          className="ment-quli-details-box"
          name="references"
          onChange={handleInput}
          required
        />
      </div>
      <button type="submit" className="save-qulification-persnol-details">
        Save and Continue
      </button>
    </form>
  );
};

export default MentorOulification;
