import React from "react";
import Insta from "../../Image/insta.png";
import Fb from "../../Image/fb.png";
import Twiter from "../../Image/twiter.png";
import Linkdin from "../../Image/Linkdin.png";
import { useLocation } from "react-router-dom";
import ProductDefault from "../../Image/productDefault.jpg";
import {
  FacebookShareButton,
  TwitterShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
} from "react-share";
const ProductDetail = ({ detail, applyDetail }) => {
  let state = useLocation();
  const handleClick = () => {
    alert(applyDetail.msg);
  };

  return (
    <div>
      <>
        <div className="about-main">
          <div className="About-event">
            <img
              className="about-img"
              src={
                detail?.thumbnail_images === null
                  ? ProductDefault
                  : detail?.thumbnail_images
              }
              alt="Product Thumbnail"
            />
            <h1 className="about-title">{detail?.name}</h1>
          </div>
        </div>
        <div className="about-second-container">
          <div className="about-temp">
            <div className="about-img-container">
              <img
                className="about-img1"
                src={
                  detail?.banner_image === null
                    ? ProductDefault
                    : detail?.thumbnail_images
                }
                alt="Product Banner"
              />
            </div>
            <div className="Desktop">
              <div className="Description-container">
                <h1 className="desp-header">Product Description</h1>
                <p className="desp-para">{detail?.description}</p>
              </div>
            </div>
          </div>

          <div className="right-side-container">
            <button
              className="GoEvent"
              onClick={() => window.open(detail?.website)}
            >
              Go to Product
            </button>

            <div className="date-box">
              <h1 className="date-time">Organization</h1>
              <div className="date-Container">
                <img
                  src={detail?.organization_details?.image}
                  alt="logo"
                  style={{ width: "5rem", borderRadius: "50%" }}
                />
                <h4 className="start-end-time">
                  {detail?.organization_details?.organization_name}
                </h4>
              </div>
            </div>
            <div className="location-container">
              <div className="mode-container">
                <h1 className="about-loacation">Product Category :</h1>
                <h1 className="mode">{detail?.category_name}</h1>
              </div>
            </div>

            <div className="location-container">
              <div className="mode-container">
                <h1 className="about-loacation">Product Price (INR):</h1>
                <h1 className="mode">
                  {detail?.fee === null ? "null" : detail?.fee}
                </h1>
              </div>
            </div>
            <div className="date-box">
              <h1 className="date-time">Uploaded By</h1>
              <div className="date-Container">
                <h1>Phone:</h1>
                <h4 className="start-end-time">{detail?.phone_number}</h4>
              </div>
              <div className="date-Container">
                <h1>Email:</h1>
                <h4 className="start-end-time">{detail?.email}</h4>
              </div>
              <div className="date-Container">
                <h1 className="webSite">Website:</h1>
                <h4 className="start-end-time">{detail?.website}</h4>
              </div>
            </div>

            <div className="social-container">
              <h1 className="about-social-media">Share across social media</h1>
              <div className="about-icon-container">
                <FacebookShareButton
                  url={`http://55notout.com//${state?.pathname}`}
                  quote={"Hey Check This Job"}
                  hashtag={"55NotOut"}
                  description={""}
                >
                  <img className="about-social-icons" src={Fb} alt="fb" />
                </FacebookShareButton>
                <TwitterShareButton
                  url={`http://55notout.com//${state?.pathname}`}
                  quote={"Hey Check This Job"}
                  hashtag={"55NotOut"}
                  description={""}
                >
                  <img
                    className="about-social-icons"
                    src={Twiter}
                    alt="twitter"
                  />
                </TwitterShareButton>
                <LinkedinShareButton
                  url={`http://55notout.com//${state?.pathname}`}
                  quote={"Hey Check This Job"}
                  hashtag={"55NotOut"}
                  description={""}
                >
                  <img
                    className="about-social-icons"
                    src={Linkdin}
                    alt="linkdin"
                  />
                </LinkedinShareButton>
              </div>
            </div>
          </div>
        </div>

        <div className="abt-down-btn">
          <button
            className="GoEvent2"
            onClick={() => window.open(detail?.website)}
          >
            Go to Product
          </button>
        </div>
      </>
    </div>
  );
};

export default ProductDetail;
