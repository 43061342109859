import React, { useState, useEffect } from "react";
import { listMentors } from "../../Api/Mentor";
import { NavLink, useNavigate } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import { ToastContainer, toast } from "react-toastify";
const MentorListings = () => {
  const popUp = () => {
    toast("Please Login To Continue", {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });

    <ToastContainer />;
  };
  const featurePopup = () => {
    toast("you are not Feature Account", {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const [list, setList] = useState([]);
  useEffect(() => {
    const loadData = () => {
      listMentors().then((res) => {
        const response = res;
        setList(response);
      });
    };
    loadData();
  }, []);
  const navigate = useNavigate();
  const executeOnClick = (isExpanded) => {};
  return (
    <div>
      <div className="mantor-list-box">
        {list.map((value) => {
          return (
            <div className="list-mentor-list-container">
              <div className="list-image-container">
                <img
                  className="list-mentor-image"
                  src={value?.image}
                  alt="mentor-pic"
                />
              </div>
              <div className="mentor-content-box">
                <div className="btnWithcontent">
                  <div>
                    <h1 className="list-mentor-head">
                      {value?.first_name} {value?.last_name}
                    </h1>
                    <h6 className="list-mentor-title">
                      CEO at {value?.company}
                    </h6>
                  </div>
                  {localStorage.getItem("TalentToken") ||
                  localStorage.getItem("token") ? (
                    <NavLink
                      to={`/mentorSlotBooking/${value?.slug}`}
                      className="list-mentor-button"
                    >
                      View Full Profile
                    </NavLink>
                  ) : (
                    <NavLink
                      to=""
                      className="list-mentor-button"
                      onClick={popUp}
                    >
                      View Full Profile
                      <ToastContainer />
                    </NavLink>
                  )}
                </div>
                {
                  <ShowMoreText
                    lines={3}
                    more="Show more"
                    less="Show less"
                    className="list-mentor-para"
                    anchorclassName="my-anchor-css-class"
                    onClick={() =>
                      navigate(
                        localStorage.getItem("TalentToken") ||
                          localStorage.getItem("token")
                          ? `/mentorSlotBooking/${value?.slug}`
                          : ""
                      )
                    }
                    expanded={false}
                    width={0}
                    truncatedEndingComponent={"... "}
                  >
                    {value?.references}
                  </ShowMoreText>
                }
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MentorListings;
