import React, { useState, useEffect } from "react";
import personalDetails from "../../Image/personalDetails.svg";
import { getUpdateDetails } from "../../Api/Mentor";
const MentorProfileDetails = () => {
  const [update, setUpdate] = useState([]);
  useEffect(() => {
    const loadData = () => {
      getUpdateDetails().then((res) => {
        const response = res.data;

        setUpdate(response);
      });
    };
    loadData();
  }, []);
  return (
    <div>
      <div className="personal-details">
        <img
          className="icon-details"
          src={personalDetails}
          alt="personalDetails"
        />

        <h2 className="personal-details-title">Mentor Details</h2>
      </div>
      <div>
        <div className="border-line"></div>
        <h4 className="user-designation">{update?.title}</h4>
        <h4 className="user-company">{update?.company}</h4>
        <h4 className="user-work-time">{update?.applicable_job_titles}</h4>
        <h4 className="user-company">{update?.higher_education}</h4>
        <h4 className="user-company">{update?.location?.city_name}</h4>
        <h4 className="user-company">{update?.previous_employer}</h4>
        <h4 className="user-company">{update?.references}</h4>
      </div>
    </div>
  );
};

export default MentorProfileDetails;
