import axios from "axios";
const baseUrl = "https://admin.55notout.com/";
const EventBanner = () => {
  const slug = "eventListingAndDetailsModule/banner/";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const FeatureEvent = () => {
  const slug =
    "eventListingAndDetailsModule/all_events_list/?is_featured_post=true";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const EventDetails = ({ slug }) => {
  const urlPath = "eventListingAndDetailsModule/event_details/";
  const url = `${baseUrl}${urlPath}${slug}`;

  const token = localStorage.getItem("TalentToken");
  try {
    const response = axios
      .get(url, {
        headers: {
          // Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MywiZXhwIjoxNjU3MjgwNjcxLCJ1c2VybmFtZSI6InB5c2F1bmRhcnlfeWFob28ifQ.GWab7rOFGcfXVVARrDKVuDXCH0ObaNzNfCXeiuCNwoQ"}`,
          Authorization: `bearer ${localStorage.getItem("TalentToken")}`,
        },
      })
      .then((response) => {
        return response.data;
      });
    return response;
  } catch (error) {
    return error;
  }
};
const ApplyEvent = ({ slug }) => {
  const urlPath = "eventListingAndDetailsModule/apply_on_event/";
  const url = `${baseUrl}${urlPath}${slug}`;

  const token = localStorage.getItem("TalentToken");

  const response = fetch(url, {
    headers: {
      // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MywiZXhwIjoxNjU3MjgwNjcxLCJ1c2VybmFtZSI6InB5c2F1bmRhcnlfeWFob28ifQ.GWab7rOFGcfXVVARrDKVuDXCH0ObaNzNfCXeiuCNwoQ`,
      Authorization: `bearer ${localStorage.getItem("TalentToken")}`,
    },
  })
    .then((e) => e.json())
    .then((e) => e);

  return response;
};
const ListEvent = () => {
  const slug =
    "eventListingAndDetailsModule/all_events_list/?is_featured_post=false";
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios.get(url).then((response) => {
      return response.data;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const AllEvents = (filter) => {
  const slug = `eventListingAndDetailsModule/all_events_list/`;
  const url = `${baseUrl}${slug}`;
  try {
    const response = axios
      .get(url, {
        params: filter,
      })
      .then((response) => {
        return response.data;
      });
    return response;
  } catch (error) {
    return error;
  }
};
export {
  EventBanner,
  FeatureEvent,
  ListEvent,
  EventDetails,
  ApplyEvent,
  AllEvents,
};
