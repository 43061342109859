import React, { useState } from "react";
import "../../Style/Jobposting/Features.css";
import { Modal } from "react-bootstrap";
import FeatureModal from "../ServicePost/FeatureModal";
const FeaturesPosting = ({ data3, handleInput, data, edit }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const handleSuceesClose = () => setIsSuccess(false);
  const handleSuceesShow = () => {
    setIsSuccess(true);
  };
  return (
    <>
      <div className="e-features-posting">
        <div className="features-posting-container">
          <h1 className="features-posting-title">{data3.title}</h1>
        </div>
        <div className="features-check-box" onChange={handleInput}>
          <div className="free">
            <input
              className="f-check-box"
              type="checkbox"
              name="is_featured_post"
              value={"true"}
              checked={edit?.is_featured_post === "true"}
            />
            <span className="f-checkBox">{data3.free}</span>
          </div>
          <div className="feature">
            <input
              className="f-check-box"
              type="checkbox"
              name="is_featured_post"
              value={"false"}
              onClick={handleSuceesShow}
            />
            <span className="f-checkBox">{data3.features}</span>
          </div>
        </div>
      </div>

      <Modal centered={true} show={isSuccess}>
        <FeatureModal closed={handleSuceesClose} />
      </Modal>
    </>
  );
};

export default FeaturesPosting;
