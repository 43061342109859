import React, { useState, useEffect } from "react";
import AboutDetails from "../../Component/CourseDetails.jsx/AboutDetails";
import { useParams } from "react-router-dom";
import { FeatureCourseDetails, FeatureCourseApply } from "../../Api/Course";
import { Helmet } from "react-helmet";
const CourseDetails = () => {
  let { slug } = useParams();

  const [featuredDetails, setFeaturedDetails] = useState([]);
  const [detailsApply, setDetailsApply] = useState([]);
  useEffect(() => {
    const loadData = () => {
      FeatureCourseDetails({ slug }).then((res) => {
        const response = res;

        setFeaturedDetails(response.data);
      });
    };
    loadData();
  }, [slug]);

  useEffect(() => {
    const loadData = () => {
      FeatureCourseApply({ slug }).then((res) => {
        const response = res;

        setDetailsApply(response.data);
      });
    };
    loadData();
  }, [slug]);
  return (
    <div>
      <Helmet>
        <title>55notout | Courses Details</title>
        <meta name="description" content="Courses Details page" />
      </Helmet>
      <AboutDetails
        featuredDetails={featuredDetails}
        detailsApply={detailsApply}
      />
    </div>
  );
};

export default CourseDetails;
