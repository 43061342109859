import React from "react";
// import LogIn from "../../Component/Login/LogIn";
import LogInPage from "../../Component/LoginPage/LoginPage";
const LoginPage = () => {
  return (
    <div>
      <LogInPage />
    </div>
  );
};

export default LoginPage;
