import React from "react";
import { useEffect } from "react";
import { createContext } from "react";

export const AuthContext = createContext(null);
function AuthContextProvider(props) {
  const [isTalentAuthed, setIsTalentAuthed] = React.useState(false);
  const [isOrgAuthed, setIsOrgAuthed] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const talentToken = localStorage.getItem("TalentToken");
    const orgToken = localStorage.getItem("Token");
    if (talentToken) {
      setIsTalentAuthed(true);
      setIsLoading(false);
    } else if (orgToken) {
      setIsOrgAuthed(true);
      setIsLoading(false);
    }
  }, []);
  return (
    <AuthContext.Provider value={{ isLoading, isOrgAuthed, isTalentAuthed }}>
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;
