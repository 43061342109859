import React, { useState, useEffect } from "react";
import RightJobs from "../../Component/FullTime-jobs/RightJobs";
import FeaturedJobsProfile from "../../Component/FullTime-jobs/FeaturedJobsProfile";
import Listing from "../../Component/FullTime-jobs/Listing";
import Pagination from "../../Component/FullTime-jobs/Pagination";
import { JobBanner, FeatureJob, ListJob } from "../../Api/JobApi";
import { Helmet } from "react-helmet";
import { Navbar } from "../../Component/Navbar";
import AllListingBtn from "../../Component/AllListingBtn";
const FullTimeJobs = () => {
  const [jobsBanner, setJobsBanner] = useState({});
  useEffect(() => {
    const loadData = () => {
      JobBanner().then((res) => {
        const response = res;
        setJobsBanner(response.data);
      });
    };
    loadData();
  }, {});
  const [featured, setFeatured] = useState([]);
  useEffect(() => {
    const loadData = () => {
      FeatureJob().then((res) => {
        const response = res;

        setFeatured(response);
      });
    };
    loadData();
  }, []);
  const [list, setList] = useState([]);
  useEffect(() => {
    const loadData = () => {
      ListJob().then((res) => {
        const response = res;
        setList(response);
      });
    };
    loadData();
  }, []);
  const [showPerPage, setShowPerPage] = useState(12);
  const [paginat, setPaginat] = useState({
    start: 0,
    end: showPerPage,
  });
  const PaginationHandle = (start, end) => {
    setPaginat({ start: start, end: end });
  };

  const data = {
    text: "View all Job",
    link: `/Job`,
  };
  return (
    <div>
      <Helmet>
        <title>55notout | Full-Time-Jobs</title>
        <meta name="description" content="Explore Featured Jobs" />
      </Helmet>
      <Navbar />
      <RightJobs jobs={jobsBanner} />
      <FeaturedJobsProfile featured={featured} paginat={paginat} />

      <Listing listJob={list} paginat={paginat} />
      {list.length >= 13 ? (
        <Pagination
          showPerPage={showPerPage}
          PaginationHandle={PaginationHandle}
          total={list?.length}
        />
      ) : (
        ""
      )}
      <AllListingBtn data={data} />
    </div>
  );
};

export default FullTimeJobs;
