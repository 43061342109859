import React, { useState, useEffect } from "react";
import FeaturesProfile from "../../Component/DashBoard/FeaturesProfile";
import { Helmet } from "react-helmet";

import { Navbar } from "../../Component/Navbar";
const Dashboard = () => {
  return (
    <div>
      <Helmet>
        <title>55notout | Talent DashBoard</title>
        <meta name="description" content="Talents Persnoal Profile" />
      </Helmet>
      <Navbar />
      <FeaturesProfile />
    </div>
  );
};

export default Dashboard;
