import React, { useState, useEffect } from "react";
import Carousel from "react-elastic-carousel";
import "../../Style/MentorList/Mentorlist.css";
import { NavLink, useNavigate } from "react-router-dom";
import { featureMentor } from "../../Api/Mentor";
import { ToastContainer, toast } from "react-toastify";
import ShowMoreText from "react-show-more-text";
const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 2500, itemsToShow: 2, itemsToScroll: 2 },
  { width: 3500, itemsToShow: 3 },
  { width: 4500, itemsToShow: 4 },
];
const MentorFeatures = () => {
  const [features, setFeatures] = useState([]);
  const navigate = useNavigate();
  const popUp = () => {
    toast("Please Login To Continue", {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const featurePopup = () => {
    toast("you are not Feature Account", {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });
  };
  useEffect(() => {
    const loadData = () => {
      featureMentor().then((res) => {
        const response = res;
        setFeatures(response);
      });
    };
    loadData();
  }, []);
  const myArrow = ({ onClick, isEdge }) => {
    return <button onClick={onClick} disabled={isEdge}></button>;
  };
  return (
    <div>
      <div className="SeviceProvide">
        <h4 className="service"> Featured Mentor</h4>
        <h1 className="provides">Book your favourite mentor with us</h1>
      </div>
      <Carousel breakPoints={breakPoints} renderArrow={myArrow}>
        {features.map((value) => {
          return (
            <div className="mentor-list-container">
              <div className="image-container">
                <img
                  className="mentor-image"
                  src={value?.image}
                  alt="mentor-pic"
                />
              </div>

              <div className="mentor-first-container">
                <h1 className="mentor-head">
                  {value?.first_name} {value?.last_name}
                </h1>
                <h6 className="mentor-title">CEO at {value?.company}</h6>

                {localStorage.getItem("TalentToken") ||
                localStorage.getItem("token") ? (
                  <ShowMoreText
                    lines={3}
                    more="Show more"
                    less="Show less"
                    className="Job-Desp"
                    anchorclassName="my-anchor-css-class"
                    onClick={() =>
                      localStorage.getItem("feature_mentor") === "true"
                        ? navigate(`/mentorSlotBooking/${value?.slug}`)
                        : featurePopup()
                    }
                    expanded={false}
                    expandByClick={false}
                    width={0}
                    truncatedEndingComponent={"... "}
                  >
                    {value?.references}
                  </ShowMoreText>
                ) : (
                  <ShowMoreText
                    lines={3}
                    more="Show more"
                    less="Show less"
                    className="mentor-para"
                    anchorclassName="my-anchor-css-class"
                    onClick={() =>
                      localStorage.getItem("TalentToken")
                        ? navigate(`/mentorSlotBooking/${value?.slug}`)
                        : popUp()
                    }
                    expanded={false}
                    expandByClick={false}
                    width={0}
                    truncatedEndingComponent={"... "}
                  >
                    {value?.references}
                  </ShowMoreText>
                )}
                <div className="mentor-buton-container">
                  {localStorage.getItem("TalentToken") ? (
                    <NavLink to={`/mentorSlotBooking/${value?.slug}`}>
                      <button className="mentor-button">
                        View Full Profile
                      </button>
                    </NavLink>
                  ) : (
                    <NavLink
                      to=""
                      onClick={
                        localStorage.getItem("TalentToken")
                          ? featurePopup
                          : popUp
                      }
                    >
                      <button className="mentor-button">
                        View Full Profile
                      </button>
                    </NavLink>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </Carousel>
      <div className="SeviceProvide">
        <h4 className="service">All Mentors</h4>
      </div>
    </div>
  );
};

export default MentorFeatures;
