import React, { useState, useEffect } from "react";
import "../Style/Available.css";
import { Highlights } from "../Api/api";

const Availability = () => {
  const [highLight, setHighLight] = useState([]);
  useEffect(() => {
    const loadData = () => {
      Highlights().then((res) => {
        const response = res;
        setHighLight(response.data);
      });
    };

    loadData();
  }, []);
  return (
    <div className="available">
      {highLight.map((values) => {
        return (
          <div>
            <img
              className="availimg"
              src={values.image}
              alt="AvailableMentors"
              // width="50px"
            />
            <h4 className="availContent">{values.title}</h4>
            <h6 className="availText">{values.description}</h6>
          </div>
        );
      })}
    </div>
  );
};

export default Availability;
