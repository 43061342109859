import React, { useState } from "react";
import ExpandIcon from "../../Image/expand.svg";
import CollaspeIcon from "../../Image/collaspe.svg";

const Faq = () => {
  const [isExpand, setIsExpand] = useState(false);
  const [isExpand2, setIsExpand2] = useState(false);
  const [isExpand3, setIsExpand3] = useState(false);
  const [isExpand4, setIsExpand4] = useState(false);
  const [isExpand5, setIsExpand5] = useState(false);
  const [isExpand6, setIsExpand6] = useState(false);

  return (
    <div className="Faq_container" id="FAQs">
      <div className="FaqTitle">Frequently asked questions</div>
      <div className="faq-card">
        <div className="faq_top">
          <p className="Faq_Question">How does the mentor programme work?</p>
          <img
            onClick={() => setIsExpand(!isExpand)}
            src={isExpand ? ExpandIcon : CollaspeIcon}
            alt=""
            className="ExpandIcon"
          />
        </div>
        <div className="bottom">
          {isExpand ? (
            <p className="Faq_Answer">
              People are looking for mentors who can impart specific knowledge
              and expertise which contributes to protege learning and skill
              development. Finding a good mentor is important for building a
              successful career and you would like to mentor. 55notout is just a
              way to connect two sides and make sure the experiences go well and
              smooth every time. At 55notout, you'll get a mentoring profile
              with the mentoring packages that you are ready to offer.
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="faq-card">
        <div className="faq_top">
          <p className="Faq_Question">How much time mentor need to invest?</p>
          <img
            onClick={() => setIsExpand2(!isExpand2)}
            src={isExpand2 ? ExpandIcon : CollaspeIcon}
            alt=""
            className="ExpandIcon"
          />
        </div>
        <div className="bottom">
          {isExpand2 ? (
            <p className="Faq_Answer">
              We understand that you are busy so our program is designed around
              efficiency. The time you invest on mentoring will be under your
              control.
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="faq-card">
        <div className="faq_top">
          <p className="Faq_Question">What's expected from a mentor?</p>
          <img
            onClick={() => setIsExpand3(!isExpand3)}
            src={isExpand3 ? ExpandIcon : CollaspeIcon}
            alt=""
            className="ExpandIcon"
          />
        </div>
        <div className="bottom">
          {isExpand3 ? (
            <p className="Faq_Answer">
              Mentees come with various expectations. Successful mentor-mentee
              relationships should be fulfilling and beneficial for all
              involved. Help your mentee set realistic expectations.
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="faq-card">
        <div className="faq_top">
          <p className="Faq_Question">Who are the mentees?</p>
          <img
            onClick={() => setIsExpand4(!isExpand4)}
            src={isExpand4 ? ExpandIcon : CollaspeIcon}
            alt=""
            className="ExpandIcon"
          />
        </div>
        <div className="bottom">
          {isExpand4 ? (
            <p className="Faq_Answer">
              Mentees come from all backgrounds. Our mentees are mostly industry
              professionals who are looking to grow or are opting for a career
              change. A mentee maybe a person who wants to learn new skills to
              achieve his/ her goals. Some of them might be working on their own
              product and startups.
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="faq-card">
        <div className="faq_top">
          <p className="Faq_Question">
            How much money would the Mentees be charged?
          </p>
          <img
            onClick={() => setIsExpand5(!isExpand5)}
            src={isExpand5 ? ExpandIcon : CollaspeIcon}
            alt=""
            className="ExpandIcon"
          />
        </div>
        <div className="bottom">
          {isExpand5 ? (
            <p className="Faq_Answer">
              In addition to the fees entered by a mentor, the mentees will also
              be charged a total of 18% GST and a 55notout platform convenience
              fees.
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
      {/* <div className="faq-card">
        <div className="faq_top">
          <p className="Faq_Question">How do you make money?</p>
          <img
            onClick={() => setIsExpand6(!isExpand6)}
            src={isExpand6 ? ExpandIcon : CollaspeIcon}
            alt=""
            className="ExpandIcon"
          />
        </div>
        <div className="bottom">
          {isExpand6 ? (
            <p className="Faq_Answer">
              We only get paid if you do! We don't take a cut – once you set up
              your price, we'll calculate a fee along the lines of 20% and put
              it on top. Once you have your mentees, we just split the payment
              and commission.
            </p>
          ) : (
            ""
          )}
        </div>
      </div> */}
    </div>
  );
};

export default Faq;
