import React, { useState, useEffect } from "react";
import RightEvents from "../../Component/Events/RightEvents";
import FeaturesEventsProfile from "../../Component/Events/FeaturesEventsProfile";
import EventListing from "../../Component/Events/EventListing";
import { EventBanner, FeatureEvent, ListEvent } from "../../Api/EventApi";
import Pagination from "../../Component/FullTime-jobs/Pagination";
import { Helmet } from "react-helmet";
import { Navbar } from "../../Component/Navbar";
import AllListingBtn from "../../Component/AllListingBtn";
const AllEvents = () => {
  const [eventBanner, setEventBanner] = useState({});
  useEffect(() => {
    const loadData = () => {
      EventBanner().then((res) => {
        const response = res;
        setEventBanner(response.data);
      });
    };
    loadData();
  }, {});
  const [featured, setFeatured] = useState([]);
  useEffect(() => {
    const loadData = () => {
      FeatureEvent().then((res) => {
        const response = res;
        setFeatured(response);
      });
    };
    loadData();
  }, []);
  const [list, setList] = useState([]);
  useEffect(() => {
    const loadData = () => {
      ListEvent().then((res) => {
        const response = res;
        setList(response);
      });
    };
    loadData();
  }, []);
  const [showPerPage, setShowPerPage] = useState(12);
  const [paginat, setPaginat] = useState({
    start: 0,
    end: showPerPage,
  });
  const PaginationHandle = (start, end) => {
    setPaginat({ start: start, end: end });
  };
  const data = {
    text: "View all Events",
    link: `/Event`,
  };
  return (
    <div>
      <Helmet>
        <title>55notout | Events</title>
        <meta name="description" content="Explore Our Events" />
      </Helmet>
      <Navbar />
      <RightEvents event={eventBanner} />
      <FeaturesEventsProfile featured={featured} />
      <EventListing list={list} paginat={paginat} />
      {list.length >= 13 ? (
        <Pagination
          showPerPage={showPerPage}
          PaginationHandle={PaginationHandle}
          total={list?.length}
        />
      ) : (
        ""
      )}
      <AllListingBtn data={data} />
    </div>
  );
};

export default AllEvents;
