import React, { useState, useEffect } from "react";
import "../../Style/Dashboard/Application.css";
import Profesonal from "../../Image/DashboardIcon.jpeg";
import { jobHistory } from "../../Api/TalentsApi";
const ApplicationDetails = () => {
  const [job, setJob] = useState([]);

  useEffect(() => {
    const loadData = () => {
      jobHistory().then((res) => {
        const response = res;
        setJob(response.results);
      });
    };
    loadData();
  }, []);
  return (
    <div>
      {job?.map((value) => {
        return (
          <div>
            <div className="personal-details">
              <img
                className="app-icon-details"
                src={Profesonal}
                alt="personalDetails"
              />

              <h2 className="application-post">{value?.job_details?.title}</h2>

              {value.is_shortlisted === true &&
              value.is_rejeted === true &&
              value.is_active === true ? (
                <button>undefined</button>
              ) : value.is_shortlisted === true &&
                value.is_rejeted === false &&
                value.is_active === true ? (
                <button className="shortlist">shortlist</button>
              ) : value.is_shortlisted === false &&
                value.is_rejeted === true &&
                value.is_active === true ? (
                <button className="shortlist" style={{ background: "#FF3F33" }}>
                  Rejected
                </button>
              ) : value.is_shortlisted === false &&
                value.is_rejeted === false &&
                value.is_active === true ? (
                <button className="shortlist" style={{ background: "#FFC107" }}>
                  Position On Hold
                </button>
              ) : value.is_shortlisted === false &&
                value.is_rejeted === false &&
                value.is_active === false ? (
                <button className="shortlist" style={{ background: "#808080" }}>
                  jobClose
                </button>
              ) : (
                "  "
              )}
            </div>
            <div className="border-line"></div>
            <div>
              <h4 className="appliction-company">
                {value?.job_details?.organization}
              </h4>
              <h4 className="job-way">{value?.job_details?.job_type}</h4>
              <h4 className="application-company-loaction">
                {value?.job_details?.location}
              </h4>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ApplicationDetails;
