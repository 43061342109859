import React from "react";
import Static from "../../Component/StaicPage/Static";
import { Helmet } from "react-helmet";
const StaticsPages = () => {
  // var new_str = str.replace(/-/g, "");
  return (
    <div>
      <Helmet>
        <title>55notout | {window.location.pathname.replace("/p/", "")}</title>
        <meta
          name="description"
          content="Explore Our New Jobs,Course,Events,Product,Service and more"
        />
      </Helmet>
      <Static />
    </div>
  );
};

export default StaticsPages;
