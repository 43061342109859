import React, { useState } from "react";
import "../../Style/AllDetails/Profesanol.css";
import { createJobPost } from "../../Api/TalentsApi";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
const ProfesonalDetails = ({ closeModal }) => {
  const [data, setData] = useState({
    company_name: "",
    start_from: "",
    end_from: "",
    title: "",
    course: "",
    // salary: "",
    salary_disclose: false,
    is_current_organization: false,
    user: 0,
  });
  const AddProfesionalData = (formData) => {
    createJobPost(formData)
      .then((res) => {
        const response = res;

        toast("Professional Detail Add suceessfull", {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 1000);
      })
      .catch((e) =>
        e.response.status === 400
          ? toast(" Bad Request", {
              autoClose: 500,
              position: toast.POSITION.TOP_RIGHT,
            })
          : ""
      );
  };
  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setData({ ...data, [name]: value });
  };
  // let name, value;
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = { ...data };

    AddProfesionalData(formData);
  };
  const close = () => {
    closeModal();
  };
  return (
    <div>
      <h6 className="cancelModel" onClick={close}>
        x
      </h6>
      <div className="f-profile">
        <h1 className="Personal-detail">Professional Details</h1>
        <div className="pro-details-w-arrow">
          <h4 className="Pro-details">Educational Details</h4>
          <img
            className="side-arrow"
            src="Images/SideArrow.svg"
            alt="sideArrow"
          />
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="designation-box1">
          <div className="designation-name">
            <h2>Designation</h2>
          </div>
          <input
            className="designation-box"
            placeholder="Type here"
            type="text"
            name="title"
            size="50"
            value={data.title}
            onChange={handleInput}
            required
          />
        </div>
        <div className="designation-box1">
          <div className="designation-name">
            <h2>Organization</h2>
          </div>
          <input
            className="designation-box"
            placeholder="Type here"
            type="text"
            name="company_name"
            size="50"
            value={data.company_name}
            onChange={handleInput}
          />
        </div>

        <div className="designation-box2">
          <h2 className="designation-name">Time Period</h2>
          <input
            className="time-designation-box"
            // className="designation-box"
            placeholder="MM"
            type="date"
            name="start_from"
            size="20"
            value={data.start_from}
            onChange={handleInput}
            // value={moment(data.start_from).format("YYYY")}
          />

          <h2 className="designation-name-exp">To</h2>
          <input
            className="to-designation-box"
            placeholder="MM"
            type="date"
            name="end_from"
            size="20"
            value={data.end_from}
            onChange={handleInput}
            // value={moment(data.end_from).format("YYYY")}
          />
          <input
            className="designation-check"
            type="checkbox"
            id="vehicle1"
            name="is_current_organization"
            onChange={handleInput}
            value={data?.is_current_organization === true ? false : true}
            // checked={pro?.is_current_organization === true ? true : false}
          />
          <span className="checkBox"> I currently work here</span>
        </div>

        <div className="designation-box2"></div>

        <button type="submit" className="save-persnol-details">
          Save & Continue
          <ToastContainer />
        </button>
      </form>
    </div>
  );
};

export default ProfesonalDetails;
