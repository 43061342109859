import React from "react";
import ApplicationVeiw from "../../Component/OrgApplicationView/ApplicationVeiw";
const ApplicationView = () => {
  return (
    <div>
      <ApplicationVeiw />
    </div>
  );
};

export default ApplicationView;
