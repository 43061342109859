import React, { useState, useRef, useEffect } from "react";
import { AuthOrgRegister } from "../../Api/OrgApi";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Address } from "../../Api/OrgApi";
import { ToastContainer, toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
const OrgSignup = () => {
  const [orgLocation, setOrgLocation] = useState("");
  const [captchValue, setCaptchValue] = useState(false);
  const [address, setAddress] = useState([]);
  const [error, setError] = useState({ open: false, msg: "" });
  const name = useRef();
  const email = useRef();
  const phone = useRef();
  const designation = useRef();
  const organisation = useRef();
  const location = useRef();
  const password = useRef();
  const cnfrmPassword = useRef();

  const submitData = (e) => {
    e.preventDefault();
    setError({ open: false, msg: "" });

    const firstName = name.current.value;
    const phones = phone.current.value;
    const designations = designation.current.value;
    const Organisations = organisation.current.value;
    const locations = location.current.value;
    const signemail = email.current.value;
    const password1 = password.current.value;
    const password2 = cnfrmPassword.current.value;

    if (password1 !== password2) {
      setError({ open: true, msg: "password does not match" });
      return;
    } else if (password1.length < 8) {
      setError({ open: true, msg: "password must shoud be 8 digit" });
      return;
    }

    const data = {
      first_name: firstName,
      email: signemail,
      phone_number: phones,
      designation: designations,
      organization_name: Organisations,
      location: locations,
      password: password1,
    };

    // Auth Resister Api
    AuthOrgRegister(data)
      .then((res) => {
        const response = res;
        if (response?.status === 200) {
          toast("SignUp SuccesFull", {
            autoClose: 500,
            position: toast.POSITION.TOP_RIGHT,
          });
          setTimeout(() => {
            window.location.href = "/organisation";
          }, 1000);
        } else if (response?.status === 400) {
          alert("Login failed");
        } else if (response?.status === 500) {
          alert("Server Error");
        } else {
          alert("Failed");
        }
        // localStorage.setItem("token", response.data.token);
      })
      .catch(() => {
        toast(e?.response?.data?.msg, {
          autoClose: 500,
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  // Address
  useEffect(() => {
    const loadData = () => {
      Address().then((res) => {
        const response = res;

        setAddress(response.data);
      });
    };
    loadData();
  }, []);

  address.map((value) => value);
  function onChange(value) {
    if (value) {
      setCaptchValue(true);
    } else {
      setCaptchValue(false);
    }
  }
  return (
    <div className="signIn-display-white-box">
      <div className="Signin-display">
        <div className="Sign-Container">
          <NavLink to="/">
            <img className="Sign-page-header" src="Icons/logo.png" alt="logo" />
          </NavLink>

          <div className="sign-header-main"></div>
          <h1 className="Sign-in">New Organisation Register</h1>
          <div id="warning" className="form"></div>

          <form onSubmit={submitData}>
            <div className="Name-box">
              <div className="Sign-Input_box">
                <input
                  className="Sign-email"
                  type="text"
                  placeholder="Name"
                  ref={name}
                  required
                />
                <input
                  className="Sign-password"
                  type="email"
                  placeholder="Work Email Address"
                  ref={email}
                  required
                />
                <input
                  className="Sign-password"
                  type="text"
                  placeholder="Phone"
                  ref={phone}
                  required
                />
                <select className="Sign-password">
                  {address.map((value) => {
                    return (
                      <option value={value.id} ref={location}>
                        {value.city_name}
                      </option>
                    );
                  })}
                </select>

                <input
                  className="Sign-password"
                  type="text"
                  placeholder="Designation"
                  ref={designation}
                  required
                />
                <input
                  className="Sign-password"
                  type="text"
                  placeholder="Organisation"
                  ref={organisation}
                  required
                />

                <input
                  className="Sign-password"
                  type="password"
                  placeholder="Password"
                  ref={password}
                  required
                />

                <input
                  className="Sign-cnfrm-password"
                  type="password"
                  placeholder="Confirm Password"
                  ref={cnfrmPassword}
                  required
                />
                {error.open && (
                  <p className="error" style={{ color: "red" }}>
                    {error.msg}
                  </p>
                )}
              </div>
            </div>
            <div className="reCaptcha">
              <ReCAPTCHA
                sitekey="6LcKk5IjAAAAAOILTl5EhutetFTwrAOxNjC0fBh-"
                onChange={onChange}
              />
            </div>
            {captchValue === true ? (
              <button type="submit" className="SignPage-btn">
                REGISTER
                <FontAwesomeIcon className="icon" icon={faAngleRight} />
                <ToastContainer />
              </button>
            ) : (
              <button type="submit" className="SignPage-btn" disabled>
                REGISTER
                <FontAwesomeIcon className="icon" icon={faAngleRight} />
                <ToastContainer />
              </button>
            )}
          </form>

          <div className="resgister-box">
            <h6 className="Alredy-register">Already Registered ?</h6>
            <h6 className="login-create-link">Login</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrgSignup;
