import React, { useState, useEffect } from "react";
import { persnol } from "../../Api/TalentsApi";
import Personal from "../PersonalDetails/Personal";
import Modal from "react-modal";
import { persnolPut } from "../../Api/TalentsApi";
const PersnolDetails = () => {
  const [personalData, setPersonalData] = useState({});

  const [cv, setCv] = useState();
  const [data, setData] = useState(false);
  let subtitle;

  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }
  function closeModal() {
    setIsOpen(false);
    // loadData();
  }
  // useEffect(() => {
  //   loadData();
  // }, [modalIsOpen]);

  useEffect(() => {
    const loadData = () => {
      persnol().then((res) => {
        const response = res;
        setPersonalData(response.data);
      });
    };
    loadData();
  }, [modalIsOpen, data]);

  // const loadData = () => {
  //   persnol().then((res) => {
  //     const response = res;
  //     setPersonalData(response.data);
  //   });
  // };

  const handleImage = (e) => {
    const user_cv = e.target.files[0];
    const formData1 = new FormData();
    formData1.append("user_cv", user_cv);
    persnolPut(formData1, true).then((res) => {
      setCv(res);
      setData(!data);
    });
  };

  const resume = personalData?.user_cv?.split("?")[0]?.split("/")[7];
  useEffect(() => {
    const loadData = () => {};
    loadData();
  }, [personalData?.user_cv]);

  return (
    <>
      <div className="user-profile-container">
        <img className="user-profile" src={personalData?.image} alt="profile" />

        <div className="edit-profile">
          <h4 className="profile-name">
            {personalData?.user?.first_name} {personalData?.user?.last_name}
          </h4>
          <img
            className="user-edit"
            src="Icons/edit.svg"
            alt="edit"
            onClick={openModal}
          />
        </div>
        <h4 className="user-gmail">{personalData?.user?.email}</h4>
        <h4 className="user-phone">{personalData?.phone_numer}</h4>
        <div className="user-icons">
          <img
            style={{ cursor: "pointer" }}
            src="Icons/linkdin-blue.svg"
            alt="linkdin"
            onClick={() => {
              window.open(personalData?.linkedin_profile);
            }}
          />
          <img
            style={{ cursor: "pointer" }}
            src="Icons/twiter-blue.svg"
            alt="twiter"
            onClick={() => {
              window.open(personalData?.twitter);
            }}
          />
        </div>
        <div className="resume-upload">
          <a href={personalData?.user_cv} style={{ textDecoration: "none" }}>
            <h1 className="resume">{resume}</h1>
          </a>
          <input
            type="file"
            id="file-input2"
            style={{ display: "none" }}
            name="user_cv"
            onChange={handleImage}
          />
        </div>
        <div className="upload">
          <img className="upload-icon" src="Icons/upload.svg" alt="edit" />

          <label for="file-input2">Upload</label>
        </div>
        <div className=" user-skill-container">
          <div>
            <h2 className="user-skill">Skills:</h2>
          </div>
          <div>
            {personalData?.skills?.map((value) => {
              return (
                <div className="pesonalSkills">
                  <ul>
                    <li>{value?.name},</li>
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <Personal dataP={personalData} closeModal={closeModal} />
      </Modal>
    </>
  );
};

export default PersnolDetails;
