import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { mentorSlotList, joinSlotPost } from "../../Api/Mentor";
import "../../Style/MentorAvailbility/MentorSlotBooking.css";
import moment from "moment";
import {
  createSlotPayment,
  slotBookingOrder,
  gstPayment,
} from "../../Api/PaymentGetway";
import { displayRazorpay } from "../../Utill/slotUtility";
import { ToastContainer, toast } from "react-toastify";
const SlotBook = ({ closed }) => {
  let { slug } = useParams();

  const [join, setJoin] = useState({
    slug: "",
  });
  const [book, setBook] = useState([]);
  const [gstPayments, setGstPayments] = useState({});
  var api_1_data = {};
  var api_2_data = {};
  const closeSlotList = () => {
    SlotBook({ closed });
  };
  async function handleClick(value) {
    const data = {
      slot: value,
    };
    await slotBookingOrder(data).then((res) => {
      const response = res;

      api_1_data = response.data;
    });

    const data1 = {
      amount: api_1_data.ammount,
      order_id: api_1_data.order_number,
      currency: "INR",
    };

    await createSlotPayment(data1)
      .then((resp) => {
        const response2 = resp.data;
        if (resp?.status === 200) {
          toast("Please Wait ", {
            autoClose: 500,
            position: toast.POSITION.TOP_RIGHT,
          });
        }

        api_2_data = response2;

        displayRazorpay(api_2_data.data);
      })
      .catch(
        (e) => console.log("resp", e)
        // e.response.status === 400
        //   ? toast("You cannot book your own slot", {
        //       autoClose: 1000,
        //       position: toast.POSITION.TOP_RIGHT,
        //     })
        //   : ""
      );
  }
  useEffect(() => {
    const loadData = () => {
      mentorSlotList({ slug }).then((res) => {
        const response = res.data;
        setBook(response);
      });
    };
    loadData();
  }, [slug]);
  useEffect(() => {
    const loadData = () => {
      gstPayment().then((res) => {
        const response = res.data;
        setGstPayments(response);
      });
    };
    loadData();
  }, []);

  return (
    <div>
      <h4 className="close" onClick={closed}>
        x
      </h4>
      <div className="my-4">
        {book?.map((value) => {
          return (
            <div className="card mb-2 mx-4">
              <div className="card-body">
                <h5 className="card-title font-weight-bold">{value?.name}</h5>
                <p className="card-text font-weight-bold">
                  Start From:
                  {moment(value?.start_date).format("DD/MM/YYYY HH:MM")}
                </p>

                <p className="card-text font-weight-bold">
                  End From:
                  {moment(value?.start_date).format("DD/MM/YYYY HH:MM")}
                </p>
                <p className="card-text font-weight-bold">
                  Price (INR):
                  {value?.price === null ? "0" : value?.price}
                </p>
                <p className="card-text font-weight-bold">
                  Gst Fees: {parseInt(gstPayments?.gst_ammount)}
                  {"%"}
                </p>
                <p className="card-text font-weight-bold">
                  Platform Fees: {parseInt(gstPayments?.platform_fees)}
                  {"%"}
                </p>

                {value?.is_booked === false ? (
                  <button
                    className="mentor-booking"
                    onClick={() => handleClick(value?.id)}
                  >
                    Book Now
                    <ToastContainer />
                  </button>
                ) : (
                  <h1 className="text-center text-danger display-3">
                    Already Booked
                  </h1>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SlotBook;
