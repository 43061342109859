import React, { useEffect, useState } from "react";
import AboutEvent from "../../Component/Events-details/AboutEvent";
import { EventDetails, ApplyEvent } from "../../Api/EventApi";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
const EventsDetails = () => {
  let { slug } = useParams();
  const [detail, setDetail] = useState({});
  const [apply, setApply] = useState({});

  useEffect(() => {
    const loadData = () => {
      EventDetails({ slug }).then((res) => {
        const response = res;

        setDetail(response.data);
      });
    };
    loadData();
  }, [slug]);

  const loadData = async () => {
    const response = await ApplyEvent({ slug });

    setApply(response);
  };

  return (
    <div>
      <Helmet>
        <title>55notout | Job Details</title>
        <meta name="description" content="Events" />
      </Helmet>
      <AboutEvent detail={detail} apply={apply} loadData={loadData} />
    </div>
  );
};

export default EventsDetails;
