import React, { useState, useEffect } from "react";
import "../../Style/OrgDashboard/DashBoard.css";
import OrgContent from "./OrgContent";
import OrgList from "../Organisation/OrgList";
import { updateOrgDetails } from "../../Api/OrgApi";
import { NavLink } from "react-router-dom";
import OrgPersnolData from "./OrgPersnolData";
import { updatePut } from "../../Api/OrgApi";
import Modal from "react-modal";
import { Helmet } from "react-helmet";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import FeaturedListProfile from "../../Component/FeaturedProfile/FeaturedListProfile";
import OrgNav from "../Organisation/OrgNav";
const OrgDashboard = () => {
  const [update, setUpdate] = useState({});
  const [update2, setUpdate2] = useState({});
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    // loadData();
  }
  useEffect(() => {
    const loadData = () => {
      updateOrgDetails().then((res) => {
        const response = res;

        setUpdate(response);
        // setUpdate2(response.data);
      });
    };
    return loadData();
  }, []);

  return (
    <>
      <Helmet>
        <title>55notout | Organisation DashBoard</title>
        <meta name="description" content="Organisation DashBoard" />
      </Helmet>
      <OrgNav />
      <div className="f-profile">
        <h1 className="Personal-detail">Organisation DashBoard</h1>
        <NavLink to="/dashboarddetail">
          <div className="pro-details-w-arrow">
            <h4 className="Pro-details">View Details</h4>
            <img
              className="side-arrow"
              src="Images/SideArrow.svg"
              alt="sideArrow"
            />
          </div>
        </NavLink>
      </div>
      <div className="org-user-details">
        <div className="user-profile-container">
          <img
            className="org-profile"
            src={update?.data?.image}
            alt="profile"
          />
          <img
            className="add-img"
            src="Images/add-item.svg"
            alt="add-item"
            onClick={openModal}
          />
          <div className="edit-profile">
            <h4 className="profile-name">
              {update?.data?.user?.first_name}
              {update?.data?.user?.last_name}
            </h4>
          </div>
          <h4 className="user-gmail">{update?.data?.user?.email}</h4>
          <h4 className="user-phone"> {update?.data?.phone_number}</h4>
          <h4 className="user-gmail">{update?.data?.organization_name}</h4>
          <h4 className="user-phone"> {update?.data?.location.city_name}</h4>
        </div>
        <div className="Side-container-main">
          <div className="Side-Container-items">
            <div className="detail-container">
              <div className="detail-container-main">
                <h4 className="detail-num">
                  {update?.jobs_count === null ? "00" : update?.jobs_count}
                </h4>
                <h4 className="detail-text">Jobs</h4>
              </div>
              <div className="detail-container-main">
                <h4 className="detail-num">
                  {update?.event_count === null ? "00" : update?.event_count}
                </h4>
                <h4 className="detail-text">Events</h4>
              </div>
              <div className="detail-container-main">
                <h4 className="detail-num">
                  {update?.courses_count === null
                    ? "00"
                    : update?.courses_count}
                </h4>
                <h4 className="detail-text">Course</h4>
              </div>
              <div className="detail-container-main">
                <h4 className="detail-num">
                  {update?.product_counts === null
                    ? "00"
                    : update?.product_counts}
                </h4>
                <h4 className="detail-text">Product</h4>
              </div>
              <div className="detail-container-main">
                <h4 className="detail-num">
                  {update?.services_counts === null
                    ? "00"
                    : update?.services_counts}
                </h4>
                <h4 className="detail-text">Service</h4>
              </div>
            </div>
            <div className="d-border"></div>
          </div>
          <OrgContent />
        </div>
      </div>
      <h2 className="Features-talent-profile">Featured Talent Profiles</h2>

      <FeaturedListProfile />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <OrgPersnolData dataP={update?.data} closeModal={closeModal} />
      </Modal>
    </>
  );
};

export default OrgDashboard;
