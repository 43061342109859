import React from "react";
import EventsPosting from "../../Component/EventPosting/EventPosting";
import EventImages from "../../Component/EventPosting/EventImages";
import FeaturesPosting from "../../Component/PostJob/FeaturesPosting";
import { Helmet } from "react-helmet";
import OrgNav from "../../Component/Organisation/OrgNav";
const EventPosting = () => {
  const data2 = {
    title: "Free or Featured Event Listing?",
    free: "Free Event Posting",
    features: "Featured Event Posting",
  };
  return (
    <div>
      <Helmet>
        <title>55notout | Post Events</title>
        <meta name="description" content="Post A New Events" />
      </Helmet>
      <OrgNav />
      <EventsPosting />
    </div>
  );
};

export default EventPosting;
