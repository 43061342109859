import React, { useState, useEffect } from "react";
import "./Search.css";
import { AllEvents } from "../../Api/EventApi";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import moment from "moment";
import { Address, eventCategory } from "../../Api/OrgApi";

import EventListing from "./EventListing";
import { Calendar } from "react-date-range";
import FilterImage from "../../Image/filters.png";
import Drawer from "@mui/material/Drawer";
// import { DateRangePicker } from "react-date-range";
import { addDays, subDays } from "date-fns";
import { DateRangePicker, defaultInputRanges } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
// import { Calendar } from "react-date-range";
function valuetext(value) {
  return `${value}°C`;
}

const EventFilter = () => {
  const [list, setList] = useState([]);
  const [appDrawer, setAppDrawer] = useState(false);
  const [address, setAddress] = useState([]);
  const [category, setCategory] = useState([]);
  const [filterValue, setFilterValue] = React.useState({});
  const [filterValuemin, setFilterValuemax] = React.useState({});
  const [skill, setSkill] = useState([]);

  const [value, setValue] = React.useState([0, 100000]);
  const [state, setState] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: addDays(new Date(), 1),
      key: "selection",
    },
  ]);
  const handleOnChange = (ranges) => {
    const { selection } = ranges;

    moment(selection?.endDate).format("ll");

    const sendData = {
      start_date: moment(selection?.startDate).format("ll"),
      end_date: moment(selection?.endDate).format("ll"),
    };

    onChange(selection);

    setState([selection]);
    setFilterValue({ ...filterValue, ...sendData });
  };
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  //   const sendData = {
  //     salary_min: value[0],
  //     salary_max: value[1],
  //   };

  //   setFilterValuemax({ ...filterValuemin, ...sendData });
  // };

  const handleChange2 = (e, newValue) => {
    const { name, value } = e.target;

    setFilterValue({ ...filterValue, [name]: value });
  };
  useEffect(() => {
    const loadData = () => {
      AllEvents(filterValue).then((res) => {
        setList(res);
      });
    };
    loadData();
  }, [filterValue]);

  useEffect(() => {
    const loadData = () => {
      Address().then((res) => {
        const response = res;
        const data = response.data;
        // let temp = { ...edit, location: data[0]?.id + "" };
        // setEdit(temp);
        setAddress(data);
      });
    };
    loadData();
  }, []);
  useEffect(() => {
    const loadData = () => {
      eventCategory().then((res) => {
        const response = res;
        setCategory(response);
      });
    };
    loadData();
  }, []);
  const handleOpen = () => {
    setAppDrawer(!appDrawer);
  };
  const onChange = (ranges) => {};
  const handleClear = () => {
    setFilterValue({
      title: "",
      job_type: "",
      job_mode: "",
      location: "",
      is_featured_post: "",
      key_skills: "",
      // salary_min: "",
    });
  };

  return (
    <>
      <img
        src={FilterImage}
        alt=""
        onClick={handleOpen}
        className="responsiveFilterIcon"
        // style={{ display: "none" }}
      />
      <div className="SearchMainContainer">
        <div className="filterSection">
          <div className="filter-section">
            <h4 className="filter">Filter</h4>
            <span className="clear" onClick={handleClear}>
              Clear
            </span>
          </div>

          <div>
            <h4 className="filter"> Title</h4>
            <FormControl fullWidth>
              <TextField
                // id="outlined-basic"
                label="Event Title"
                variant="outlined"
                // className="inputBox"
                value={filterValue?.title}
                name="title"
                onChange={handleChange2}
                id="Book Name"
              />
            </FormControl>
          </div>
          <div>
            <h4 className="filter">Category</h4>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="category"
                label="Selecttttttttttttt"
                onChange={handleChange2}
                defaultValue=""
                className="selectedValue"
              >
                {category?.map((value, key) => {
                  return (
                    <MenuItem value={value?.id} className="selectedValue">
                      {value?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>

          <div>
            <h4 className="filter"> Location</h4>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="location"
                label="Selecttttttttttttt"
                onChange={handleChange2}
                defaultValue=""
              >
                {address.map((value, key) => {
                  return (
                    <MenuItem value={value?.id}>{value?.city_name}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>

          <div>
            <h4 className="filter">Free</h4>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                //   value={age}
                name="is_featured_post"
                label="Selecttttttttttttt"
                onChange={handleChange2}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <h4 className="filter"> Featured</h4>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                //   value={age}
                name="is_featured_post"
                label="Selecttttttttttttt"
                onChange={handleChange2}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <h4 className="filter">Date</h4>
            <div className="DatePicker">
              <DateRangePicker
                onChange={handleOnChange}
                showSelectionPreview={false}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={state}
                direction="vertical"
                // showDateDisplay={true}
                // staticRanges={[0]}
                inputRanges={[]}
                className="datePicker"
              />
            </div>
          </div>
        </div>
        <div className="listing">
          {/* <FilterCategory /> */}
          {/* Mobile Application */}
          <Drawer
            anchor={"left"}
            open={appDrawer}
            onClose={() => setAppDrawer(false)}
            // className="filterDrawer"
          >
            <div className="filterDrawer">
              <div className="filter-section">
                <h4 className="filter">Filter</h4>
                <span className="clear">Clear</span>
              </div>

              <div>
                <h4 className="filter"> Title</h4>
                <FormControl fullWidth>
                  <TextField
                    // id="outlined-basic"
                    label="Event Title"
                    variant="outlined"
                    // className="inputBox"
                    name="title"
                    onChange={handleChange2}
                    id="Book Name"
                  />
                </FormControl>
              </div>
              <div>
                <h4 className="filter">Category</h4>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="category"
                    label="Selecttttttttttttt"
                    onChange={handleChange2}
                    defaultValue=""
                    className="selectedValue"
                  >
                    {category?.map((value, key) => {
                      return (
                        <MenuItem value={value?.id} className="selectedValue">
                          {value?.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>

              <div>
                <h4 className="filter"> Location</h4>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="location"
                    label="Selecttttttttttttt"
                    onChange={handleChange2}
                    defaultValue=""
                    className="selectedValue"
                  >
                    {address.map((value, key) => {
                      return (
                        <MenuItem value={value?.id} className="selectedValue">
                          {value?.city_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>

              <div>
                <h4 className="filter">Free</h4>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    //   value={age}
                    name="is_featured_post"
                    label="Selecttttttttttttt"
                    onChange={handleChange2}
                    className="selectedValue"
                  >
                    <MenuItem value="true" className="selectedValue">
                      Yes
                    </MenuItem>
                    <MenuItem value="false" className="selectedValue">
                      No
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div>
                <h4 className="filter"> Featured</h4>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    //   value={age}
                    name="is_featured_post"
                    label="Selecttttttttttttt"
                    onChange={handleChange2}
                    className="selectedValue"
                  >
                    <MenuItem value="true" className="selectedValue">
                      Yes
                    </MenuItem>
                    <MenuItem value="false" className="selectedValue">
                      No
                    </MenuItem>
                  </Select>
                </FormControl>
                <div>
                  <h4 className="filter">Date</h4>
                  <div className="DatePicker">
                    <DateRangePicker
                      onChange={handleOnChange}
                      showSelectionPreview={false}
                      moveRangeOnFirstSelection={false}
                      months={1}
                      ranges={state}
                      direction="vertical"
                      // showDateDisplay={true}
                      // staticRanges={[0]}
                      inputRanges={[]}
                      className="datePicker"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Drawer>
          <EventListing list={list} />
        </div>
      </div>
    </>
  );
};

export default EventFilter;
