import React from "react";

const SignupBtn = () => {
  return (
    <div>
      <button className="mentor-Btn">SIGNUP NOW</button>
    </div>
  );
};
// onClick={() => window.open(orgIcon.url)}
export default SignupBtn;
